import { ModalName, ModalsMapType } from 'store/modals/types';

import WaitingModal from 'components/Modals/WaitingModal';
import VideoInstructionModal from 'components/Modals/VideoInstructionModal';
import UniversalModal from 'components/Modals/UniversalModal';
import RestoreSubscriptionModal from 'components/Modals/RestoreSubscriptionModal';
import ReminderSuccessModal from 'components/Modals/ReminderSuccessModal';
import ReminderOfferModal from 'components/Modals/ReminderOfferModal';
import ReasonAbandonModal from 'components/Modals/ReasonAbandonModal';
import PauseSubscriptionModal from 'components/Modals/PauseSubscriptionModal';
import ErrorModal from 'components/Modals/ErrorModal';
import DiscountOfferModal from 'components/Modals/DiscountOfferModal';
import ConfirmCancellationModalContainer from 'components/Modals/ConfirmCancellationModalContainer';
import CancellationReasonModal from 'components/Modals/CancellationReasonModal';

export const MODAL_LIST: ModalsMapType = {
    [ModalName.confirmCancelWorkout]: ConfirmCancellationModalContainer,
    [ModalName.workoutReasonAbandon]: ReasonAbandonModal,
    [ModalName.universalModal]: UniversalModal,
    [ModalName.videoInstructionModal]: VideoInstructionModal,
    [ModalName.cancellationReasonModal]: CancellationReasonModal,
    [ModalName.discountOfferModal]: DiscountOfferModal,
    [ModalName.pauseSubscriptionModal]: PauseSubscriptionModal,
    [ModalName.waitingModal]: WaitingModal,
    [ModalName.reminderOfferModal]: ReminderOfferModal,
    [ModalName.restoreSubscriptionModal]: RestoreSubscriptionModal,
    [ModalName.reminderSuccessModal]: ReminderSuccessModal,
    [ModalName.errorModal]: ErrorModal,
};
