export const CANCEL_SUBSCRIPTION = 'SUBSCRIPTION/CANCEL_SUBSCRIPTION';
export const FETCH_USER_SUBSCRIPTIONS = 'SUBSCRIPTION/FETCH_USER_SUBSCRIPTIONS';
export const SET_USER_SUBSCRIPTIONS = 'SUBSCRIPTION/SET_USER_SUBSCRIPTIONS';
export const RESET_SUBSCRIPTIONS = 'SUBSCRIPTION/RESET_SUBSCRIPTIONS';
export const SET_FETCHING_STATUS = 'SUBSCRIPTION/SET_FETCHING_STATUS';
export const SEND_SUBSCRIPTION_FEEDBACK = 'SUBSCRIPTION/SEND_SUBSCRIPTION_FEEDBACK';
export const CHANGE_SUBSCRIPTION = 'SUBSCRIPTION/CHANGE_SUBSCRIPTION';
export const RETURN_SUBSCRIPTION = 'SUBSCRIPTION/RETURN_SUBSCRIPTION';
export const FETCH_DISCOUNT_SUBSCRIPTION = 'SUBSCRIPTION/FETCH_DISCOUNT_SUBSCRIPTION';
export const SET_IS_DISCOUNT_LOADED = 'SUBSCRIPTION/SET_IS_DISCOUNT_LOADED';
export const RESTORE_SUBSCRIPTION = 'SUBSCRIPTION/RESTORE_SUBSCRIPTION';
export const RESUME_SUBSCRIPTION = 'SUBSCRIPTION/RESUME_SUBSCRIPTION';
export const PAUSE_SUBSCRIPTION = 'SUBSCRIPTION/PAUSE_SUBSCRIPTION';
export const SET_REMINDER = 'SUBSCRIPTION/SET_REMINDER';
