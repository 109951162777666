/* eslint-disable max-lines */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { EXERCISE_AUDIO_CONFIG, LAUNCH_AUDIO_CONFIG, DEFAULT_VOLUME, VOLUME_KEY_IN_LS } from '../constants';

import useScreenfull from 'hooks/useScreenfull';
import { useWorkoutAudio, useCountdown, useWorkoutContext } from '../hooks';

import isMobile from 'helpers/isMobile';
import { getInitialVolume } from '../helpers';

import { Play, Cross, List } from 'components/Icon';

import * as S from '../styled';

import { ExerciseType } from 'types/exercise';
import { ExercisePlayerPropsTypes, audioOptionsType } from '../types';

import {
    trackFullScreenButtonClick,
    trackMuteButtonClick,
    trackTrackPauseButtonClick,
    trackTrackPlayButtonClick,
    trackVideoWatched,
} from 'analytics/trackers/exercise';
import WorkoutProgressInfo from './WorkoutProgressInfo';
import SidebarExercises from './SidebarExercises';
import BottomBar from './Player/BottomBar';
import Player from './Player';
import LaunchCountdown from './LaunchCountdown';

const isNotMobile = !isMobile.any();

const ExercisePlayer = ({ playlistPosition, exerciseTime, onProgress, isStop = false }: ExercisePlayerPropsTypes) => {
    const [displayExercises, setDisplayExercisesStatus] = useState(false);
    const playerRef = useRef(null);

    const [volume, setVolume] = useState(getInitialVolume());

    const { requestFullscreen, isFullscreen, exit } = useScreenfull();
    const { handleExerciseClick, toNextExercise, exerciseData, isComplete } = useWorkoutContext();

    const isAudioAvailable = !isMobile.iOS();
    const isMute = volume === 0;

    const { audios, stopAll } = useWorkoutAudio({ volume, isAvailable: isAudioAvailable });

    const handleAudioByTimer = (left: number, audioOptions: audioOptionsType) => {
        const key = audioOptions[left];

        if (!key) return;

        const audioType = audios[key];

        audioType?.play?.();
    };

    const handleProgress = (left: number) => {
        const progressTime = exerciseTime - left;
        const progressPercent = (progressTime / exerciseTime) * 100;

        const progressData = {
            time: progressTime,
            percents: progressPercent,
        };

        onProgress(exerciseData.id, progressData);
    };

    const exerciseTimer = useCountdown({
        autostart: false,
        time: exerciseTime,
        onTick: (left: number) => {
            if (!isMute) handleAudioByTimer(left, EXERCISE_AUDIO_CONFIG);

            handleProgress(left);
        },
        onEnd: () => {
            trackVideoWatched(exerciseData.id, playlistPosition);
            exerciseTimer.reset();
            launchTimer.reset();
            toNextExercise();
        },
    });

    const launchTimer = useCountdown({
        time: 6,
        onEnd: exerciseTimer.start,
        onTick: (left: number) => {
            if (!isMute) handleAudioByTimer(left, LAUNCH_AUDIO_CONFIG);
        },
    });

    useEffect(() => {
        if (isStop) {
            exerciseTimer.stop();
            launchTimer.stop();
        }
    }, [isStop]);

    useEffect(() => {
        exerciseTimer.reset();
        launchTimer.reset();
    }, [exerciseData]);

    useEffect(() => {
        isComplete && exit();
    }, [isComplete]);

    const onFullscreen = () => {
        trackFullScreenButtonClick(exerciseData.id, playlistPosition);
        playerRef.current && requestFullscreen(playerRef.current);
    };

    const handleMute = () => {
        trackMuteButtonClick(exerciseData.id, playlistPosition);

        const volumeValue = isMute ? DEFAULT_VOLUME : 0;

        localStorage.setItem(VOLUME_KEY_IN_LS, String(volumeValue));

        setVolume(volumeValue);
    };

    const handleVolumeChange = (e: ChangeEvent<HTMLInputElement>) => {
        localStorage.setItem(VOLUME_KEY_IN_LS, e.target.value);

        setVolume(Number(e.target.value));
    };

    const isPlaying = Boolean(launchTimer.isStart || exerciseTimer.isStart);

    const handlePlayButton = () => {
        stopAll();

        const trackFunction = isPlaying ? trackTrackPauseButtonClick : trackTrackPlayButtonClick;

        trackFunction(exerciseData.id, playlistPosition);

        if (launchTimer.isStart) {
            launchTimer.stop();

            return;
        }

        if (launchTimer.left > 0) {
            launchTimer.toggle();

            return;
        }

        exerciseTimer.toggle();
    };

    const toggleDisplayExerciseList = () => {
        setDisplayExercisesStatus(!displayExercises);
    };

    const handleSelectExercise = (selectedExercise: ExerciseType) => {
        handleExerciseClick(selectedExercise);
        setDisplayExercisesStatus(false);
    };

    return (
        <S.PlayerWrapper>
            <S.Player ref={playerRef}>
                {isFullscreen && (
                    <>
                        <S.ProgressFullscreen>
                            <WorkoutProgressInfo />
                        </S.ProgressFullscreen>
                        <S.ExercisesToggle onClick={toggleDisplayExerciseList}>
                            <List />
                        </S.ExercisesToggle>
                        <S.ExerciseListWrapper show={displayExercises}>
                            <S.CloseExercises onClick={toggleDisplayExerciseList}>
                                <Cross />
                            </S.CloseExercises>
                            <SidebarExercises
                                handleExerciseClick={handleSelectExercise}
                                currentExerciseId={exerciseData.id}
                            />
                        </S.ExerciseListWrapper>
                    </>
                )}

                <S.PlayButton onClick={handlePlayButton}>
                    {!isPlaying && (
                        <S.PlayIcon>
                            <Play color="text-main" />
                        </S.PlayIcon>
                    )}
                </S.PlayButton>

                <BottomBar
                    onMute={handleMute}
                    isMute={isMute}
                    onVolumeChange={handleVolumeChange}
                    onFullscreen={onFullscreen}
                    timeLeft={exerciseTimer.left}
                    isDesktop={isNotMobile}
                    volume={volume}
                    isFullscreen={isFullscreen}
                    isAudioAvailable={isAudioAvailable}
                />

                <Player
                    url={exerciseData.media_url}
                    onReady={launchTimer.start}
                    playing={isPlaying}
                    key={exerciseData.media_url}
                />

                {launchTimer.left > 0 && !exerciseTimer.isStart && (
                    <S.CountDownOverlay>
                        <LaunchCountdown time={launchTimer.left} />
                    </S.CountDownOverlay>
                )}
            </S.Player>
        </S.PlayerWrapper>
    );
};

export default ExercisePlayer;
