import { store } from 'store/configureStore';

import { logout, setAuthToken } from './actions';

import { AUTH_TOKEN_NAME } from 'constants/api';

import { deleteCookie, setCookie } from 'helpers/cookie';

export const logOutOperation = () => {
    store.dispatch(logout());
};

export const setTokenOperation = (token: string) => {
    setCookie(AUTH_TOKEN_NAME, token);
    store.dispatch(setAuthToken(token));
};

export const deleteTokenOperation = () => {
    deleteCookie(AUTH_TOKEN_NAME);
    store.dispatch(setAuthToken(null));
};
