import { Box, Button } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { SUBSCRIPTION_LABEL_STATES } from '../constants';

import SubscriptionStatusLabel from './subComponents/SubscriptionLabel';
import SubscriptionCardHeader from './subComponents/SubscriptionCardHeader';
import PeriodRow from './subComponents/PeriodRow';

import { IExpiredSubscription } from '../types';

import SubscriptionCardWrapper from './SubscriptionCardWrapper';

const ExpiredSubscription = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    isTrial,
    isMainSubscription,
    productCode,
    onRestoreSubscription,
    isAlreadyRestored,
}: IExpiredSubscription) => {
    const { t } = useTranslation();

    return (
        <SubscriptionCardWrapper>
            <Box mb={20}>
                <SubscriptionCardHeader
                    isMainSubscription={isMainSubscription}
                    productCode={productCode}
                    icon={tabIcon}
                    heading={heading}
                />
                <SubscriptionStatusLabel state={SUBSCRIPTION_LABEL_STATES.EXPIRED} />
                <PeriodRow period={subscriptionPeriod} color="text-secondary" isTrial={isTrial} />
            </Box>
            {isMainSubscription && !isAlreadyRestored && (
                <Button
                    text={t('subscription.text.restore')}
                    dataLocator="restoreSub"
                    onClick={onRestoreSubscription}
                />
            )}
        </SubscriptionCardWrapper>
    );
};

export default ExpiredSubscription;
