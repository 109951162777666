import * as actionTypes from './actionTypes';

import { INavigate } from './types';

export const navigateTo = ({ url, options }: INavigate) => {
    return {
        type: actionTypes.NAVIGATE,
        payload: {
            url: url,
            options: options,
        },
    } as const;
};

export const navigateBack = (amount?: number) => {
    return {
        type: actionTypes.NAVIGATE_BACK,
        payload: {
            amount: amount,
        },
    } as const;
};
