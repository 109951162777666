import { useEffect } from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';
import { fetchSubscriptions } from 'store/subscriptions/actions';

import { useDispatch, useSelector } from 'hooks';

import { ISubscription } from 'types/subscription';

const useSubscriptions = (): { subscriptions: ISubscription[]; isLoading: boolean } => {
    const dispatch = useDispatch();
    const { subscriptions, isLoading } = useSelector(selectSubscriptions);

    useEffect(() => {
        dispatch(fetchSubscriptions());
    }, []);

    return {
        subscriptions,
        isLoading,
    };
};

export default useSubscriptions;
