import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { HEADER_HEIGHT } from 'constants/theme';

import { color } from 'theme/selectors';

const StyledContainer = styled(ToastContainer)`
    &&&.Toastify__toast-container {
        padding: 0 16px;

        &--top-right {
            top: calc(${HEADER_HEIGHT} + 16px);
            right: 0;
        }
    }

    .Toastify {
        &__toast {
            min-height: 48px;
            border-radius: 16px;
            padding: 6px;
            box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
            background-color: ${color('surface-main')};
            border: 1px solid ${color('border-default')};
        }

        &__toast-icon {
            margin-right: 10px;
        }

        &__toast--success {
            background-color: ${color('surface-secondary')};
            border: 1px solid ${color('surface-default')};

            svg {
                fill: ${color('success-default')};
            }
        }

        &__toast--error {
            background-color: ${color('critical-surface')};
            border: 1px solid ${color('critical-highlight')};

            svg {
                fill: ${color('critical-default')};
            }
        }

        &__toast-body {
            color: ${color('text-secondary')};
        }
    }
`;

export default StyledContainer;
