/* eslint-disable max-lines */
import { Text } from 'wikr-core-components';
import styled from 'styled-components';

import { borderRadius, color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const WorkoutFilter = styled.div`
    border-radius: 12px;
    background: ${color('surface-default')};
    padding: 24px 16px;
    margin: 24px -16px 0;

    ${mediaQueries.laptop} {
        padding: 32px;
        margin: 32px auto 0;
    }
`;

export const WorkoutBuilderForm = styled.div`
    max-width: 600px;
    padding: 0 16px;

    ${mediaQueries.laptop} {
        padding: 0;
    }
`;

export const FormSubtitle = styled(Text)`
    margin-bottom: 12px;

    ${mediaQueries.laptop} {
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
    }
`;

export const Selected = styled.div`
    display: flex;
    align-items: center;
`;

export const SelectedIcon = styled.div<{ isActive?: boolean }>`
    background: ${({ isActive, theme }) => color(isActive ? 'primary-default' : 'surface-default')({ theme })};
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-radius: ${borderRadius('medium')};
    margin-right: 12px;
`;

export const EquipmentsButton = styled.button`
    opacity: 0.6;
    background: ${color('surface-hovered')};
    width: 100%;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: ${borderRadius('medium')};
    min-height: 64px;
    justify-content: space-between;
    cursor: pointer;
    pointer-events: none;
`;

export const DurationButton = styled.button`
    position: relative;
    cursor: pointer;

    :not(:last-child) {
        margin-right: 8px;
    }

    :hover path:first-child {
        fill: ${color('primary-default')};
    }

    :hover p {
        color: ${color('text-main')};
    }

    ${mediaQueries.laptop} {
        :not(:last-child) {
            margin-right: 16px;
        }
    }
`;

export const ClockText = styled(Text)<{ isActive?: boolean }>`
    color: ${({ isActive, theme }) => color(isActive ? 'text-main' : 'text-secondary')({ theme })};

    position: absolute;
    top: 12px;
    left: 12px;
    width: 24px;
`;

export const WorkoutFilterItem = styled.div`
    margin-bottom: 24px;

    ${mediaQueries.laptop} {
        margin-bottom: 32px;
    }
`;

export const WorkoutSwitcherBlock = styled.div`
    padding: 20px 16px 20px 20px;
    background: ${color('surface-hovered')};
    border-radius: 12px;
    margin-bottom: 32px;
`;

export const WorkoutSwitcher = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const WorkoutDifficulty = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
`;

export const LevelButton = styled.button<{ isActive: boolean }>`
    background-color: ${({ isActive, theme }) => color(isActive ? 'primary-default' : 'surface-hovered')({ theme })};
    cursor: pointer;
    width: 100%;
    max-width: 168px;
    max-height: 44px;
    padding: 12px 16px;
    border-radius: ${borderRadius('medium')};
    display: inline-flex;
    align-items: center;

    &:hover {
        background: ${color('primary-default')};
    }

    :not(:last-child) {
        margin-right: 12px;
    }
`;
