import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { borderRadius, color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const WorkoutPreviewContainer = styled.div`
    max-width: 600px;
    padding: 0 16px;

    ${mediaQueries.laptop} {
        padding: 0;
    }
`;

export const TitleContainer = styled.div`
    margin-bottom: 24px;

    ${mediaQueries.laptop} {
        margin-bottom: 32px;
    }
,`;

export const Header = styled.div`
    border-radius: ${borderRadius('medium')};
    background: ${color('surface-default')};
    margin-bottom: 24px;
    padding: 16px 24px;
`;

export const WorkoutsWrapper = styled(Box)`
    padding: 32px 16px;
    margin: 0 -16px;

    ${mediaQueries.laptop} {
        padding: 32px;
        margin: 0;
    }
`;

export const PreviewImage = styled.img`
    max-width: 140px;
    margin: 0 auto;
    display: block;

    ${mediaQueries.laptop} {
        max-width: 240px;
    }
`;

export const WorkoutData = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

export const WorkoutDataItem = styled.div`
    position: relative;

    :first-child:after {
        content: '';
        position: absolute;
        top: 4px;
        right: 0;
        width: 1px;
        height: 24px;
        border-radius: 24px;
        background-color: rgba(255, 255, 255, 0.05);
    }
`;
