/* eslint-disable quotes */
export enum ISOLanguages {
    AF = 'af',
    SQ = 'sq',
    AR = 'ar',
    EU = 'eu',
    BG = 'bg',
    BE = 'be',
    CA = 'ca',
    ZH = 'zh',
    HR = 'hr',
    CS = 'cs',
    DA = 'da',
    NL = 'nl',
    EN = 'en',
    ET = 'et',
    FO = 'fo',
    FA = 'fa',
    FI = 'fi',
    FR = 'fr',
    GD = 'gd',
    DE = 'de',
    EL = 'el',
    HE = 'he',
    HI = 'hi',
    HU = 'hu',
    IS = 'is',
    ID = 'id',
    IT = 'it',
    JA = 'ja',
    KO = 'ko',
    LV = 'lv',
    LT = 'lt',
    MK = 'mk',
    MT = 'mt',
    NO = 'no',
    PL = 'pl',
    PT = 'pt',
    RM = 'rm',
    RO = 'ro',
    RU = 'ru',
    SZ = 'sz',
    SR = 'sr',
    SK = 'sk',
    SL = 'sl',
    SB = 'sb',
    ES = 'es',
    SX = 'sx',
    SV = 'sv',
    TH = 'th',
    TS = 'ts',
    TN = 'tn',
    TR = 'tr',
    UK = 'uk',
    UR = 'ur',
    VE = 've',
    VI = 'vi',
    XH = 'xh',
    JI = 'ji',
    ZU = 'zu',
    CH = 'ch',
}

export enum workoutFeedback {
    easy = 'easy',
    perfect = 'perfect',
    hard = 'hard',
}
