import styled from 'styled-components';

import { viewPortWithoutHeader } from 'theme/selectors';

export const ErrorBoundaryWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    min-height: ${viewPortWithoutHeader()};
    text-align: center;
`;
