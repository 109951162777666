/* eslint-disable max-lines */
import { Button, Image, Text } from 'wikr-core-components';
import { ANDROID } from 'sentry-utils';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import QRCode from 'qrcode.react';

import config from 'config';

import { ModalName } from 'store/modals/types';
import { closeModal, openModal } from 'store/modals/actions';
import { createProcessingSelector } from 'store/loading/selectors';
import { selectDeepLink } from 'store/deepLink/selectors';
import { GET_DEEP_LINK } from 'store/deepLink/actionTypes';
import { getDeepLinkRequest } from 'store/deepLink/actions';

import { HIDE_FRONT_CHAT_VALUE } from 'constants/frontChat';

import { showFrontChat } from 'services/FrontChat';

import { useScreenLoadTracker, useSelector } from 'hooks';

import Skeleton from 'components/Skeleton';
import { Video } from 'components/Icon';

import * as S from './styled';

import phone from './img/phone.webp';

import { getContent } from './config';

import { isDesktopDevice } from 'theme/verifiers';
import {
    trackSimpleLoginChatClick,
    trackSimpleLoginHelpButtonClick,
    trackSimpleLoginMainButtonClick,
    trackSimpleLoginPageLoad,
} from 'analytics/trackers/installApp';

export const LoginInstructionSimple = () => {
    useScreenLoadTracker(() => trackSimpleLoginPageLoad());

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const deepLinkUrl = useSelector(selectDeepLink);
    const isLoading: boolean = useSelector(createProcessingSelector([GET_DEEP_LINK]));

    const instructions = getContent();

    useEffect(() => {
        if (!deepLinkUrl) dispatch(getDeepLinkRequest());
    }, []);

    const displayFrontChat = () => {
        trackSimpleLoginChatClick();
        showFrontChat();
    };

    const handleDownloadApp = () => {
        trackSimpleLoginMainButtonClick();

        // Workaround for window.open method since it doesn't work for Safari browser
        Object.assign(document.createElement('a'), {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: deepLinkUrl,
        }).click();
    };

    const onCloseModal = () => {
        dispatch(closeModal());
    };

    const displayModal = (device: string) => {
        const title = device === ANDROID ? 'basics.androidHelpTitle' : 'basics.iphoneHelpTitle';

        trackSimpleLoginHelpButtonClick();
        dispatch(openModal(ModalName.videoInstructionModal, { onClose: onCloseModal, title, device }));
    };

    const isFrontChatAvailable = config.HIDE_FRONT_CHAT !== HIDE_FRONT_CHAT_VALUE;

    return (
        <>
            {isDesktopDevice ? (
                <S.Container>
                    <Text text={t('loginInstructionSimple.title')} type="h3" mb={32} />
                    <S.InstructionBlock>
                        <S.InstructionsWrapper>
                            {instructions.data.map((instruction, index) => (
                                <S.InstructionContainer
                                    key={index}
                                    backgroundColor="surface-default"
                                    paddingX={16}
                                    paddingBottom={16}
                                    paddingTop={16}
                                    mb={16}
                                    borderRadius={12}
                                >
                                    <S.InstructionNumberContainer>
                                        <S.InstructionNumber>
                                            <Text type="small-text" bold color="text-main">
                                                {index + 1}
                                            </Text>
                                        </S.InstructionNumber>
                                    </S.InstructionNumberContainer>
                                    <Text text={t(instruction.text)} />
                                </S.InstructionContainer>
                            ))}
                        </S.InstructionsWrapper>
                        <S.ImageWrapper>
                            <Image maxWidth={172} src={phone} alt="phone" />
                            <S.DownloadWrapper>
                                <S.ScanQrCodeWrapper>
                                    <S.QRWrapper>
                                        {isLoading ? (
                                            <Skeleton borderRadius={8} width={192} height={192} />
                                        ) : (
                                            <QRCode value={deepLinkUrl} />
                                        )}
                                    </S.QRWrapper>
                                </S.ScanQrCodeWrapper>
                            </S.DownloadWrapper>
                        </S.ImageWrapper>
                    </S.InstructionBlock>
                    <S.VideoBlock>
                        <Text text={t('loginInstructionSimple.videoTitle')} color="text-secondary" bold />
                        <S.LinksWrapper>
                            <S.Link onClick={() => displayModal('iPhone')}>
                                <Video />
                                <S.LinkText text={t('basics.iphoneHelp')} color="text-main" />
                            </S.Link>
                            <S.Link onClick={() => displayModal('Android')}>
                                <Video />
                                <S.LinkText text={t('basics.androidHelp')} color="text-main" />
                            </S.Link>
                        </S.LinksWrapper>
                    </S.VideoBlock>
                    {isFrontChatAvailable && (
                        <Text type="small-text" center color="text-secondary">
                            <Trans
                                i18nKey="loginInstructionSimple.subtitle"
                                components={{ Button: <S.FrontChatButton onClick={displayFrontChat} /> }}
                            />
                        </Text>
                    )}
                </S.Container>
            ) : (
                <S.ButtonContainer>
                    <Button
                        withPulsating
                        dataLocator="deeplinkBtn"
                        text={t('main.button')}
                        mb={24}
                        onClick={handleDownloadApp}
                    />
                    {isFrontChatAvailable && (
                        <Text type="small-text" center color="text-secondary">
                            <Trans
                                i18nKey="loginInstructionSimple.subtitle"
                                components={{ Button: <S.FrontChatButton onClick={displayFrontChat} /> }}
                            />
                        </Text>
                    )}
                </S.ButtonContainer>
            )}
        </>
    );
};
