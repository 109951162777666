import sexPlan from 'assets/images/products/sx_plan.webp';
import morningPlan from 'assets/images/products/morning_plan.webp';
import morningMealBundle from 'assets/images/products/morning_meal.webp';
import mealPlan from 'assets/images/products/meal_plan.webp';
import absSexBundle from 'assets/images/products/abs_sx.webp';
import absPlan from 'assets/images/products/abs_plan.webp';

import { UpsellSubscriptionProductType } from 'types/subscription';

export enum ProductCodes {
    meal_plan__challenge_7_min = 'meal_plan__challenge_7_min',
    meal_plan = 'meal_plan',
    challenge_7_min = 'challenge_7_min',
    challenge_6_packs__challenge_sex = 'challenge_6_packs__challenge_sex',
    challenge_6_packs = 'challenge_6_packs',
    challenge_sex = 'challenge_sex',
}

export const PRODUCTS_THUMBNAILS = {
    [ProductCodes.meal_plan__challenge_7_min]: morningMealBundle,
    [ProductCodes.meal_plan]: mealPlan,
    [ProductCodes.challenge_7_min]: morningPlan,
    [ProductCodes.challenge_6_packs__challenge_sex]: absSexBundle,
    [ProductCodes.challenge_6_packs]: absPlan,
    [ProductCodes.challenge_sex]: sexPlan,
};

export const MOCKED_PRODUCTS: Record<string, UpsellSubscriptionProductType> = {
    meal_plan__challenge_7_min: {
        content_id: 'One-month upsell subscription (10.99) CRM offer (bundle_meal_7min)',
        product_code: 'meal_plan__challenge_7_min',
        start_price: 1999,
        actual_price: 1099,
        discount: 45,
        ltv: 20,
        solid_id: '9a23d1ae-3ada-4768-8742-1da25bbd9819',
    },
    meal_plan: {
        content_id: 'One-month upsell subscription (8.99) CRM offer (meal_plan)',
        product_code: 'meal_plan',
        start_price: 2200,
        actual_price: 899,
        discount: 60,
        ltv: 16,
        solid_id: 'd6953fde-2bf3-41c2-8c47-8083bc6266cc',
    },
    challenge_7_min: {
        content_id: 'One-month upsell subscription (8.99) CRM offer (7min_work)',
        product_code: 'challenge_7_min',
        start_price: 2200,
        actual_price: 899,
        discount: 60,
        ltv: 16,
        solid_id: 'a964c1e5-7714-4cc7-9bda-345f11a96fee',
    },
    challenge_6_packs__challenge_sex: {
        content_id: 'One-month upsell subscription (10.99) CRM offer (bundle 6packs+sex)',
        product_code: 'challenge_6_packs__challenge_sex',
        start_price: 1999,
        actual_price: 1099,
        discount: 45,
        ltv: 20,
        solid_id: '6c815a68-0e59-4377-9c9c-f85dcfa4f1c3',
    },
    challenge_6_packs: {
        content_id: 'One-month upsell subscription (8.99) CRM offer (6packs)',
        product_code: 'challenge_6_packs',
        start_price: 2200,
        actual_price: 899,
        discount: 60,
        ltv: 16,
        solid_id: '2cd6862d-a373-4fd1-a2bf-9a10c99c8978',
    },
    challenge_sex: {
        content_id: 'One-month upsell subscription (8.99) CRM offer (better_sex)',
        product_code: 'challenge_sex',
        start_price: 2200,
        actual_price: 899,
        discount: 60,
        ltv: 16,
        solid_id: '804e4ebe-2772-4306-b46f-7e4b1a56c0e8',
    },
};
