import { useTranslation } from 'react-i18next';

import { PRIVATE } from 'router/routes';

import { EmailIcon } from 'components/Icon';

import { trackChangeEmailClick } from 'analytics/trackers/accountSettings';

export const getInputsData = (email: string) => {
    const { t } = useTranslation();

    return [
        {
            field: 'email',
            icon: () => <EmailIcon />,
            value: email,
            label: t('input.email.label'),
            buttonRoute: PRIVATE.CHANGE_EMAIL.path,
            track: trackChangeEmailClick,
            dataLocator: 'changeEmailButton',
        },
    ];
};
