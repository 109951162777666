import { compareDates, getToday } from 'helpers/date';

import { ISubscription, ITrialUpcomingProduct } from 'types/subscription';
import { Product } from 'types/product';

const getUpcomingProduct = ({
    product,
    upcoming_product,
    is_trial,
    expired_date,
}: ISubscription): Product | ITrialUpcomingProduct | null => {
    if (upcoming_product && compareDates(upcoming_product.started_from, getToday()) > 0) return upcoming_product;

    if (is_trial) {
        return {
            started_from: expired_date,
            amount: product.amount,
            currency: product.currency,
            billing_period: {
                value: product.subscription_period,
                unit: 'day',
            },
        };
    }

    return null;
};

export default getUpcomingProduct;
