import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import * as S from '../styled';

const SubscriptionCardAdditionalNote = () => {
    const { t } = useTranslation();

    return (
        <S.SubscriptionCardAdditionalNote>
            <Text
                type="small-text"
                dataLocator="upsellSubscriptionNote"
                text={t('subscriptionCard.upsellSubscription.note.text')}
                mb={16}
            />
        </S.SubscriptionCardAdditionalNote>
    );
};

export default SubscriptionCardAdditionalNote;
