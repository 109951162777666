import styled, { css } from 'styled-components';

import { IContentModal, zIndexTypes } from './types';

import { color } from 'theme/selectors';
import MediaQueries from 'theme/mediaQueries';

const fullscreenContent = css`
    position: fixed;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    transform: translateX(-50%);

    ${MediaQueries.tablet} {
        width: calc(100% - 32px);
        max-width: 432px;
        height: auto;
    }
`;

const defaultContent = css`
    width: calc(100% - 32px);
    max-width: 432px;
    border-radius: 12px;
    margin: 16px auto;

    ${MediaQueries.mobile} {
        width: calc(100vw - 16px);
    }
`;

export const ContentStyle = styled.div<IContentModal>`
    padding: ${({ paddingX = 24, paddingY = 24 }) =>
        css`
            ${paddingY}px ${paddingX}px
        `};
    background-color: ${color('surface-default')};
    display: inline-block;
    line-height: normal;
    text-align: left;
    position: relative;
    z-index: 10;

    ${({ fullscreen }) => (fullscreen ? fullscreenContent : defaultContent)}

    ${({ fullscreen, transparentOverlay }) =>
        !fullscreen &&
        transparentOverlay &&
        css`
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
        `}


    ${({ width }) =>
        width &&
        css`
            max-width: ${width}px;
        `}

    ${({ height }) =>
        height &&
        css`
            height: ${height}px;
        `}

    ${({ contentAlignmentY }) =>
        contentAlignmentY &&
        css`
            vertical-align: ${contentAlignmentY};
        `}}

`;

export const OverlayStyle = styled.div<{
    fullscreen?: boolean;
    zPosition: zIndexTypes;
    transparentOverlay?: boolean;
}>`
    position: fixed;
    width: 100vw;
    height: 100vh;
    line-height: 100vh;
    text-align: center;
    top: 0;
    left: 0;
    z-index: ${({ zPosition }) => zPosition};
    background: ${({ transparentOverlay }) =>
        transparentOverlay ? color('surface-secondary', 0.5) : color('surface-main', 1)};};
    ${({ fullscreen }) =>
        !fullscreen &&
        css`
            overflow-y: scroll;
        `}
`;
export const CloseButton = styled.button<{ withBackground?: boolean }>`
    position: absolute;

    ${({ withBackground }) => {
        if (withBackground) {
            return css`
                right: 16px;
                top: 16px;
                height: 44px;
                width: 44px;
                background: ${color('surface-secondary')};
            `;
        }

        return css`
            right: 10px;
            top: 10px;
            height: 40px;
            width: 40px;
            background-color: transparent;
        `;
    }}

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 2;

    svg path {
        transition: all 0.3s ease;
    }

    &:hover {
        cursor: pointer;

        svg path {
            fill: ${color('text-main')};
        }
    }
`;
