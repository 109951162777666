import { DeviceType, StringDictionary } from 'wikr-core-analytics/dist/types';
import { UserId } from 'wikr-core-analytics/dist/Interfaces/DataProvider';
import { Main, uaParser } from 'wikr-core-analytics';
import { Event } from '@sentry/react';

import {
    ANDROID,
    ANDROID_MIN_VERSION,
    CHROME,
    CHROME_MIN_VERSION,
    FB_BOT_HEIGHT_SIZE,
    FB_BOT_WIDTH_SIZE,
    IOS,
    IOS_MIN_VERSION,
} from 'constants/device';
import { SENTRY_AXIOS } from './constants';

import { objectEntriesPolyfill } from 'helpers/polyfills';

import { errorType, Extras, IError, Tags } from './interfaces';

export const getCustomTags = (error: IError, type: errorType, extras?: Extras): Tags => {
    const customTags: Record<string, string> = {};

    if (type === SENTRY_AXIOS) {
        customTags.API_ERROR_CODE = extras?.status as string;
    }

    return objectEntriesPolyfill(customTags);
};

export const checkIsOldDevices = (event: Event) => {
    const UAParser = new uaParser();
    const userAgent = UAParser.setUA(event?.extra?.agent as string);

    const browser = userAgent?.getBrowser();
    const deviceOS = userAgent?.getOS();

    const parsedOSVersion = parseFloat(deviceOS?.version || '');
    const parsedBrowserVersion = parseFloat(browser?.major || '');

    return (
        (deviceOS?.name === IOS && parsedOSVersion < IOS_MIN_VERSION) ||
        (deviceOS?.name === ANDROID && parsedOSVersion < ANDROID_MIN_VERSION) ||
        (browser?.name === CHROME && parsedBrowserVersion < CHROME_MIN_VERSION)
    );
};

export const checkFbBots = (): boolean => {
    const windowWidth = window.screen.width;
    const windowHeight = window.screen.height;

    return windowWidth === FB_BOT_WIDTH_SIZE && windowHeight === FB_BOT_HEIGHT_SIZE;
};

export const getAmazonDefaultData = async (): Promise<{
    aws_id: string;
    event_type: string;
    host: string;
    url: string;
    screen_height: number;
    screen_width: number;
    lang: string;
    language: string;
    agent: string;
    device: string | undefined;
    referrer: string;
    timestamp: number;
    browser_info: object;
    device_type: DeviceType;
    uuid: string;
    user_id: UserId;
    url_params: StringDictionary;
    country: string;
    cookies: object;
    gender: string;
    ab_test_name: string;
    growthbook_ab_test_name: string;
    region: string | null;
} | null> => {
    const isInitAmazonAnalytic = Main.checkIsAlreadyInited({ name: 'amazon' });

    if (isInitAmazonAnalytic) {
        return await Main?.getDefaultAmazonAnalyticPurchaseData?.();
    }

    return null;
};
