import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { getVideoLink } from 'helpers/getVideoLink';

import * as S from '../styled';

const SecondStep = () => {
    const { t } = useTranslation();

    return (
        <S.Step>
            <S.StepContent>
                <S.StepColumn>
                    <S.StepNumber mb={24}>
                        <Text type="large-text" center bold text="2" />
                    </S.StepNumber>
                    <Text mb={16} type="large-text" bold text={t('main.logInToAccount')} />
                    <S.SubText text={t('main.logInToApp')} />
                </S.StepColumn>

                <S.StepColumn>
                    <S.Video maxWidth={190} alt="video" src={getVideoLink()} />
                </S.StepColumn>
            </S.StepContent>
        </S.Step>
    );
};

export default SecondStep;
