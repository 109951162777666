import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const SubPause = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'border-default'];

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8.14494" cy="7.93742" r="5.90484" stroke="#151515" strokeWidth="1.28" />
            <rect x="5.94897" y="5.28906" width="1.271" height="5.42212" rx="0.635498" fill={iconColor} />
            <rect x="9.06982" y="5.28906" width="1.271" height="5.42212" rx="0.635498" fill={iconColor} />
        </svg>
    );
};

export default withTheme(SubPause);
