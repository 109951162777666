/* eslint-disable max-lines */
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';

import { checkTemporaryPasswordRequest, generateTemporaryPasswordRequest } from 'store/auth/actions';

import { EMAIL_STEP, PASSWORD_STEP, PREVENTED_CHARS } from 'constants/login';
import { ONE_MINUTE } from 'constants/date';

import { useDispatch } from 'hooks';

import isMobile from 'helpers/isMobile';

import ContactUsLink from 'components/ContactUsLink';
import { PasswordForm } from './components/PasswordForm';
import { EmailForm } from './components/EmailForm';

import * as S from './styled';

import text from 'assets/img/webp/login-text.webp';
import man from 'assets/img/webp/login-man.webp';

import {
    trackChangeEmail,
    trackEmailSubmit,
    trackLoginPageLoad,
    trackLoginSubmit,
    trackPasswordPageLoad,
    trackResendButton,
    trackSupportOnEmailPage,
    trackSupportOnPasswordPage,
} from 'analytics/trackers/login';
import { LoginSchema } from './validationSchema';

export const TemporaryPassword = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation();

    const isMobileDevice = isMobile.any();

    const email: string = location.state?.email || '';
    const [code, setCode] = useState(['', '', '', '']);
    const [loginStep, setLoginStep] = useState<typeof EMAIL_STEP | typeof PASSWORD_STEP>(EMAIL_STEP);
    const [resendTimer, setResendTimer] = useState<number | null>(null);
    const [isFetching, setFetchingStatus] = useState(false);
    const [isError, setIsError] = useState(false);

    const inputRefs = code.map(() => useRef<HTMLInputElement | null>(null));

    const resetCode = () => {
        setIsError(false);
        setCode(['', '', '', '']);
    };

    const handleSubmitForm = () => {
        trackEmailSubmit();
        setFetchingStatus(true);
        dispatch(
            generateTemporaryPasswordRequest({
                email: values.email,
                onError: handleEmailLoginError,
                onSuccess: handleEmailLoginSuccess,
            })
        );
    };

    const {
        errors,
        dirty,
        values,
        initialValues,
        setFieldTouched,
        setFieldValue,
        touched,
        handleSubmit,
        setFieldError,
    } = useFormik({
        initialValues: {
            email: email || '',
        },
        validationSchema: LoginSchema,
        validateOnBlur: true,
        onSubmit: handleSubmitForm,
    });

    const handleEmailLoginError = () => {
        setFieldError('email', t('emailLogin.error'));
        setFetchingStatus(false);
    };

    const handleEmailLoginSuccess = () => {
        setFetchingStatus(false);
        setLoginStep(PASSWORD_STEP);
        trackPasswordPageLoad();
        setResendTimer(ONE_MINUTE);
    };

    const handleCodeChange = (value: string, index: number) => {
        setIsError(false);

        const updatedCode = [...code];

        updatedCode[index] = value;

        setCode(updatedCode);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = e.target;

        const trimmedValue = value.slice(0, 1);

        handleCodeChange(trimmedValue, index);

        if (trimmedValue && index < code.length - 1) {
            const nextInput = inputRefs[index + 1];

            if (nextInput.current) {
                nextInput.current.focus();
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
        setIsError(false);

        if (PREVENTED_CHARS.includes(e.key) || (/^[A-Za-z]$/.test(e.key) && isMobileDevice)) {
            e.preventDefault();

            return;
        }

        if (e.key === 'Backspace' && index > 0) {
            e.preventDefault();

            if (code[index]) {
                const updatedCode = [...code];

                updatedCode[index] = '';

                setCode(updatedCode);

                return;
            }

            const previousInput = inputRefs[index - 1];

            if (previousInput.current) {
                previousInput.current.focus();
            }
        }
    };

    const handlePaste = (e: React.ClipboardEvent) => {
        e.preventDefault();

        setIsError(false);

        const clipboardData = e.clipboardData.getData('text/plain');
        const clipboardDigits = clipboardData.match(/\d/g);

        const updatedCode = [...code];

        clipboardDigits?.slice(0, 4).forEach((digit, index) => {
            updatedCode[index] = digit;
        });

        setCode(updatedCode);
    };

    const handleReturnClick = () => {
        resetCode();
        trackChangeEmail();
        setLoginStep(EMAIL_STEP);
    };

    const handleResendClick = () => {
        trackResendButton();

        dispatch(
            generateTemporaryPasswordRequest({
                email: values.email,
            })
        );

        resetCode();
        setResendTimer(ONE_MINUTE);
    };

    const handleLoginClick = () => {
        const codeString = code.join('');

        setFetchingStatus(true);
        trackLoginSubmit();

        dispatch(
            checkTemporaryPasswordRequest({
                email: values.email,
                password: codeString,
                onError: handleErrorCode,
                onSuccess: handleSuccessCode,
            })
        );
    };

    const handleSupportClick = () => {
        if (loginStep === EMAIL_STEP) {
            trackSupportOnEmailPage();
        }

        if (loginStep === PASSWORD_STEP) {
            trackSupportOnPasswordPage();
        }
    };

    const handleErrorCode = () => {
        setIsError(true);
        setFetchingStatus(false);
    };

    const handleSuccessCode = () => {
        setIsError(false);
        setFetchingStatus(false);
    };

    useEffect(() => {
        trackLoginPageLoad();
    }, []);

    useEffect(() => {
        if (resendTimer !== null && resendTimer > 0) {
            const timerId = setInterval(() => {
                setResendTimer((prevTime) => (prevTime !== null ? prevTime - 1 : null));
            }, 1000);

            return () => {
                clearInterval(timerId);
            };
        } else if (resendTimer === 0) {
            setResendTimer(null);
        }
    }, [resendTimer]);

    const isCodeComplete = code.every((digit) => /^\d$/.test(digit));

    return (
        <S.Container>
            <S.AuthImage>
                <S.LoginText src={text} alt="changeYourself" />
                <S.LoginMan src={man} alt="man" />
            </S.AuthImage>
            <S.AuthForm>
                {loginStep === EMAIL_STEP ? (
                    <EmailForm
                        handleSubmit={handleSubmit}
                        setFieldTouched={setFieldTouched}
                        initialValues={initialValues}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        errors={errors}
                        dirty={dirty}
                        isFetching={isFetching}
                    />
                ) : (
                    <PasswordForm
                        handleReturnClick={handleReturnClick}
                        handleInputChange={handleInputChange}
                        handleKeyDown={handleKeyDown}
                        handlePaste={handlePaste}
                        handleLoginClick={handleLoginClick}
                        handleResendClick={handleResendClick}
                        isFetching={isFetching}
                        isError={isError}
                        inputRefs={inputRefs}
                        isCodeComplete={isCodeComplete}
                        code={code}
                        resendTimer={resendTimer}
                        values={values}
                    />
                )}
                <S.SupportContainer>
                    <S.HelpText text={t('subscription.helpButton.question')} color="text-secondary" />
                    <ContactUsLink onClick={handleSupportClick} type="medium-text" />
                </S.SupportContainer>
            </S.AuthForm>
        </S.Container>
    );
};
