import React from 'react';

import * as S from 'containers/Offers/ReturnOffer/styled';

import Skeleton from 'components/Skeleton';

const ReturnOfferSkeleton = () => {
    return (
        <S.SurveyContent>
            <S.DiscountsContainer>
                <Skeleton height={20} width={110} mb={12} />
                <Skeleton height={72} width={343} mb={24} />
                <Skeleton height={140} width={343} mb={24} />
                <Skeleton height={328} width={343} mb={32} />
            </S.DiscountsContainer>
        </S.SurveyContent>
    );
};

export default ReturnOfferSkeleton;
