import { Text } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueries from 'theme/mediaQueries';

export const Container = styled.div`
    padding: 48px 16px;

    ${mediaQueries.tabletLandscape} {
        padding: 48px 96px;
    }
`;

export const GuidesList = styled.ul`
    margin-bottom: 48px;
`;

export const Title = styled(Text)`
    max-width: 552px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    ${mediaQueries.tabletLandscape} {
        margin-left: 0;
    }

    ${mediaQueries.desktop} {
        max-width: 456px;
    }
`;

export const GuidesListWrapper = styled.div`
    max-width: 552px;
    width: 100%;
    margin: auto;

    ${mediaQueries.tabletLandscape} {
        margin: 0;
    }

    ${mediaQueries.desktop} {
        max-width: 456px;
    }
`;

export const GuidesListItem = styled.li`
    margin-bottom: 12px;
`;
