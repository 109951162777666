import { Text, Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const Container = styled.div`
    padding: 24px 16px;
    margin: 0 auto;
    max-width: 432px;
`;

export const Title = styled(Text)`
    max-width: 400px;
`;

export const PrimaryTitle = styled.span`
    color: ${color('primary-default')};
`;

export const CardWrapper = styled.div`
    background-image: linear-gradient(to bottom right, #f57d29, #e15446, #d6a130, #53c85f);
    padding: 2px;
    border-radius: 12px;
    transform: rotate(4deg);
    width: fit-content;
    position: relative;
    z-index: 5;
`;

export const Wrapper = styled.div`
    position: relative;
    width: 328px;
    margin: 0 auto;

    ${mediaQueries.laptop} {
        margin-bottom: 90px;
    }
`;

export const CardPseudo = styled.div`
    width: 311px;
    height: 170px;
    background-color: ${color('surface-default')};
    transform: rotate(-2deg);
    position: absolute;
    z-index: -1;
    top: 32px;
    right: 0;
    border-radius: 12px;

    &::after {
        content: '';
        position: absolute;
        z-index: -2;
        width: 257px;
        height: 140px;
        background-color: ${color('surface-default')};
        transform: rotate(-4deg);
        border-radius: 12px;
        top: 44px;
        right: 0;
    }
`;

export const Card = styled.div`
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${color('surface-hovered')};
    width: 328px;
    margin: 0 auto;
    min-height: 180px;
    border: 2px solid transparent;
    z-index: 3;
    border-radius: 12px;
    position: relative;
`;

export const ButtonWrapper = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 432px;
    left: 50%;
    transform: translateX(-50%);
    padding: 44px 16px 16px;
    z-index: 1000;
    background: linear-gradient(
        180deg,
        rgba(29, 29, 29, 0) 0%,
        rgba(29, 29, 29, 0.11) 6.25%,
        rgba(29, 29, 29, 0.21) 11.98%,
        #1d1d1d 38.54%
    );

    button {
        padding: 20px 0;
    }

    ${mediaQueries.laptop} {
        position: relative;
        padding-top: 0;
    }
`;

export const FrontChatText = styled(Text)`
    margin-top: 20px;
`;

export const WorkoutOrderText = styled(Text)`
    padding: 2px 8px;
    border-radius: 6px;
    background-color: ${color('action-disabled')};
    width: fit-content;
`;

export const FrontChatButton = styled.button`
    border-bottom: 1px solid ${color('text-secondary')};
    color: ${color('text-secondary')};
    cursor: pointer;
`;

export const CodeWrapper = styled.div`
    background-color: ${color('surface-default')};
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px;
    border-radius: 12px;
`;

export const ScanQrCodeWrapper = styled(Box)`
    width: 80px;
    height: 80px;
    padding-top: 0;
    position: relative;
    display: flex;
`;

export const QRWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;

    svg {
        transform: translate(-6px, -6px);
    }

    canvas {
        width: 100% !important;
        height: 100% !important;
    }
`;

export const DownloadWrapper = styled(Box)`
    max-width: 80px;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
