import { Text } from 'wikr-core-components';
import { useLocation } from 'react-router-dom';
import React, { memo } from 'react';

import * as S from '../styled';

import { SidebarLinkSecondaryProps } from '../types';

const SidebarLinkSecondary = ({ to, title, target = '_self', dataLocator }: SidebarLinkSecondaryProps) => {
    const { pathname } = useLocation();
    const isActive = pathname === to || pathname.startsWith(`${to}/`);

    return (
        <S.LinkSecondary href={to} target={target} rel="noopener noreferrer" data-locator={dataLocator}>
            <Text type="small-text" text={title} bold={isActive} />
        </S.LinkSecondary>
    );
};

export default memo(SidebarLinkSecondary);
