import React from 'react';

import { workoutFitnessLevelsTypes } from 'constants/workouts/workouts';

import * as S from './styled';

const LevelIcon = ({ level }: { level: workoutFitnessLevelsTypes }) => (
    <S.LevelIcon level={level}>
        <i />
        <i />
        <i />
    </S.LevelIcon>
);

export default LevelIcon;
