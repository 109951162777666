import { withTheme } from 'styled-components';
import ContentLoader from 'react-content-loader';
import React from 'react';

import { SkeletonContainer } from './styled';

import { ISkeleton } from './types';

import 'react-loading-skeleton/dist/skeleton.css';

const WIDTH = 1000;
const HEIGHT = 1000;

const Skeleton = ({ width, height, borderRadius, mb, theme, marginLeft }: ISkeleton) => (
    <SkeletonContainer
        width={width}
        height={height}
        dataLocator="SkeletonContainer"
        mb={mb}
        marginLeft={marginLeft}
        borderRadius={borderRadius || Number(theme['border-radius-medium'])}
    >
        <ContentLoader foregroundColor={theme['secondary-default']} backgroundColor={theme['surface-hovered']} animate>
            <rect x={0} y={0} width={WIDTH} height={HEIGHT} />
        </ContentLoader>
    </SkeletonContainer>
);

export default withTheme(Skeleton);
