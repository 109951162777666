import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';
import QRCode from 'qrcode.react';

import { createProcessingSelector } from 'store/loading/selectors';
import { selectDeepLink } from 'store/deepLink/selectors';
import { GET_DEEP_LINK } from 'store/deepLink/actionTypes';

import { useSelector } from 'hooks';

import isMobile from 'helpers/isMobile';

import Skeleton from 'components/Skeleton';
import InstallAppButton from 'components/InstallAppButton';

import * as S from '../styled';

import { trackAppStoreClick, trackGooglePayClick } from 'analytics/trackers/installApp';

const FirstStep = ({ isManualPage = false }) => {
    const { t } = useTranslation();
    const isLoading: boolean = useSelector(createProcessingSelector([GET_DEEP_LINK]));
    const deepLinkUrl = useSelector(selectDeepLink);

    const isAndroid = isMobile.android();
    const isIos = isMobile.iOS();
    const isNotMobile = !isIos && !isAndroid;

    return (
        <S.Step>
            <S.StepNumber>
                <Text type="large-text" center bold text="1" />
            </S.StepNumber>

            <S.StepContent paddingTop={24}>
                <S.StepColumn>
                    <Text mb={16} type="large-text" bold text={t('main.step.title.getApp')} />
                    {isNotMobile && <S.SubText text={t('main.step.title.scanCode')} />}
                </S.StepColumn>

                <S.StepColumn>
                    {isNotMobile && (
                        <S.DownloadWrapper>
                            <S.ScanQrCodeWrapper>
                                <S.QRWrapper>
                                    {isLoading ? (
                                        <Skeleton borderRadius={8} width={192} height={192} />
                                    ) : (
                                        <QRCode value={deepLinkUrl} />
                                    )}
                                </S.QRWrapper>
                            </S.ScanQrCodeWrapper>
                        </S.DownloadWrapper>
                    )}

                    {!isNotMobile && (
                        <S.StoreButton>
                            {isIos && (
                                <InstallAppButton
                                    dataLocator="appleStoreLink"
                                    size="md"
                                    isMobile
                                    type="apple"
                                    trackClick={() => trackAppStoreClick(isManualPage)}
                                    alt="workout"
                                />
                            )}
                            {isAndroid && (
                                <InstallAppButton
                                    dataLocator="googleStoreLink"
                                    size="md"
                                    isMobile
                                    type="google"
                                    trackClick={() => trackGooglePayClick(isManualPage)}
                                    alt="workout"
                                />
                            )}
                        </S.StoreButton>
                    )}
                </S.StepColumn>
            </S.StepContent>
        </S.Step>
    );
};

export default FirstStep;
