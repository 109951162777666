import { LOG_IN, SECURITY_CODE } from 'analytics/constants/ids';

import { trackClick, trackNewScreenLoad, trackScreenLoad } from 'services/Analytics';

export const trackLoginPageLoad = () => trackScreenLoad(LOG_IN);

export const trackEmailSubmit = () => trackClick(LOG_IN, { event_action: 'login' });

export const trackPasswordPageLoad = () =>
    trackNewScreenLoad(SECURITY_CODE, { event_action: 'screen__load', event_label: 'security_code' });

export const trackLoginSubmit = () => trackClick(LOG_IN, { event_action: 'login', event_label: 'security_code' });

export const trackChangeEmail = () => trackClick(LOG_IN, { event_action: 'use_different_email' });

export const trackResendButton = () =>
    trackClick(LOG_IN, { event_action: 'resend_code', event_label: 'security_code' });

export const trackSupportOnPasswordPage = () =>
    trackClick(LOG_IN, { event_action: 'contact_us', event_label: 'security_code' });

export const trackSupportOnEmailPage = () => trackClick(LOG_IN, { event_action: 'contact_us' });

export const trackStartSurvey = () => trackClick(LOG_IN, { event_action: 'start_the_survey' });
