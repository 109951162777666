import { ACCOUNT_SUBSCRIPTION, REMINDER_OFFER } from 'analytics/constants/ids';

import { trackClick, trackEvent, trackNewScreenLoad } from 'services/Analytics';

export const trackReminderCancelSubscription = () =>
    trackClick(REMINDER_OFFER, { event_action: 'button__continue_cancel', event_label: REMINDER_OFFER });

export const trackSuccessReminderSet = () =>
    trackEvent('cancel_offer_success', {
        event_label: REMINDER_OFFER,
    });

export const trackFailedReminderSet = () =>
    trackEvent('cancel_offer_error', {
        event_label: REMINDER_OFFER,
    });

export const ReminderSuccessLoad = () => {
    trackEvent('account_subscription__screen__load', {
        event_action: 'subscription__reminder',
        event_label: REMINDER_OFFER,
    });
};

export const ReminderSetClick = () =>
    trackClick(REMINDER_OFFER, { event_action: 'button_continue', event_label: REMINDER_OFFER });

export const ReminderSuccessClick = () =>
    trackClick(ACCOUNT_SUBSCRIPTION, { event_action: 'continue', event_label: REMINDER_OFFER });

export const ReminderErrorLoad = () => {
    trackNewScreenLoad(ACCOUNT_SUBSCRIPTION, { event_action: 'error', event_label: REMINDER_OFFER });
};

export const ReminderErrorClick = () =>
    trackClick(ACCOUNT_SUBSCRIPTION, { event_action: 'try_again', event_label: REMINDER_OFFER });

export const trackReminderModalLoad = () => trackNewScreenLoad(REMINDER_OFFER, { event_label: REMINDER_OFFER });

export const trackCloseReminderOffer = () =>
    trackClick(REMINDER_OFFER, { event_action: 'close', event_label: REMINDER_OFFER });

export const trackReturnReminderOffer = () =>
    trackClick(REMINDER_OFFER, { event_action: 'return', event_label: REMINDER_OFFER });
