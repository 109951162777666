import * as actionTypes from './actionTypes';

import {
    WorkoutParamsType,
    WorkoutCompleteParamsType,
    WorkoutAudioMapType,
    WorkoutType,
    WorkoutBlocksType,
    BlockExercise,
    PlaylistExercise,
} from 'types/workouts';

export const createWorkout = ({
    params,
    onSuccess,
    onError,
}: {
    params: WorkoutParamsType;
    onSuccess: () => void;
    onError: () => void;
}) => {
    return {
        type: actionTypes.CREATE_WORKOUT,
        payload: {
            params,
            onSuccess,
            onError,
        },
    } as const;
};

export const setWorkout = (payload: {
    workout: WorkoutType;
    blocks: WorkoutBlocksType;
    playlist: BlockExercise[];
    exercises: PlaylistExercise[];
}) => {
    return {
        type: actionTypes.SET_WORKOUT,
        payload,
    } as const;
};

export const getWorkoutAudios = () => {
    return {
        type: actionTypes.GET_WORKOUT_AUDIOS,
    } as const;
};

export const setWorkoutAudios = (audiosMap: WorkoutAudioMapType) => {
    return {
        type: actionTypes.SET_WORKOUT_AUDIOS,
        payload: audiosMap,
    } as const;
};

export const workoutComplete = (payload: {
    params: WorkoutCompleteParamsType;
    onSuccess: () => void;
    onError: () => void;
}) => {
    return {
        type: actionTypes.COMPLETE_WORKOUT,
        payload,
    } as const;
};

export const setLoadingState = (payload: boolean) => {
    return {
        type: actionTypes.SET_LOADING_STATE,
        payload,
    } as const;
};
