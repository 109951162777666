import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const Burger = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'text-main'];

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.91699 16.667C5.91699 16.2528 6.25278 15.917 6.66699 15.917H17.5003C17.9145 15.917 18.2503 16.2528 18.2503 16.667C18.2503 17.0812 17.9145 17.417 17.5003 17.417H6.66699C6.25278 17.417 5.91699 17.0812 5.91699 16.667Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.91699 10C5.91699 9.58579 6.25278 9.25 6.66699 9.25H17.5003C17.9145 9.25 18.2503 9.58579 18.2503 10C18.2503 10.4142 17.9145 10.75 17.5003 10.75H6.66699C6.25278 10.75 5.91699 10.4142 5.91699 10Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.91699 3.33301C5.91699 2.91879 6.25278 2.58301 6.66699 2.58301H17.5003C17.9145 2.58301 18.2503 2.91879 18.2503 3.33301C18.2503 3.74722 17.9145 4.08301 17.5003 4.08301H6.66699C6.25278 4.08301 5.91699 3.74722 5.91699 3.33301Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.75 16.6665C1.75 16.2523 2.08579 15.9165 2.5 15.9165H2.50833C2.92254 15.9165 3.25833 16.2523 3.25833 16.6665C3.25833 17.0807 2.92254 17.4165 2.50833 17.4165H2.5C2.08579 17.4165 1.75 17.0807 1.75 16.6665Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.75 10C1.75 9.58579 2.08579 9.25 2.5 9.25H2.50833C2.92254 9.25 3.25833 9.58579 3.25833 10C3.25833 10.4142 2.92254 10.75 2.50833 10.75H2.5C2.08579 10.75 1.75 10.4142 1.75 10Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.75 3.3335C1.75 2.91928 2.08579 2.5835 2.5 2.5835H2.50833C2.92254 2.5835 3.25833 2.91928 3.25833 3.3335C3.25833 3.74771 2.92254 4.0835 2.50833 4.0835H2.5C2.08579 4.0835 1.75 3.74771 1.75 3.3335Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(Burger);
