import { Button } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const CancelButton = styled(Button)`
    max-width: 100%;
`;

export const ConfirmButton = styled(Button)`
    border: 2px solid ${color('border-default')};
    background-color: transparent;
    color: ${color('border-default')};
    max-width: 100%;

    &:hover {
        background-color: ${color('surface-hovered')} !important;
        color: ${color('surface-hovered')} !important;
    }
`;

export const ButtonsWrp = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    ${mediaQueries.laptop} {
        flex-direction: row;
    }
`;
