import { Button } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const ReasonItem = styled.button<{ isSelected: boolean }>`
    background: ${color('surface-default')};
    padding: 16px 20px;
    display: flex;
    align-items: center;
    height: 80px;
    width: 100%;
    border-radius: 12px;
    text-align: left;
    margin-bottom: 12px;
    cursor: pointer;
    min-height: 56px;
    overflow: hidden;
    transition: 0.2s ease;

    &:last-child {
        margin-bottom: 0;
    }

    > * {
        transition: 0.2s ease;
    }

    ${({ isSelected }) =>
        isSelected &&
        css`
            background: ${color('primary-default')};

            > * {
                color: ${color('on-primary')};
            }
        `}
    ${mediaQueries.laptop} {
        &:hover {
            ${({ isSelected }) => css`
                background: ${color(isSelected ? 'primary-active' : 'primary-default')};
            `}
            > * {
                color: ${color('on-primary')};
            }
        }
    }
`;

export const IconBox = styled.div`
    display: flex;
    justify-content: center;
    width: 32px;
    margin-right: 12px;
`;

export const Icon = styled.span<{ isSelected: boolean }>`
    width: ${({ isSelected }) => (isSelected ? '21px' : '24px')};
    height: ${({ isSelected }) => (isSelected ? '21px' : '24px')};
    display: inline-block;
    border-radius: 50%;
    ${({ isSelected }) =>
        isSelected
            ? css`
                  border: 4px solid ${color('on-primary')};
                  background-color: ${color('primary-default')};
              `
            : css`
                  border: 2px solid ${color('border-default')};
                  background-color: ${color('surface-main')};
              `}
`;
export const SurveyContent = styled.div`
    height: 100%;
    padding: 0 16px 32px 16px;
    overflow: hidden;
    background: ${color('surface-main')};
`;

export const ModalHeader = styled.div<{ withAdditionalInfo: boolean }>`
    text-align: center;
    margin-top: ${({ withAdditionalInfo }) => (withAdditionalInfo ? '0px' : '32px')};
    margin-bottom: 32px;
    padding-top: ${({ withAdditionalInfo }) => (withAdditionalInfo ? '0px' : '76px')};
`;

export const ModalHeaderTitle = styled.div`
    position: fixed;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding: 16px;
    background: ${color('surface-main')};
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    border-bottom: 1px solid ${color('border-default')};

    ${mediaQueries.laptop} {
        padding: 16px 40px;
    }
`;

export const ModalScrollBody = styled.div`
    height: 100%;
    overflow-y: auto;
`;

export const TitleText = styled.div`
    flex: 1;
    margin-left: 44px;
`;

export const CloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${color('surface-secondary')};
    height: 44px;
    width: 44px;
    border-radius: 50%;

    &:hover {
        cursor: pointer;
    }
`;

export const ConfirmButton = styled(Button)`
    border: 1px solid ${color('border-default')};
    padding: 20px;
    background-color: transparent;
    color: ${color('border-default')};
    max-width: 100%;

    &:hover {
        background-color: ${color('surface-hovered')} !important;
        color: ${color('surface-hovered')} !important;
    }
`;

export const SubscriptionAdditionalInfo = styled.div`
    margin: 90px 0 24px 0;
    background: ${color('surface-default')};
    padding: 8px 12px;
    border-radius: 8px;
`;
