import { getRedirectPath } from 'helpers/getRedirectPath';

import * as S from './styled';

import { ILogoProps } from './types';

const Logo = ({ withoutText, height }: ILogoProps) => {
    const link = getRedirectPath();

    return (
        <S.LogoContainer to={link} height={height}>
            <S.LogoImage />
            {!withoutText && <S.LogoText />}
        </S.LogoContainer>
    );
};

export default Logo;
