import { Button, Text } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';

import { workoutDurations, workoutFitnessLevels } from 'constants/workouts/workouts';

import WorkoutsContainer from 'containers/Workouts';

import { WorkoutTitle } from 'components/WorkoutTitle';
import Switch from 'components/Switch';
import ProgressDialog from 'components/Modals/ProgressDialog';
import { Clock } from 'components/Icon';

import * as S from '../styled';

import { WorkoutBuilderFormPropsType } from '../types';

import { trackWorkoutCooldownClick, trackWorkoutWarmupClick } from 'analytics/trackers/workout';
import TargetAreasSlider from './TargetAreasSlider';
import ProgressBar from './ProgressBar';
import LevelIcon from './LevelIcon';

const WorkoutBuilderForm = ({
    isFetching,
    state,
    onCreate,
    onSelectFitnessLevel,
    onSelectTargetArea,
    onChangeCheckbox,
    onChangeDuration,
}: WorkoutBuilderFormPropsType) => {
    const { t } = useTranslation();

    return (
        <>
            <WorkoutsContainer>
                <S.WorkoutBuilderForm>
                    <WorkoutTitle title="workout.title" subtitle="workout.subtitle" />
                    <S.WorkoutFilter>
                        {/* TODO: Wait for equipments implementation*/}
                        {/* <S.WorkoutFilterItem>*/}
                        {/*    <S.FormSubtitle type="medium-text" bold mb={12}>*/}
                        {/*        <Trans i18nKey="workout.workoutBuilder.equipment" />*/}
                        {/*    </S.FormSubtitle>*/}
                        {/*    <S.EquipmentsButton>*/}
                        {/*        <S.Selected>*/}
                        {/*            <S.SelectedIcon isActive={false}>*/}
                        {/*                <Workout color="text-secondary-subdued" />*/}
                        {/*            </S.SelectedIcon>*/}
                        {/*            <Text type="medium-text">*/}
                        {/*                <Trans i18nKey="basics.noEquipment" />*/}
                        {/*            </Text>*/}
                        {/*        </S.Selected>*/}
                        {/*        <Icon name="arrowRight" />*/}
                        {/*    </S.EquipmentsButton>*/}
                        {/* </S.WorkoutFilterItem>*/}

                        <S.WorkoutFilterItem>
                            <S.FormSubtitle type="medium-text" bold mb={12}>
                                <Trans i18nKey="workout.workoutBuilder.duration" />
                            </S.FormSubtitle>
                            {workoutDurations.map((time: number, index) => (
                                <S.DurationButton key={`duration-${time}-${index}`} onClick={onChangeDuration(time)}>
                                    <Clock isActive={state.workout_time === time} />
                                    <S.ClockText center isActive={state.workout_time === time} type="medium-text">
                                        {time}
                                    </S.ClockText>
                                </S.DurationButton>
                            ))}
                        </S.WorkoutFilterItem>
                        <S.WorkoutFilterItem>
                            <S.FormSubtitle type="medium-text" bold mb={12}>
                                <Trans i18nKey="workout.workoutBuilder.targetAreas.title" /> ({state.areas.length})
                            </S.FormSubtitle>
                            <TargetAreasSlider stateAreas={state.areas} handleButtonClick={onSelectTargetArea} />
                        </S.WorkoutFilterItem>
                        <S.WorkoutFilterItem>
                            <S.FormSubtitle type="medium-text" bold mb={12}>
                                <Trans i18nKey="workout.workoutBuilder.difficulty" />
                            </S.FormSubtitle>
                            <S.WorkoutDifficulty>
                                {workoutFitnessLevels.map((level) => (
                                    <S.LevelButton
                                        key={level.type}
                                        name="level"
                                        value={level.type}
                                        onClick={onSelectFitnessLevel}
                                        isActive={state.level === level.type}
                                    >
                                        <LevelIcon level={level.type} />
                                        <Text medium type="small-text">
                                            <Trans i18nKey={level.title} />
                                        </Text>
                                    </S.LevelButton>
                                ))}
                            </S.WorkoutDifficulty>
                        </S.WorkoutFilterItem>

                        <S.WorkoutSwitcherBlock>
                            <S.WorkoutSwitcher>
                                <div>
                                    <Text type="medium-text" mb={4}>
                                        <Trans i18nKey="workout.workoutBuilder.warmUp.title" />
                                    </Text>
                                    <Text type="small-text" color="text-secondary-subdued">
                                        <Trans i18nKey="workout.workoutBuilder.warmUp.subtitle" />
                                    </Text>
                                </div>
                                <Switch
                                    name="warm_up"
                                    checked={state.warm_up}
                                    onChange={onChangeCheckbox(trackWorkoutWarmupClick)}
                                />
                            </S.WorkoutSwitcher>
                            <S.WorkoutSwitcher>
                                <div>
                                    <Text type="medium-text" mb={4}>
                                        <Trans i18nKey="workout.workoutBuilder.coolDown.title" />
                                    </Text>
                                    <Text type="small-text" color="text-secondary-subdued">
                                        <Trans i18nKey="workout.workoutBuilder.coolDown.subtitle" />
                                    </Text>
                                </div>
                                <Switch
                                    name="cool_down"
                                    checked={state.cool_down}
                                    onChange={onChangeCheckbox(trackWorkoutCooldownClick)}
                                />
                            </S.WorkoutSwitcher>
                        </S.WorkoutSwitcherBlock>
                        {!isFetching && <Button onClick={onCreate} text={t('workout.workoutBuilder.createWorkout')} />}
                        {isFetching && <ProgressBar />}
                    </S.WorkoutFilter>
                </S.WorkoutBuilderForm>
            </WorkoutsContainer>
            <ProgressDialog isOpen={isFetching} />
        </>
    );
};

export default WorkoutBuilderForm;
