import { Text, Box } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';

import { logout } from 'store/auth/actions';

import { SidebarEventLabels } from 'analytics/constants/labels';
import { getSecondaryLinks } from './constants';

import { useSidebar, useDispatch } from 'hooks';

import { isPaidUser } from 'helpers/verifiers';
import isMobile from 'helpers/isMobile';
import { getRedirectPath } from 'helpers/getRedirectPath';
import { getPrimaryLinks } from './helpers';

import SidebarLinkSecondary from './components/SidebarLinkSecondary';
import SidebarLink from './components/SidebarLink';

import * as S from './styled';

import { linkType } from './types';

import { trackSidebarLinkClick } from 'analytics/trackers/sidebar';

export default function SideBar() {
    const { t } = useTranslation();
    const isPaid = isPaidUser();
    const isMobileDevice = isMobile.any();
    const mainPath = getRedirectPath();

    const primaryLinks = useMemo(() => getPrimaryLinks(isPaid, isMobileDevice, mainPath), [isPaid]);

    const dispatch = useDispatch();

    const { isOpen, close } = useSidebar();

    const navigate = useNavigate();

    const handleLogout = () => {
        trackSidebarLinkClick(SidebarEventLabels.logout);

        setTimeout(() => {
            dispatch(logout());
            navigate('/');
            close();
        }, 300);
    };

    const handleLinkClick = (analyticLabel?: SidebarEventLabels) => () => {
        analyticLabel && trackSidebarLinkClick(analyticLabel);
        close();
    };

    return (
        <S.SideBarWrapper isOpen={isOpen}>
            <S.SideBarOverlay onClick={close} />
            <S.SideBarMenu>
                <Box paddingTop={24} paddingX={12}>
                    {primaryLinks.map(({ to, title, dataLocator, icon, analyticLabel }) => (
                        <SidebarLink
                            icon={icon}
                            key={to}
                            onClick={handleLinkClick(analyticLabel)}
                            to={to}
                            title={title}
                            dataLocator={dataLocator}
                            type={linkType.Primary}
                        />
                    ))}
                    <S.Hr />
                </Box>
                <Box paddingTop={40} paddingX={12}>
                    {getSecondaryLinks().map(({ dataLocator, title, to, analyticLabel, target }) => (
                        <SidebarLinkSecondary
                            key={dataLocator}
                            onClick={handleLinkClick(analyticLabel)}
                            to={to}
                            title={t(title)}
                            dataLocator={dataLocator}
                            target={target}
                        />
                    ))}
                    <S.NavSecondaryButton onClick={handleLogout} data-locator="burgerLogoutBtn">
                        <Text type="small-text" text={t('sidebar.logout')} />
                    </S.NavSecondaryButton>
                </Box>
            </S.SideBarMenu>
        </S.SideBarWrapper>
    );
}
