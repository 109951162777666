import React from 'react';

import Skeleton from 'components/Skeleton';

import { SkeletonWrapper } from './styled';

const SubscriptionsSkeleton = () => (
    <SkeletonWrapper>
        <Skeleton height={240} mb={20} />
    </SkeletonWrapper>
);

export default SubscriptionsSkeleton;
