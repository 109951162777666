import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { DATE_FORMAT } from 'constants/date';
import { CURRENCY_SIGN_MAP } from 'constants/currency';
import { SUBSCRIPTION_LABEL_STATES } from '../constants';

import { replaceUnderscoreToDash } from 'helpers/utils';
import { getPriceForCurrency } from 'helpers/prices';
import { getFormattedDate } from 'helpers/date';

import SubscriptionStatusLabel from './subComponents/SubscriptionLabel';
import PeriodRow from './subComponents/PeriodRow';
import BillingPeriod from './subComponents/BillingPeriod';

import * as S from './styled';

import { IChangeSubscriptionProduct, IDiscountSubscription, ITrialUpcomingProduct } from 'types/subscription';
import { IUpcomingProductProps } from '../types';

const UpcomingProduct = ({ upcomingProduct }: IUpcomingProductProps) => {
    const { t } = useTranslation();

    const autoRenewalDate = getFormattedDate(upcomingProduct.started_from as string, DATE_FORMAT);
    const price = getPriceForCurrency(upcomingProduct);

    const currencySign = CURRENCY_SIGN_MAP[upcomingProduct.currency] || upcomingProduct.currency;

    const getPeriod = (upcomingProduct: IDiscountSubscription | ITrialUpcomingProduct | IChangeSubscriptionProduct) => {
        return 'billing_period' in upcomingProduct
            ? upcomingProduct?.billing_period.value
            : upcomingProduct.subscription_period;
    };

    const period = getPeriod(upcomingProduct);

    return (
        <>
            <S.GeneralInfo mb={16} mt={24}>
                <SubscriptionStatusLabel state={SUBSCRIPTION_LABEL_STATES.UPCOMING} />
                <PeriodRow isTrial={false} period={period} />
                <S.SubscriptionCarRow>
                    <Text text={t('basics.price')} />
                    <Text dataLocator="subscriptionPrice" text={`${price} ${currencySign}`} />
                </S.SubscriptionCarRow>
                <BillingPeriod period={period} />
                <S.SubscriptionCarRow>
                    <Text text={t('subscription.text.newChargeDate')} />
                    <Text
                        text={`${replaceUnderscoreToDash(autoRenewalDate)} UTC`}
                        dataLocator="subscriptionNextChargeDate"
                    />
                </S.SubscriptionCarRow>
            </S.GeneralInfo>
            <S.Line mb={20} />
        </>
    );
};

export default UpcomingProduct;
