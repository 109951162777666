import styled from 'styled-components';

import { color } from 'theme/selectors';

export const GuideLinkButton = styled.a`
    height: 96px;
    background: ${color('surface-default')};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 12px;
    transition: 0.3s ease;

    svg {
        width: 32px;
    }

    &:hover {
        background: ${color('surface-hovered')};
    }
`;

export const GuideImg = styled.img`
    max-height: 95px;
    max-width: 71.25px;
`;

export const GuideTitleContainer = styled.div`
    width: 100%;
    margin: 0 8px;

    p {
        font-weight: 700;
    }
`;
