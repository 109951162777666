import { Button, Colors } from 'wikr-core-components';

import * as S from './styled';

type OutlinedButtonPropsType = {
    text: string;
    onClick: () => void;
    dataLocator?: string;
    mb?: number;
    isLoading?: boolean;
    textColor?: Colors;
};

const OutlinedButton = ({ text, onClick, dataLocator, mb, isLoading, textColor }: OutlinedButtonPropsType) => {
    return (
        <S.Container mb={mb} borderRadius={12} borderColor="border-default">
            <Button
                isLoading={isLoading}
                disabled={isLoading}
                text={text}
                textColor={textColor ?? 'text-main'}
                onClick={onClick}
                dataLocator={dataLocator}
            />
        </S.Container>
    );
};

export default OutlinedButton;
