import { Text, Button } from 'wikr-core-components';
import { Link, Navigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import React from 'react';

import { PRIVATE } from 'router/routes';

import { useWorkout } from 'hooks/workout/useWorkout';
import { useScreenLoadTracker, useSelector } from 'hooks';

import isMobile from 'helpers/isMobile';

import WorkoutsContainer from 'containers/Workouts';

import { WorkoutTitle } from 'components/WorkoutTitle';
import WorkoutExercisesList from 'components/WorkoutExercisesList';

import * as S from './styled';

import { trackStartWorkoutClick, trackWorkoutPreviewPageLoad } from 'analytics/trackers/workout';

export const WorkoutPreview = () => {
    useScreenLoadTracker(trackWorkoutPreviewPageLoad);

    const { workout, workoutExercises } = useWorkout();
    const { isLoading } = useSelector((state) => state.exercises);

    if (!workout) {
        return <Navigate to={PRIVATE.WORKOUT_BUILDER.path} />;
    }

    const duration = workout.total_time / 60;

    return (
        <WorkoutsContainer withBackButton={!isMobile.any()}>
            <S.WorkoutPreviewContainer>
                <S.TitleContainer>
                    <WorkoutTitle title="workout.custom.title" subtitle="workout.custom.subtitle" />
                </S.TitleContainer>
                <S.PreviewImage src={workout.preview} />
                <S.Header>
                    <S.WorkoutData>
                        <S.WorkoutDataItem>
                            <Text type="small-text" center medium>
                                <Trans i18nKey="basics.time" />
                            </Text>
                            <Text type="small-text" color="text-secondary-subdued" center>
                                <Trans
                                    i18nKey="workout.workoutPage.workoutTime"
                                    values={{ duration: duration.toFixed() }}
                                />
                            </Text>
                        </S.WorkoutDataItem>
                        <S.WorkoutDataItem>
                            <Text type="small-text" center medium>
                                <Trans i18nKey="basics.exercises" />
                            </Text>
                            <Text type="small-text" color="text-secondary-subdued" center>
                                {workoutExercises.length}
                            </Text>
                        </S.WorkoutDataItem>
                    </S.WorkoutData>
                </S.Header>
                <Link to={PRIVATE.WORKOUT.path}>
                    <Button mb={24} onClick={trackStartWorkoutClick} isLoading={isLoading}>
                        <Trans i18nKey="workout.workoutPage.startWorkout" />
                    </Button>
                </Link>

                <S.WorkoutsWrapper backgroundColor="surface-default" borderRadius={12}>
                    <WorkoutExercisesList isLoading={isLoading} displayHeader />

                    <Link to={PRIVATE.WORKOUT.path}>
                        <Button onClick={trackStartWorkoutClick} isLoading={isLoading}>
                            <Trans i18nKey="workout.workoutPage.startWorkout" />
                        </Button>
                    </Link>
                </S.WorkoutsWrapper>
            </S.WorkoutPreviewContainer>
        </WorkoutsContainer>
    );
};
