import styled from 'styled-components';

import mediaQueries from 'theme/mediaQueries';

export const PlayerWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    [data-vjs-player] > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: stretch;
    }
`;

export const PlayerBottomBar = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 12px;
    width: 100%;
    z-index: 5;
    justify-content: space-between;
    display: flex;
`;

export const MuteButton = styled.button`
    padding: 0 12px;
    height: 40px;
    cursor: pointer;
`;

export const FullscreenButton = styled.button`
    padding: 0 12px;
    height: 40px;
    cursor: pointer;
`;

export const VolumeInput = styled.input`
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100px;
    transition: 0.3s ease;

    &:focus {
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 0.5rem;
        height: 4px;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        margin-top: -2px;
        background-color: #fff;
        border-radius: 50%;
        height: 8px;
        width: 8px;
    }

    &:focus::-webkit-slider-thumb {
        outline: 2px solid #fff;
        outline-offset: 0.125rem;
    }

    &::-moz-range-track {
        background-color: #add8e6;
        border-radius: 0.5rem;
        height: 0.5rem;
    }

    &::-moz-range-thumb {
        background-color: #808080;
        border: none;
        border-radius: 0.5rem;
        height: 1rem;
        width: 1rem;
    }

    &:focus::-moz-range-thumb {
        outline: 3px solid #808080;
        outline-offset: 0.125rem;
    }
`;

export const VolumeWrapper = styled.div`
    z-index: 5;
    display: flex;
    align-items: center;

    ${VolumeInput} {
        width: 0;
        opacity: 0;
        visibility: hidden;
    }

    &:hover {
        ${VolumeInput} {
            width: 100px;
            opacity: 1;
            visibility: visible;
        }
    }
`;

export const VideoTimer = styled.div`
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translate(-50%, 0%);
    z-index: 2;
`;

export const NavigationWrapper = styled.div`
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translate(-50%, 0%);
    z-index: 2;
    width: 100%;
    max-width: calc(100% - 32px);

    ${mediaQueries.tabletLandscape} {
        left: 75%;
        bottom: 24px;
        transform: translate(-50%, 0);
        max-width: 304px;
    }

    ${mediaQueries.desktop} {
        max-width: 400px;
    }
`;
