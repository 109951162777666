import { uaParser } from 'wikr-core-analytics';

export const getDeviceBrowser = (): string | undefined => {
    const UAParser = new uaParser();

    const userAgent = window.navigator.userAgent;
    const browserData = UAParser.setUA(userAgent).getBrowser();

    return browserData?.name;
};
