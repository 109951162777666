export const GET_CURRENT_USER_REQUEST = 'USER/GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_ERROR = 'USER/GET_CURRENT_USER_ERROR';
export const SET_USER_DATA = 'USER/SET_USER_DATA';
export const UPDATE_PRODUCT_CODES = 'USER/UPDATE_PRODUCT_CODES';
export const UPDATE_USER = 'USER/UPDATE_USER';
export const UPDATE_USER_REQUEST = 'USER/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'USER/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'USER/UPDATE_USER_ERROR';
export const UPDATE_USER_EMAIL = 'USER/UPDATE_USER_EMAIL';
export const RESET_USER_DATA = 'USER/RESET_USER_DATA';
export const SET_USER_REGION = 'USER/SET_USER_REGION';
export const SET_USER_WORKOUT_INFO = 'USER/SET_USER_WORKOUT_INFO';
