import { Box, Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React, { useState } from 'react';

import { useNetworkStatus } from 'hooks/common';

import { zIndexTypes } from 'components/Modal/types';
import Modal from 'components/Modal';

import * as S from './styled';

const NetworkErrorModal = () => {
    const isOnline = useNetworkStatus();

    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    };

    return (
        <Modal isOpen={!isOnline} paddingX={16} paddingY={16} zPosition={zIndexTypes.top}>
            <Box paddingTop={8} paddingBottom={8}>
                <S.Emoji role="img" aria-label="network">
                    🌐
                </S.Emoji>
                <Text type="h5" center color="text-main" mb={12}>
                    <Trans i18nKey="networkErrorModal.title" />
                </Text>
                <Text center color="text-secondary" mb={32}>
                    <Trans i18nKey="networkErrorModal.subtitle" />
                </Text>
                <S.CustomButton
                    backgroundColor="surface-default"
                    hoverColor="surface-hovered"
                    isLoading={isLoading}
                    onClick={handleClick}
                >
                    <Trans i18nKey="basics.tryAgain" />
                </S.CustomButton>
            </Box>
        </Modal>
    );
};

export default NetworkErrorModal;
