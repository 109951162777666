import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const Cross = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'text-main'];

    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.50024 15.5255L17.5002 4.80209L18.4997 5.92058L6.49974 16.6439L5.50024 15.5255Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.5002 16.6439L5.50024 5.92058L6.49974 4.80209L18.4997 15.5255L17.5002 16.6439Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(Cross);
