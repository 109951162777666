import Logo from 'components/Logo';

import * as S from './styled';

const HeaderPublic = () => {
    return (
        <S.HeaderPublicContainer>
            <S.TitleContainer>
                <Logo />
            </S.TitleContainer>
        </S.HeaderPublicContainer>
    );
};

export default HeaderPublic;
