import { Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React from 'react';

import { useWorkout } from 'hooks/workout/useWorkout';
import { useSelector } from 'hooks';

import * as S from './styled';

import { ExerciseType } from 'types/exercise';

import ExerciseRoundList from './ExerciseRoundList';
import ExerciseListSkeleton from './ExerciseListSkeleton';
import ExerciseList from './ExerciseList';

type WorkoutExercisesList = {
    onClick?: (exercise: ExerciseType) => void;
    displayHeader?: boolean;
    activeExercise?: number;
    isLoading?: boolean;
};

const WorkoutExercisesList = ({ onClick, displayHeader, isLoading, activeExercise }: WorkoutExercisesList) => {
    const { exercises: exerciseMap } = useSelector((state) => state.exercises);
    const { blocks, workoutExercises } = useWorkout();

    if (isLoading) return <ExerciseListSkeleton />;

    return (
        <>
            {displayHeader && (
                <S.CountExercisesTitle type="h6" bold mb={32}>
                    <Trans i18nKey="basics.exercises.count" values={{ count: workoutExercises?.length }} />
                </S.CountExercisesTitle>
            )}

            {blocks.warm_up && (
                <>
                    <Text type="medium-text" medium bold mb={4}>
                        <Trans i18nKey="workout.workoutBuilder.warmUp.title" />
                    </Text>
                    <Text type="small-text" color="text-secondary-subdued" mb={16}>
                        <Trans i18nKey="workout.workoutPage.warmUpTime" />
                    </Text>
                    <ExerciseList
                        exercises={blocks.warm_up.exercises}
                        exercisesMap={exerciseMap}
                        onClick={onClick}
                        activeExercise={activeExercise}
                    />
                </>
            )}

            <Text type="medium-text" medium bold mb={4}>
                <Trans i18nKey="workout.workoutPage.training" />
            </Text>
            <ExerciseRoundList
                exercises={blocks.training.exercises}
                exercisesMap={exerciseMap}
                onClick={onClick}
                activeExercise={activeExercise}
            />

            {blocks.cool_down && (
                <>
                    <Text type="medium-text" medium bold mb={4}>
                        <Trans i18nKey="workout.workoutBuilder.coolDown.title" />
                    </Text>
                    <Text type="small-text" color="text-secondary-subdued" mb={16}>
                        <Trans i18nKey="workout.workoutPage.coolDownTime" />
                    </Text>
                    <ExerciseList
                        exercises={blocks.cool_down.exercises}
                        exercisesMap={exerciseMap}
                        onClick={onClick}
                        activeExercise={activeExercise}
                    />
                </>
            )}
        </>
    );
};

export default WorkoutExercisesList;
