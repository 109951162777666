import useMeasure from 'react-use-measure';
import { useSpring, animated } from 'react-spring';
import { ReactNode } from 'react';

const CollapsibleBlock = ({ isOpen, children }: { isOpen?: boolean; children: ReactNode }) => {
    const [measureRef, { height }] = useMeasure();

    const styles = useSpring({
        from: {
            height: 0,
        },
        to: {
            height: isOpen ? height : 0,
        },
    });

    return (
        <div>
            <animated.div style={{ overflow: 'hidden', ...styles }}>
                <div
                    ref={measureRef}
                    style={{
                        overflow: 'hidden',
                    }}
                >
                    {children}
                </div>
            </animated.div>
        </div>
    );
};

export default CollapsibleBlock;
