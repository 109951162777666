import React from 'react';

import { SUBSCRIPTION_LABEL_STATES } from '../constants';

import SubscriptionStatusLabel from './subComponents/SubscriptionLabel';
import SubscriptionCardHeader from './subComponents/SubscriptionCardHeader';
import PriceRow from './subComponents/PriceRow';
import PeriodRow from './subComponents/PeriodRow';
import BillingPeriod from './subComponents/BillingPeriod';
import AdditionalStatusInfo from './subComponents/AdditionalStatusInfo';

import * as S from './styled';

import { ISubscriptionCardCommonProps } from '../types';

import SubscriptionCardWrapper from './SubscriptionCardWrapper';

const UpdatingSubscription = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    price,
    isTrial,
    isMainSubscription,
    productCode,
    captions,
}: ISubscriptionCardCommonProps) => {
    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader
                isMainSubscription={isMainSubscription}
                productCode={productCode}
                icon={tabIcon}
                heading={heading}
            />
            <SubscriptionStatusLabel state={SUBSCRIPTION_LABEL_STATES.UPDATING} />
            <PeriodRow period={subscriptionPeriod} isTrial={isTrial} />
            <PriceRow price={price} />
            <BillingPeriod period={subscriptionPeriod} />
            <S.Line mt={16} />
            {captions?.description && <AdditionalStatusInfo captions={captions} mb={0} />}
        </SubscriptionCardWrapper>
    );
};

export default UpdatingSubscription;
