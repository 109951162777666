import React, { useReducer, useState } from 'react';

import { createWorkout } from 'store/workouts/actions';

import { workoutFitnessLevelsTypes, workoutMethods, workoutTargetAreas } from 'constants/workouts/workouts';

import { useDispatch, useScreenLoadTracker, useSelector } from 'hooks';

import { getAreasState } from './helpers';

import WorkoutBuilderForm from './components/WorkoutBuilderForm';

import { WorkoutBuilderFormPropsType, WorkoutBuilderStateType } from './types';

import {
    trackCreateWorkoutPageLoad,
    trackWorkoutAreasClick,
    trackWorkoutCreateClick,
    trackWorkoutDifficultyClick,
    trackWorkoutDurationClick,
} from 'analytics/trackers/workout';

export const WorkoutBuilder = () => {
    useScreenLoadTracker(trackCreateWorkoutPageLoad);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const [isFetching, setFetchingStatus] = useState(false);

    const getInitialFitnessLevel = () => {
        if (user.fitness_level) return user.fitness_level as workoutFitnessLevelsTypes;

        return workoutFitnessLevelsTypes.beginner;
    };

    const [state, updateState] = useReducer(
        (prev: WorkoutBuilderStateType, next: Partial<WorkoutBuilderStateType>) => ({ ...prev, ...next }),
        {
            warm_up: true,
            cool_down: false,
            equipments: ['decline_bench'],
            workout_time: 10,
            areas: ['full'],
            level: getInitialFitnessLevel(),
            workout_method: workoutMethods.strengthSet,
        }
    );

    const handleChangeDuration: WorkoutBuilderFormPropsType['onChangeDuration'] = (time) => () => {
        trackWorkoutDurationClick(time?.toString());
        updateState({ workout_time: time });
    };

    const handleSelectFitnessLevel: WorkoutBuilderFormPropsType['onSelectFitnessLevel'] = ({ currentTarget }) => {
        const { name, value } = currentTarget;

        trackWorkoutDifficultyClick(value);
        updateState({ [name]: value });
    };

    const handleSelectTargetArea: WorkoutBuilderFormPropsType['onSelectTargetArea'] = ({ currentTarget }) => {
        const { value } = currentTarget;

        const nextState = getAreasState(value, state.areas);

        trackWorkoutAreasClick(nextState);

        updateState({ areas: nextState as WorkoutBuilderStateType['areas'] });
    };

    const handleCheckboxChange: WorkoutBuilderFormPropsType['onChangeCheckbox'] =
        (track) =>
        ({ currentTarget }) => {
            const { name, checked } = currentTarget;

            if (track) track(checked);
            updateState({ [name]: checked });
        };

    const onCreate = () => {
        const { warm_up, cool_down, workout_time, level, areas, workout_method, equipments } = state;

        trackWorkoutCreateClick();

        const selectedAreas = areas
            .map((area) => workoutTargetAreas[area as keyof typeof workoutTargetAreas].areas)
            .flat();

        const params = {
            debug_mode: true,
            warm_up,
            cool_down,
            workout_time,
            workout_method,
            workout_fitness_level: level,
            equipments,
            target_areas: selectedAreas,
        };

        setFetchingStatus(true);
        dispatch(
            createWorkout({
                params,
                onSuccess: () => {
                    setFetchingStatus(false);
                },
                onError: () => {
                    setFetchingStatus(false);
                },
            })
        );
    };

    return (
        <WorkoutBuilderForm
            isFetching={isFetching}
            state={state}
            onCreate={onCreate}
            onSelectFitnessLevel={handleSelectFitnessLevel}
            onSelectTargetArea={handleSelectTargetArea}
            onChangeCheckbox={handleCheckboxChange}
            onChangeDuration={handleChangeDuration}
        />
    );
};
