import * as S from './styled';

const SimpleHeader = () => {
    return (
        <S.HeaderPublicContainer>
            <S.TitleContainer>
                <S.LogoContainer>
                    <S.LogoImage />
                    <S.LogoText />
                </S.LogoContainer>
            </S.TitleContainer>
        </S.HeaderPublicContainer>
    );
};

export default SimpleHeader;
