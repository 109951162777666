import ApiClient from 'services/ApiClient';

import { BuildWorkoutParamsType, WorkoutAudioType, WorkoutCompleteParamsType, WorkoutResponse } from 'types/workouts';
import { ExerciseType } from 'types/exercise';
import { IMessageResponse } from '../types/response';

class WorkoutsApi {
    getExercisesDetails = async <Data = number, Response = ExerciseType[]>(
        exerciseId: Data
    ): Promise<Partial<Response> | unknown> =>
        await ApiClient.get<Response>(`workout-service/exercises/details/${exerciseId}`);

    getEquipments = async <Response = any>(): Promise<Partial<Response> | unknown> =>
        await ApiClient.get<Response>('workout-builder/equipments');

    getWorkoutAudios = async <Response = WorkoutAudioType[]>(): Promise<Partial<Response>> =>
        await ApiClient.get<Response>('workout-service/audio-tracks');

    getExercisesByWorkout = async <Data = number, Response = ExerciseType[]>(
        workoutId: Data
    ): Promise<Partial<Response> | unknown> =>
        await ApiClient.get<Response>(`workout-service/workout-builder/exercises?workout_id=${workoutId}`);

    buildWorkout = async <Data = BuildWorkoutParamsType, Response = WorkoutResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> =>
        await ApiClient.post<Data, Response>('workout-service/workout-builder/', payload);

    complete = async <Data = WorkoutCompleteParamsType, Response = IMessageResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> =>
        await ApiClient.post<Data[], Response>('workout-service/workout-builder/complete', [payload]);
}

export default WorkoutsApi;
