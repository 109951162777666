import React from 'react';

import { IWaitingModal } from 'components/Modals/WaitingModal/types';
import { VideoInstructionModalProps } from 'components/Modals/VideoInstructionModal';
import { UniversalModalProps } from 'components/Modals/UniversalModal';
import { IRestoreSubscriptionModal } from 'components/Modals/RestoreSubscriptionModal/types';
import { ReminderSuccessModalProps } from 'components/Modals/ReminderSuccessModal/types';
import { ReminderOfferModalProps } from 'components/Modals/ReminderOfferModal/types';
import { WorkoutReasonAbandonProps } from 'components/Modals/ReasonAbandonModal';
import { PauseSubscriptionModalProps } from 'components/Modals/PauseSubscriptionModal/types';
import { ErrorModalProps } from 'components/Modals/ErrorModal/types';
import { IDiscountOfferModal } from 'components/Modals/DiscountOfferModal/types';
import { ConfirmCancelWorkoutProps } from 'components/Modals/ConfirmCancellationModalContainer/ConfirmCancellationModalContainer';
import { ICancellationReasonModal } from 'components/Modals/CancellationReasonModal/types';

export enum ModalName {
    confirmCancelWorkout = 'confirmCancelWorkout',
    workoutReasonAbandon = 'workoutReasonAbandon',
    videoInstructionModal = 'videoInstructionModal',
    cancellationReasonModal = 'cancellationReasonModal',
    discountOfferModal = 'discountOfferModal',
    waitingModal = 'waitingModal',
    universalModal = 'universalModal',
    restoreSubscriptionModal = 'restoreSubscriptionModal',
    pauseSubscriptionModal = 'pauseSubscriptionModal',
    reminderOfferModal = 'reminderOfferModal',
    reminderSuccessModal = 'reminderSuccessModal',
    errorModal = 'errorModal',
}

export type ModalPropsType = {
    [ModalName.confirmCancelWorkout]: ConfirmCancelWorkoutProps;
    [ModalName.workoutReasonAbandon]: WorkoutReasonAbandonProps;
    [ModalName.universalModal]: UniversalModalProps;
    [ModalName.videoInstructionModal]: VideoInstructionModalProps;
    [ModalName.cancellationReasonModal]: ICancellationReasonModal;
    [ModalName.discountOfferModal]: IDiscountOfferModal;
    [ModalName.pauseSubscriptionModal]: PauseSubscriptionModalProps;
    [ModalName.waitingModal]: IWaitingModal;
    [ModalName.restoreSubscriptionModal]: IRestoreSubscriptionModal;
    [ModalName.reminderOfferModal]: ReminderOfferModalProps;
    [ModalName.reminderSuccessModal]: ReminderSuccessModalProps;
    [ModalName.errorModal]: ErrorModalProps;
};

export type ModalsMapType = {
    [key in ModalName]: React.ComponentType<ModalPropsType[key]>;
};
