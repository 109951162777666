import { Box, Button, Input as InputComponent } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const SmallButton = styled(Button)`
    padding: 8px 12px;
    min-height: unset;
    min-width: unset;
    width: auto;

    * {
        letter-spacing: unset;
        text-transform: unset;
        font-size: 12px;
        line-height: 16px;
    }
`;

export const StyledInput = styled(Box)`
    > * {
        margin-bottom: 20px;
    }
`;

export const Input = styled(InputComponent)`
    &:disabled {
        background: ${color('secondary-hovered')};
        color: ${color('text-main')};
    }
`;
