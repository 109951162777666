import {
    ACCOUNT_SUBSCRIPTION,
    DISCOUNT_OFFER,
    RETURN_OFFER,
    RETURN_OFFER_CA,
    SPECIAL_OFFER_DISCOUNT,
} from '../constants/ids';

import { trackClick, trackEvent, trackNewScreenLoad } from 'services/Analytics';

export const trackSuccessChangeProduct = (screenId: string, offer: string) =>
    trackNewScreenLoad(screenId, { event_action: 'subscription__renewal', event_label: offer });

export const trackDiscountApplied = (offer: string, contentId: string) =>
    trackEvent('cancel_offer_success', {
        event_label: offer,
        event_action: 'cancel_offer_success',
        contentId,
    });

export const trackDiscountAppliedClick = (offer: string) =>
    trackClick(ACCOUNT_SUBSCRIPTION, { event_action: 'continue', event_label: offer });

export const trackSpecialOfferLoad = (contentId: string) =>
    trackNewScreenLoad(DISCOUNT_OFFER, { event_label: SPECIAL_OFFER_DISCOUNT, contentId });

export const trackReturnOfferLoad = (label: string) => trackNewScreenLoad(RETURN_OFFER, { event_label: label });

export const trackOfferErrorModalLoad = (screenId: string, eventLabel: string, contentId: string) => {
    trackNewScreenLoad(screenId, { event_action: 'error', event_label: eventLabel, contentId });
};

export const trackRetryOfferClick = (sreenId: string, eventLabel: string) => {
    trackClick(sreenId, { event_action: 'try_again', event_label: eventLabel });
};

export const trackFailedCancelOffer = () =>
    trackEvent('cancel_offer_error', {
        event_action: 'cancel_offer_error',
        event_label: RETURN_OFFER_CA,
    });
