import { useSelector } from 'hooks';

export const useWorkout = () => {
    const { workout, isLoading, blocks, playlist, workoutExercises } = useSelector((state) => state.workouts);

    return {
        workout,
        isLoading,
        blocks,
        playlist,
        workoutExercises,
    };
};
