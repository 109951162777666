export default {
    videoLinks: {
        ios: {
            EN: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Ios/EN.webp',
            ES: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Ios/ES.webp',
            PT: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Ios/PT.webp',
            IT: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Ios/IT.webp',
            DE: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Ios/DE.webp',
            FR: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Ios/FR.webp',
            JA: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Ios/JA.webp',
        },
        android: {
            EN: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Android/EN.webp',
            ES: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Android/ES.webp',
            PT: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Android/PT.webp',
            IT: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Android/IT.webp',
            DE: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Android/DE.webp',
            FR: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Android/FR.webp',
            JA: 'https://mobile.appscdn.io/!VIDEO/MyCoachApp/Public/MB%28w%29/Android/JA_.webp',
        },
    },
};
