import { RETURN_OFFER, RETURN_OFFER_CRM } from 'analytics/constants/ids';

import { trackClick, trackEvent, trackNewScreenLoad } from 'services/Analytics';

export const trackSuccessReturnOffer = () =>
    trackEvent('cancel_offer_success', {
        event_label: RETURN_OFFER_CRM,
    });

export const trackFailedReturnOffer = () =>
    trackEvent('cancel_offer_error', {
        event_label: RETURN_OFFER_CRM,
    });

export const trackReturnOfferFailedScreenLoad = () => {
    trackNewScreenLoad(RETURN_OFFER, { event_action: 'error', event_label: RETURN_OFFER_CRM });
};

export const trackReturnOfferScreenLoad = () => {
    trackNewScreenLoad(RETURN_OFFER, { event_label: RETURN_OFFER_CRM });
};

export const trackShowReturnOfferForCrmClick = () => {
    trackClick(RETURN_OFFER, { event_action: 'button__return_offer', event_label: 'subscription' });
};

export const trackApplyReturnOffer = (eventId: string, retry: boolean | undefined | object, eventLabel: string) => {
    // retry can be syntetic event when form submission, so we should check retry === true
    const eventAction = retry === true ? 'try_again' : 'button__renew_subscription';

    trackClick(eventId, {
        event_action: eventAction,
        event_label: eventLabel,
    });
};
