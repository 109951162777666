import { Text, TextType } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { ReactNode } from 'react';

import base from 'appConfig/base';

const ContactUsLink = ({
    subject = 'Muscle Booster - Your Order',
    onClick,
    children,
    dataLocator,
    text,
    type,
    boldText,
}: {
    subject?: string;
    onClick?: () => void;
    children?: ReactNode;
    dataLocator?: string;
    text?: string;
    type?: TextType;
    boldText?: boolean;
}) => {
    const { t } = useTranslation();

    return (
        <a
            href={`mailto:${base.supportEmail}?subject=${subject}`}
            data-locator={dataLocator}
            target="_blank"
            rel="noreferrer"
            onClick={onClick}
        >
            {children ? (
                children
            ) : (
                <Text type={type ?? 'overline'} center bold={boldText} text={text ? text : t('basics.contactUs')} />
            )}
        </a>
    );
};

export default ContactUsLink;
