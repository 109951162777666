import {
    DAYS_PER_EIGHT_MONTH,
    DAYS_PER_ELEVEN_MONTH,
    DAYS_PER_FIVE_MONTH,
    DAYS_PER_FOUR_MONTH,
    DAYS_PER_MONTH,
    DAYS_PER_NINE_MONTH,
    DAYS_PER_SEVEN_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_TEN_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_TWO_MONTH,
    DAYS_PER_WEEK,
    DAYS_PER_YEAR,
} from 'constants/periods';

const getSubscriptionTitle = (period: number, displayWeekInDays: boolean) => {
    const PERIODS_ARR = [
        { period: 1, count: 1, context: 'day' },
        { period: 2, count: 2, context: 'day' },
        { period: 3, count: 3, context: 'day' },
        { period: 4, count: 4, context: 'day' },
        { period: 5, count: 5, context: 'day' },
        { period: 6, count: 6, context: 'day' },
        { period: DAYS_PER_WEEK, count: displayWeekInDays ? 7 : 1, context: displayWeekInDays ? 'day' : 'week' },
        { period: 8, count: 8, context: 'day' },
        { period: 9, count: 9, context: 'day' },
        { period: 10, count: 10, context: 'day' },
        { period: 11, count: 11, context: 'day' },
        { period: 12, count: 12, context: 'day' },
        { period: 13, count: 13, context: 'day' },
        { period: 14, count: 14, context: 'day' },
        { period: 30, count: 1, context: 'month' },
        { period: DAYS_PER_MONTH, count: 1, context: 'month' },
        { period: DAYS_PER_TWO_MONTH, count: 2, context: 'month' },
        { period: DAYS_PER_THREE_MONTH, count: 3, context: 'month' },
        { period: DAYS_PER_FOUR_MONTH, count: 4, context: 'month' },
        { period: DAYS_PER_FIVE_MONTH, count: 5, context: 'month' },
        { period: DAYS_PER_SIX_MONTH, count: 6, context: 'month' },
        { period: DAYS_PER_SEVEN_MONTH, count: 7, context: 'month' },
        { period: DAYS_PER_EIGHT_MONTH, count: 8, context: 'month' },
        { period: DAYS_PER_NINE_MONTH, count: 9, context: 'month' },
        { period: DAYS_PER_TEN_MONTH, count: 10, context: 'month' },
        { period: DAYS_PER_ELEVEN_MONTH, count: 11, context: 'month' },
        { period: DAYS_PER_YEAR, count: 1, context: 'year' },
    ];

    if (period) {
        const currentPeriod = PERIODS_ARR.find((item) => item.period === period);

        return { count: currentPeriod?.count || 'NULL', context: currentPeriod?.context || 'NULL' };
    }

    return { count: 'NULL', context: 'NULL' };
};

export default getSubscriptionTitle;
