import * as actionTypes from './actionTypes';

import {
    IAuthBySignature,
    ISignInRequest,
    UserSource,
    CheckTemporaryPasswordRequest,
    GenerateTemporaryPasswordRequest,
} from './types';

export const authentication = () => {
    return {
        type: actionTypes.AUTHENTICATE,
    } as const;
};

export const authenticationSuccess = () => {
    return {
        type: actionTypes.AUTHENTICATION_SUCCESS,
    } as const;
};

export const authenticationFailed = () => {
    return {
        type: actionTypes.AUTHENTICATION_FAILED,
    } as const;
};

export const authenticationBySignature = ({ uId, signature }: IAuthBySignature) => {
    return {
        type: actionTypes.AUTHENTICATE_BY_SIGNATURE,
        payload: {
            uId,
            signature,
        },
    } as const;
};

export const setAuthenticationStatus = (isAuthenticated: boolean) => {
    return {
        type: actionTypes.SET_AUTHENTICATION_STATUS,
        payload: isAuthenticated,
    } as const;
};

export const setAuthRedirectUrl = (pathname: string | null) => {
    return {
        type: actionTypes.SET_AUTHENTICATION_REDIRECT_URL,
        payload: pathname,
    } as const;
};

export const signInRequest = (payload: ISignInRequest) => {
    return {
        type: actionTypes.SIGN_IN_REQUEST,
        payload,
    } as const;
};

export const signInError = (payload: Error) => {
    return {
        type: actionTypes.SIGN_IN_ERROR,
        payload,
    } as const;
};

export const logout = () => {
    return {
        type: actionTypes.LOG_OUT,
    } as const;
};

export const setUserSource = (payload: UserSource) => {
    return {
        type: actionTypes.SET_USER_SOURCE,
        payload,
    } as const;
};

export const generateTemporaryPasswordRequest = (payload: GenerateTemporaryPasswordRequest) => {
    return {
        type: actionTypes.GENERATE_TEMPORARY_PASSWORD_REQUEST,
        payload,
    } as const;
};

export const checkTemporaryPasswordRequest = (payload: CheckTemporaryPasswordRequest) => {
    return {
        type: actionTypes.CHECK_TEMPORARY_PASSWORD_REQUEST,
        payload,
    } as const;
};

export const setAuthToken = (payload: string | null) => {
    return {
        type: actionTypes.SET_AUTH_TOKEN,
        payload,
    } as const;
};
