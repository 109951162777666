import { Button } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import MediaQueries from 'theme/mediaQueries';

export const SurveyContent = styled.div`
    height: 100%;
    padding: 0 16px 32px 16px;
    overflow: hidden;
    background: ${color('surface-main')};
`;

export const ModalHeaderTitle = styled.div`
    position: fixed;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding: 16px;
    background: ${color('surface-main')};
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    border-bottom: 1px solid ${color('border-default')};

    ${MediaQueries.laptop} {
        padding: 16px 40px;
    }
`;

export const TitleText = styled.div`
    flex: 1;
`;

export const DiscountsContainer = styled.div`
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${color('surface-secondary')};
    height: 44px;
    width: 44px;
    border-radius: 50%;

    &:hover {
        cursor: pointer;
    }
`;

export const ConfirmButton = styled(Button)`
    border: 1px solid ${color('border-default')};
    padding: 20px;
    background-color: transparent;
    color: ${color('border-default')};
    max-width: 100%;

    &:hover {
        background-color: ${color('surface-hovered')} !important;
        color: ${color('surface-hovered')} !important;
    }
`;

export const SubscriptionAdditionalInfo = styled.div`
    margin-bottom: 24px;
    background: ${color('surface-default')};
    padding: 8px 12px;
    border-radius: 8px;
`;

export const OfferLabel = styled.div`
    width: fit-content;
    margin-bottom: 24px;
    background: ${color('critical-default')};
    padding: 2px 6px;
    border-radius: 4px;
`;

export const AccentColorPrice = styled.span`
    color: ${color('warning-default')};
`;

export const Disclaimer = styled.div`
    margin-top: 24px;
`;
