import { IReturnOfferCaptions } from './types';

export const getContent = (): IReturnOfferCaptions => {
    return {
        label: 'subscription.cancellation.returnOffer.lastChanceOffer',
        title: 'subscription.cancellation.returnOffer.renewNow.title',
        extraText: 'subscription.discountOffer.disclaimerNew',
        firstBadgeText: 'subscription.cancellation.returnOffer.firstBadge',
        secondBadgeText: 'subscription.cancellation.specialOffer.withDynamicPercent.secondBadge',
        submitButton: 'basics.renewSubscription',
    };
};
