import { Button, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { RefObject } from 'react';

import { formatSecondsToFullTime } from 'helpers/date';

import CodeInput from 'components/CodeInput';

import * as S from './styled';

interface PasswordFormProps {
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    handleKeyDown: (e: React.KeyboardEvent, index: number) => void;
    handlePaste: (e: React.ClipboardEvent) => void;
    handleResendClick: () => void;
    handleReturnClick: () => void;
    handleLoginClick: () => void;
    isFetching: boolean;
    isError: boolean;
    isCodeComplete: boolean;
    inputRefs: RefObject<HTMLInputElement>[];
    code: string[];
    resendTimer: number | null;
    values: Record<string, any>;
}

export const PasswordForm = ({
    handleLoginClick,
    handleResendClick,
    handleReturnClick,
    handleKeyDown,
    handleInputChange,
    handlePaste,
    resendTimer,
    inputRefs,
    isCodeComplete,
    code,
    isError,
    values,
    isFetching,
}: PasswordFormProps) => {
    const { t } = useTranslation();

    return (
        <S.Container>
            <Text text={t('login.temPass.title')} type="h4" mb={12} center />
            <Text text={t('login.temPass.subtitle', { email: values.email })} center color="text-secondary" />
            <S.LinkButton onClick={handleReturnClick}>
                <Text text={t('login.temPass.chooseDiffEmail')} />
            </S.LinkButton>

            <CodeInput
                isError={isError}
                code={code}
                handleInputChange={handleInputChange}
                handleKeyDown={handleKeyDown}
                handlePaste={handlePaste}
                inputRefs={inputRefs}
            />
            <S.ErrorText type="caption" bold text={t('basics.codeError')} color="critical-default" isError={isError} />
            <Button
                text={t('basics.login')}
                isLoading={isFetching}
                onClick={handleLoginClick}
                disabled={!isCodeComplete}
                mb={36}
            />

            {resendTimer === null ? (
                <S.LinkButton onClick={handleResendClick}>
                    <Text text={t('basics.resend')} />
                </S.LinkButton>
            ) : (
                <>
                    <Text center text={t('login.temPass.noEmail')} color="text-secondary" />
                    <S.TimerBox>
                        <Text text={t('login.temPass.resend')} color="text-secondary" />
                        <S.Timer>{formatSecondsToFullTime(resendTimer)}</S.Timer>
                    </S.TimerBox>
                </>
            )}
        </S.Container>
    );
};
