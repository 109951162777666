import { Box } from 'wikr-core-components';
import React, { ReactNode } from 'react';

const SubscriptionCardWrapper = ({ children }: { children: JSX.Element | JSX.Element[] | ReactNode }) => {
    return (
        <Box
            mb={20}
            paddingX={16}
            paddingTop={16}
            borderRadius={12}
            paddingBottom={16}
            borderColor="border-default"
            dataLocator="subscriptionContainer"
        >
            {children}
        </Box>
    );
};

export default SubscriptionCardWrapper;
