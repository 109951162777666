import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const ArrowRight = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'text-main'];

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.41699 10C3.41699 9.58579 3.75278 9.25 4.16699 9.25H15.8337C16.2479 9.25 16.5837 9.58579 16.5837 10C16.5837 10.4142 16.2479 10.75 15.8337 10.75H4.16699C3.75278 10.75 3.41699 10.4142 3.41699 10Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.46967 3.63666C9.76256 3.34377 10.2374 3.34377 10.5303 3.63666L16.3636 9.46997C16.6565 9.76286 16.6565 10.2377 16.3636 10.5306L10.5303 16.364C10.2374 16.6569 9.76257 16.6569 9.46967 16.364C9.17678 16.0711 9.17677 15.5963 9.46967 15.3034L14.7726 10.0003L9.46967 4.69732C9.17678 4.40443 9.17678 3.92955 9.46967 3.63666Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(ArrowRight);
