import React, { FC } from 'react';

import * as S from './styled';

interface BurgerProps {
    isActive: boolean;
    onClick: () => void;
}

const Burger: FC<BurgerProps> = ({ isActive, onClick }) => (
    <S.Burger
        onClick={onClick}
        data-locator={isActive ? 'burgerCloseMenuBtn' : 'burgerOpenMenuBtn'}
        isActive={isActive}
    >
        <S.Glyph />
    </S.Burger>
);

export default Burger;
