const DEVICE_TYPE_REGEXP_MAP = {
    mobile: new RegExp(
        'Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)',
        'i'
    ),
    tablet: new RegExp('(tablet|ipad|playbook|silk)|(android(?!.*mobi))', 'i'),
};

export const getDeviceType = (): 'mobile' | 'tablet' | 'desktop' => {
    const userAgent = window.navigator.userAgent;

    if (DEVICE_TYPE_REGEXP_MAP.tablet.test(userAgent)) {
        return 'tablet';
    }

    if (DEVICE_TYPE_REGEXP_MAP.mobile.test(userAgent)) {
        return 'mobile';
    }

    return 'desktop';
};
