import { Text } from 'wikr-core-components';
import styled from 'styled-components';

export const LinkButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0 auto 68px auto;
    display: block;
`;

export const TimerBox = styled.div`
    display: flex;
    justify-content: center;
`;

export const Timer = styled(Text)`
    margin-left: 5px;
`;

export const ErrorText = styled(Text)<{ isError: boolean }>`
    margin: 0 0 32px 12px;
    opacity: ${({ isError }) => (isError ? 1 : 0)};
    align-self: flex-start;
`;

export const Container = styled.div`
    width: 100%;
`;
