import { EMAIL_MATCH_REGEXP } from 'constants/RegExp';

import * as Yup from 'yup';

export const ChangeEmailSchema = Yup.object().shape({
    email: Yup.string()
        .trim()
        .matches(EMAIL_MATCH_REGEXP, 'validation.email.invalid')
        .required('validation.email.required'),
});
