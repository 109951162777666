export const SUBSCRIPTION_TITLE = {
    PERSONALIZED_PROGRAM: 'subscription.heading.personalizedProgram',
    APP_VIP_SUPPORT: 'subscription.heading.vipSupport',
    APP_ADDITIONAL_SUBSCRIPTION: 'subscription.heading.additionalPlanName',
};

export enum DISCOUNT_TYPES {
    base = 'base',
    extra = 'extra',
}
