import { Theme } from 'wikr-core-components';
import { createGlobalStyle } from 'styled-components';

import resetStyles from './styles/reset';
import { color } from './selectors';

const GlobalStyle = createGlobalStyle<{ rootVariables: string; theme: Theme }>`
    ${resetStyles}

    :root {
        ${({ rootVariables }) => rootVariables}
    }

    body,
    html{
        height: 100%;
    }

    body {
        background: ${color('secondary-default')};

        &.ReactModal__Body--open {
            overflow: hidden;
        }
    }

`;

export default GlobalStyle;
