import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import * as S from '../styled';

import { ISubscriptionCardHeader } from '../../types';

const SubscriptionCardHeader = ({ icon, heading, isMainSubscription, productCode }: ISubscriptionCardHeader) => {
    const { t } = useTranslation();
    const isAdditionalSubscription = !isMainSubscription;
    const productCodesToBeDisplayed = productCode?.split('__');

    return (
        <S.SubscriptionCardHeader>
            <S.Name>
                <S.Img src={icon} alt={heading} />
                {isMainSubscription && (
                    <Text dataLocator="subscriptionTitle" type="large-text" color="text-main" text={t(heading)} />
                )}
                {/* <Box>*/}
                {isAdditionalSubscription &&
                    productCodesToBeDisplayed?.length > 0 &&
                    productCodesToBeDisplayed.map((productCode, index) => (
                        <React.Fragment key={productCode}>
                            <Text
                                dataLocator="subscriptionTitle"
                                type="large-text"
                                inline={true}
                                text={t(heading, { plan: productCode })}
                            />
                            {index < productCodesToBeDisplayed.length - 1 && <Text inline>+</Text>}
                        </React.Fragment>
                    ))}
                {/* </Box>*/}
            </S.Name>
        </S.SubscriptionCardHeader>
    );
};

export default SubscriptionCardHeader;
