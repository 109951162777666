import React from 'react';

const PlanIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.03518 2C8.70082 2 8.38859 2.1671 8.20313 2.4453L7.53646 3.4453C7.09342 4.10985 7.56981 5 8.36851 5H15.6315C16.4302 5 16.9066 4.10986 16.4635 3.4453L15.7969 2.4453C15.6114 2.1671 15.2992 2 14.9648 2H9.03518ZM5 4H6.13826L6.13797 4.03517C6.13797 5.23077 7.10719 6.19999 8.30279 6.19999H15.6972C16.1246 6.19999 16.5424 6.07348 16.8981 5.83641C17.5337 5.41266 17.8729 4.71096 17.862 4H19C19.5523 4 20 4.44772 20 5V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V5C4 4.44772 4.44772 4 5 4ZM16.3375 9.33749C16.1486 9.14862 15.8446 9.14155 15.6472 9.32145L11.944 12.6955C11.6886 12.9282 11.3015 12.9397 11.0327 12.7226L9.21911 11.2578C9.03359 11.1079 8.76806 11.1099 8.58485 11.2626L8.09093 11.6742C7.87614 11.8532 7.84986 12.1735 8.03261 12.3851L10.9689 15.7851C11.2486 16.1089 11.7507 16.1083 12.0296 15.7838L16.6978 10.3516C16.8683 10.1533 16.8571 9.8571 16.6722 9.67218L16.3375 9.33749Z"
                fill="white"
            />
        </svg>
    );
};

export default PlanIcon;
