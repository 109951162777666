import styled from 'styled-components';

export const QuestionWrapper = styled.div`
    margin: 16px 0;
    display: flex;
    flex-wrap: wrap;
`;

export const QuestionLabel = styled.label`
    margin-left: 12px;
    cursor: pointer;
`;
