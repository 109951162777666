/* eslint-disable max-lines */
import { Button, Text } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import isMobile from 'helpers/isMobile';

import { zIndexTypes } from 'components/Modal/types';
import Modal from 'components/Modal';
import { Cross } from 'components/Icon';
import FeedbackSlider from 'components/FeedbackSlider';

import * as S from './styled';

import background from 'assets/img/webp/complete-bg.webp';

import { workoutFeedback } from 'types/enums';
import { CompleteWorkoutModalProps } from './types';

import {
    trackCompleteModalLoad,
    trackCompleteWorkoutContinue,
    trackCompleteWorkoutDifficultyClick,
    trackCompleteWorkoutRatingClick,
} from 'analytics/trackers/workout';

const CompleteWorkoutModal = ({ isOpen, onClose, workoutData }: CompleteWorkoutModalProps) => {
    const { t } = useTranslation();

    const [activeButton, setActiveButton] = useState<number | null>(null);
    const [workoutDifficulty, setWorkoutDifficulty] = useState('' as workoutFeedback);

    const handleWorkoutDifficultyClick = (value: workoutFeedback) => {
        trackCompleteWorkoutDifficultyClick(value);
        setWorkoutDifficulty(value);
    };

    const handleWorkoutRatingClick = (value: boolean, id: number) => {
        trackCompleteWorkoutRatingClick(value);
        setActiveButton(id);
    };

    const handleOnClose = () => {
        trackCompleteWorkoutContinue();
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            customContent
            trackLoad={trackCompleteModalLoad}
            fullscreen={isMobile.any()}
            zPosition={isMobile.any() ? zIndexTypes.top : zIndexTypes.middle}
        >
            <S.ModalContainer>
                <S.CloseButton onClick={onClose}>
                    <Cross color="text-main" />
                </S.CloseButton>
                <S.ModalHero>
                    <S.Background src={background} />
                    <S.TitleWrp>
                        <S.WorkoutModalTitle type="medium-text" color="text-main" center mb={16} uppercase>
                            <Trans i18nKey="workout.workoutCompleteModal.workoutCompleted" />
                        </S.WorkoutModalTitle>
                        <Text type="h1" color="text-main" center uppercase>
                            <Trans i18nKey="workout.workoutCompleteModal.nextStop" />
                        </Text>
                        <Text type="h1" color="text-main" center mb={16} uppercase>
                            <Trans i18nKey="workout.workoutCompleteModal.theTop" />
                        </Text>
                        <S.ResultInfo>
                            <S.ResultInfoItem>
                                <S.Emoji>
                                    <span role="img" aria-labelledby="duration">
                                        🕒
                                    </span>
                                </S.Emoji>
                                <Text type="medium-text" bold>
                                    {workoutData.duration}
                                </Text>
                                <Text type="caption" color="text-secondary-subdued">
                                    <Trans i18nKey="basics.duration" />
                                </Text>
                            </S.ResultInfoItem>
                            <S.ResultInfoItem>
                                <S.Emoji>
                                    <span role="img" aria-labelledby="calories">
                                        🔥
                                    </span>
                                </S.Emoji>
                                <Text type="medium-text" bold>
                                    {workoutData.burned}
                                </Text>
                                <Text type="caption" color="text-secondary-subdued">
                                    <Trans i18nKey="basics.calories" />
                                </Text>
                            </S.ResultInfoItem>
                            <S.ResultInfoItem>
                                <S.Emoji>
                                    <span role="img" aria-labelledby="exercises">
                                        🏋🏽‍♀️
                                    </span>
                                </S.Emoji>
                                <Text type="medium-text" bold>
                                    {workoutData.exercises}
                                </Text>
                                <Text type="caption" color="text-secondary-subdued">
                                    <Trans i18nKey="basics.exercises" />
                                </Text>
                            </S.ResultInfoItem>
                        </S.ResultInfo>
                    </S.TitleWrp>
                </S.ModalHero>
                <S.ModalBody>
                    <FeedbackSlider
                        handleWorkoutDifficultyClick={handleWorkoutDifficultyClick}
                        handleWorkoutRatingClick={handleWorkoutRatingClick}
                        workoutDifficulty={workoutDifficulty}
                        activeButton={activeButton}
                    />
                    <Button onClick={handleOnClose} text={t('workout.workoutBuilder.createWorkout')} />
                </S.ModalBody>
            </S.ModalContainer>
        </Modal>
    );
};

export default CompleteWorkoutModal;
