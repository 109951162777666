import { RESTORE, RETURN_OFFER } from '../constants/ids';

import { trackClick, trackScreenLoad } from 'services/Analytics';

export const trackRestoreClick = (subscriptionType: string, subscription_id: string) =>
    trackClick(RESTORE, { event_action: `restore_${subscriptionType}`, event_label: subscription_id });

export const trackCancelRestoreClick = () => trackClick(`cancel_${RESTORE}`);

export const trackSubscriptionRestoreClick = (subscriptionType: string, subscription_id: string) =>
    trackClick(`${RESTORE}_subscription`, {
        event_action: `restore_${subscriptionType}`,
        event_label: subscription_id,
    });

export const trackRestoreSuccessLoad = () => trackScreenLoad(`${RESTORE}_success`);

export const trackRestoreFailedLoad = () => trackScreenLoad(`${RESTORE}_failed`);

export const trackRestoreFromCRMClick = () =>
    trackClick(RETURN_OFFER, { event_action: `button_${RESTORE}`, event_label: 'subscription' });
