import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { selectDiscountedSubscriptions, selectMainSubscription } from 'store/subscriptions/selectors';
import { getAppropriateDiscountForSubscription } from 'store/subscriptions/helpers';
import { returnSubscription } from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';

import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { RETURN_OFFER, RETURN_OFFER_CRM } from 'analytics/constants/ids';

import { useDiscountProduct } from 'hooks/subscriptions';
import { useModals } from 'hooks/modals';
import { useDispatch, useReturnOfferPageProtection, useSelector, useSubscriptions } from 'hooks';

import { isEmpty } from 'helpers/utils';
import { getSubscriptionsPageUrl } from 'helpers/pathes';

import ReturnOfferContainer from 'containers/Offers/ReturnOffer';

import ReturnOfferSkeleton from 'components/RetrunOfferSkeleton/ReturnOfferSkeleton';

import { IDiscountsForSubscriptions, IDiscountSubscription, ISubscription } from 'types/subscription';
import { Product } from 'types/product';

import {
    trackApplyReturnOffer,
    trackFailedReturnOffer,
    trackReturnOfferFailedScreenLoad,
    trackReturnOfferScreenLoad,
    trackSuccessReturnOffer,
} from 'analytics/trackers/returnOffer';

export const ReturnOffer = () => {
    const { subscriptions } = useSubscriptions();

    useDiscountProduct(subscriptions);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { openModal, closeModal } = useModals();
    const { t } = useTranslation();
    const [isLoading, setLoadingState] = useState(false);

    useEffect(() => {
        trackReturnOfferScreenLoad();
    }, []);

    useReturnOfferPageProtection();

    const discountedSubscriptions: IDiscountsForSubscriptions = useSelector(selectDiscountedSubscriptions);
    const subscription: ISubscription | undefined = useSelector(selectMainSubscription);

    const specialOfferProduct: IDiscountSubscription | undefined = getAppropriateDiscountForSubscription(
        subscription as ISubscription,
        discountedSubscriptions,
        DISCOUNT_TYPES.extra
    );

    const oldProduct = subscription?.product;
    const isLoadingOffer = isEmpty(specialOfferProduct);

    const handleRetrySubmit = () => {
        closeModal();
        handleSubmit(true);
    };

    const handleSubmit = (retry?: boolean) => {
        setLoadingState(true);

        trackApplyReturnOffer(RETURN_OFFER, retry, RETURN_OFFER_CRM);

        dispatch(
            returnSubscription({
                external_id: subscription?.external_id as string,
                product: specialOfferProduct as IDiscountSubscription,
                onError: () => {
                    setLoadingState(false);
                    trackFailedReturnOffer();
                    openModal(ModalName.universalModal, {
                        image: 'errorAttention',
                        trackLoad: trackReturnOfferFailedScreenLoad,
                        onClose: closeModal,
                        onPrimaryClick: handleRetrySubmit,
                        withCloseButton: true,
                        withContactUsLink: true,
                        captions: {
                            title: t('basics.somethingWentWrong'),
                            primaryButton: t('basics.tryAgain'),
                            tertiaryButton: t('basics.contactSupport'),
                        },
                        dataLocators: {
                            modal: 'cancelSubModal',
                            container: 'updatingSubscriptionPlanModal',
                            primaryButton: 'errorModalTryAgain',
                            closeButton: 'errorModalCloseButton',
                        },
                    });
                },
                onSuccess: () => {
                    setLoadingState(false);
                    trackSuccessReturnOffer();
                    navigate(getSubscriptionsPageUrl());
                },
            })
        );
    };

    return (
        <>
            {isLoadingOffer ? (
                <ReturnOfferSkeleton />
            ) : (
                <ReturnOfferContainer
                    handleSubmit={handleSubmit}
                    oldProduct={oldProduct as Product}
                    specialOfferProduct={specialOfferProduct as IDiscountSubscription}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};
