import { ACCOUNT_SUBSCRIPTION, RESUME_SUBSCRIPTION } from '../constants/ids';

import { trackClick, trackNewScreenLoad } from 'services/Analytics';

export const trackResumeSuccessLoad = () =>
    trackNewScreenLoad(ACCOUNT_SUBSCRIPTION, {
        event_action: 'subscription__resume',
        event_label: RESUME_SUBSCRIPTION,
    });

export const trackSubscriptionResumeClick = () =>
    trackClick(ACCOUNT_SUBSCRIPTION, {
        event_action: 'button__resume_subscription',
        event_label: 'subscription',
    });

export const trackSuccessfulResumeContinue = () =>
    trackClick(ACCOUNT_SUBSCRIPTION, { event_action: 'continue', event_label: RESUME_SUBSCRIPTION });

export const trackResumeFailedLoad = () =>
    trackNewScreenLoad(ACCOUNT_SUBSCRIPTION, { event_action: 'error', event_label: RESUME_SUBSCRIPTION });

export const trackResumeRetryClick = () =>
    trackClick(ACCOUNT_SUBSCRIPTION, { event_action: 'try_again', event_label: RESUME_SUBSCRIPTION });
