import { ANDROID } from 'sentry-utils';

import { DEFAULT_LANGUAGE } from 'constants/localization';

export const getVideoInstructionLink = (userLanguage: string, device: string) => {
    const languagesList = ['de', 'en', 'fr', 'it', 'es'];

    const videoLanguage = languagesList.includes(userLanguage)
        ? userLanguage.toUpperCase()
        : DEFAULT_LANGUAGE.toUpperCase();

    if (device === ANDROID) {
        return `https://web.appscdn.io/web/WebDivisionFiles/Public/MB(w)/product/video_instruction_new/MB_instal_manual_Andr_${videoLanguage}.mp4`;
    }

    return `https://web.appscdn.io/web/WebDivisionFiles/Public/MB(w)/product/video_instruction_new/MB_instal_manual_iOS_${videoLanguage}.mp4`;
};
