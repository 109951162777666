/* eslint-disable max-lines */
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';

import { selectDiscountedSubscriptions, selectIsReminderSet } from 'store/subscriptions/selectors';
import { checkBeforeFetchingDiscount, getAppropriateDiscountForSubscription } from 'store/subscriptions/helpers';
import {
    cancelSubscription,
    changeSubscription,
    fetchDiscountSubscriptions,
    pauseSubscription,
    sendFeedback,
    setIsDiscountLoaded,
    setReminder,
} from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';

import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { DISCOUNT_OFFER, SPECIAL_OFFER_DISCOUNT } from 'analytics/constants/ids';

import { useDispatch, useSelector } from 'hooks';

import { isMainSubscription } from 'helpers/subscriptions';
import { getCurrencySign, getPriceForCurrency, getToFixedNumber } from 'helpers/prices';
import { isPauseFlow } from './helpers';

import { CancellationFlow, IUserFeedback } from 'components/Modals/CancellationReasonModal/types';

import { IDiscountsForSubscriptions, IDiscountSubscription, ISubscription } from 'types/subscription';
import { Product, SubscriptionProductCodes } from 'types/product';

import {
    trackCancelSubscription,
    trackCancelSubscriptionFailedEvent,
    trackCancelSubscriptionSuccessEvent,
    trackChangePlan,
    trackCloseDiscountOffer,
    trackReturnDiscountOffer,
    trackSelectCancellationReason,
} from 'analytics/trackers/subscription';
import {
    ReminderErrorClick,
    ReminderSetClick,
    ReminderSuccessClick,
    trackCloseReminderOffer,
    trackFailedReminderSet,
    trackReminderCancelSubscription,
    trackReturnReminderOffer,
    trackSuccessReminderSet,
} from 'analytics/trackers/reminderForSubscription';
import {
    trackClosePauseOffer,
    trackDiscardPauseClick,
    trackPausedApplied,
    trackPausedModalContinueClick,
    trackPauseError,
    trackPauseErrorModalLoad,
    trackPauseRetryClick,
    trackPauseSubmitClick,
    trackPauseSuccessModalLoad,
    trackReturnPauseOffer,
} from 'analytics/trackers/pauseSubscription';
import {
    trackDiscountApplied,
    trackDiscountAppliedClick,
    trackOfferErrorModalLoad,
    trackSpecialOfferLoad,
    trackSuccessChangeProduct,
} from 'analytics/trackers/changeProduct';
import { useCommonSubscriptionLogic } from './index';
import { useModals } from '../modals';

const useCancelSubscription = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openModal, closeModal } = useModals();
    const {
        getDiscountSubscriptionInfo,
        displaySurveyModal,
        displayUpdatingProcessModal,
        displayProcessingIssue,
        displayCancellingIssue,
        displayReturnOffer,
    } = useCommonSubscriptionLogic();

    const cancellationCandidateRef = useRef<ISubscription | null>(null);
    const specialOfferProductRef = useRef<IDiscountsForSubscriptions>({});

    const discountedSubscriptions: IDiscountsForSubscriptions = useSelector(selectDiscountedSubscriptions);
    const isReminderSet: boolean = useSelector(selectIsReminderSet);

    useEffect(() => {
        specialOfferProductRef.current = discountedSubscriptions;
    }, [discountedSubscriptions.length]);

    const initSubscriptionCancellation = (candidate: ISubscription) => {
        cancellationCandidateRef.current = candidate;

        // Check if the discount is already present in the state
        const existingDiscount = getAppropriateDiscountForSubscription(
            candidate,
            discountedSubscriptions,
            DISCOUNT_TYPES.base
        );

        const shouldProceedWithFetch = checkBeforeFetchingDiscount(candidate);

        if (!existingDiscount && shouldProceedWithFetch) {
            dispatch(
                fetchDiscountSubscriptions({
                    discount_type: DISCOUNT_TYPES.base,
                    external_id: candidate?.external_id as string,
                })
            );
        } else {
            dispatch(setIsDiscountLoaded(true));
        }

        handleSurveyModal(onSubmitSurvey);
    };

    const handleDiscountAppliedPopupClosed = (offer: string) => {
        closeModal();
        trackDiscountAppliedClick(offer);
    };

    const onSuccessfulChangeSubscription = (product: IDiscountSubscription) => {
        trackSuccessChangeProduct(DISCOUNT_OFFER, SPECIAL_OFFER_DISCOUNT);

        openModal(ModalName.universalModal, {
            image: 'rising-hands',
            trackLoad: () =>
                trackDiscountApplied(SPECIAL_OFFER_DISCOUNT, cancellationCandidateRef.current?.contentId as string),
            onClose: () => handleDiscountAppliedPopupClosed(SPECIAL_OFFER_DISCOUNT),
            onPrimaryClick: () => handleDiscountAppliedPopupClosed(SPECIAL_OFFER_DISCOUNT),
            withCloseButton: true,
            captions: {
                title: t('subscription.specialOffer.offerApplied.title'),
                extraText: {
                    text: t('subscription.specialOffer.offerApplied.description', {
                        currencySign: getCurrencySign(product?.currency),
                        price: getPriceForCurrency(product),
                        period: cancellationCandidateRef.current?.product.subscription_period,
                    }),
                    color: 'text-main',
                },
                primaryButton: t('basics.continue'),
            },
            dataLocators: {
                modal: 'cancelSubModal',
                container: 'updatingSubscriptionPlanModal',
                primaryButton: 'updatingSubscriptionPlanContinueButton',
                closeButton: 'updatingSubscriptionPlanCloseButton',
            },
        });
    };

    const handleSubmitSpecialOffer = () => {
        const candidate = cancellationCandidateRef.current as ISubscription;
        const specialOfferProduct = getAppropriateDiscountForSubscription(
            candidate,
            specialOfferProductRef.current as IDiscountsForSubscriptions,
            DISCOUNT_TYPES.base
        );

        if (!specialOfferProduct) return;

        const { name, id, currency, billing_period, trial, product_code } = specialOfferProduct;

        const price = getToFixedNumber(getPriceForCurrency(specialOfferProduct) * 100, 0);

        displayUpdatingProcessModal();

        dispatch(
            changeSubscription({
                onError: () => {
                    handleChangingSubscriptionIssue(
                        () => trackOfferErrorModalLoad(DISCOUNT_OFFER, SPECIAL_OFFER_DISCOUNT, candidate.contentId),
                        SPECIAL_OFFER_DISCOUNT,
                        handleSubmitSpecialOffer
                    );
                },
                onSuccess: () => {
                    onSuccessfulChangeSubscription(specialOfferProduct);
                },
                subscription: {
                    subscription_id: candidate.external_id,
                    product: {
                        id,
                        amount: price,
                        name: name,
                        currency: currency,
                        subscription_period: billing_period.value,
                        subscription_trial_period: trial?.billing_period.value,
                        product_code,
                    },
                },
            })
        );
    };

    const moveBack = () => {
        closeModal();

        if (cancellationCandidateRef.current) {
            initSubscriptionCancellation(cancellationCandidateRef.current);
        }
    };

    const handleChangingSubscriptionIssue = (
        trackLoad: () => void,
        eventLabel: string,
        retryCall: (discountedSubscriptions?: IDiscountSubscription, candidate?: ISubscription) => void
    ) => {
        displayProcessingIssue(trackLoad, eventLabel, retryCall);
    };

    const onCancelSubscription = () => {
        const candidate = cancellationCandidateRef.current as ISubscription;

        if (!candidate) return closeModal();

        const runCancel = () => {
            dispatch(
                cancelSubscription({
                    externalId: candidate?.external_id,
                    onError: () => {
                        trackCancelSubscriptionFailedEvent();
                        displayCancellingIssue(onCancelSubscription);
                    },
                    onSuccess: () => {
                        displayReturnOffer(candidate, true);
                        trackCancelSubscriptionSuccessEvent();
                    },
                    onCancel: closeModal,
                })
            );
        };

        displayUpdatingProcessModal();

        const shouldProceedWithFetch = checkBeforeFetchingDiscount(candidate);

        if (shouldProceedWithFetch) {
            dispatch(
                fetchDiscountSubscriptions({
                    discount_type: DISCOUNT_TYPES.extra,
                    external_id: candidate?.external_id as string,
                    onError: () => {
                        runCancel();
                    },
                    onSuccess: () => {
                        runCancel();
                    },
                })
            );
        } else {
            runCancel();
        }
    };

    const onSubmitSurvey = ({ reason }: IUserFeedback) => {
        const candidate = cancellationCandidateRef.current as ISubscription;
        const discountedSubscription: IDiscountSubscription | undefined = getAppropriateDiscountForSubscription(
            candidate,
            specialOfferProductRef.current as IDiscountsForSubscriptions,
            DISCOUNT_TYPES.base
        );
        const isCurrentSubscriptionMain = isMainSubscription(candidate.product_code);

        const theReason = reason ? reason.shortKey : '';

        dispatch(
            sendFeedback({
                reason: theReason,
            })
        );

        trackSelectCancellationReason(theReason);

        if (reason?.flow === CancellationFlow.withReminderOffer && !isReminderSet && isCurrentSubscriptionMain) {
            onReminderOffer();

            return;
        }

        const shouldRunPauseFlow = isPauseFlow({
            flow: String(reason?.flow),
            productCode: String(candidate?.product_code) as SubscriptionProductCodes,
            wasPaused: Boolean(candidate?.pause_from),
        });

        if (shouldRunPauseFlow) {
            onPauseSubscription();

            return;
        }

        if (!discountedSubscription) {
            // if no appropriate product found - immediately cancelling the subscription
            onCancelSubscription();

            return;
        }

        const { isSpecialOfferLessExpensive, discount } = getDiscountSubscriptionInfo(
            candidate,
            discountedSubscription
        );

        if (isSpecialOfferLessExpensive && !candidate.discount) {
            openModal(ModalName.discountOfferModal, {
                onClose: () => {
                    trackCloseDiscountOffer(discount, candidate.contentId);
                    closeModal();
                },
                onDiscard: () => {
                    trackCancelSubscription();
                    onCancelSubscription();
                },
                onSubmit: () => {
                    trackChangePlan(DISCOUNT_OFFER, SPECIAL_OFFER_DISCOUNT, candidate.contentId);
                    handleSubmitSpecialOffer();
                },
                onMoveBack: () => {
                    trackReturnDiscountOffer(discount);
                    moveBack();
                },
                trackLoad: () => trackSpecialOfferLoad(candidate.contentId),
                captions: {
                    headerTitle: t('subscription.text.cancel'),
                    title: 'subscription.discountOffer.title',
                    firstBadgeText: t('subscription.cancellation.specialOffer.firstBadge'),
                    secondBadgeText: t('subscription.cancellation.specialOffer.withDynamicPercent.secondBadge', {
                        discount: discount,
                    }),
                    subTitle: t('subscription.discountOffer.subTitle', { discount: discount }),
                    extraText: t('subscription.discountOffer.disclaimerNew'),
                    submitButton: t('subscription.discountOffer.button.changePlan'),
                    discardButton: t('subscription.text.cancel'),
                    periodTitle: t('subscription.cancellation.specialOffer.namePeriodICU', {
                        period: discountedSubscription.billing_period.value,
                    }),
                    period: discountedSubscription.billing_period.value,
                    bottomDisclaimer: t('subscription.discountOffer.bottomDisclaimer.text'),
                },
                oldProduct: candidate?.product as Product,
                specialOfferProduct: discountedSubscription,
                discount,
                isCurrentSubscriptionMain,
            });

            return;
        }

        console.warn('Special offer is not available');
        onCancelSubscription();
    };

    const handleSurveyModal = (onSubmitSurvey: ({ reason }: IUserFeedback) => void) => {
        displaySurveyModal(onSubmitSurvey, DISCOUNT_OFFER);
    };

    const onPauseSubscription = () => {
        const onPauseSubmit = () => {
            trackPauseSubmitClick();
            const candidate = cancellationCandidateRef.current as ISubscription;

            if (!candidate) return closeModal();

            displayUpdatingProcessModal();

            dispatch(
                pauseSubscription({
                    external_id: candidate.external_id,
                    onSuccess: () => {
                        openModal(ModalName.universalModal, {
                            image: 'rising-hands',
                            trackLoad: () => {
                                trackPausedApplied();
                                trackPauseSuccessModalLoad();
                            },
                            onClose: closeModal,
                            onPrimaryClick: () => {
                                closeModal();
                                trackPausedModalContinueClick();
                            },
                            withCloseButton: true,
                            captions: {
                                title: t('subscription.pause.success.title'),
                                primaryButton: t('basics.continue'),
                            },
                            dataLocators: {
                                modal: 'cancelSubModal',
                                container: 'updatingSubscriptionPlanModal',
                                primaryButton: 'updatingSubscriptionPlanContinueButton',
                                closeButton: 'updatingSubscriptionPlanCloseButton',
                            },
                        });
                    },
                    onError: () => {
                        openModal(ModalName.universalModal, {
                            image: 'errorAttention',
                            trackLoad: () => {
                                trackPauseError();
                                trackPauseErrorModalLoad();
                            },
                            onClose: closeModal,
                            onPrimaryClick: () => {
                                onPauseSubmit();
                                trackPauseRetryClick();
                            },
                            withCloseButton: true,
                            withContactUsLink: true,
                            captions: {
                                title: t('subscription.updating.issueModal.title'),
                                primaryButton: t('basics.tryAgain'),
                                tertiaryButton: t('basics.contactSupport'),
                            },
                            dataLocators: {
                                modal: 'errorCancelSubModal',
                                container: 'errorSubscriptionPlanModal',
                                primaryButton: 'errorModalTryAgain',
                                closeButton: 'errorModalCloseButton',
                            },
                        });
                    },
                })
            );
        };

        openModal(ModalName.pauseSubscriptionModal, {
            onClose: () => {
                trackClosePauseOffer();
                closeModal();
            },
            onDiscard: () => {
                onCancelSubscription();
                trackDiscardPauseClick();
            },
            onSubmit: onPauseSubmit,
            onMoveBack: () => {
                trackReturnPauseOffer();
                handleSurveyModal(onSubmitSurvey);
            },
        });
    };

    const onReminderOffer = () => {
        const candidate = cancellationCandidateRef.current as ISubscription;
        const subscriptionPeriod = candidate.product.subscription_period;

        const onReminderSubmit = () => {
            ReminderSetClick();

            if (!candidate) return closeModal();

            openModal(ModalName.waitingModal, {
                captions: {
                    title: t('basics.loading'),
                },
                dataLocatorContainer: 'updatingSubscriptionPlanLoader',
                styles: {
                    paddingTop: 28,
                    paddingBottom: 32,
                    textType: 'h5',
                },
            });

            dispatch(
                setReminder({
                    external_id: candidate.external_id,
                    onSuccess: () => {
                        trackSuccessReminderSet();

                        openModal(ModalName.reminderSuccessModal, {
                            onClose: closeModal,
                            onSubmit: onReminderSuccessModalClick,
                            subscriptionPeriod,
                        });
                    },
                    onError: () => {
                        trackFailedReminderSet();

                        openModal(ModalName.errorModal, {
                            onClose: closeModal,
                            onSubmit: onErrorModalClick,
                        });
                    },
                })
            );
        };

        const onReminderSuccessModalClick = () => {
            ReminderSuccessClick();
            closeModal();
        };

        const onErrorModalClick = () => {
            ReminderErrorClick();
            onReminderSubmit();
        };

        openModal(ModalName.reminderOfferModal, {
            onClose: () => {
                trackCloseReminderOffer();
                closeModal();
            },
            onDiscard: () => {
                trackReminderCancelSubscription();
                onCancelSubscription();
            },
            onSubmit: onReminderSubmit,
            onMoveBack: () => {
                trackReturnReminderOffer();
                handleSurveyModal(onSubmitSurvey);
            },
            subscriptionPeriod,
        });
    };

    return {
        initSubscriptionCancellation,
    };
};

export default useCancelSubscription;
