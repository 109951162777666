import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

import { PRIVATE } from 'router/routes';

import { selectIsOneYearMainSubscription, selectIsReturnOfferApplied } from 'store/subscriptions/selectors';
import { restoreSubscription } from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';
import { closeModal, openModal } from 'store/modals/actions';
import { isUserFromCRM } from 'store/auth/selectors';

import { PRODUCT_CODES, PRODUCT_TYPES } from 'constants/product';
import { CURRENCY_SIGN_MAP } from 'constants/currency';

import { useDispatch, useSelector } from 'hooks';

import { getPriceFromCents } from 'helpers/prices';

import { ISubscription } from 'types/subscription';
import { Product } from 'types/product';

import {
    trackCancelRestoreClick,
    trackRestoreClick,
    trackRestoreFailedLoad,
    trackRestoreFromCRMClick,
    trackRestoreSuccessLoad,
    trackSubscriptionRestoreClick,
} from 'analytics/trackers/restoreSubscription';

const useRestoreSubscription = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isFromCRM: boolean = useSelector(isUserFromCRM);
    const isOneYearSubscription: boolean = useSelector(selectIsOneYearMainSubscription);
    const withAlreadyAppiledOffer: boolean = useSelector(selectIsReturnOfferApplied);

    const productRef = useRef<ISubscription | null>(null);

    const handleCloseModal = () => {
        dispatch(closeModal());
    };

    const initRestoreSubscription = (candidate: ISubscription) => {
        if (isFromCRM && !isOneYearSubscription && !withAlreadyAppiledOffer) {
            trackRestoreFromCRMClick();
            navigate(PRIVATE.RETURN_OFFER.path);

            return;
        }

        const { product, product_code: productCode } = candidate;

        productRef.current = candidate;

        const isVIPSupport = productCode === PRODUCT_CODES.APP_VIP_SUPPORT;
        const subscriptionType = isVIPSupport ? PRODUCT_TYPES.VIP_SUPPORT : PRODUCT_TYPES.PERSONALIZED_PROGRAM;
        const { currency, amount, subscription_period } = product;

        trackRestoreClick(subscriptionType, candidate.external_id);

        dispatch(
            openModal(ModalName.restoreSubscriptionModal, {
                onClose: () => {
                    trackCancelRestoreClick();
                    handleCloseModal();
                },
                onDiscard: () => {
                    trackCancelRestoreClick();
                    handleCloseModal();
                },
                onSubmit: () => {
                    trackSubscriptionRestoreClick(subscriptionType, candidate.external_id);
                    restoreSubmit(candidate?.external_id);
                },
                captions: {
                    title: t('subscription.restore.modal.title'),
                    description: t('subscription.restore.modal.description', {
                        currencySign: CURRENCY_SIGN_MAP[currency],
                        price: getPriceFromCents(amount, currency),
                        period: subscription_period,
                    }),
                    submitButton: t('subscription.restore.modal.accept.buttonText'),
                    discardButton: t('basics.cancel'),
                },
            })
        );
    };

    const handleSuccessfulRestore = () => {
        const { currency, amount, subscription_period } = productRef.current?.product as Product;

        dispatch(
            openModal(ModalName.restoreSubscriptionModal, {
                onClose: () => {
                    handleCloseModal();
                },
                onSubmit: () => {
                    handleCloseModal();
                },
                trackLoad: trackRestoreSuccessLoad,
                captions: {
                    title: t('subscription.planRestored.modal.title'),
                    description: t('subscription.restore.modal.description', {
                        currencySign: CURRENCY_SIGN_MAP[currency],
                        price: getPriceFromCents(amount, currency),
                        period: subscription_period,
                    }),
                    submitButton: t('basics.continue'),
                },
            })
        );
    };

    const restoreSubmit = (external_id: string) => {
        dispatch(
            openModal(ModalName.waitingModal, {
                captions: {
                    title: t('subscription.updatingPlan.inProgress'),
                },
            })
        );
        dispatch(
            restoreSubscription({
                external_id,
                onSuccess: () => {
                    handleSuccessfulRestore();
                },
                onError: () => {
                    trackRestoreFailedLoad();
                    handleCloseModal();
                },
            })
        );
    };

    return {
        initRestoreSubscription,
    };
};

export default useRestoreSubscription;
