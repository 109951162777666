export enum CancellationFlow {
    withCancellingOffer = 'PROCEED_WITH_CANCELLING_OFFER',
    withSupport = 'PROCEED_WITH_SUPPORT',
    withPauseSubscription = 'PROCEED_WITH_PAUSE_SUBSCRIPTION',
    withReminderOffer = 'PROCEED_WITH_REMINDER_OFFER',
}

export interface ICancellationReasonModal {
    onSubmit: (reason: { reason: IReason | null }) => void;
    onDiscard?: () => void;
    onClose: () => void;
    trackLoad?: () => void;
    captions: {
        headerTitle: string;
        additionalInfo?: {
            title: string;
            text: string;
        };
        title: string;
        submitButton: string;
        discardButton?: string;
    };
}

export interface IUserFeedback {
    reason: IReason | null;
}

export interface IReason {
    title: string;
    shortKey: string;
    flow: string;
    preselected?: boolean;
}
