import { workoutTargetAreas } from 'constants/workouts/workouts';

export const getAreasState = (currentArea: string, selectedAreas: string[]) => {
    if (currentArea === 'full') {
        return [currentArea];
    }

    const withoutFull = selectedAreas.filter((area) => area !== 'full');

    const isSelected = withoutFull.includes(currentArea);

    if (isSelected) {
        const withoutCurrent = withoutFull.filter((area) => area !== currentArea);

        if (withoutCurrent.length === 0) {
            return ['full'];
        }

        return withoutCurrent;
    }

    // compare category without full body option and current selected
    if (withoutFull.length === Object.keys(workoutTargetAreas).length - 2) {
        return ['full'];
    }

    return [...withoutFull, currentArea];
};
