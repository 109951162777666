import styled, { keyframes } from 'styled-components';

import { color } from 'theme/selectors';

const loaderPulseAnimation = keyframes`
    0% {
        transform: translate(-50%, -50%) scale(1);
    }

    50% {
        transform: translate(-50%, -50%) scale(1.4);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
`;

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${color('surface-main')};

    > * {
        width: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: ${loaderPulseAnimation} 1s infinite;

        * {
            width: 100%;
        }
    }
`;
