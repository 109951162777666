import * as action from './actions';

import * as actionTypes from './actionTypes';

import { BlockExercise, PlaylistExercise, WorkoutAudioMapType, WorkoutBlocksType, WorkoutType } from 'types/workouts';
import { InferValueTypes } from 'types/commonTypes';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

interface WorkoutsState {
    workout: WorkoutType | null;
    tracks: WorkoutAudioMapType | null;
    blocks: WorkoutBlocksType;
    playlist: BlockExercise[];
    workoutExercises: PlaylistExercise[];
    isLoading: boolean;
}

const initialState: WorkoutsState = {
    workout: null,
    tracks: null,
    isLoading: true,
    blocks: {
        training: {
            id: 0,
            block_time: 0,
            block_type: '',
            repetitions: 0,
            required_unique_exercises: 0,
            not_enough_content: false,
            exercises: [],
        },
    },
    playlist: [],
    workoutExercises: [],
};

const workoutsReducer = (state: WorkoutsState = initialState, action: ActionType): WorkoutsState => {
    switch (action.type) {
        case actionTypes.SET_LOADING_STATE:
            return { ...state, isLoading: action.payload };

        case actionTypes.SET_WORKOUT:
            return {
                ...state,
                isLoading: false,
                workout: action.payload.workout,
                blocks: action.payload.blocks,
                workoutExercises: action.payload.exercises,
                playlist: action.payload.playlist,
            };

        case actionTypes.SET_WORKOUT_AUDIOS:
            return { ...state, tracks: action.payload };

        default:
            return state;
    }
};

export default workoutsReducer;
