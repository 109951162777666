import styled, { css } from 'styled-components';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as Text } from 'assets/images/logo-label.svg';

import { color } from 'theme/selectors';

const baseLogo = css`
    display: block;
    height: 100%;
    width: auto;
`;

export const HeaderContainer = styled.header`
    min-height: 56px;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 24px;
    position: sticky;
    background-color: ${color('surface-default')};
    z-index: 10001;
    filter: drop-shadow(0 5px 6px rgba(0, 0, 0, 0.3));
`;

export const HeaderPublicContainer = styled(HeaderContainer)`
    border: none;
    filter: none;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    max-width: 50%;
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    height: auto;
`;

export const LogoImage = styled(Logo)`
    ${baseLogo};
`;

export const LogoText = styled(Text)`
    ${baseLogo};
    position: relative;
    top: 1px;
`;
