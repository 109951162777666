import { CancellationFlow, IReason } from './types';

export const reasons: IReason[] = [
    {
        title: 'subscription.cancellationReason.forgetToCancel',
        flow: CancellationFlow.withReminderOffer,
        shortKey: 'forget_to_cancel',
    },
    {
        title: 'subscription.cancellationReason.tooExpensive',
        flow: CancellationFlow.withCancellingOffer,
        shortKey: 'too_expensive',
        preselected: true,
    },
    {
        title: 'subscription.cancellationReason.doNotHaveTime',
        flow: CancellationFlow.withPauseSubscription,
        shortKey: 'do_not_have_time',
    },
    {
        title: 'subscription.cancellationReason.noNeedForApp',
        flow: CancellationFlow.withPauseSubscription,
        shortKey: 'no_need_for_the_app',
    },
    {
        title: 'subscription.cancellationReason.disappointedWithContent',
        flow: CancellationFlow.withSupport,
        shortKey: 'disappointed_with_content',
    },
    {
        title: 'subscription.cancellationReason.DoesNotMeetExpectations',
        flow: CancellationFlow.withCancellingOffer,
        shortKey: 'app_not_meet_my_expectations',
    },
    {
        title: 'basics.other',
        flow: CancellationFlow.withCancellingOffer,
        shortKey: 'other',
    },
];

export const DEFAULT_REASON: IReason = reasons.find((reason) => reason.preselected) || {
    title: '',
    flow: CancellationFlow.withCancellingOffer,
    shortKey: '',
    preselected: false,
};
