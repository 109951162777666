import useI18next from 'services/i18n';
import { Outlet, ScrollRestoration, Navigate, useLocation } from 'react-router-dom';

import { useAuthentication, useSelector } from 'hooks';

import routeInterceptor from '../helpers/routeInterceptor';

import Modals from 'modals';

import { PageLoader } from 'components/PageLoader';

const RootLayout = () => {
    const { isComplete, isAuthenticated } = useSelector((store) => store.auth);
    const location = useLocation();

    const { isTranslationsLoaded } = useI18next();

    const isReady = isTranslationsLoaded && isComplete;

    useAuthentication();

    if (isReady) {
        const redirectPath = routeInterceptor({
            pathname: location.pathname,
            isAuthenticated,
        });

        if (redirectPath) return <Navigate to={redirectPath} />;

        return (
            <>
                <ScrollRestoration />
                <Outlet />
                <Modals />
            </>
        );
    }

    return <PageLoader />;
};

export default RootLayout;
