import React, { RefObject } from 'react';

import * as S from './styled';

interface CodeInputProps {
    code: string[];
    isError: boolean;
    handlePaste: (e: React.ClipboardEvent) => void;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    handleKeyDown: (e: React.KeyboardEvent, index: number) => void;
    inputRefs: RefObject<HTMLInputElement>[];
}

const CodeInput = ({ code, isError, handlePaste, handleInputChange, handleKeyDown, inputRefs }: CodeInputProps) => {
    return (
        <S.CodeContainer>
            {code.map((digit, index) => (
                <S.CodeInput
                    key={index}
                    type="number"
                    value={digit}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={handlePaste}
                    maxLength={1}
                    ref={inputRefs[index]}
                    isError={isError}
                />
            ))}
        </S.CodeContainer>
    );
};

export default CodeInput;
