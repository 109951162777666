import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { SUBSCRIPTION_LABEL_STATES } from '../constants';

import { isEmpty } from 'helpers/utils';

import SubscriptionStatusLabel from './subComponents/SubscriptionLabel';
import SubscriptionCardHeader from './subComponents/SubscriptionCardHeader';
import PriceRow from './subComponents/PriceRow';
import PeriodRow from './subComponents/PeriodRow';
import CancelButton from './subComponents/CancelButton';
import BillingPeriod from './subComponents/BillingPeriod';

import * as S from './styled';

import { IDefaultSubscription } from '../types';

import UpcomingProduct from './UpcomingProduct';
import SubscriptionCardWrapper from './SubscriptionCardWrapper';

const DefaultSubscription = ({
    tabIcon,
    heading,
    state,
    subscriptionPeriod,
    price,
    expirationDate,
    onCancelSubscription,
    upcomingProduct,
    isTrial,
    isMainSubscription,
    productCode,
    isDiscountedSubscription,
    isReminderSet,
}: IDefaultSubscription) => {
    const { t } = useTranslation();

    const upcomingProductExists = upcomingProduct && !isEmpty(upcomingProduct);

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader
                icon={tabIcon}
                heading={heading}
                isMainSubscription={isMainSubscription}
                productCode={productCode}
            />
            <S.GeneralInfo mb={16}>
                <S.StatusesWrapper>
                    <SubscriptionStatusLabel state={state} />
                    {isDiscountedSubscription && (
                        <SubscriptionStatusLabel state={SUBSCRIPTION_LABEL_STATES.DISCOUNTED} />
                    )}
                    {isReminderSet && <SubscriptionStatusLabel state={SUBSCRIPTION_LABEL_STATES.REMINDER} />}
                </S.StatusesWrapper>
                <PeriodRow period={subscriptionPeriod} isTrial={isTrial} />
                <PriceRow price={price} />
                <S.SubscriptionCarRow>
                    <Text text={t('subscription.text.expires')} />
                    <Text text={`${expirationDate} UTC`} dataLocator="subscriptionExpirationDate" />
                </S.SubscriptionCarRow>
                {!upcomingProductExists && <BillingPeriod period={subscriptionPeriod} />}
            </S.GeneralInfo>
            <S.Line mb={20} />
            {upcomingProductExists && <UpcomingProduct upcomingProduct={upcomingProduct} />}
            <CancelButton onCancel={onCancelSubscription} />
        </SubscriptionCardWrapper>
    );
};

export default DefaultSubscription;
