import { Text } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import React from 'react';

import { useWorkout } from 'hooks/workout/useWorkout';
import { useWorkoutContext } from '../../hooks';

import { Cross } from 'components/Icon';

import * as S from '../../styled';

import { trackBackButtonClick } from 'analytics/trackers/exercise';
import WorkoutPagination from './WorkoutPagination';

const WorkoutProgressInfo = ({ withClose }: { withClose?: boolean }) => {
    const navigate = useNavigate();

    const { workoutExercises } = useWorkout();

    const { fancyProgressTime, exerciseData, currentIndex, currentPlaylistPosition, progressStore } =
        useWorkoutContext();

    return (
        <S.PlayerHeader>
            <WorkoutPagination
                workoutExercises={workoutExercises}
                currentIndex={currentIndex}
                progress={progressStore}
            />
            <S.TimerWrapper>
                <Text type="medium-text" medium>
                    {fancyProgressTime}
                </Text>

                {withClose && (
                    <S.CrossButton
                        onClick={() => {
                            trackBackButtonClick(exerciseData.id, currentPlaylistPosition);
                            navigate(-1);
                        }}
                    >
                        <Cross />
                    </S.CrossButton>
                )}
            </S.TimerWrapper>
        </S.PlayerHeader>
    );
};

export default WorkoutProgressInfo;
