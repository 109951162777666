import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const Guides = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'border-default'];

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.7354 5.03735H18.2093V17.9679C18.2093 18.8269 17.5103 19.5262 16.6516 19.5262H11.321H7.08203V20.0055C7.08203 20.5544 7.52735 20.9999 8.07601 20.9999H13.4048H18.7335C19.2822 20.9999 19.7275 20.5544 19.7275 20.0055V6.03172C19.7275 5.48285 19.2841 5.03735 18.7354 5.03735Z"
                fill={iconColor}
            />
            <path
                d="M11.3209 18.9625H16.6496C17.1983 18.9625 17.6436 18.517 17.6436 17.9681V5.03761V3.99437C17.6436 3.44549 17.1983 3 16.6496 3H11.3209H9.54898V3.32895C9.55272 3.36278 9.55462 3.3985 9.55462 3.43421V5.60152V6.15791C9.55462 6.93047 8.92704 7.5583 8.15479 7.5583H7.59861H5.43216C5.40398 7.5583 5.37391 7.55642 5.34573 7.55454H5V17.9681C5 18.517 5.44532 18.9625 5.99397 18.9625H7.08378H11.3209Z"
                fill={iconColor}
            />
            <path
                d="M5.43223 6.99423H8.15486C8.15674 6.99423 8.1605 6.99423 8.16238 6.99423C8.61709 6.99048 8.98537 6.62205 8.98913 6.16716C8.98913 6.16528 8.98913 6.16152 8.98913 6.15964V3.43407C8.98913 3.18219 8.78244 3.01489 8.56826 3.01489C8.46491 3.01489 8.36157 3.05249 8.27514 3.13895L5.13536 6.27995C4.8723 6.5431 5.05832 6.99423 5.43223 6.99423Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(Guides);
