import { SUBSCRIPTION_TITLE } from 'constants/subscriptions';
import { PRODUCT_CODES } from 'constants/product';

import { isMainSubscription } from 'helpers/subscriptions';

import { SubscriptionProductCodes } from 'types/product';

const getSubscriptionName = (productCode: SubscriptionProductCodes) => {
    if (isMainSubscription(productCode)) return SUBSCRIPTION_TITLE.PERSONALIZED_PROGRAM;

    if (productCode === PRODUCT_CODES.APP_VIP_SUPPORT) return SUBSCRIPTION_TITLE.APP_VIP_SUPPORT;

    return SUBSCRIPTION_TITLE.APP_ADDITIONAL_SUBSCRIPTION;
};

export default getSubscriptionName;
