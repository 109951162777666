import styled, { css } from 'styled-components';

import { borderRadius, color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const FeedbackSlider = styled.div<{ isActive: boolean }>`
    ::after,
    ::before {
        top: 140px;
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
    }

    ::after {
        left: 50%;
        margin-left: 4px;
        background: ${({ isActive }) => (isActive ? color('text-main') : color('text-secondary-subdued'))};
    }

    ::before {
        right: 50%;
        margin-right: 4px;
        background: ${({ isActive }) => (isActive ? color('text-secondary-subdued') : color('text-main'))};
    }
`;

export const Feedback = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    ${mediaQueries.laptop} {
        margin-bottom: 78px;
    }
`;

export const EmojiWrapper = styled.div`
    display: inline-block;
    margin-bottom: 8px;
`;

export const FeedbackWrapper = styled.div<{ isActive: boolean }>`
    width: 100%;
    display: flex;

    ${({ isActive }) =>
        isActive &&
        css`
            transition: transform 0.6s ease-in-out;
            transform: translateX(calc(-100% - 32px));

            ${mediaQueries.largeTablet} {
                transform: translateX(calc(-100% - 48px));
            }
        `}
`;

export const FeedbackContainer = styled.div`
    min-width: 100%;

    :first-child {
        margin-right: 32px;
    }

    ${mediaQueries.largeTablet} {
        :first-child {
            margin-right: 48px;
        }
    }
`;

export const FeedbackItem = styled.button<{ isActive?: boolean }>`
    text-align: center;
    width: 100%;
    max-width: 196px;
    min-height: 64px;
    padding: 8px 12px;
    background-color: ${({ isActive }) => (isActive ? color('surface-main') : color('surface-hovered'))};

    :not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.05);
    }

    :not(:disabled):hover {
        background-color: ${color('surface-main')};
        cursor: pointer;
    }

    &:first-child {
        border-top-left-radius: ${borderRadius('medium')};
        border-bottom-left-radius: ${borderRadius('medium')};
    }

    &:last-child {
        border-top-right-radius: ${borderRadius('medium')};
        border-bottom-right-radius: ${borderRadius('medium')};
    }
`;
