/* eslint-disable max-lines */
import { unstable_useBlocker as useBlocker } from 'react-router';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { workoutComplete } from 'store/workouts/actions';
import { selectCurrentUser } from 'store/user/selectors';
import { ModalName } from 'store/modals/types';
import { closeModal, openModal } from 'store/modals/actions';

import { DEFAULT_SPEND_TIME } from './constants';

import { useWorkout } from 'hooks/workout/useWorkout';
import { useDispatch, useSelector } from 'hooks';

import isMobile from 'helpers/isMobile';
import { calculateBurnedCalories, fancyTime } from './helpers';

import { WorkoutCompleteParamsType } from 'types/workouts';
import { ExerciseType } from 'types/exercise';
import { ProgressDataType } from './types';

import { trackWorkoutCompleteSuccess } from 'analytics/trackers/workout';
import { trackFinishButtonClick, trackSelectExerciseButtonClick } from 'analytics/trackers/exercise';
import WorkoutContext from './context';

const WorkoutProvider = ({ children }: { children: ReactNode }) => {
    const { exercises: exerciseMap } = useSelector((state) => state.exercises);
    const { weight } = useSelector(selectCurrentUser);

    const dispatch = useDispatch();
    const [currentIndex, setCurrentIndex] = useState(0);
    const playerContainer = useRef<null | HTMLDivElement>(null);

    const [progressStore, setProgress] = useState<{ [key: string]: ProgressDataType }>({});

    const [completeData, setCompleteData] = useState<WorkoutCompleteParamsType | null>(null);

    const blocker = useBlocker(Boolean(!completeData));

    const [totalProgress, updateTotalProgress] = useState(0);

    const { workout, playlist } = useWorkout();

    const prevExercise = playlist[currentIndex - 1];
    const currentExercise = playlist[currentIndex];
    const nextExercise = playlist[currentIndex + 1];

    const exerciseData = exerciseMap[currentExercise.id];
    const nextExerciseData = nextExercise && exerciseMap[nextExercise.id];

    const currentPlaylistPosition = currentIndex + 1;

    const isPageBlocked = blocker.state === 'blocked';

    const stayOnWorkoutPage = () => {
        dispatch(closeModal());
        blocker.reset?.();
    };

    const closeWorkoutPage = () => {
        dispatch(closeModal());
        blocker.proceed?.();
    };

    const toNextExercise = () => {
        const nextIndex = currentIndex + 1;

        if (nextIndex === playlist.length) {
            return onCompleteWorkout();
        }

        setCurrentIndex(nextIndex);
    };

    const toPrevExercise = () => {
        if (!currentIndex) return;

        setCurrentIndex(currentIndex - 1);
    };

    const handleProgress = (id: number, progressData: ProgressDataType) => {
        setProgress({ ...progressStore, [id]: progressData });
        updateTotalProgress(totalProgress + 1);
    };

    const handleExerciseClick = (selectedExercise: ExerciseType) => {
        trackSelectExerciseButtonClick(exerciseData.id, currentPlaylistPosition);
        const selectedExerciseIndex = playlist.findIndex(({ id }) => id === selectedExercise.id);

        setCurrentIndex(selectedExerciseIndex);

        if (playerContainer.current && isMobile.any()) {
            playerContainer.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const fancyProgressTime = fancyTime(totalProgress);
    const fullWorkoutTime = fancyTime(totalProgress, true);

    const onCompleteWorkout = () => {
        if (!workout) return;

        trackFinishButtonClick(exerciseData.id, currentPlaylistPosition);

        const params = {
            workout_id: workout.id,
            calories_burned: calculateBurnedCalories({ playlist, exercises: exerciseMap, weight: weight || 80 }),
            time_spent: totalProgress || DEFAULT_SPEND_TIME,
            created_at: workout.created_at,
            // TODO: wait for the app's release
            // exercise_data: Object.keys(progressStore).map((key) => ({
            //     id: Number(key),
            //     time: progressStore[key].time,
            // })),
            // is_main_workout: false,
        };

        setCompleteData(params);

        dispatch(
            workoutComplete({
                params,
                onSuccess: trackWorkoutCompleteSuccess,
                onError: console.warn,
            })
        );
    };

    const onDisplayReasonAbandon = () => {
        dispatch(openModal(ModalName.workoutReasonAbandon, { onClose: closeWorkoutPage }));
    };

    useEffect(() => {
        if (isPageBlocked) {
            dispatch(
                openModal(ModalName.confirmCancelWorkout, {
                    onCancel: stayOnWorkoutPage,
                    onSubmit: onDisplayReasonAbandon,
                })
            );
        }
    }, [isPageBlocked, stayOnWorkoutPage, onDisplayReasonAbandon]);

    const handleNavigateExercise =
        (moveFunction: () => void, trackFunction: (id: number, position: number) => void) => () => {
            moveFunction();
            trackFunction(exerciseData.id, currentPlaylistPosition);
        };

    const value = {
        handleNavigateExercise,
        fancyProgressTime,
        fullWorkoutTime,
        handleExerciseClick,
        prevExercise,
        exerciseData,
        nextExerciseData,
        completeData,
        isComplete: Boolean(completeData),
        toNextExercise,
        toPrevExercise,
        handleProgress,
        currentExercise,
        currentIndex,
        currentPlaylistPosition,
        progressStore,
        playerContainer,
        isPageBlocked,
    };

    return <WorkoutContext.Provider value={value}> {children} </WorkoutContext.Provider>;
};

export default WorkoutProvider;
