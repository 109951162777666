import { useNavigate } from 'react-router-dom';

import { PRIVATE } from 'router/routes';

import { useSidebar } from 'hooks';

import Logo from 'components/Logo';
import Burger from 'components/Burger';

import * as S from './styled';

import defaultUserPic from 'assets/images/default-avatar.svg';

import { trackUserProfileClick, trackBurgerClick } from 'analytics/trackers/header';

const Header = () => {
    const navigate = useNavigate();

    const { isOpen, toggle, close } = useSidebar();

    const onBurgerClick = () => {
        trackBurgerClick(!isOpen);
        toggle();
    };

    const handleRedirect = () => {
        trackUserProfileClick();
        navigate(PRIVATE.ACCOUNT_DEFAULT.path);
        close();
    };

    return (
        <S.HeaderContainer>
            <S.TitleContainer>
                <Burger onClick={onBurgerClick} isActive={isOpen} />
                <Logo />
            </S.TitleContainer>
            <S.AvatarImage onClick={handleRedirect} src={defaultUserPic} />
        </S.HeaderContainer>
    );
};

export default Header;
