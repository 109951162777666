import { Colors, Theme } from 'wikr-core-components';
import { css } from 'styled-components';

import { withAlpha } from './helpers';

export const color =
    (colorKey: Colors, alpha?: number) =>
    ({ theme }: { theme: Theme }) => {
        const themeColor = theme[colorKey];

        return alpha ? withAlpha(themeColor, alpha) : themeColor;
    };

export const borderRadius =
    (size: 'small' | 'medium') =>
    ({ theme }: { theme: Theme }) => {
        const radius = theme[`border-radius-${size}`] || 0;

        return `${radius}px`;
    };

export const viewPortWithoutHeader = () => css`calc(100vh - 56px);`;
