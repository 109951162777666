import { Text, Button, Image } from 'wikr-core-components';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import QRCode from 'qrcode.react';

import config from 'config';

import { getUserData } from 'store/user/accessors';
import { createProcessingSelector } from 'store/loading/selectors';
import { selectDeepLink } from 'store/deepLink/selectors';
import { GET_DEEP_LINK } from 'store/deepLink/actionTypes';
import { getDeepLinkRequest } from 'store/deepLink/actions';

import { GYM_LOCATION, HOME_LOCATION } from 'constants/user';
import { HIDE_FRONT_CHAT_VALUE } from 'constants/frontChat';

import { showFrontChat } from 'services/FrontChat';

import { useScreenLoadTracker, useSelector } from 'hooks';

import Skeleton from 'components/Skeleton';

import * as S from './styled';

import home from './img/home.webp';
import gym from './img/gym.webp';

import { isDesktopDevice } from 'theme/verifiers';
import {
    trackWorkoutTestPageClick,
    trackWorkoutTestPageHelpClick,
    trackWorkoutTestPageLoad,
} from 'analytics/trackers/installApp';

interface WorkoutCTAContainerProps {
    buttonText: string;
    titleText: string;
    screenId: string;
    eventLabel: string;
    boxDataLocator?: string;
}

export const WorkoutCTAContainer = ({
    buttonText,
    titleText,
    screenId,
    eventLabel,
    boxDataLocator,
}: WorkoutCTAContainerProps) => {
    const dispatch = useDispatch();
    const { training_locations } = getUserData();

    useScreenLoadTracker(() =>
        trackWorkoutTestPageLoad(screenId, training_locations?.includes(GYM_LOCATION) ? GYM_LOCATION : HOME_LOCATION)
    );

    const { t } = useTranslation();
    const deepLinkUrl = useSelector(selectDeepLink);
    const isLoading: boolean = useSelector(createProcessingSelector([GET_DEEP_LINK]));
    const isFrontChatAvailable = config.HIDE_FRONT_CHAT !== HIDE_FRONT_CHAT_VALUE;

    useEffect(() => {
        if (!deepLinkUrl) dispatch(getDeepLinkRequest());
    }, []);

    const displayFrontChat = () => {
        trackWorkoutTestPageHelpClick(screenId);
        showFrontChat();
    };

    const handleDownloadApp = () => {
        trackWorkoutTestPageClick(screenId, eventLabel);

        // Workaround for window.open method since it doesn't work for Safari browser
        Object.assign(document.createElement('a'), {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: deepLinkUrl,
        }).click();
    };

    return (
        <S.Container>
            <S.Title center type="h3" mb={32}>
                <Trans i18nKey={titleText} components={{ Primary: <S.PrimaryTitle /> }} />
            </S.Title>
            <S.Wrapper data-locator={boxDataLocator}>
                <S.CardWrapper>
                    <S.Card>
                        <div>
                            <S.WorkoutOrderText text={t('startWorkoutPage.card.title')} type="overline" bold mb={8} />
                            <Text
                                uppercase
                                text={t('startWorkoutPage.card.subtitle', { location: training_locations })}
                                medium
                            />
                            <Text text={t('startWorkoutPage.card.text')} type="small-text" color="text-secondary" />
                        </div>
                        <Image
                            src={training_locations?.includes(GYM_LOCATION) ? gym : home}
                            alt="workout"
                            maxWidth={training_locations?.includes(GYM_LOCATION) ? 143 : 123}
                        />
                    </S.Card>
                </S.CardWrapper>
                <S.CardPseudo />
            </S.Wrapper>
            {isDesktopDevice ? (
                <S.CodeWrapper>
                    <S.DownloadWrapper>
                        <S.ScanQrCodeWrapper>
                            <S.QRWrapper>
                                {isLoading ? (
                                    <Skeleton borderRadius={8} width={192} height={192} />
                                ) : (
                                    <QRCode value={deepLinkUrl} />
                                )}
                            </S.QRWrapper>
                        </S.ScanQrCodeWrapper>
                    </S.DownloadWrapper>
                    <div>
                        <Text medium text={t('common.downloadMBApp')} mb={4} />
                        <Text type="caption" color="text-secondary" text={t('common.captureQRCode')} />
                    </div>
                </S.CodeWrapper>
            ) : (
                <S.ButtonWrapper>
                    <Button text={t(buttonText)} onClick={handleDownloadApp} />
                    {isFrontChatAvailable && (
                        <S.FrontChatText type="small-text" color="text-secondary" center>
                            <Trans
                                i18nKey="common.contactViaChat"
                                components={{ ChatLink: <S.FrontChatButton onClick={displayFrontChat} /> }}
                            />
                        </S.FrontChatText>
                    )}
                </S.ButtonWrapper>
            )}
        </S.Container>
    );
};
