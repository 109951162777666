import { WorkoutCTAContainer } from 'containers/WorkoutCTAContainer';

export const StartWorkoutPage = () => {
    return (
        <WorkoutCTAContainer
            buttonText="workout.workoutPage.startWorkout"
            titleText="startWorkoutPage.title"
            screenId="first_workout_journey"
            eventLabel="start_workout"
            boxDataLocator="startWorkoutContainer"
        />
    );
};
