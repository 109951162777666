import * as actionTypes from 'store/user/actionTypes';

import { UserWorkoutInfo } from 'types/user/userApiInterface';
import { UserStore } from 'types/store/userStore';
import { SagaActionsProps } from 'types/commonInterfaces';
import { BaseSendEmailRequest, UpdateUserRequest } from './types';

export const getCurrentUserRequest = (payload?: SagaActionsProps) => {
    return {
        type: actionTypes.GET_CURRENT_USER_REQUEST,
        payload,
    } as const;
};

export const setUserData = (payload: UserStore) => {
    return {
        type: actionTypes.SET_USER_DATA,
        payload,
    } as const;
};

export const setUserWorkoutInfo = (payload: UserWorkoutInfo) => {
    return {
        type: actionTypes.SET_USER_WORKOUT_INFO,
        payload,
    } as const;
};

export const getCurrentUserError = (payload: Error) => {
    return {
        type: actionTypes.GET_CURRENT_USER_ERROR,
        payload,
    } as const;
};

export const updateUserEmail = (payload: BaseSendEmailRequest) => {
    return {
        type: actionTypes.UPDATE_USER_EMAIL,
        payload,
    } as const;
};

export const updateUserRequest = (payload: UpdateUserRequest) => {
    return {
        type: actionTypes.UPDATE_USER_REQUEST,
        payload,
    } as const;
};

export const updateUserSuccess = (payload: UserStore) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        payload,
    } as const;
};

export const updateUserError = (payload: Error) => {
    return {
        type: actionTypes.UPDATE_USER_ERROR,
        payload,
    } as const;
};

export const resetUserData = () => {
    return {
        type: actionTypes.RESET_USER_DATA,
    } as const;
};

export const updateUserProductCodes = (productCodes: Record<string, boolean>) => {
    return {
        type: actionTypes.UPDATE_PRODUCT_CODES,
        payload: productCodes,
    } as const;
};

export const setUserRegion = (payload: string | null) => {
    return {
        type: actionTypes.SET_USER_REGION,
        payload,
    } as const;
};
