import ApiClient from 'services/ApiClient';

import { OneClickPaymentParams } from 'types/payments';
import { IResultResponse } from '../types/response';

class PaymentApi {
    oneClickPayment = async <Data = OneClickPaymentParams, Response = IResultResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post<Data, Response>('payments/recurring', payload);
    };
}

export default PaymentApi;
