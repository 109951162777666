import { USER_FROM_CRM } from 'hooks/common/constants';

import { RootReducer } from '../rootReducer';

export const isAuthenticatedSelector = (state: RootReducer) => state.auth.isAuthenticated;
export const authRedirectURLSelector = (state: RootReducer) => state.auth.redirectUrl;
export const isAuthenticationCompleteSelector = (state: RootReducer) => state.auth.isComplete;
export const getUserSource = (state: RootReducer) => state.auth.userSource;
export const isUserFromCRM = (state: RootReducer) => {
    const userSource = getUserSource(state);

    return userSource === USER_FROM_CRM;
};
