import { Button, Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { ProductCodes, PRODUCTS_THUMBNAILS } from 'constants/products/upsell';
import { DEFAULT_CURRENCY } from 'constants/currency';

import { getPriceFromCents, prettyPrice } from 'helpers/prices';

import { CheckCircle } from 'components/Icon';

import * as S from '../styled';

import plusIcon from 'assets/img/svg/plus.svg';

import { UpsellSubscriptionProductType } from 'types/subscription';

import useProductInfo from '../useProductInfo';

const Product = ({
    product,
    handlePurchase,
    isProcessing,
    price,
    name,
}: {
    product: UpsellSubscriptionProductType;
    price: number;
    handlePurchase: () => void;
    isProcessing: boolean;
    name: string;
}) => {
    const { t } = useTranslation();
    const productCode = product.product_code as ProductCodes;

    const { subProductsCodes, productCardInfo } = useProductInfo(productCode);

    const productThumbnail = PRODUCTS_THUMBNAILS[productCode];

    const currentPrice = getPriceFromCents(price);

    const cabinetUrl = window.location.host;

    const { [productCode]: productUiData } = productCardInfo;

    const productTips = productUiData.tips;

    return (
        <>
            <S.ProductTitle type="h6" mb={16} medium>
                {productUiData.title}
            </S.ProductTitle>
            <Image src={productThumbnail} alt={name} mb={32} />
            <S.ProductInfoBlock key={product.product_code}>
                <S.SalePercents>
                    <Text type="small-text">
                        {t('payment.discount.label', {
                            percents: product.discount,
                        })}
                    </Text>
                </S.SalePercents>

                <S.TitleWrapper>
                    <div>
                        {subProductsCodes.map((code, index) => {
                            const subProductUiData = productCardInfo[code as ProductCodes];

                            return (
                                <div key={code}>
                                    <S.Title>
                                        <S.ProductEmoji>{index === 0 ? '💪' : '🔥'}</S.ProductEmoji>

                                        <Text key={code}>{subProductUiData.title}</Text>
                                    </S.Title>

                                    {subProductsCodes.length > 1 && index + 1 < subProductsCodes.length && (
                                        <S.PlusIcon>
                                            <img src={plusIcon} alt="plus" />
                                        </S.PlusIcon>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </S.TitleWrapper>
                <S.Price>
                    <div>
                        <S.OldPrice center color="text-secondary-subdued" type="small-text">
                            {DEFAULT_CURRENCY.sign}
                            {prettyPrice(getPriceFromCents(product.start_price))}
                        </S.OldPrice>
                        <Text center color="text-main">
                            {DEFAULT_CURRENCY.sign}
                            {prettyPrice(getPriceFromCents(price))}
                        </Text>
                        <Text
                            center
                            type="small-text"
                            color="text-secondary-subdued"
                            text={t('payment.period.perMonth')}
                        />
                    </div>
                </S.Price>
            </S.ProductInfoBlock>

            <S.TipsList>
                {productTips.map((tip) => (
                    <li key={tip}>
                        <CheckCircle color="text-secondary-subdued" />
                        <Text type="large-text" color="border-subdued">
                            {tip}
                        </Text>
                    </li>
                ))}
            </S.TipsList>

            <S.LegalText type="caption" center mb={32} color="text-secondary-subdued">
                {t('payment.disclaimer', {
                    sign: DEFAULT_CURRENCY.sign,
                    price: currentPrice,
                    cabinetUrl: cabinetUrl,
                    buttonText: t('basics.continue').toUpperCase(),
                })}
            </S.LegalText>

            <S.ButtonWrp>
                <Button onClick={handlePurchase} isLoading={isProcessing}>
                    {t('basics.continue')}
                </Button>
            </S.ButtonWrp>
        </>
    );
};

export default Product;
