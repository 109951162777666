import * as actionTypes from './actionTypes';

import { ExerciseDetails, ExerciseMapType } from 'types/exercise';

export const fetchExercises = (workoutId?: number) => {
    return {
        type: actionTypes.FETCH_EXERCISES,
        payload: {
            workoutId,
        },
    } as const;
};

export const getDetails = (exerciseId?: number) => {
    return {
        type: actionTypes.GET_DETAILS,
        payload: {
            exerciseId,
        },
    } as const;
};

export const saveDetails = (details: ExerciseDetails) => {
    return {
        type: actionTypes.SAVE_DETAILS,
        payload: details,
    } as const;
};

export const setExercises = (payload: ExerciseMapType) => {
    return {
        type: actionTypes.SET_EXERCISES,
        payload,
    } as const;
};

export const setLoadingState = (payload: boolean) => {
    return {
        type: actionTypes.SET_LOADING_STATE,
        payload,
    } as const;
};
