import styled from 'styled-components';

import { color } from 'theme/selectors';

export const SwitchToggle = styled.label`
    width: 100%;
    height: 100%;
    background: ${color('surface-default')};
    position: absolute;
    display: block;
    cursor: pointer;
    transition: 0.3s ease;

    &:after {
        position: absolute;
        top: 50%;
        left: 2px;
        height: 20px;
        width: 20px;
        margin-top: -10px;
        background: ${color('action-main')};
        border-radius: 50%;
        content: '';
        transition: 0.3s ease;
    }
`;

export const SwitchBox = styled.div`
    width: 44px;
    height: 24px;
    position: relative;
    display: inline-block;
    border-radius: 12px;
    overflow: hidden;
    margin-left: 12px;

    input {
        display: none;
    }

    input:checked + ${SwitchToggle} {
        background: ${color('primary-default')};

        &:after {
            left: 100%;
            margin-left: -22px;
            background: ${color('text-main')};
        }
    }
`;
