import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { selectDeepLink } from 'store/deepLink/selectors';
import { getDeepLinkRequest } from 'store/deepLink/actions';

import { useDispatch, useSelector, useScreenLoadTracker } from 'hooks';

import isMobile from 'helpers/isMobile';

import SecondStep from './components/SecondStep';
import InstallAppFooter from './components/InstallAppFooter';
import FirstStep from './components/FirstStep';

import * as S from './styled';

import { isTabletDevice } from 'theme/verifiers';
import { trackAppStoreClick, trackGooglePayClick, trackInstallAppPageLoad } from 'analytics/trackers/installApp';

export const InstallApp = ({ isManualPage = false }) => {
    useScreenLoadTracker(() => trackInstallAppPageLoad(isManualPage));

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const deepLinkUrl = useSelector(selectDeepLink);

    const isAndroid = isMobile.android();
    const isIos = isMobile.iOS();

    const handleDownloadApp = () => {
        if (isAndroid) trackGooglePayClick(isManualPage);
        if (isIos) trackAppStoreClick(isManualPage);

        // Workaround for window.open method since it doesn't work for Safari browser
        Object.assign(document.createElement('a'), {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: deepLinkUrl,
        }).click();
    };

    useEffect(() => {
        if (!deepLinkUrl) dispatch(getDeepLinkRequest());
    }, []);

    return (
        <S.Container centered={isManualPage}>
            <S.ContentContainer>
                <S.Title
                    type={isTabletDevice ? 'h3' : 'h5'}
                    mb={isTabletDevice ? 40 : 24}
                    bold
                    text={`${t('main.title')} 💪`}
                />
                <S.StepContainer>
                    <FirstStep isManualPage={isManualPage} />
                    <SecondStep />
                </S.StepContainer>

                <InstallAppFooter handleDownloadApp={handleDownloadApp} isManualPage={isManualPage} />
            </S.ContentContainer>
        </S.Container>
    );
};
