import { Button } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from '../../../theme/selectors';

export const Img = styled.img`
    margin: 30px auto 12px;
    display: block;
`;

export const ConfirmButton = styled(Button)`
    border: 1px solid ${color('border-default')};
    padding: 20px;
    background-color: transparent;
    color: ${color('border-default')};
    max-width: 100%;

    &:hover {
        background-color: ${color('surface-hovered')} !important;
        color: ${color('surface-hovered')} !important;
    }
`;
