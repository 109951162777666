import { Trans } from 'react-i18next';
import React, { useEffect, useRef } from 'react';

import { progressCount } from './helpers';

import * as S from './styled';

const ProgressBar = () => {
    const progressBar = useRef<HTMLDivElement | null>(null);
    const progressNumber = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        progressCount(progressBar, progressNumber);
    }, []);

    return (
        <S.ProgressButtonContainer>
            <S.ProgressBar ref={progressBar} />
            <S.ButtonWrapper>
                <S.ButtonText type="medium-button" bold>
                    <Trans i18nKey="workout.workoutBuilder.createWorkout" />
                </S.ButtonText>
                <S.ProgressNumber ref={progressNumber}>0%</S.ProgressNumber>
            </S.ButtonWrapper>
        </S.ProgressButtonContainer>
    );
};

export default ProgressBar;
