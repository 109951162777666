import { Text } from 'wikr-core-components';
import React, { memo } from 'react';

import * as S from '../styled';

const LaunchCountdownNumber = ({ text }: any) => (
    <S.LaunchCountdown>
        <Text type="h3" italic>{`${text}`}</Text>
    </S.LaunchCountdown>
);

const LaunchCountdown = ({ time, total = 5 }: any) => {
    const seconds = new Array(total).fill('').map((_, index) => () => <LaunchCountdownNumber text={index + 1} />);

    seconds.unshift(() => <LaunchCountdownNumber text="GO!" />);

    const Current = seconds[time - 1];

    if (!Current) return null;

    return <Current />;
};

export default memo(LaunchCountdown);
