import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from '../styled';

import { SidebarLinkButtonProps } from '../types';

const SidebarLinkButton = ({ isActive, title, icon: Icon }: SidebarLinkButtonProps) => {
    const { t } = useTranslation();

    return (
        <S.NavButton isActive={isActive}>
            <Text type="medium-text" color="text-main">
                {Icon && <Icon color="text-secondary" />} {t(title)}
            </Text>
        </S.NavButton>
    );
};

export default SidebarLinkButton;
