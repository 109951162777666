import { Button, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import appConfig from 'appConfig';

import { isEmpty } from 'helpers/utils';

import { EmailIcon } from 'components/Icon';
import FormikInput from 'components/FormikInput';

import * as S from './styled';

import { trackStartSurvey } from 'analytics/trackers/login';

interface EmailFormProps {
    handleSubmit: () => void;
    setFieldTouched: (field: string) => void;
    initialValues: Record<string, any>;
    setFieldValue: (field: string, value: any) => void;
    touched: Record<string, boolean>;
    values: Record<string, any>;
    errors: Record<string, string>;
    dirty: boolean;
    isFetching: boolean;
}

export const EmailForm = ({
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    initialValues,
    isFetching,
    values,
    touched,
    errors,
    dirty,
}: EmailFormProps) => {
    const { t } = useTranslation();

    return (
        <S.Container>
            <Text text={t('login.title')} type="h4" mb={12} center />
            <Text text={t('login.subTitle')} color="text-secondary" center />
            <Text mb={24} center>
                <a href={appConfig.links.onboarding} data-locator="surveyButton" onClick={trackStartSurvey}>
                    {t('login.survey')}
                </a>
            </Text>

            <S.Form onSubmit={handleSubmit}>
                <FormikInput
                    mb={52}
                    type="email"
                    field="email"
                    label="input.email.label"
                    setFieldTouched={setFieldTouched}
                    initialValues={initialValues}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    errors={errors}
                    leftIcon={() => <EmailIcon />}
                    dataLocator="loginEmailInput"
                    placeholder="input.email.label"
                />
                <Button
                    text={t('basics.login')}
                    disabled={!dirty || !isEmpty(errors) || isFetching}
                    isLoading={isFetching}
                    onClick={handleSubmit}
                    dataLocator="loginButton"
                />
            </S.Form>
        </S.Container>
    );
};
