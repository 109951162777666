/* eslint-disable max-lines */
import { Main } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_CABINET } from 'sentry-utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';

import { PRIVATE } from 'router/routes';

import api from 'api';

import { selectCurrentUser } from 'store/user/selectors';
import { updateUserProductCodes } from 'store/user/actions';
import { ModalName } from 'store/modals/types';
import { selectDeepLink } from 'store/deepLink/selectors';

import { MOCKED_PRODUCTS } from 'constants/products/upsell';
import { SHOP_SUBSCRIPTIONS } from 'analytics/constants/ids';

import sentry from 'services/Sentry/SentryInstance';
import { showFrontChat } from 'services/FrontChat';

import useNotify from 'hooks/notification/useNotify';
import { useModals } from 'hooks/modals';
import { useDispatch, useScreenLoadTracker, useSelector } from 'hooks';

import { getPriceFromCents } from 'helpers/prices';
import { doesObjectContainKey } from 'helpers/doesObjectContainKey';

import Product from './components/Product';

import * as S from './styled';

import {
    submitDoublePurchaseModal,
    trackContactSupportClick,
    trackContinueBuyClick,
    trackDeclinePaymentModalLoad,
    trackDoublePurchaseModalLoad,
    trackGoToMainClick,
    trackShopPageLoad,
    trackTryAgainClick,
} from 'analytics/trackers/shop';
import useProductInfo from './useProductInfo';

const Shop = () => {
    // @ts-ignore
    const { productCode }: { productCode: keyof typeof MOCKED_PRODUCTS } = useParams();
    const product = MOCKED_PRODUCTS[productCode];

    useScreenLoadTracker(trackShopPageLoad(productCode));

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { openModal, closeModal } = useModals();
    const { notifySuccess } = useNotify();
    const [isProcessing, setProcessingStatus] = useState(false);
    const { subProductsCodes, title, userProductsTitle } = useProductInfo(product.product_code);
    const deepLinkUrl = useSelector(selectDeepLink);

    const user = useSelector(selectCurrentUser);
    const currentPrice = product.actual_price;

    const hasAnySubProductCode = (subProductCodesArray: string[], productList: Record<string, boolean> | null) => {
        return subProductCodesArray.some((code) => doesObjectContainKey(productList, code));
    };

    const checkAndDisplayModal = (
        productList: Record<string, boolean> | null,
        productCode: string,
        subProductsCodes: string[]
    ) => {
        if (doesObjectContainKey(productList, productCode) || hasAnySubProductCode(subProductsCodes, productList)) {
            displayDoublePurchaseModal();
        }
    };

    useEffect(() => {
        checkAndDisplayModal(user.product_list, productCode, subProductsCodes);
    }, []);

    const handlePurchase = async () => {
        setProcessingStatus(true);

        try {
            // @ts-ignore
            const response: {
                order: any;
                error?: any;
            } = await api.payments.oneClickPayment({
                product_id: product.solid_id,
                product_code: product.product_code,
            });

            if (response?.error) {
                sentry.logError(
                    new Error(
                        `${response?.error?.code?.recommended_message_for_user || 'PAYMENT'}: ${response?.error.code}`
                    ),
                    SENTRY_CABINET,
                    ERROR_LEVELS.CRITICAL,
                    response?.error
                );

                setProcessingStatus(false);
                displayPaymentDeclinedModal();

                return;
            }

            dispatch(
                updateUserProductCodes({
                    [product.product_code]: true,
                })
            );

            notifySuccess('Your payment was successful! Thank you!');

            const { order } = response;

            const analyticData = {
                currency: order.currency,
                price: getPriceFromCents(currentPrice),
                screen_id: SHOP_SUBSCRIPTIONS,
                ab_test_name: user.ab_test_name,
                order_type: 'subscription',
                value: product.ltv,
                content_id: product.content_id,
                tariff: product.solid_id,
                payment: 'paypal',
                order_id: order.order_id,
                subscription_id: order.subscription_id,
            };

            Main.purchase(analyticData);

            navigate(PRIVATE.MAIN.path);
        } catch (error) {
            // @ts-ignore
            const { response } = error;

            setProcessingStatus(false);

            // Double purchase back-end validation
            // @ts-ignore
            if (response?.data?.error_product_code) {
                displayDoublePurchaseModal();
            } else {
                displayPaymentDeclinedModal();
            }
        }
    };

    const displayDoublePurchaseModal = () => {
        openModal(ModalName.universalModal, {
            trackLoad: trackDoublePurchaseModalLoad(productCode),
            image: 'rising-hands',
            captions: {
                title: t('payment.error.doublePurchase.subscription.title', {
                    name: userProductsTitle,
                }),
                subtitle: t('payment.error.doublePurchase.description'),
                secondaryButton: t('subscription.button.text.goToApp').toUpperCase(),
            },
            onSecondaryClick: () => {
                submitDoublePurchaseModal(productCode);

                Object.assign(document.createElement('a'), {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    href: deepLinkUrl,
                }).click();
            },
        });
    };

    const displayPaymentDeclinedModal = () => {
        openModal(ModalName.universalModal, {
            image: 'decline',
            trackLoad: trackDeclinePaymentModalLoad(productCode),
            captions: {
                title: t('basics.payment.decline'),
                extraText: {
                    text: t('basics.appError'),
                    color: 'text-secondary',
                },
                primaryButton: t('basics.contactSupport'),
                secondaryButton: t('basics.goToMain'),
                tertiaryButton: t('basics.tryAgain'),
            },
            onPrimaryClick: () => {
                trackContactSupportClick(productCode);
                showFrontChat();
            },
            onSecondaryClick: () => {
                trackGoToMainClick(productCode);
                closeModal();
            },
            onTertiaryClick: () => {
                trackTryAgainClick(productCode);
                closeModal();
                handlePurchase();
            },
        });
    };

    return (
        <S.ProductWrapper>
            <Helmet title={title} />

            {product && (
                <Product
                    handlePurchase={() => {
                        trackContinueBuyClick(productCode);
                        handlePurchase();
                    }}
                    name={title}
                    price={currentPrice}
                    isProcessing={isProcessing}
                    product={product}
                />
            )}
        </S.ProductWrapper>
    );
};

export default Shop;
