import { useState, useEffect, useRef } from 'react';

import { getWorkoutAudios } from 'store/workouts/actions';

import { useDispatch, useSelector } from 'hooks';

import { WorkoutAudioType } from 'types/workouts';

type audioRefs = {
    [key: string]: HTMLAudioElement | null;
};

type AudiosLibrary = {
    audios: {
        [key: string]: {
            play: () => void;
        };
    };
    stopAll: () => void;
};

const useWorkoutAudio = ({ volume, isAvailable }: { volume: number; isAvailable: boolean }): AudiosLibrary => {
    const dispatch = useDispatch();

    const tracks = useSelector((state) => state.workouts.tracks);

    const audios = useRef<audioRefs>({ start: null, finish: null, preEnd: null });

    const [iterator, setIterator] = useState(0);

    const loadAudio = (url: string, callback: (audio: HTMLAudioElement) => void) => {
        const audio = new Audio(url);

        audio.volume = volume;

        audio.oncanplaythrough = () => {
            callback(audio as HTMLAudioElement);
        };
    };

    useEffect(() => {
        if (!tracks && isAvailable) {
            dispatch(getWorkoutAudios());
        }
    }, []);

    useEffect(() => {
        if (tracks) {
            const timeLeftCategory: WorkoutAudioType[] = tracks.time_left;

            if (timeLeftCategory?.[iterator]) {
                loadAudio(timeLeftCategory[iterator].url, (track) => {
                    audios.current.preEnd = track;
                });
            }
        }
    }, [tracks, iterator]);

    useEffect(() => {
        if (tracks) {
            if (!audios.current.start && tracks.start?.[0]) {
                loadAudio(tracks.start[0].url, (track) => {
                    audios.current.start = track;
                });
            }

            if (!audios.current.finish && tracks.finish?.[0]) {
                loadAudio(tracks.finish[0].url, (track) => {
                    audios.current.finish = track;
                });
            }
        }
    }, [tracks]);

    useEffect(() => {
        for (const key in audios.current) {
            const audioInstance = audios.current[key];

            if (audioInstance) {
                audioInstance.volume = volume;
            }
        }
    }, [audios.current, volume]);

    const stopAll = () => {
        for (const key in audios.current) {
            const audioInstance = audios.current[key];

            if (audioInstance) {
                audioInstance.pause();
            }
        }
    };

    const play = (audio: HTMLAudioElement | null, callback?: () => void) => {
        if (!isAvailable) return;

        if (audio)
            audio.play().then(() => {
                callback?.();
            });
    };

    return {
        audios: {
            start: {
                play: () => play(audios.current.start),
            },
            finish: {
                play: () => play(audios.current.finish),
            },
            preEnd: {
                play: () =>
                    play(audios.current.preEnd, () => {
                        const next = iterator + 1;
                        const max = tracks?.time_left?.length || 0;

                        setIterator(next < max ? next : 0);
                    }),
            },
        },
        stopAll,
    };
};

export default useWorkoutAudio;
