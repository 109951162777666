import { ERROR_LEVELS, DEEP_LINK_ERROR } from 'services/Sentry/constants';

import sentry from 'services/Sentry/SentryInstance';

const ERROR_MESSAGE_IGNORED_IN_SENTRY = 'Request failed with status code 400';

export const logDeepLinkError = (error: any) => {
    const backEndErrorMessage =
        (error?.request?.response ? parseJson(error.request.response).error : null) ||
        error.message ||
        'No error message from BE';

    if (error.message === ERROR_MESSAGE_IGNORED_IN_SENTRY) {
        // reason to rewrite this message is to ignore sentry filter -> IGNORE_ERRORS_LIST  /.*code 400.*/g,
        error.message = 'Error with deep link';
    }

    sentry.logError(error as Error, DEEP_LINK_ERROR, ERROR_LEVELS.CRITICAL, {
        backEndErrorMessage,
    });
};

function parseJson(toBeParsed: string) {
    try {
        return toBeParsed && JSON.parse(toBeParsed);
    } catch (error) {
        console.error('Error parsing JSON:', error);

        return null; // Return null if parsing fails
    }
}
