import { Box, Image, Text } from 'wikr-core-components';
import React from 'react';

import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';

import * as S from './styled';

import sandClock from 'assets/img/webp/sand_clock.webp';

import { IWaitingModal } from './types';

const WaitingModal = ({
    onDiscard,
    onClose,
    withCloseButton,
    captions,
    trackLoad,
    dataLocatorContainer,
}: IWaitingModal) => (
    <Modal
        isOpen
        onClose={onClose}
        trackLoad={trackLoad}
        withCloseButton={withCloseButton}
        dataLocatorContainer={dataLocatorContainer}
    >
        <Box paddingTop={8} paddingBottom={8}>
            <Image maxWidth={48} center alt="sand clock" src={sandClock} mb={12} isForceVisible />
            <Text type="large-text" color="text-main" bold center mb={24} text={captions.title} />
            <Box>
                <S.Spinner />
            </Box>
            {onDiscard && captions.discardButton && (
                <OutlinedButton dataLocator="stopCancelBtn" text={captions.discardButton} onClick={onDiscard} />
            )}
        </Box>
    </Modal>
);

export default WaitingModal;
