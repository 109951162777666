/* eslint-disable max-lines */
import { call, takeLatest, all, put, delay, select } from 'redux-saga/effects';

import api from 'api';

import { notifyError, notifySuccess } from 'store/notifications/actions';
import { navigateBack } from 'store/navigation/actions';
import { handleErrorAction } from 'store/errors/actions';

import {
    getCurrentUserRequest,
    setUserData,
    getCurrentUserError,
    updateUserRequest,
    updateUserSuccess,
    updateUserError,
    updateUserEmail,
    setUserRegion,
    setUserWorkoutInfo,
} from './actions';

import { GET_CURRENT_USER_REQUEST, UPDATE_USER_REQUEST, UPDATE_USER_EMAIL } from './actionTypes';

import { DEFAULT_COUNTRY } from 'constants/user';

import { setUserDataToAnalytics } from 'services/Analytics';

import { getBirthdayByAge } from 'helpers/date';

import { UserGeoResp, UserWorkoutInfo } from 'types/user/userApiInterface';
import { UserStore } from 'types/store/userStore';

import { selectCurrentUser } from './selectors';

export function* getCurrentUser({ payload }: ReturnType<typeof getCurrentUserRequest>) {
    try {
        const userData: UserStore = yield call(api.user.getUser);

        const fixedData = {
            country: userData.country as string,
            id: userData.id as number,
            gender: userData.gender as string,
            abTestName: userData.ab_test_name as string,
            region: userData.geo_region_code as string,
        };

        setUserDataToAnalytics(fixedData);

        yield put(setUserData(userData));

        payload?.onSuccess?.(userData);
    } catch (error: any) {
        yield put(notifyError('basics.appError'));
        yield put(getCurrentUserError(error.error));
        yield put(handleErrorAction(error, payload));

        payload?.onError?.(error);
    }
}

function* updateUser({ payload: { payload, onSuccess } }: ReturnType<typeof updateUserRequest>) {
    try {
        const { age, ...restUserData } = payload;

        const userData: UserStore = yield call(api.user.update, {
            ...restUserData,
            date_of_birth: age && getBirthdayByAge(age),
        });

        const fixedData = {
            country: userData.country as string,
            id: userData.id as number,
            gender: userData.gender as string,
            abTestName: userData.ab_test_name as string,
            region: userData.geo_region_code as string,
        };

        setUserDataToAnalytics(fixedData);
        yield put(updateUserSuccess(userData));
        yield put(notifySuccess('message.success.changesSaved'));

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(updateUserError(error.error));
        yield put(notifyError('message.error.changesNotSaved'));
        yield put(handleErrorAction(error, payload));
    }
}

function* updateUserEmailRequest({ payload: { email, onError } }: ReturnType<typeof updateUserEmail>) {
    try {
        const userData: UserStore = yield call(api.user.update, {
            email,
        });

        yield put(updateUserSuccess(userData));
        yield put(notifySuccess('change.email.success'));

        // Fix closing notification on redirect
        yield delay(500);

        yield put(navigateBack());
    } catch (error: any) {
        yield put(updateUserError(error.error));
        yield put(notifyError('change.email.error'));
        yield put(handleErrorAction(error));

        onError && onError();
    }
}

function* fetchUserRegion() {
    try {
        const geoDataFromThirdPartyService: UserGeoResp = yield call(api.user.getUserGeoData);
        const currentUser: UserStore = yield select(selectCurrentUser);

        if (geoDataFromThirdPartyService.geo_region_code) {
            yield put(setUserRegion(geoDataFromThirdPartyService.geo_region_code));
        } else {
            yield put(setUserRegion(currentUser.user_postal_code));
        }
    } catch (error: any) {
        yield put(handleErrorAction(error, 'error when getting user geo data from third party'));
    }
}

export function* getUserWorkoutInfoSaga() {
    try {
        const workoutInfo: UserWorkoutInfo = yield call(api.user.getUserWorkoutInfoRequest);

        yield put(setUserWorkoutInfo(workoutInfo));
    } catch (error: any) {
        yield put(handleErrorAction(error));
    }
}

export function* getUserRegionSaga() {
    try {
        const currentUser: UserStore = yield select(selectCurrentUser);

        // if user don't have region we need to make a request to third party service
        if (!currentUser.geo_region_code && currentUser.country === DEFAULT_COUNTRY) {
            yield call(fetchUserRegion);
        }
    } catch (error: any) {
        yield put(handleErrorAction(error));
    }
}

export default function* watchUser() {
    yield all([
        takeLatest(GET_CURRENT_USER_REQUEST, getCurrentUser),
        takeLatest(UPDATE_USER_REQUEST, updateUser),
        takeLatest(UPDATE_USER_EMAIL, updateUserEmailRequest),
    ]);
}
