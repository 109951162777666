import { Button, Box, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useFormik } from 'formik';

import { reasons } from './constants';

import isMobile from 'helpers/isMobile';
import { shouldDisplayFeedbackForm } from './helpers';

import Textarea from 'components/Textarea';
import Modal from 'components/Modal';
import CollapsibleBlock from 'components/CollapsibleBlock';
import Checkbox from 'components/Checkbox';

import * as S from './styled';

import { trackQuiteFeedbackPopupLoad, trackSendFeedbackClick } from 'analytics/trackers/exercise';
import getValidationSchema from './validation-scheme';

export type WorkoutReasonAbandonProps = { onClose: () => void };

const ReasonAbandonModal = ({ onClose }: WorkoutReasonAbandonProps) => {
    const { t } = useTranslation();

    const submitSurvey = ({ feedback, reason }: { feedback: string; reason: string }) => {
        trackSendFeedbackClick(reason, feedback);

        onClose();
    };

    const { values, handleChange, handleSubmit, errors, isValid, handleBlur, touched } = useFormik({
        initialValues: { feedback: '', reason: '' },
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema: getValidationSchema(),
        onSubmit: submitSurvey,
    });

    return (
        <Modal
            isOpen
            onClose={onClose}
            fullscreen={isMobile.any()}
            withCloseButton
            width={384}
            trackLoad={trackQuiteFeedbackPopupLoad}
        >
            <Box paddingTop={32}>
                <Text type="h5" mb={12} text={t('workout.reasonAbandonModal.title')} />
                <Text type="medium-text" color="text-secondary" text={t('workout.reasonAbandonModal.subtitle')} />

                <form onSubmit={handleSubmit}>
                    <Box paddingTop={12} paddingBottom={40}>
                        {reasons.map((reason) => {
                            return (
                                <S.QuestionWrapper key={reason.name}>
                                    <Checkbox
                                        type="radio"
                                        name="reason"
                                        checked={reason.name === values.reason}
                                        value={reason.name}
                                        onChange={handleChange}
                                    />
                                    <S.QuestionLabel htmlFor={reason.name}>
                                        <Text color="text-secondary" text={t(reason.label)} />
                                    </S.QuestionLabel>
                                </S.QuestionWrapper>
                            );
                        })}

                        <CollapsibleBlock isOpen={shouldDisplayFeedbackForm(values.reason)}>
                            <Box paddingTop={12} paddingBottom={0}>
                                <Textarea
                                    name="feedback"
                                    autocomplete="off"
                                    rows="6"
                                    placeholder={t('basics.feedbackAsk')}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.feedback}
                                    invalid={Boolean(errors.feedback && touched.feedback)}
                                />

                                {Boolean(errors.feedback && touched.feedback) && (
                                    <Text type="caption" color="critical-default" mb={24} text={errors.feedback} />
                                )}
                            </Box>
                        </CollapsibleBlock>
                    </Box>

                    <Button onClick={handleSubmit} disabled={Boolean(!isValid || !values.reason)}>
                        {t('basics.send')}
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default ReasonAbandonModal;
