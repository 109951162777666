import { getPaidOnlyLinks, getFreeLinks } from './constants';

export const getPrimaryLinks = (isPaid: boolean, isMobileDevice: boolean, mainPath: string) => {
    const FREE_LINKS = getFreeLinks(mainPath);
    const PAID_ONLY_LINKS = getPaidOnlyLinks();

    if (!isPaid || isMobileDevice) return FREE_LINKS;

    return PAID_ONLY_LINKS.reduce(
        (accum, itm) => {
            accum.splice(itm.positionIndex, 0, itm.link);

            return accum;
        },
        [...FREE_LINKS]
    );
};
