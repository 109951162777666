import { Image, Text, Box } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';
import { Cross, PlanIcon, MoveBack } from 'components/Icon';

import * as S from './styled';

import mail from './img/mail.webp';
import check from './img/check.webp';
import bankCard from './img/bankCard.webp';

import { ReminderOfferModalProps } from './types';

import { isTabletDevice } from 'theme/verifiers';
import { trackReminderModalLoad } from 'analytics/trackers/reminderForSubscription';

const ReminderOfferModal = ({
    onSubmit,
    onDiscard,
    onClose,
    onMoveBack,
    subscriptionPeriod,
}: ReminderOfferModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            paddingX={0}
            paddingY={0}
            isOpen
            onClose={onClose}
            transparentOverlay={false}
            trackLoad={trackReminderModalLoad}
        >
            <S.ReminderModalContent data-locator="reminderOfferContainer">
                <S.ModalHeaderTitle>
                    <S.IconWrapper onClick={onMoveBack} data-locator="cancelSubscriptionModalBackButton">
                        <MoveBack />
                    </S.IconWrapper>
                    <S.TitleText>
                        <Text text={t('subscription.text.cancel')} type="large-text" medium />
                    </S.TitleText>
                    <S.IconWrapper onClick={onClose} data-locator="cancelSubscriptionModalCloseButton">
                        <Cross />
                    </S.IconWrapper>
                </S.ModalHeaderTitle>

                <Text
                    type="h4"
                    medium
                    center
                    text={t('subscription.reminder.title', { subscription: subscriptionPeriod })}
                    mb={14}
                />
                <Text type="large-text" center text={t('subscription.reminder.subtitle')} mb={34} />

                <S.StepsWrapper>
                    <S.Line />

                    <S.Step data-locator="reminderOfferFullAccessStep">
                        <S.StepIconWrapper>
                            <Image src={check} alt="check" maxWidth={26} />
                        </S.StepIconWrapper>
                        <Box paddingTop={12}>
                            <Text mb={20} type="h6" medium text={t('subscription.reminder.steps.firstTitle')} />
                            <S.FirstStepBlock>
                                <S.FirstStepBlockTitle>
                                    <PlanIcon />
                                    <S.FirstStepBlockText type="large-text" medium text={t('basics.plan')} />
                                </S.FirstStepBlockTitle>
                                <Text type="small-text" text={t('subscription.reminder.steps.firstSubtitle')} />
                            </S.FirstStepBlock>
                        </Box>
                    </S.Step>
                    <S.Step data-locator="reminderOfferReminderStep">
                        <S.StepIconWrapper>
                            <Image src={mail} alt="mail" maxWidth={30} />
                        </S.StepIconWrapper>
                        <div>
                            <Text
                                mb={isTabletDevice ? 32 : 8}
                                type="h6"
                                medium
                                text={t('subscription.reminder.steps.secondTitle', {
                                    subscription: subscriptionPeriod,
                                })}
                            />
                            <Text type="small-text" text={t('subscription.reminder.steps.secondSubtitle')} />
                        </div>
                    </S.Step>
                    <S.Step data-locator="reminderOfferChargeDateStep">
                        <S.StepIconWrapper>
                            <Image src={bankCard} alt="card" maxWidth={30} />
                        </S.StepIconWrapper>
                        <Box paddingTop={12}>
                            <Text mb={20} type="h6" medium text={t('subscription.reminder.steps.thirdTitle')} />
                            <Text type="small-text" text={t('subscription.reminder.steps.thirdSubtitle')} />
                        </Box>
                    </S.Step>
                </S.StepsWrapper>

                <S.SetReminderButton mb={16} onClick={onSubmit} dataLocator="reminderOfferSetReminderButton">
                    {t('subscription.reminder.setReminder')}
                </S.SetReminderButton>
                <OutlinedButton
                    dataLocator="reminderOfferCancelSubscriptionButton"
                    text={t('subscription.text.cancel')}
                    onClick={onDiscard}
                    textColor="action-main"
                />
            </S.ReminderModalContent>
        </Modal>
    );
};

export default ReminderOfferModal;
