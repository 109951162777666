import { combineReducers } from 'redux';

import WorkoutsReducer from './workouts/reducer';
import UserReducer from './user/reducer';
import UiReducer from './uiEffects/reducer';
import SubscriptionsReducer from './subscriptions/reducer';
import ModalsReducer from './modals/reducer';
import LoadingReducer from './loading/reducer';
import GuidesReducer from './guides/reducer';
import ExercisesReducer from './exercises/reducer';
import DeepLinkReducer from './deepLink/reducer';
import AuthReducer from './auth/reducer';
import AbTestingReducer from './abTesting/reducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    user: UserReducer,
    subscriptions: SubscriptionsReducer,
    guides: GuidesReducer,
    ui: UiReducer,
    loading: LoadingReducer,
    deepLink: DeepLinkReducer,
    exercises: ExercisesReducer,
    workouts: WorkoutsReducer,
    modals: ModalsReducer,
    abTesting: AbTestingReducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;
export default rootReducer;
