import React from 'react';

const Video = () => {
    return (
        <svg width="42" height="29" viewBox="0 0 42 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M41.5734 22.7426C41.5734 22.7426 41.1633 25.5854 39.9 26.8337C38.3004 28.4765 36.5121 28.4846 35.6918 28.5812C29.8184 29 21 29 21 29H20.9836C20.9836 29 12.1652 29 6.2918 28.5812C5.47149 28.4846 3.6832 28.4765 2.08359 26.8337C0.820313 25.5854 0.418359 22.7426 0.418359 22.7426C0.418359 22.7426 0 19.4085 0 16.0664V12.9417C0 9.60761 0.418359 6.26548 0.418359 6.26548C0.418359 6.26548 0.828516 3.42266 2.08359 2.1744C3.6832 0.531519 5.7832 0.587893 6.71836 0.410719C10.0816 0.0966396 21 0 21 0C21 0 29.8266 0.0161057 35.7 0.426825C36.5203 0.523464 38.3086 0.531519 39.9082 2.1744C41.1715 3.42266 41.5816 6.26548 41.5816 6.26548C41.5816 6.26548 42 9.59956 42 12.9417V16.0664C41.9918 19.4004 41.5734 22.7426 41.5734 22.7426ZM16.6523 9.14857V20.7373L27.9973 14.9228L16.6523 9.14857Z"
                fill="#DFDFDF"
            />
        </svg>
    );
};

export default Video;
