import { takeLatest, all, put } from 'redux-saga/effects';

import router from 'router';

import { handleErrorAction } from 'store/errors/actions';

import { navigateBack, navigateTo } from './actions';

import { NAVIGATE, NAVIGATE_BACK } from './actionTypes';

function* navigateSaga({ payload }: ReturnType<typeof navigateTo>) {
    try {
        const { url } = payload;

        yield router.navigate(url);
    } catch (error: any) {
        yield put(handleErrorAction(error, payload));
    }
}

function* navigateBackSaga({ payload }: ReturnType<typeof navigateBack>) {
    try {
        yield router.navigate(payload?.amount || -1);
    } catch (error: any) {
        yield put(handleErrorAction(error, payload));
    }
}

export default function* watchNavigation() {
    yield all([takeLatest(NAVIGATE, navigateSaga)]);
    yield all([takeLatest(NAVIGATE_BACK, navigateBackSaga)]);
}
