import { Text, Button, Box } from 'wikr-core-components';
import useI18next from 'services/i18n';
import { ERROR_LEVELS, SENTRY_CABINET } from 'sentry-utils';
import { useRouteError } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import AuthLayout from 'router/layouts/AuthLayout';

import sentry from 'services/Sentry/SentryInstance';

import AlertIcon from 'components/Icon/Alert';
import ContactUsLink from 'components/ContactUsLink';

import * as S from './styled';

export const ErrorPage = () => {
    const { t } = useTranslation();

    useI18next();

    const error = useRouteError();

    useEffect(() => {
        sentry.logError(new Error(error?.toString()), SENTRY_CABINET, ERROR_LEVELS.CRITICAL);
    }, [error]);

    return (
        <AuthLayout titleKey="basics.appError">
            <S.ErrorBoundaryWrapper>
                <Box
                    backgroundColor="surface-secondary"
                    withShadow
                    paddingX={16}
                    paddingTop={40}
                    paddingBottom={40}
                    borderRadius={12}
                >
                    <Box mb={12}>
                        <AlertIcon size={50} />
                    </Box>
                    <Text color="text-main" type="h6" text={t('basics.appError')} bold center mb={32} uppercase />

                    <Button onClick={window.location.reload} text={t('basics.reload')} mb={24} />

                    <ContactUsLink subject={t('basics.errorMessage')} boldText />
                </Box>
            </S.ErrorBoundaryWrapper>
        </AuthLayout>
    );
};
