import { Box, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { getLanguage } from 'api/utils/languageManagment';

import { getVideoInstructionLink } from 'helpers/getVideoInstructionLink';

import Modal from 'components/Modal';

import * as S from './styled';

export interface VideoInstructionModalProps {
    onClose: () => void;
    title: string;
    device: string;
}

const VideoInstructionModal = ({ onClose, title, device }: VideoInstructionModalProps) => {
    const { t } = useTranslation();
    const userLanguage = getLanguage();

    return (
        <Modal isOpen onClose={onClose} withCloseButton width={648} height={466}>
            <Box backgroundColor="surface-default" borderRadius={12}>
                <Text text={t(title)} type="h6" bold center mb={8} />
                <Text text={t('videoInstructionModal.subtitle')} center mb={16} />
                <S.VideoWrapper>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video playsInline controls width={600} height={338}>
                        <source src={getVideoInstructionLink(userLanguage, device)} type="video/mp4" />
                    </video>
                </S.VideoWrapper>
            </Box>
        </Modal>
    );
};

export default VideoInstructionModal;
