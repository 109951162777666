import { EXERCISE, EXERCISE_QUIT, QUITE_FEEDBACK_POPUP } from 'analytics/constants/ids';

import { trackScreenLoad, trackClick, trackEvent } from 'services/Analytics';

const createTrackObject = (action: string, exerciseId: number, order: number) => ({
    event_action: action,
    event_label: `${exerciseId}__${order}`,
});

export const trackExercisePageLoad = () => trackScreenLoad(EXERCISE);

export const trackQuiteFeedbackPopupLoad = () => trackScreenLoad(QUITE_FEEDBACK_POPUP);

export const trackTrackPlayButtonClick = (exerciseId: number, order: number) =>
    trackClick(EXERCISE, createTrackObject('button__play', exerciseId, order));

export const trackTrackPauseButtonClick = (exerciseId: number, order: number) =>
    trackClick(EXERCISE, createTrackObject('button__pause', exerciseId, order));

export const trackFullScreenButtonClick = (exerciseId: number, order: number) =>
    trackClick(EXERCISE, createTrackObject('button__fullscreen', exerciseId, order));

export const trackMuteButtonClick = (exerciseId: number, order: number) =>
    trackClick(EXERCISE, createTrackObject('button__mute', exerciseId, order));

export const trackNextButtonClick = (exerciseId: number, order: number) =>
    trackClick(EXERCISE, createTrackObject('button__next', exerciseId, order));

export const trackPreviousButtonClick = (exerciseId: number, order: number) =>
    trackClick(EXERCISE, createTrackObject('button__previous', exerciseId, order));

export const trackDetailsClick = (exerciseId: number, order: number) =>
    trackClick(EXERCISE, createTrackObject('button__details', exerciseId, order));

export const trackBackButtonClick = (exerciseId: number, order: number) =>
    trackClick(EXERCISE, createTrackObject('button__back', exerciseId, order));

export const trackSelectExerciseButtonClick = (exerciseId: number, order: number) =>
    trackClick(EXERCISE, createTrackObject('button__select', exerciseId, order));

export const trackFinishButtonClick = (exerciseId: number, order: number) =>
    trackClick(EXERCISE, createTrackObject('button__finish', exerciseId, order));

export const trackExerciseQuitModalLoad = () => trackScreenLoad(EXERCISE_QUIT);

export const trackDiscardQuitButtonClick = (exerciseId: number, order: number) =>
    trackClick(EXERCISE_QUIT, createTrackObject('button__continue', exerciseId, order));

export const trackAcceptQuitButtonClick = (exerciseId: number, order: number) =>
    trackClick(EXERCISE_QUIT, createTrackObject('button__stop', exerciseId, order));

export const trackSendFeedbackClick = (reason: string, feedback: string) =>
    trackClick(QUITE_FEEDBACK_POPUP, {
        event_action: 'button__send',
        event_label: `${reason}__${feedback}`,
    });

export const trackVideoWatched = (exerciseId: number, order: number) =>
    trackEvent('video_watched', {
        event_label: `${exerciseId}__${order}`,
    });
