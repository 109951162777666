import { Button, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useFormik } from 'formik';

import { updateUserEmail } from 'store/user/actions';

import { useDispatch, useScreenLoadTracker } from 'hooks';

import { isEmpty } from 'helpers/utils';

import { EmailIcon } from 'components/Icon';
import FormikInput from 'components/FormikInput';

import * as S from './styled';

import { trackChangeEmailPageLoad, trackChangeEmailSave } from 'analytics/trackers/changeEmail';
import { ChangeEmailSchema } from './validationSchema';

const initialValues = {
    email: '',
};

type FormValue = {
    email: string;
};

export const ChangeEmail = () => {
    useScreenLoadTracker(trackChangeEmailPageLoad);

    const [isLoading, setLoadingState] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { errors, dirty, values, setFieldTouched, setFieldValue, handleSubmit, touched } = useFormik({
        initialValues,
        validationSchema: ChangeEmailSchema,
        onSubmit: (payload: FormValue) => {
            setLoadingState(true);
            trackChangeEmailSave();
            dispatch(
                updateUserEmail({
                    email: payload.email,
                    onError: () => {
                        setLoadingState(false);
                    },
                })
            );
        },
    });

    return (
        <S.Container paddingTop={88} paddingX={16} paddingBottom={32}>
            <Text center mb={20} type="h5" text={t('change.email.title')} />
            <form onSubmit={handleSubmit}>
                <div>
                    <FormikInput
                        type="email"
                        field="email"
                        label="change.email.newEmail"
                        setFieldTouched={setFieldTouched}
                        initialValues={values}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        errors={errors}
                        mb={44}
                        leftIcon={() => <EmailIcon />}
                        dataLocator="newEmailInput"
                        placeholder={t('change.email.newEmail')}
                    />
                </div>
                <Button
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    backgroundColor="primary-default"
                    disabled={!dirty || !isEmpty(errors)}
                    mb={24}
                    text={t('account.profile.button.save')}
                    dataLocator="saveEmailButton"
                />
            </form>
        </S.Container>
    );
};
