import { WorkoutCTAContainer } from 'containers/WorkoutCTAContainer';

export const FinishWorkoutPage = () => {
    return (
        <WorkoutCTAContainer
            buttonText="workout.workoutPlayer.finish"
            titleText="finishWorkoutPage.title"
            screenId="first_workout_not_finished"
            eventLabel="finish_workout"
            boxDataLocator="finishWorkoutContainer"
        />
    );
};
