import styled from 'styled-components';

export const Img = styled.img`
    margin: 0 auto 20px;
    display: block;
    width: 48px;
    height: 48px;
`;

export const ButtonsBlock = styled.div`
    margin-top: 24px;
`;
