import React from 'react';

interface CommonProps {
    isOpen: boolean;
    onClose?: () => void;
    closeOnOverlayClick?: boolean;
    trackLoad?: () => void;
}

export interface IModal extends CommonProps {
    children: React.ReactNode;
    withCloseButton?: boolean;
    fullscreen?: boolean;
    paddingX?: number;
    paddingY?: number;
    customContent?: boolean;
    width?: number;
    height?: number;
    dataLocatorCloseButton?: string;
    dataLocatorContainer?: string;
    zPosition?: zIndexTypes;
    transparentOverlay?: boolean;
    buttonWithBackground?: boolean;
    contentAlignmentY?: 'top' | 'middle' | 'bottom';
}

export interface IContentModal {
    fullscreen?: boolean;
    paddingY?: number;
    paddingX?: number;
    width?: number;
    height?: number;
    transparentOverlay?: boolean;
    contentAlignmentY?: 'top' | 'middle' | 'bottom';
}

export enum zIndexTypes {
    top = 999999999,
    middle = 100000,
    low = 100,
}
