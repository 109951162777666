import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const StyledWrapper = styled(Box)`
    label {
        font-weight: 700;
    }

    //rm after padding issue in lib will be fixed
    > * {
        margin-bottom: 20px;
    }
`;
