import { Button } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const CustomButton = styled(Button)`
    border: 1px solid ${color('border-default')};
    :disabled {
        background-color: ${color('surface-default')} !important;
    }
    :active {
        background-color: ${color('surface-hovered')} !important;
    }
`;

export const Emoji = styled.span`
    font-size: 52px;
    display: block;
    text-align: center;
    margin-bottom: 20px;
`;
