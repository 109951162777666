import { Icon } from 'wikr-core-components';
import React from 'react';

import * as S from './styled';

const Checkbox = ({
    type = 'checkbox',
    name,
    checked,
    onChange,
    value,
}: {
    type: 'checkbox' | 'radio';
    value: string;
    name: string;
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    return (
        <S.Checkbox>
            <input type={type} name={name} id={value} checked={checked} onChange={onChange} value={value} />
            <S.CheckboxIcon htmlFor={value}>
                <Icon name="check" />
            </S.CheckboxIcon>
        </S.Checkbox>
    );
};

export default Checkbox;
