import { ACCOUNT_SUBSCRIPTION, PAUSE_SUBSCRIPTION_OFFER } from '../constants/ids';

import { trackClick, trackEvent, trackNewScreenLoad } from 'services/Analytics';

export const trackPauseModalLoad = () => trackNewScreenLoad('pause_offer', { event_label: PAUSE_SUBSCRIPTION_OFFER });

export const trackPauseSuccessModalLoad = () =>
    trackNewScreenLoad(ACCOUNT_SUBSCRIPTION, {
        event_label: PAUSE_SUBSCRIPTION_OFFER,
        event_action: 'subscription__pause',
    });

export const trackPausedApplied = () =>
    trackEvent('cancel_offer_success', {
        event_label: `${PAUSE_SUBSCRIPTION_OFFER}`,
    });

export const trackPauseError = () =>
    trackEvent('cancel_offer_error', {
        event_label: `${PAUSE_SUBSCRIPTION_OFFER}`,
    });

export const trackPauseErrorModalLoad = () =>
    trackNewScreenLoad(ACCOUNT_SUBSCRIPTION, { event_action: 'error', event_label: PAUSE_SUBSCRIPTION_OFFER });

export const trackPausedModalContinueClick = () =>
    trackClick(ACCOUNT_SUBSCRIPTION, { event_action: 'continue', event_label: PAUSE_SUBSCRIPTION_OFFER });

export const trackPauseSubmitClick = () =>
    trackClick('pause_offer', { event_action: 'button__continue', event_label: PAUSE_SUBSCRIPTION_OFFER });

export const trackPauseRetryClick = () =>
    trackClick(ACCOUNT_SUBSCRIPTION, { event_action: 'try_again', event_label: PAUSE_SUBSCRIPTION_OFFER });

export const trackDiscardPauseClick = () =>
    trackClick('pause_offer', { event_action: 'button__continue_cancel', event_label: PAUSE_SUBSCRIPTION_OFFER });

export const trackClosePauseOffer = () =>
    trackClick('close_cancel_offer', { event_action: 'close', event_label: PAUSE_SUBSCRIPTION_OFFER });

export const trackReturnPauseOffer = () =>
    trackClick('return_cancel_offer', { event_action: 'return', event_label: PAUSE_SUBSCRIPTION_OFFER });
