/* eslint-disable max-lines */
import { Text } from 'wikr-core-components';
import styled from 'styled-components';

import { borderRadius, color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const ModalContainer = styled.div`
    background: ${color('surface-default')};
    width: 100%;
    max-width: 440px;
    display: inline-block;
    line-height: normal;
    border-radius: ${borderRadius('medium')};
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    min-height: 100%;

    ${mediaQueries.laptop} {
        min-height: initial;
    }
`;

export const ModalHero = styled.div`
    position: relative;
    padding: 56px 16px 32px;
    overflow: hidden;

    ${mediaQueries.largeTablet} {
        padding: 64px 24px 32px;
    }
`;

export const TitleWrp = styled.div`
    position: relative;
    z-index: 1;
`;

export const WorkoutModalTitle = styled(Text)`
    font-weight: 600;
`;

export const Background = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0 !important;
    pointer-events: none;
`;

export const ModalBody = styled.div`
    padding: 24px 16px;
    text-align: left;
    position: relative;
    height: calc(100dvh - 336px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${mediaQueries.laptop} {
        display: block;
        height: auto;
    }

    ${mediaQueries.largeTablet} {
        padding: 24px;
    }
`;

export const ResultInfo = styled.div`
    background: ${color('primary-disabled')};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${borderRadius('medium')};
    width: 100%;
    min-height: 80px;
`;

export const ResultInfoItem = styled.div`
    text-align: center;
    width: calc(100% / 3);
    padding: 8px 10px;
    position: relative;

    :not(:last-child)&:after {
        content: '';
        width: 1px;
        height: 24px;
        position: absolute;
        right: 0;
        top: 28px;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 24px;
    }
`;

export const Emoji = styled.span`
    height: 24px;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 24px;
    background: transparent;
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 2;

    svg path {
        transition: all 0.3s ease;
    }

    &:hover {
        background: ${color('surface-secondary')};

        svg path {
            fill: ${color('text-main')};
        }
    }
`;
