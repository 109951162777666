import styled, { css } from 'styled-components';

import { SIDEBAR_WIDTH } from 'components/Sidebar/constants';

import mediaQueries from 'theme/mediaQueries';

export const CabinetContent = styled.div<{ withCenteredContent: boolean }>`
    ${({ withCenteredContent }) =>
        withCenteredContent &&
        css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100vh - 56px);
        `}

    ${mediaQueries.tabletLandscape} {
        padding-left: ${SIDEBAR_WIDTH}px;
    }
`;
