import { REST_EXERCISE_ID } from 'constants/exercises';

import { BlockExercise } from 'types/workouts';

const isRestExercise = (exerciseId: number) => exerciseId === REST_EXERCISE_ID;

type WorkoutRound = {
    exercises: BlockExercise[];
    restExercise: BlockExercise;
    id: string;
};

type WorkoutRoundWithRepetitions = WorkoutRound & {
    repetitions: number;
};

export const splitByRounds = (exercises: BlockExercise[]) => {
    const rounds: WorkoutRound[] = [];
    let currentChunk: BlockExercise[] = [];
    let chunkNameParts: number[] = [];

    exercises.forEach((exercise) => {
        // Each round is ending by rest exercise
        const isRest = isRestExercise(exercise.id);

        if (isRest) {
            // If it's a rest exercise, we create a round
            chunkNameParts.push(exercise.id);
            rounds.push({
                exercises: currentChunk,
                restExercise: exercise,
                id: chunkNameParts.join('_'),
            });

            // Clear the temporary chunk storage
            currentChunk = [];
            chunkNameParts = [];
        } else {
            // if it's not a rest exercise, we store the exercise
            currentChunk.push(exercise);
            chunkNameParts.push(exercise.id);
        }
    });

    return rounds;
};

export const groupRoundsByRepetitions = (rounds: WorkoutRound[]) => {
    const uniqueRounds: WorkoutRoundWithRepetitions[] = [];

    rounds.forEach((round) => {
        const lastRounds = uniqueRounds.at(-1);

        if (!lastRounds || lastRounds.id !== round.id) {
            uniqueRounds.push({
                ...round,
                repetitions: 1,
            });
        }

        if (lastRounds?.id === round.id) {
            lastRounds.repetitions += 1;
        }
    });

    return uniqueRounds;
};
