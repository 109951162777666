import { REST_EXERCISE_ID } from 'constants/exercises';

import { BlockExercise, PlaylistExercise, WorkoutBlocksType, WorkoutType } from 'types/workouts';

export const getBlocksMap = (workout: WorkoutType | null): WorkoutBlocksType => {
    if (!workout) return {} as WorkoutBlocksType;

    return workout.blocks.reduce((acc, block) => {
        acc[block.block_type as keyof WorkoutBlocksType] = block;

        return acc;
    }, {} as WorkoutBlocksType);
};

export const getPlaylist = (workout: WorkoutType) => workout.blocks.map(({ exercises }) => exercises).flat();

export const getOnlyExercises = (playlist: BlockExercise[]): PlaylistExercise[] =>
    playlist
        .map((exercise, index) => ({
            ...exercise,
            playlistPosition: index,
        }))
        .filter(({ id }) => id !== REST_EXERCISE_ID);
