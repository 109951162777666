/* eslint-disable max-lines */
import { Box, Button, Text } from 'wikr-core-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useFormik } from 'formik';

import { signInRequest } from 'store/auth/actions';

import { useDispatch, useScreenLoadTracker } from 'hooks';

import { isEmpty } from 'helpers/utils';

import * as S from 'containers/AuthFlow/styled';

import FormikInput from 'components/FormikInput';

import text from 'assets/img/webp/login-text.webp';
import man from 'assets/img/webp/login-man.webp';

import { CONTENT } from './config';

import { trackLoginPageLoad, trackLoginSubmit } from 'analytics/trackers/login';
import { LoginSchema } from './validationSchema';

type FormValue = {
    email: string;
    password: string;
};

export const Login = () => {
    useScreenLoadTracker(trackLoginPageLoad);
    const { t } = useTranslation();
    const [isFetching, setFetchingStatus] = useState(false);

    const dispatch = useDispatch();
    const { email } = (useLocation().state as { email?: string }) || {};

    const {
        errors,
        dirty,
        values,
        initialValues,
        setFieldTouched,
        setFieldValue,
        touched,
        handleSubmit,
        setFieldError,
    } = useFormik({
        initialValues: {
            email: email || '',
            password: '',
        },
        validationSchema: LoginSchema,
        validateOnBlur: true,
        onSubmit: (userData: FormValue) => {
            trackLoginSubmit();
            setFetchingStatus(true);
            dispatch(
                signInRequest({
                    userData,
                    onError: handleLoginError,
                })
            );
        },
    });

    const handleLoginError = () => {
        CONTENT.forEach((field) => setFieldError(field.name, t('login.error')));
        setFetchingStatus(false);
    };

    return (
        <S.AuthContainer>
            <S.AuthImage>
                <S.LoginText src={text} alt="changeYourself" />
                <S.LoginMan src={man} alt="man" />
            </S.AuthImage>
            <S.AuthForm>
                <Box paddingBottom={32} paddingX={16}>
                    <form onSubmit={handleSubmit}>
                        <Text type="h5" bold center mb={12} text={t('login.title')} />
                        <Box mb={44}>
                            {CONTENT.map(({ name, label, type, icon, dataLocator, placeholder }) => (
                                <FormikInput
                                    key={name}
                                    mb={32}
                                    type={type}
                                    field={name}
                                    label={label}
                                    setFieldTouched={setFieldTouched}
                                    initialValues={initialValues}
                                    setFieldValue={setFieldValue}
                                    touched={touched}
                                    values={values}
                                    errors={errors}
                                    leftIcon={icon}
                                    dataLocator={dataLocator}
                                    placeholder={placeholder}
                                />
                            ))}
                        </Box>
                        <Button
                            onClick={handleSubmit}
                            disabled={!dirty || !isEmpty(errors) || isFetching}
                            mb={24}
                            text={t('basics.login')}
                            isLoading={isFetching}
                            dataLocator="loginButton"
                        />
                    </form>
                </Box>
            </S.AuthForm>
        </S.AuthContainer>
    );
};
