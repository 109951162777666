import { ERROR_LEVELS, SENTRY_CABINET } from 'sentry-utils';
import { takeEvery } from 'redux-saga/effects';

import { handleErrorAction } from './actions';

import { HANDLE_ERROR_ACTION } from './actionTypes';

import sentry from 'services/Sentry/SentryInstance';

import { isApiClientError } from 'welltech-web-api-client';

function* handleErrorSaga({ payload: { error, data } }: ReturnType<typeof handleErrorAction>) {
    if (isApiClientError(error)) {
        return;
    }

    console.error('Error:', error);

    sentry.logError(error, SENTRY_CABINET, ERROR_LEVELS.ERROR, {
        ...data,
    });
}

export default function* watchHandleErrorAction() {
    yield takeEvery(HANDLE_ERROR_ACTION, handleErrorSaga);
}
