import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import * as S from '../styled';

import { ExerciseDetails } from 'types/exercise';

const ExerciseDetailsList = ({ exerciseDetails }: { exerciseDetails: ExerciseDetails }) => {
    const { t } = useTranslation();
    const isInstructionsExists = Boolean(exerciseDetails?.instructions?.length);
    const isTipsExists = Boolean(exerciseDetails?.tips.length);

    return (
        <S.ExerciseDescription>
            {isInstructionsExists && (
                <>
                    <Text color="text-secondary" type="small-text" bold mb={12}>
                        {t('workout.instructions.title')}
                    </Text>

                    <S.TipsList>
                        {exerciseDetails?.instructions?.map((instruction, index) => (
                            <li key={instruction}>
                                <Text color="text-secondary" type="small-text">
                                    {index + 1}. {instruction}
                                </Text>
                            </li>
                        ))}
                    </S.TipsList>
                </>
            )}

            {isTipsExists && (
                <>
                    <Text color="text-secondary" type="small-text" bold mb={12}>
                        {t('workout.tips.title')}
                    </Text>

                    <S.TipsList>
                        {exerciseDetails?.tips.map((tip, index) => (
                            <li key={tip.name}>
                                <Text color="text-secondary" type="small-text">
                                    {index + 1}. {tip.name}
                                </Text>
                            </li>
                        ))}
                    </S.TipsList>
                </>
            )}
        </S.ExerciseDescription>
    );
};

export default ExerciseDetailsList;
