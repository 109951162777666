import { AVOID_ONLY_APP_LOGGED_IN_KEY } from 'constants/user';

const routeInterceptor = ({ pathname, isAuthenticated }: { pathname: string; isAuthenticated: boolean }) => {
    const lsDevKey = localStorage.getItem(AVOID_ONLY_APP_LOGGED_IN_KEY);

    if (lsDevKey || !isAuthenticated) return null;

    // Unlock the ecommerce pages
    if (pathname.startsWith('/shop/')) return null;

    return null;
};

export default routeInterceptor;
