import { Text, Button } from 'wikr-core-components';
import { ERROR_LEVELS, SENTRY_CABINET } from 'sentry-utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { PRIVATE } from 'router/routes';

import { MOCKED_PRODUCTS } from 'constants/products/upsell';

import sentry from 'services/Sentry/SentryInstance';

import * as S from './styled';

import { trackGoToMainClick, trackProductNotFoundScreenLoad } from 'analytics/trackers/shop';
import ShopPage from './ShopPage';

export const Shop = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // @ts-ignore
    const { productCode }: { productCode: keyof typeof MOCKED_PRODUCTS } = useParams();
    const product = MOCKED_PRODUCTS[productCode];

    useEffect(() => {
        if (!product) {
            trackProductNotFoundScreenLoad(productCode);

            sentry.logError(
                new Error('[UPSELL via CRM]: PRODUCT IS NOT FOUND'),
                SENTRY_CABINET,
                ERROR_LEVELS.CRITICAL,
                {
                    productCode,
                }
            );
        }
    }, []);

    const handleErrorProductClick = () => {
        trackGoToMainClick(productCode);
        navigate(PRIVATE.MAIN.path);
    };

    if (!product) {
        return (
            <S.ProductErrorWrapper>
                <Text center type="h2" mb={30}>
                    Product is not found
                </Text>

                <Button onClick={handleErrorProductClick} text={t('Go to main')} />
            </S.ProductErrorWrapper>
        );
    }

    return <ShopPage />;
};
