import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { LayoutProps } from 'router/types';

import { CabinetContent } from 'containers/CabinetContent/styled';

import Sidebar from 'components/Sidebar/SideBar';
import Header from 'components/Header/Header';

const CabinetLayout = ({ children, titleKey, withCenteredContent = false }: LayoutProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet title={t(titleKey)} />
            <Header />
            <CabinetContent withCenteredContent={withCenteredContent}>
                <Sidebar />
                {children}
            </CabinetContent>
        </>
    );
};

export default CabinetLayout;
