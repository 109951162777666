import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const List = ({ theme, color, size = 24 }: { theme: Theme; color?: Colors; size?: number }) => {
    const iconColor = theme[color || 'text-main'];

    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.25049 3.99988C7.25049 3.58566 7.58627 3.24988 8.00049 3.24988H21.0005C21.4147 3.24988 21.7505 3.58566 21.7505 3.99988C21.7505 4.41409 21.4147 4.74988 21.0005 4.74988H8.00049C7.58627 4.74988 7.25049 4.41409 7.25049 3.99988Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.25049 12C7.25049 11.5858 7.58627 11.25 8.00049 11.25H21.0005C21.4147 11.25 21.7505 11.5858 21.7505 12C21.7505 12.4142 21.4147 12.75 21.0005 12.75H8.00049C7.58627 12.75 7.25049 12.4142 7.25049 12Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.25049 20.0001C7.25049 19.5859 7.58627 19.2501 8.00049 19.2501H21.0005C21.4147 19.2501 21.7505 19.5859 21.7505 20.0001C21.7505 20.4143 21.4147 20.7501 21.0005 20.7501H8.00049C7.58627 20.7501 7.25049 20.4143 7.25049 20.0001Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.25 4C2.25 3.58579 2.58579 3.25 3 3.25H3.01C3.42421 3.25 3.76 3.58579 3.76 4C3.76 4.41421 3.42421 4.75 3.01 4.75H3C2.58579 4.75 2.25 4.41421 2.25 4Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H3.01C3.42421 11.25 3.76 11.5858 3.76 12C3.76 12.4142 3.42421 12.75 3.01 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.25 20C2.25 19.5858 2.58579 19.25 3 19.25H3.01C3.42421 19.25 3.76 19.5858 3.76 20C3.76 20.4142 3.42421 20.75 3.01 20.75H3C2.58579 20.75 2.25 20.4142 2.25 20Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(List);
