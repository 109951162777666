import styled from 'styled-components';

import { color } from 'theme/selectors';

export const SurveyContent = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 0 16px 32px 16px;
    overflow: hidden;
    background: ${color('surface-main')};
`;

export const DiscountsContainer = styled.div`
    margin-top: 32px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const OfferLabel = styled.div`
    width: fit-content;
    margin-bottom: 24px;
    background: ${color('critical-default')};
    padding: 2px 6px;
    border-radius: 4px;
`;

export const AccentColorPrice = styled.span`
    color: ${color('warning-default')};
`;
