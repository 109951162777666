import React from 'react';

import { selectIsUserFromCalifornia } from 'store/user/selectors';

import {
    useCaliforniaFlow,
    useCancelSubscription,
    useCommonSubscriptionLogic,
    useDiscountProduct,
    useRestoreSubscription,
    useResumeSubscription,
} from 'hooks/subscriptions';
import { useScreenLoadTracker, useSelector, useSubscriptions } from 'hooks';

import { isMainSubscription } from 'helpers/subscriptions';

import SubscriptionsSkeleton from './components/SubscriptionsSkeleton';
import SubscriptionHelpButton from './components/SubscriptionHelpButton';
import SubscriptionCardAdditionalNote from './components/SubscriptionCard/components/subComponents/SubscriptionCardAdditionalNote';
import SubscriptionCard from './components/SubscriptionCard';

import * as S from './styled';

import { ISubscription } from 'types/subscription';
import { CancellationStrategy, FlowType } from './types';

import { trackSubscriptionsTabLoad } from 'analytics/trackers/subscription';

const SubscriptionsTab = () => {
    useScreenLoadTracker(trackSubscriptionsTabLoad);

    const isFlowForCalifornia: boolean = useSelector(selectIsUserFromCalifornia);

    const { isLoading, subscriptions } = useSubscriptions();
    const { initRestoreSubscription } = useRestoreSubscription();
    const { initResumeSubscription } = useResumeSubscription();
    const { initSubscriptionCancellation } = useCancelSubscription();
    const { displayReturnOffer } = useCommonSubscriptionLogic();
    const { initCancellationForCalifornia } = useCaliforniaFlow();

    useDiscountProduct(subscriptions);

    const sortedSubscriptions = subscriptions.sort((a: ISubscription) => {
        if (isMainSubscription(a.product_code)) {
            return -1;
        }

        return 0;
    });

    const cancellationStrategies: Record<string, CancellationStrategy> = {
        california: initCancellationForCalifornia,
        regular: initSubscriptionCancellation,
    };

    const flowConditions: Record<FlowType, boolean> = {
        california: isFlowForCalifornia,
        regular: !isFlowForCalifornia, // Regular flow is the default
    };

    // @ts-ignore
    const flowType = Object.keys(flowConditions).find((key) => flowConditions[key]) || 'regular';

    const handleCancelFlow = cancellationStrategies[flowType];

    const displaySkeleton = isLoading && !sortedSubscriptions?.length;

    return (
        <S.Container paddingTop={40} paddingBottom={40}>
            {displaySkeleton ? (
                <SubscriptionsSkeleton />
            ) : (
                sortedSubscriptions.map((subscription: ISubscription) => (
                    <React.Fragment key={subscription.id}>
                        {!isMainSubscription(subscription.product_code) && <SubscriptionCardAdditionalNote />}
                        <SubscriptionCard
                            subscription={subscription}
                            onCancelSubscription={handleCancelFlow}
                            onReturnOffer={displayReturnOffer}
                            onResumeSubscription={initResumeSubscription}
                            onRestoreSubscription={initRestoreSubscription}
                        />
                    </React.Fragment>
                ))
            )}
            <SubscriptionHelpButton />
        </S.Container>
    );
};

export default SubscriptionsTab;
