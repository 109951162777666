import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const DownloadApp = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'border-default'];

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.9091 8.20001H14.9945C14.672 8.20169 14.3524 8.14027 14.0545 8.01937C13.7566 7.89847 13.4865 7.72052 13.26 7.49601L11.9018 6.16801C11.5214 5.79657 11.0697 5.5022 10.5727 5.30177C10.0757 5.10134 9.54308 4.99879 9.00545 5.00001H7.09091C6.00605 5.00039 4.96574 5.42194 4.19863 6.172C3.43152 6.92207 3.00039 7.93926 3 9.00001V15.4C3.00039 16.4608 3.43152 17.4779 4.19863 18.228C4.96574 18.9781 6.00605 19.3996 7.09091 19.4H16.9091C17.9939 19.3996 19.0343 18.9781 19.8014 18.228C20.5685 17.4779 20.9996 16.4608 21 15.4V12.2C20.9996 11.1393 20.5685 10.1221 19.8014 9.372C19.0343 8.62194 17.9939 8.20039 16.9091 8.20001V8.20001ZM14.2148 15.1656L12.5785 16.7656C12.5025 16.8399 12.4123 16.8989 12.3131 16.9391C12.2138 16.9793 12.1074 17 12 17C11.8926 17 11.7862 16.9793 11.6869 16.9391C11.5877 16.8989 11.4975 16.8399 11.4215 16.7656L9.78518 15.1656C9.63614 15.0147 9.55368 14.8126 9.55554 14.6029C9.5574 14.3931 9.64345 14.1925 9.79515 14.0441C9.94685 13.8958 10.1521 13.8117 10.3666 13.8099C10.5811 13.808 10.7878 13.8887 10.9421 14.0344L11.1818 14.2687V11.4C11.1818 11.1878 11.268 10.9843 11.4215 10.8343C11.5749 10.6843 11.783 10.6 12 10.6C12.217 10.6 12.4251 10.6843 12.5785 10.8343C12.732 10.9843 12.8182 11.1878 12.8182 11.4V14.2687L13.0579 14.0344C13.2122 13.8887 13.4189 13.808 13.6334 13.8099C13.8479 13.8117 14.0532 13.8958 14.2049 14.0441C14.3565 14.1925 14.4426 14.3931 14.4445 14.6029C14.4463 14.8126 14.3639 15.0147 14.2148 15.1656V15.1656Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(DownloadApp);
