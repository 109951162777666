import { SeverityLevel } from '@sentry/react';

const CRITICAL: SeverityLevel = 'fatal';
const ERROR: SeverityLevel = 'error';
const WARNING: SeverityLevel = 'warning';

const ERROR_LEVELS = {
    CRITICAL,
    ERROR,
    WARNING,
};

const SENTRY_AXIOS = 'APP_AXIOS';

const SUBSCRIPTION_ACTION = 'SUBSCRIPTION_ACTION';

const DEEP_LINK_ERROR = 'DEEP_LINK_ERROR';

export { SENTRY_AXIOS, ERROR_LEVELS, SUBSCRIPTION_ACTION, DEEP_LINK_ERROR };
