import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const Mute = ({ theme, color, size = 20 }: { theme: Theme; color?: Colors; size?: number }) => {
    const iconColor = theme[color || 'border-default'];

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99984 16.7851V3.20922C9.99984 2.92005 9.82734 2.66255 9.56067 2.55339C9.29234 2.44339 8.989 2.50672 8.7865 2.71255L5.264 6.29089C5.0315 6.52755 4.7065 6.66339 4.374 6.66339H2.9165C2.22734 6.66339 1.6665 7.22422 1.6665 7.91339V12.0801C1.6665 12.7692 2.22734 13.3301 2.9165 13.3301H4.374C4.7065 13.3301 5.0315 13.4659 5.264 13.7026L8.7865 17.2809C8.92317 17.4192 9.10484 17.4934 9.29067 17.4934C9.3815 17.4934 9.47317 17.4767 9.56067 17.4409C9.82734 17.3309 9.99984 17.0734 9.99984 16.7851ZM16.1783 7.64296C16.5038 7.31753 17.0314 7.31753 17.3568 7.64296C17.6823 7.9684 17.6823 8.49604 17.3568 8.82147L16.1783 9.99999L17.3568 11.1785C17.6823 11.5039 17.6823 12.0316 17.3568 12.357C17.0314 12.6824 16.5038 12.6824 16.1783 12.357L14.9998 11.1785L13.8213 12.357C13.4959 12.6824 12.9682 12.6824 12.6428 12.357C12.3174 12.0316 12.3174 11.5039 12.6428 11.1785L13.8213 9.99999L12.6428 8.82147C12.3174 8.49604 12.3174 7.9684 12.6428 7.64296C12.9682 7.31753 13.4959 7.31753 13.8213 7.64296L14.9998 8.82147L16.1783 7.64296Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(Mute);
