import * as action from './actions';

import * as actionTypes from './actionTypes';

import { InferValueTypes } from 'types/commonTypes';
import { FeatureDefinition } from '@growthbook/growthbook/src/types/growthbook';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

export type AbTestState = {
    features?: FeatureDefinition;
};

const initialState: AbTestState = {
    features: {},
};

const AbTestingReducer = (state = initialState, action: ActionType): AbTestState => {
    switch (action.type) {
        case actionTypes.SET_FEATURES:
            return { ...state, features: { ...state.features, ...action.payload } };

        default:
            return state;
    }
};

export default AbTestingReducer;
