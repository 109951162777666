/* eslint-disable max-lines */
import { useTranslation } from 'react-i18next';
import React from 'react';

import UpdatingSubscription from './components/UpdatingSubscription';
import SubscriptionRedemption from './components/SubscriptionRedemption';
import PausedSubscription from './components/PausedSubscription';
import ExpiresSubscription from './components/ExpiresSubscription';
import ExpiredSubscription from './components/ExpiredSubscription';
import DefaultSubscription from './components/DefaultSubscription';

import { ISubscriptionCard } from './types';

import useSubscriptionCard from './useSubscriptionCard';

const SubscriptionCard = ({
    onCancelSubscription,
    onReturnOffer,
    onRestoreSubscription,
    onResumeSubscription,
    subscription,
}: ISubscriptionCard) => {
    const { t } = useTranslation();
    const {
        isExpiredSubscription,
        isExpiresSubscription,
        isSubscriptionRedemption,
        isSubscriptionPaused,
        isSubscriptionUpdating,
        isDiscountedSubscription,
        isRestoredSubscription,
        isReminderSet,
        isTimePassedSincePaused,
        handleCancelSubscription,
        handleRestoreSubscription,
        handleResumeSubscription,
        state,
        upcomingProduct,
        tabIcon,
        heading,
        subscriptionPeriod,
        price,
        expirationDate,
        isTrial,
        isMainSubscription,
        productCode,
        subscriptionPausePeriod,
        onShowReturnOffer,
        isOfferAvailableForSubscription,
        isReturnOfferApplied,
        pausedTo,
    } = useSubscriptionCard({
        subscription,
        onCancelSubscription,
        onRestoreSubscription,
        onResumeSubscription,
        onReturnOffer,
    });

    const commonProps = {
        tabIcon,
        heading,
        subscriptionPeriod,
        price,
        isTrial,
        isMainSubscription,
        productCode,
        isReminderSet,
    };

    if (isExpiredSubscription) {
        return (
            <ExpiredSubscription
                {...commonProps}
                onRestoreSubscription={handleRestoreSubscription}
                isAlreadyRestored={isRestoredSubscription}
            />
        );
    }

    if (isSubscriptionUpdating) {
        return (
            <UpdatingSubscription
                {...commonProps}
                captions={{
                    description: t('subscription.text.updatingDescription'),
                }}
            />
        );
    }

    if (isSubscriptionRedemption) {
        return (
            <SubscriptionRedemption
                {...commonProps}
                captions={{
                    title: t('subscription.text.awaitingPayment'),
                    description: t('subscription.text.payYourBalance'),
                }}
                onCancelSubscription={handleCancelSubscription}
            />
        );
    }

    if (isSubscriptionPaused) {
        return (
            <PausedSubscription
                {...commonProps}
                captions={{
                    title: t('subscription.text.pausedTitle', {
                        pausePeriod: t('basic.periods', {
                            count: subscriptionPausePeriod.count,
                            context: subscriptionPausePeriod.context,
                        }),
                        date: pausedTo,
                    }),
                    description: t('subscription.text.pausedDescription'),
                }}
                nextChargeDate={expirationDate}
                isTimePassedSincePaused={isTimePassedSincePaused}
                onResumeSubscription={handleResumeSubscription}
                onCancelSubscription={handleCancelSubscription}
            />
        );
    }

    if (isExpiresSubscription) {
        return (
            <ExpiresSubscription
                {...commonProps}
                captions={{
                    title: t('subscription.text.cancelledTitle'),
                    description: t('subscription.text.cancelledDescription', {
                        date: expirationDate,
                    }),
                }}
                onShowReturnOffer={onShowReturnOffer}
                isOfferAvailableForSubscription={isOfferAvailableForSubscription}
                isReturnOfferApplied={isReturnOfferApplied}
            />
        );
    }

    return (
        <DefaultSubscription
            {...commonProps}
            expirationDate={expirationDate}
            state={state}
            // @ts-ignore
            upcomingProduct={upcomingProduct}
            onCancelSubscription={handleCancelSubscription}
            isDiscountedSubscription={isDiscountedSubscription}
        />
    );
};

export default SubscriptionCard;
