import { useNavigate } from 'react-router-dom';

import { useSelector } from './store';
import {
    selectIsMainSubscriptionActive,
    selectIsOneYearMainSubscription,
    selectIsReturnOfferApplied,
} from '../store/subscriptions/selectors';
import { PRIVATE } from '../router/routes';

export function useReturnOfferPageProtection() {
    const navigate = useNavigate();

    const isMainSubscriptionActive: boolean = useSelector(selectIsMainSubscriptionActive);
    const isReturnOfferApplied: boolean = useSelector(selectIsReturnOfferApplied);
    const isOneYearSubscription: boolean = useSelector(selectIsOneYearMainSubscription);

    if (isReturnOfferApplied || isMainSubscriptionActive || isOneYearSubscription) {
        navigate(PRIVATE.MAIN.path);

        return;
    }
}
