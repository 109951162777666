export const USD = {
    sign: '$',
    name: 'USD',
};

export const DEFAULT_CURRENCY = USD;

export const EUR = {
    sign: '€',
    name: 'EUR',
};

export const GBP = {
    sign: '£',
    name: 'GBP',
};

export const AUD = {
    sign: 'A$',
    name: 'AUD',
};

export const CAD = {
    sign: 'C$',
    name: 'CAD',
};

export const BRL = {
    sign: 'R$',
    name: 'BRL',
};

export const MXN = {
    sign: 'M$',
    name: 'MXN',
};

export const ARS = {
    sign: 'ARS',
    name: 'ARS',
};

export const CLP = {
    sign: 'CLP$',
    name: 'CLP',
};

export const COP = {
    sign: 'COL$',
    name: 'COP',
};

export const PEN = {
    sign: 'S/',
    name: 'PEN',
};

export const CURRENCY_SIGN_MAP: Record<string, string> = {
    USD: USD.sign,
    EUR: EUR.sign,
    GBP: GBP.sign,
    AUD: AUD.sign,
    CAD: CAD.sign,
    BRL: BRL.sign,
    MXN: MXN.sign,
    ARS: ARS.sign,
    CLP: CLP.sign,
    COP: COP.sign,
    PEN: PEN.sign,
};
