import { DEFAULT_ZENDESK_LANGUAGE, LANG_MAP } from 'constants/localization';
import { ZENDESK_ANDROID_URL, ZENDESK_IOS_URL, ZENDESK_URL } from 'constants/links';
import { ANDROID, IOS } from 'constants/device';

export const getFaqLinks = (lang: string, deviceOs: string) => {
    const url = ZENDESK_URL;
    const defaultLang = DEFAULT_ZENDESK_LANGUAGE;
    const isMobileDevice = deviceOs === IOS || deviceOs === ANDROID;
    const langCode = LANG_MAP[lang] || lang || defaultLang;
    const deviceLink = deviceOs === ANDROID ? ZENDESK_ANDROID_URL : ZENDESK_IOS_URL;

    const urlWithLang = `${url}${langCode}`;

    if (!isMobileDevice) return urlWithLang;

    return `${urlWithLang}/categories/${deviceLink}`;
};
