import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color, borderRadius } from 'theme/selectors';

export const ButtonContainer = styled(Box)`
    align-self: center;
    margin: 0 auto;
    width: 100%;
    padding: 0 16px;
    max-width: 432px;
`;

export const DownloadWrapper = styled(Box)`
    max-width: 110px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 69px;
    left: 32px;
    z-index: 10000;
`;

export const ImageWrapper = styled.div`
    position: relative;
    flex-shrink: 0;
`;

export const ScanQrCodeWrapper = styled(Box)`
    width: 110px;
    height: 110px;
    padding-top: 0;
    position: relative;
    display: flex;
`;

export const QRWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;

    svg {
        transform: translate(-6px, -6px);
    }

    canvas {
        width: 100% !important;
        height: 100% !important;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 880px;
`;

export const InstructionBlock = styled.div`
    display: flex;
    padding-bottom: 22px;
`;

export const InstructionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 32px;
`;

export const InstructionContainer = styled(Box)`
    display: flex;
`;

export const InstructionNumberContainer = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
`;

export const InstructionNumber = styled.div`
    width: 18px;
    height: 18px;
    background-color: ${color('primary-default')};
    border-radius: ${borderRadius('medium')};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const VideoBlock = styled.div`
    border: 1px solid ${color('border-default')};
    height: 79px;
    padding: 16px;
    border-radius: ${borderRadius('medium')};
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LinksWrapper = styled.div`
    display: flex;
`;

export const Link = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 162px;
    cursor: pointer;
    opacity: 0.5;
    background-color: ${color('surface-hovered')};
    border-radius: ${borderRadius('medium')};
    padding: 9px 12px;

    :hover {
        opacity: 1;
    }

    :active {
        opacity: 1;
    }

    :first-child {
        margin-right: 12px;
    }
`;

export const LinkText = styled(Text)`
    margin-left: 12px;
`;

export const FrontChatButton = styled.button`
    border-bottom: 1px solid ${color('text-secondary')};
    color: ${color('text-secondary')};
    cursor: pointer;
`;
