import { EMAIL_MATCH_REGEXP } from 'constants/RegExp';

import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .required('validation.email.required')
        .trim()
        .matches(EMAIL_MATCH_REGEXP, 'validation.email.invalid'),
});
