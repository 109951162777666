import { NavLink, useLocation } from 'react-router-dom';
import { memo } from 'react';

import { linkType, SidebarLinkProps } from '../types';

import SidebarLinkButton from './SidebarLinkButton';

const SidebarLink = ({ to, title, onClick, dataLocator, type, target = '_self', icon }: SidebarLinkProps) => {
    const handleClick = () => onClick && onClick(to);
    const { pathname } = useLocation();

    const isActive = pathname === to || pathname.startsWith(`${to}/`);
    const isPrimary = linkType.Primary === type;

    const linkProps = {
        'data-locator': dataLocator,
        onClick: handleClick,
        target,
    };

    const commonButtonProps = {
        isActive,
        isPrimary,
        title,
        icon,
    };

    return (
        <NavLink to={to} {...linkProps}>
            <SidebarLinkButton {...commonButtonProps} />
        </NavLink>
    );
};

export default memo(SidebarLink);
