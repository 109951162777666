import { Icon } from 'wikr-core-components';
import { Navigate, useNavigate } from 'react-router-dom';
import React, { ReactNode, useEffect } from 'react';

import { PRIVATE } from 'router/routes';

import { initHotjar } from 'services/Hotjar';

import { isPaidUser } from 'helpers/verifiers';

import * as S from './styled';

const WorkoutsContainer = ({
    children,
    withBackButton,
    trackOnBack,
}: {
    children: ReactNode;
    withBackButton?: boolean;
    trackOnBack?: () => void;
}) => {
    const navigate = useNavigate();
    const isFeatureEnable = isPaidUser();

    useEffect(() => {
        isFeatureEnable && initHotjar();
    }, []);

    if (!isFeatureEnable) {
        return <Navigate to={PRIVATE.MAIN.path} />;
    }

    const onBack = () => {
        trackOnBack?.();
        navigate(-1);
    };

    return (
        <S.WorkoutsContainer>
            {withBackButton && (
                <>
                    <S.BackButton onClick={onBack}>
                        <Icon name="arrowLeft" />
                    </S.BackButton>
                </>
            )}

            {children}
        </S.WorkoutsContainer>
    );
};

export default WorkoutsContainer;
