import React from 'react';

import { createProcessingSelector } from 'store/loading/selectors';
import { selectDeepLink } from 'store/deepLink/selectors';
import { GET_DEEP_LINK } from 'store/deepLink/actionTypes';

import { useSelector } from 'hooks';

import Skeleton from 'components/Skeleton';

import * as S from './styled';

import { InstallAppButtonProps } from './types';

const InstallAppButton = ({
    type,
    link,
    isMobile = false,
    trackClick,
    size = 'sm',
    dataLocator,
    alt,
}: InstallAppButtonProps) => {
    const isLoading: boolean = useSelector(createProcessingSelector([GET_DEEP_LINK]));
    const deepLinkUrl = useSelector(selectDeepLink);

    return (
        <>
            {isLoading ? (
                <Skeleton width={150} height={45} borderRadius={8} marginLeft={8} />
            ) : (
                <button onClick={trackClick}>
                    <S.StoreBtn
                        dataLocator={dataLocator}
                        size={size}
                        isMobile={isMobile}
                        link={link || deepLinkUrl}
                        type={type}
                        alt={alt}
                    />
                </button>
            )}
        </>
    );
};

export default InstallAppButton;
