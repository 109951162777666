import { Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const ProductWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 40px 12px 80px;
`;

export const ProductErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    max-width: 400px;
    height: 100%;
    min-height: inherit;
    padding: 12px;
`;

export const ProductInfoBlock = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    background: ${color('surface-default')};
    margin-bottom: 32px;
    border-radius: 12px;
    border: 1px solid ${color('primary-disabled')}};
`;

export const TitleWrapper = styled.div`
    padding: 23px 16px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    max-width: calc(100% - 90px);
`;

export const ButtonWrp = styled.div`
    &:before {
        width: 100%;
        height: 44px;
        content: '';
        position: absolute;
        bottom: 100%;
        left: 0;
        background: linear-gradient(180deg, rgba(29, 29, 29, 0), ${color('secondary-default')});
    }

    &:after {
        width: 100%;
        height: 12px;
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        background: ${color('secondary-default')};
    }

    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    bottom: 12px;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
`;

export const OldPrice = styled(Text)`
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        border-top: 1px solid ${color('critical-default')};
        transform: rotate(-15deg);
    }
`;

export const Price = styled.div`
    padding: 23px 15px;
    display: flex;
    width: 90px;
    align-items: center;
    border-left: 1px solid ${color('surface-hovered')};
`;

export const SalePercents = styled.div`
    background: ${color('primary-default')};
    padding: 2px 14px;
    position: absolute;
    left: 16px;
    top: 0;
    transform: translateY(-50%);
    border-radius: 14px;
`;

export const ProductEmoji = styled.div`
    background: ${color('secondary-default')};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
`;

export const PlusIcon = styled.div`
    padding: 3px 14px;
    display: flex;
`;

export const ProductTitle = styled(Text)`
    width: 100%;
`;

export const LegalText = styled(Text)`
    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
`;

export const TipsList = styled.ul`
    li {
        padding-left: 36px;
        list-style: none;
        margin-bottom: 16px;
        position: relative;

        svg {
            position: absolute;
            width: 24px;
            height: 24px;
            left: 0;
            top: 0;
        }
    }
`;
