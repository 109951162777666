import { PRIVATE, PUBLIC } from 'router/routes';

import { selectCurrentUser } from 'store/user/selectors';
import { isAuthenticatedSelector } from 'store/auth/selectors';
import { isFeatureEnabled } from 'store/abTesting/helpers';

import { START_FINISH_TEST } from 'constants/features';

import { useSelector } from 'hooks';

export const getRedirectPath = () => {
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const isStartWorkoutFeatureEnabled = isFeatureEnabled(START_FINISH_TEST);
    const { is_logged_in, user_workout_info } = useSelector(selectCurrentUser);
    const isExperiment = isStartWorkoutFeatureEnabled && is_logged_in;
    const finishFirstWorkout = user_workout_info?.finish_first_workout;
    const startFirstWorkout = user_workout_info?.start_first_workout;

    if (!isStartWorkoutFeatureEnabled && !isAuthenticated) return PUBLIC.TEMPORARY_PASSWORD.path;
    if (isExperiment && !finishFirstWorkout && !startFirstWorkout) return PRIVATE.START_WORKOUT_PAGE.path;
    if (isExperiment && startFirstWorkout && !finishFirstWorkout) return PRIVATE.FINISH_WORKOUT_PAGE.path;
    if (isExperiment && finishFirstWorkout) return PRIVATE.CONTINUE_PROGRESS_PAGE.path;

    return PRIVATE.MAIN.path;
};
