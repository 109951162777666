export const getContent = (): { data: { text: string }[] } => {
    return {
        data: [
            {
                text: 'loginInstructionSimple.instruction1',
            },
            {
                text: 'loginInstructionSimple.instruction2',
            },
            {
                text: 'loginInstructionSimple.instruction3',
            },
            {
                text: 'loginInstructionSimple.instruction4',
            },
            {
                text: 'loginInstructionSimple.instruction5',
            },
        ],
    };
};
