import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const AlarmIcon = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'text-main'];

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.99951 14.666C4.70396 14.6226 2.04295 11.9616 1.99951 8.66602C2.04295 5.37047 4.70396 2.70945 7.99951 2.66602C11.2951 2.70945 13.9561 5.37047 13.9995 8.66602C13.9561 11.9616 11.2951 14.6226 7.99951 14.666ZM7.99951 3.99935C5.43629 4.03309 3.36659 6.1028 3.33285 8.66602C3.36659 11.2292 5.43629 13.2989 7.99951 13.3327C10.5627 13.2989 12.6324 11.2292 12.6662 8.66602C12.6324 6.1028 10.5627 4.03309 7.99951 3.99935Z"
                fill={iconColor}
            />
            <path
                d="M13.3 8.66602C13.3 11.5931 10.9271 13.966 8 13.966C5.07289 13.966 2.7 11.5931 2.7 8.66602C2.7 5.73891 5.07289 3.36602 8 3.36602C10.9271 3.36602 13.3 5.73891 13.3 8.66602Z"
                stroke="white"
                strokeWidth="1.4"
            />
            <path d="M2.6665 3.33333L3.99984 2" stroke="white" strokeWidth="1.4" strokeLinecap="round" />
            <path d="M12 2.00065L13.3333 3.33398" stroke="white" strokeWidth="1.4" strokeLinecap="round" />
            <path
                d="M8 6.33398L8.0001 9.6678L9.33333 10.6673"
                stroke="white"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default withTheme(AlarmIcon);
