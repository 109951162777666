import {
    ACCOUNT_SETTINGS_SUBSCRIPTION,
    ACCOUNT_SUBSCRIPTION,
    CANCEL_OFFER,
    CANCEL_SURVEY,
    SPECIAL_OFFER_DISCOUNT,
    SUBSCRIPTION_CANCELLED,
    SUBSCRIPTION_IS_NOT_CANCELLED,
} from 'analytics/constants/ids';

import { trackClick, trackEvent, trackNewScreenLoad, trackScreenLoad } from 'services/Analytics';

import { SubscriptionProductCodes } from 'types/product';

export const trackSubscriptionsTabLoad = () =>
    trackNewScreenLoad(ACCOUNT_SETTINGS_SUBSCRIPTION, { event_action: 'screen__load', event_label: 'subscription' });

export const trackSurveyLoad = () => trackScreenLoad(CANCEL_SURVEY);

export const trackSubscriptionsTabNeedSupport = () =>
    trackClick(ACCOUNT_SUBSCRIPTION, { event_action: 'help_support' });

export const trackSubscriptionCardCancelClick = (
    subscriptionType: string,
    productCode: SubscriptionProductCodes,
    contentId: string
) => {
    trackClick(ACCOUNT_SUBSCRIPTION, {
        event_action: `cancel_${subscriptionType}`,
        event_label: productCode,
        contentId,
    });
};

export const trackDiscardCancellation = () => trackClick(CANCEL_SURVEY, { event_action: 'no_cancel' });

export const trackCloseSurvey = () => trackClick('survey_screen_button_close');

export const trackCancelSubscription = () =>
    trackClick(CANCEL_OFFER, { event_action: 'button__continue_cancel', event_label: SPECIAL_OFFER_DISCOUNT });

export const trackCloseDiscountOffer = (discount: number, contentId: string, isCaliforniaFlow?: boolean) => {
    const offerLabel = isCaliforniaFlow ? `discount_offer_${discount}_ca` : `discount_offer_${discount}`;

    trackClick('close_cancel_offer', { event_action: 'close', event_label: offerLabel, contentId });
};

export const trackReturnDiscountOffer = (discount: number) =>
    trackClick('return_cancel_offer', { event_action: 'return', event_label: `discount_offer_${discount}` });

export const trackChangePlan = (eventId: string, eventLabel: string, contentId: string) =>
    trackClick(eventId, { event_action: 'button__continue', event_label: eventLabel, contentId });

export const trackRenewPlan = (eventId: string, eventLabel: string) =>
    trackClick(eventId, { event_action: 'button__continue', event_label: eventLabel });

export const trackSelectCancellationReason = (reason: string) => trackClick(CANCEL_SURVEY, { event_label: reason });

export const trackCancelSubscriptionSuccessEvent = () => trackEvent(SUBSCRIPTION_CANCELLED);

export const trackCancelSubscriptionFailedEvent = () =>
    trackEvent(SUBSCRIPTION_IS_NOT_CANCELLED, {
        event_label: 'cancel_renewal_errored',
    });

export const trackCancelSubscriptionFailedScreenLoad = () =>
    trackNewScreenLoad(SUBSCRIPTION_IS_NOT_CANCELLED, {
        event_action: 'error',
        event_label: 'cancel_renewal_errored',
    });

export const trackCancelSubscriptionFailedRetryClick = () =>
    trackClick(SUBSCRIPTION_IS_NOT_CANCELLED, { event_action: 'try_again', event_label: 'cancel_renewal_errored' });
