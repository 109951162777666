import * as actionTypes from './actionTypes';

import { FeatureDefinition } from '@growthbook/growthbook/src/types/growthbook';
import { UserStore } from '../../types/store/userStore';

export const setFeatures = (payload: FeatureDefinition) => {
    return {
        type: actionTypes.SET_FEATURES,
        payload,
    } as const;
};

export const initAbTest = (payload: UserStore) => {
    return {
        type: actionTypes.INIT_AB_TEST,
        payload,
    } as const;
};
