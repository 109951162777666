import { EmailIcon, PasswordIcon } from 'components/Icon';

export const CONTENT = [
    {
        name: 'email',
        label: 'input.email.label',
        type: 'email',
        icon: () => <EmailIcon />,
        dataLocator: 'loginEmailInput',
        placeholder: 'input.email.placeholder',
    },
    {
        name: 'password',
        label: 'input.password.label',
        type: 'password',
        icon: () => <PasswordIcon />,
        dataLocator: 'loginPasswordInput',
        placeholder: 'input.password.placeholder',
    },
];
