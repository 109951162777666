import { all, call } from 'redux-saga/effects';

import workoutsGuides from './workouts/sagas';
import watchUser from './user/sagas';
import watchSubscriptions from './subscriptions/sagas';
import watchNotifications from './notifications/sagas';
import watchNavigation from './navigation/sagas';
import watchGuides from './guides/sagas';
import exercisesGuides from './exercises/sagas';
import watchHandleErrorAction from './errors/sagas';
import watchDeepLink from './deepLink/sagas';
import watchAuth from './auth/sagas';

export default function* rootSaga() {
    yield all([
        call(watchUser),
        call(watchDeepLink),
        call(watchAuth),
        call(watchNotifications),
        call(watchSubscriptions),
        call(watchGuides),
        call(exercisesGuides),
        call(workoutsGuides),
        call(watchNavigation),
        call(watchHandleErrorAction),
    ]);
}
