import { useCallback, useState, useEffect } from 'react';

import screenfull from 'screenfull';

type UseScreenfull = {
    isFullscreen: boolean;
    requestFullscreen: (element?: Element | MouseEvent) => Promise<void>;
    exit: typeof screenfull.exit;
};

// Function to request fullscreen or exit fullscreen
const requestFullscreen: UseScreenfull['requestFullscreen'] = (requestElement) => {
    if (screenfull.isEnabled) {
        // Determine the target element
        const element =
            // eslint-disable-next-line no-nested-ternary
            requestElement instanceof Element
                ? requestElement
                : requestElement instanceof MouseEvent
                ? (requestElement.target as Element)
                : undefined; // Screenfull uses the window by default if the element is undefined

        // If currently in fullscreen and either no requestElement or the target element is the current fullscreen element, exit fullscreen
        if (screenfull.isFullscreen && (!requestElement || element === screenfull.element)) {
            return screenfull.exit();
        }

        // Otherwise, request fullscreen for the target element
        return screenfull.request(element);
    }

    return Promise.resolve();
};

// Custom React Hook for managing fullscreen state
const useScreenfull = (): UseScreenfull => {
    const [isFullscreen, setIsFullscreen] = useState(screenfull.isEnabled && screenfull.isFullscreen);

    // Handler for updating isFullscreen state when the fullscreen state changes
    const handler = useCallback(() => {
        if (screenfull.isEnabled) {
            setIsFullscreen(screenfull.isFullscreen);
        } else {
            console.log('Screenfull not enabled');
        }
    }, []);

    // Subscribe to the 'change' event to listen for fullscreen state changes and clean up on unmount
    useEffect(() => {
        if (screenfull.isEnabled) {
            screenfull.on('change', handler);
        }

        return () => {
            if (screenfull.isEnabled) screenfull.off('change', handler);
        };
    }, [handler]);

    return { isFullscreen, requestFullscreen, exit: screenfull.exit };
};

export default useScreenfull;
