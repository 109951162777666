import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const Workout = ({ theme, color, size = 24 }: { theme: Theme; color?: Colors; size?: number }) => {
    const iconColor = theme[color || 'border-default'];

    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.66671 5.7C3.66671 5.3134 3.98011 5 4.36671 5H5.74449C6.13108 5 6.44448 5.3134 6.44448 5.7V18.3C6.44448 18.6866 6.13108 19 5.74448 19H4.36671C3.98011 19 3.66671 18.6866 3.66671 18.3V5.7ZM17.5556 5.7C17.5556 5.3134 17.869 5 18.2556 5H19.6334C20.02 5 20.3334 5.3134 20.3334 5.7V18.3C20.3334 18.6866 20.02 19 19.6334 19H18.2556C17.869 19 17.5556 18.6866 17.5556 18.3V5.7ZM8.61112 10.3846C8.33498 10.3846 8.11112 10.6085 8.11112 10.8846V13.1154C8.11112 13.3915 8.33498 13.6154 8.61112 13.6154H15.3889C15.665 13.6154 15.8889 13.3915 15.8889 13.1154V10.8846C15.8889 10.6085 15.665 10.3846 15.3889 10.3846H8.61112ZM20.3334 8.23081H21.3001C21.6867 8.23081 22.0001 8.54421 22.0001 8.93081V15.0693C22.0001 15.4559 21.6867 15.7693 21.3001 15.7693H20.3334V8.23081ZM2.7 8.23081H3.66667V15.7693H2.7C2.3134 15.7693 2 15.4559 2 15.0693V8.93081C2 8.54421 2.3134 8.23081 2.7 8.23081Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(Workout);
