import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const DownloadIcon = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'primary-default'];

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10L12 15L17 10" stroke={iconColor} strokeWidth="2" />
            <path d="M12 15V3" stroke={iconColor} strokeWidth="2" strokeLinejoin="round" />
            <path
                d="M3 15V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V15"
                stroke={iconColor}
                strokeWidth="2"
            />
        </svg>
    );
};

export default withTheme(DownloadIcon);
