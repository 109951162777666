import { Text, Box, Image, Button } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import Modal from 'components/Modal';

import mail from './img/mail.webp';

import { ReminderSuccessModalProps } from './types';

import { ReminderSuccessLoad } from 'analytics/trackers/reminderForSubscription';

const ReminderSuccessModal = ({ onClose, onSubmit, subscriptionPeriod }: ReminderSuccessModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            paddingY={16}
            paddingX={16}
            isOpen
            onClose={onClose}
            trackLoad={ReminderSuccessLoad}
            withCloseButton
            buttonWithBackground
            dataLocatorContainer="updatingSubscriptionPlanModal"
            dataLocatorCloseButton="updatingSubscriptionPlanCloseButton"
        >
            <Box paddingTop={44}>
                <Image maxWidth={72} src={mail} alt="mail" center />
                <Text mb={12} type="h5" text={t('subscription.reminder.success.title')} center />
                <Text
                    mb={24}
                    text={t('subscription.reminder.success.subtitle', { subscription: subscriptionPeriod })}
                    center
                />
                <Button
                    text={t('basics.continue')}
                    dataLocator="updatingSubscriptionPlanContinueButton"
                    onClick={onSubmit}
                />
            </Box>
        </Modal>
    );
};

export default ReminderSuccessModal;
