import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';

export const Textarea = styled.textarea<{ mb?: number; invalid?: boolean; resizable?: boolean }>`
    background: ${color('surface-default')};
    color: ${color('text-main')};
    border: 1px solid
        ${({ invalid }) =>
            css`
                ${color(invalid ? 'critical-default' : 'border-default')}
            `};

    border-radius: ${({ theme }) => theme['border-radius-medium']}px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-family);
    padding: 12px 12px;
    resize: ${({ resizable }) => (resizable ? 'vertical' : 'none')};

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: ${color('text-secondary-subdued')};
    }

    margin-bottom: ${({ mb = 0 }) => mb}px;
`;
