import { all, call, put, takeLatest } from 'redux-saga/effects';

import { PRIVATE } from 'router/routes';

import api from 'api';

import { notifyError } from 'store/notifications/actions';
import { navigateTo } from 'store/navigation/actions';
import { fetchExercises } from 'store/exercises/actions';
import { handleErrorAction } from 'store/errors/actions';

import { createWorkout, setLoadingState, setWorkout, setWorkoutAudios, workoutComplete } from './actions';

import { COMPLETE_WORKOUT, CREATE_WORKOUT, GET_WORKOUT_AUDIOS } from './actionTypes';

import { EXTENDED_DATE_FORMAT } from 'constants/date';

import { getBlocksMap, getOnlyExercises, getPlaylist } from 'helpers/workouts';
import { getToday } from 'helpers/date';

import { WorkoutAudioMapType, WorkoutAudioType, WorkoutResponse } from 'types/workouts';

import { trackWorkoutCreateSuccess } from 'analytics/trackers/workout';

function* getWorkout({ payload }: ReturnType<typeof createWorkout>) {
    const { params, onError } = payload;

    try {
        const response: WorkoutResponse = yield call(api.workouts.buildWorkout, params);

        const workout = {
            ...response,
            created_at: getToday(EXTENDED_DATE_FORMAT),
        };

        const blocks = getBlocksMap(workout);
        const playlist = getPlaylist(workout);
        const exercises = getOnlyExercises(playlist);

        yield put(
            setWorkout({
                workout,
                blocks,
                playlist,
                exercises,
            })
        );

        const workoutConfiguration = {
            equipment: params.equipments,
            duration: params.workout_time,
            area: params.target_areas,
            difficulty: params.workout_fitness_level,
            is_warmup: params.warm_up,
            is_cooldown: params.cool_down,
        };

        trackWorkoutCreateSuccess(workoutConfiguration, exercises.length.toString());

        yield put(fetchExercises(workout.id));
        yield put(setLoadingState(false));

        yield put(navigateTo({ url: PRIVATE.WORKOUT_PREVIEW.path }));
    } catch (error) {
        yield put(notifyError('basics.appError'));
        yield put(setLoadingState(false));
        yield put(handleErrorAction(error, payload));
        onError && onError();
    }
}

function* completeWorkout({ payload }: ReturnType<typeof workoutComplete>) {
    const { params, onSuccess, onError } = payload;

    try {
        yield call(api.workouts.complete, params);

        onSuccess && onSuccess();
    } catch (error) {
        yield put(notifyError('basics.appError'));
        yield put(setLoadingState(false));
        yield put(handleErrorAction(error, payload));

        onError && onError();
    }
}

function* getWorkoutAudios() {
    try {
        const audiosResponse: WorkoutAudioType[] = yield call(api.workouts.getWorkoutAudios);

        const map: WorkoutAudioMapType = { unknown: [] };

        audiosResponse.forEach((audio) => {
            if (!audio) return;

            if (!audio.category_name) {
                map.unknown.push(audio);

                return;
            }

            if (!map[audio.category_name]) {
                map[audio.category_name] = [audio];
            } else {
                map[audio.category_name].push(audio);
            }
        });

        yield put(setWorkoutAudios(map));
    } catch (error) {
        yield put(handleErrorAction(error));
    }
}

export default function* watchWorkouts() {
    yield all([
        takeLatest(CREATE_WORKOUT, getWorkout),
        takeLatest(COMPLETE_WORKOUT, completeWorkout),
        takeLatest(GET_WORKOUT_AUDIOS, getWorkoutAudios),
    ]);
}
