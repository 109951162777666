export enum SidebarEventLabels {
    app = 'muscle_booster_app',
    guides = 'fitness_guides',
    account = 'account',
    support = 'help_support',
    faq = 'faq',
    terms = 'terms_of_use',
    privacy = 'privacy_policy',
    refund = 'refund_policy',
    logout = 'log_out',
    workout = 'workout',
}
