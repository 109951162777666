import { Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React from 'react';

import * as S from './styled';

interface IWorkoutTitle {
    title: string;
    subtitle: string;
}

export const WorkoutTitle = ({ title, subtitle }: IWorkoutTitle) => {
    return (
        <>
            <S.Title type="h5" mb={12}>
                <Trans i18nKey={title} />
            </S.Title>
            <Text type="medium-text" color="text-secondary">
                <Trans i18nKey={subtitle} />
            </Text>
        </>
    );
};
