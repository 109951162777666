import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';

const flexBlock = css`
    display: flex;
    justify-content: space-between;
`;

export const SubscriptionCardHeader = styled.div<{ isExpired?: boolean }>`
    ${flexBlock};
    align-items: flex-start;
    margin-bottom: 20px;

    ${({ isExpired }) =>
        isExpired &&
        css`
            img {
                opacity: 0.5;
            }
        `}
`;

export const Name = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8px;
`;

export const ExpandButton = styled.button<{ isTabExpanded?: boolean }>`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 0;
    overflow: hidden;
    transform: rotate(180deg);
    flex-shrink: 0;

    &:hover {
        background: ${color('border-default')};
    }

    svg {
        position: relative;
        top: 2px;
    }

    ${({ isTabExpanded }) =>
        isTabExpanded &&
        css`
            transform: rotate(0deg);
        `}
`;

interface Colors {
    color: 'yellow' | 'gray' | 'red' | 'green' | 'blue';
}

export const Status = styled.div<Colors>`
    display: flex;
    align-items: center;
    padding: 2px 6px;
    border-radius: 4px;
    width: fit-content;
    background-color: ${(props) => {
        switch (props.color) {
            case 'blue':
                return color('primary-default');

            case 'gray':
                return color('border-default');

            case 'red':
                return color('critical-default');

            case 'yellow':
                return color('warning-default');

            case 'green':
                return color('success-highlight');

            default:
                return color('primary-default');
        }
    }};

    svg {
        margin-right: 4px;
    }
`;

export const StatusesWrapper = styled.div`
    display: flex;

    > div {
        margin-right: 6px;
    }
`;

export const SubscriptionCarRow = styled.div`
    margin-top: 8px;
    display: flex;
    align-items: center;

    p {
        flex: 1;
        margin: 0;
    }
`;

export const GeneralInfo = styled.div<{ mb?: number; mt?: number }>`
    margin-bottom: ${({ mb = 24 }) => mb}px;
    margin-top: ${({ mt = 0 }) => mt}px;
`;

export const Line = styled.div<{ mt?: number; mb?: number }>`
    margin-top: ${({ mt = 0 }) => mt}px;
    margin-bottom: ${({ mb = 0 }) => mb}px;
    width: 100%;
    height: 1px;
    background-color: ${color('border-default')};
`;

export const CancelButton = styled.button`
    margin-bottom: 12px;
    cursor: pointer;
`;

export const Img = styled.img`
    width: 32px;
`;

export const AdditionalStatusInfo = styled.div<{ mb?: number }>`
    margin-bottom: ${({ mb = 20 }) => mb}px;
    margin-top: 20px;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    background-color: ${color('surface-default')};
`;

export const SubscriptionCardAdditionalNote = styled.div`
    padding: 0 16px;
`;
