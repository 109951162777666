import { ACCOUNT, ACCOUNT_SETTINGS, ACCOUNT_SETTINGS_ACCOUNT, ACCOUNT_SETTINGS_PROFILE } from 'analytics/constants/ids';

import { trackScreenLoad, trackClick } from 'services/Analytics';

export const trackAccountSettingsScreenLoad = () => trackScreenLoad(ACCOUNT);

export const trackChangeTab = (tab: string) => trackClick(ACCOUNT_SETTINGS, { event_action: tab });

export const trackProfileTabLoad = () => trackScreenLoad(ACCOUNT_SETTINGS_PROFILE);

export const trackProfileTabSave = () => trackClick(ACCOUNT_SETTINGS_PROFILE, { event_action: 'save' });

export const trackAccountTabLoad = () => trackScreenLoad(ACCOUNT_SETTINGS_ACCOUNT);

export const trackChangeEmailClick = () => trackClick(ACCOUNT_SETTINGS_ACCOUNT, { event_action: 'change_email' });
