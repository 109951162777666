export const AUTHENTICATE = 'AUTH/AUTHENTICATE';
export const AUTHENTICATE_BY_SIGNATURE = 'AUTH/AUTHENTICATE_BY_SIGNATURE';
export const AUTHENTICATION_SUCCESS = 'AUTH/AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILED = 'AUTH/AUTHENTICATION_FAILED';
export const SET_AUTHENTICATION_STATUS = 'AUTH/SET_AUTHENTICATION_STATUS';
export const SET_AUTHENTICATION_REDIRECT_URL = 'AUTH/SET_AUTHENTICATION_REDIRECT_URL';
export const SIGN_IN_REQUEST = 'AUTH/SIGN_IN_REQUEST';
export const SIGN_IN_ERROR = 'AUTH/SIGN_IN_ERROR';
export const SET_USER_SOURCE = 'AUTH/SET_USER_SOURCE';
export const LOG_OUT = 'AUTH/LOG_OUT';

export const GENERATE_TEMPORARY_PASSWORD_REQUEST = 'USER/GENERATE_TEMPORARY_PASSWORD_REQUEST';
export const CHECK_TEMPORARY_PASSWORD_REQUEST = 'USER/CHECK_TEMPORARY_PASSWORD_REQUEST';

export const SET_AUTH_TOKEN = 'AUTH/SET_AUTH_TOKEN';
