import styled, { keyframes } from 'styled-components';

import { color } from 'theme/selectors';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const spin = keyframes`
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
`;

export const Progress = styled.div`
    position: relative;
    width: 80px;
    height: 80px;
    display: block;

    &:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
        width: 80px;
        min-height: 80px;
        border: 4px solid ${color('primary-default')};
        border-left: 4px solid transparent;
        border-radius: 50%;
        box-sizing: border-box;
        animation: ${spin} 1.3s infinite linear;
        transition: opacity 0.3s;
    }
`;
