/* eslint-disable max-lines */
import { Text } from 'wikr-core-components';
import styled, { keyframes, css } from 'styled-components';

import { borderRadius, color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const WorkoutPlayerContainer = styled.div`
    display: grid;
    grid-gap: 24px 32px;
    grid-template-areas:
        'player'
        'details'
        'sidebar';
    margin-top: -8px;

    ${mediaQueries.laptop} {
        grid-template-areas:
            'header'
            'player'
            'details'
            'sidebar';
        margin-top: 0;
    }

    ${mediaQueries.tabletLandscape} {
        grid-gap: 32px 24px;
    }

    ${mediaQueries.desktop} {
        grid-template-columns: 65% 35%;
        grid-template-areas:
            'header  header'
            'player sidebar'
            'details  sidebar';
    }
`;

export const PlayerContainer = styled.div`
    grid-area: player;
    overflow: hidden;
    grid-row-gap: 0;

    ${mediaQueries.laptop} {
        background-color: ${color('surface-default')};
        border-radius: ${borderRadius('medium')};
    }
`;

export const WorkoutTitleWrapper = styled.div`
    grid-area: header;
    display: none;
    padding: 0 16px;

    ${mediaQueries.laptop} {
        display: block;
    }

    ${mediaQueries.laptop} {
        padding: 0;
    }
`;

export const ExercisesCountBlock = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 0 0 20px;
    margin-bottom: 20px;
`;

export const AllExercisesTitle = styled(Text)`
    margin-right: 8px;
`;

export const AllExercisesSubtitle = styled(Text)`
    text-transform: lowercase;
`;

export const Line = styled.div`
    position: absolute;
    top: 52px;
    left: -16px;
    width: calc(100% + 32px);
    height: 1px;
    background-color: ${color('surface-hovered')};
`;

export const PlayerWrapper = styled.div`
    position: relative;
`;

export const PlayButton = styled.button`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    cursor: pointer;
    z-index: 5;
    transform: translate(-50%, -50%);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PlayIcon = styled.span`
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: rgba(40, 41, 43, 0.8);
    transition: 0.3s ease;

    &:hover {
        background-color: ${color('primary-default')};
    }
`;

export const Player = styled.div`
    background-color: #000;
    position: relative;
    padding-top: 100%; /* Player ratio: 1 */

    ${mediaQueries.laptop} {
        padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    }

    .react-player {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100% !important;
        height: 100% !important;
    }

    &:hover {
        ${PlayButton} {
            opacity: 1;
        }
    }
`;

export const CountDownOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
`;

const breatheAnimation = keyframes`
    0% {
        transform: scale3d(5, 5, 1);
        opacity: 0;
    }
    5% {
        transform: scale3d(5, 5, 1);
        opacity: 1;
    }
    100% {
        transform: scale3d(1, 1, 1);
        opacity: 0;
    }
`;

export const LaunchCountdown = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    text-align: center;
    color: ${color('text-main')};
    text-transform: uppercase;
    padding: 50px;
    transform: translate(-50%, -50%);
    z-index: 1;

    > * {
        animation-name: ${breatheAnimation};
        animation-duration: 1s;
        animation-iteration-count: initial;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        -webkit-font-smoothing: antialiased;
    }
`;

export const Pagination = styled.div`
    padding: 0 0 12px;
    display: flex;
    width: 100%;
    align-content: stretch;
    gap: 4px;

    ${mediaQueries.laptop} {
        padding: 12px 0;
    }
`;

export const PlayerHeader = styled.div`
    padding: 0 12px 12px;

    ${mediaQueries.tablet} {
        padding: 12px 16px;
    }

    ${mediaQueries.laptop} {
        padding: 24px 24px 12px;
    }
`;

export const Page = styled.div<{ progress: number }>`
    height: 4px;
    border-radius: 100px;
    flex-grow: 1;
    flex-basis: 0;
    position: relative;
    background-color: ${color('surface-hovered')};
    overflow: hidden;

    &:after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        background: #fff;
        width: ${({ progress }) => progress}%;
        height: 100%;
        transition: 0.3s ease;
    }
`;

export const Sidebar = styled.div`
    padding: 20px 16px;
    border-radius: ${borderRadius('medium')};
    grid-area: sidebar;
    height: 664px;
    background-color: ${color('surface-default')};
    margin: 0 16px;

    ${mediaQueries.laptop} {
        margin: 0;
        padding: 20px 22px 50px;
    }

    ${mediaQueries.desktop} {
        height: initial;
    }
`;

export const SidebarInnerContainer = styled.div`
    height: calc(100% - 32px - 24px);
    overflow: hidden;
    position: relative;
`;

export const SidebarScrollContainer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
`;

export const InfoBlock = styled.div`
    padding: 16px 0;
    margin: 0 16px;

    ${mediaQueries.laptop} {
        padding: 24px;
        margin: 0;
    }
`;

export const PlaylistNavigation = styled.div`
    display: flex;
    gap: 12px;
`;

export const PrevExercise = styled.button`
    width: 64px;
    height: 64px;
    background-color: ${color('surface-hovered')};
    border-radius: ${borderRadius('medium')};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
`;

export const NextPreview = styled.div`
    width: 56px;
    min-height: 56px;
    background: #000000;
    border-radius: ${borderRadius('medium')};
    margin-right: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: ${borderRadius('medium')};
    }
`;

export const NextExercise = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: ${color('primary-default')};
    padding: 4px 12px 4px 4px;
    height: 64px;
    cursor: pointer;
    border-radius: ${borderRadius('medium')};
    text-align: left;

    div:last-child {
        margin-right: 0;
        margin-left: auto;
    }

    &:hover {
        background-color: ${color('primary-hovered')};
    }
`;

export const ExerciseNameBlock = styled.div`
    display: flex;
    align-items: center;
`;

export const ExerciseDetails = styled.div<{ isDisabled: boolean }>`
    grid-area: details;
    border-radius: ${borderRadius('medium')};
    background-color: ${color('surface-default')};
    overflow: hidden;
    margin: 0 16px;

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            opacity: 0.6;
            pointer-events: none;
        `}
    ${mediaQueries.laptop} {
        margin: 0;
    }
`;

export const ExerciseDetailsHeader = styled.div`
    padding: 20px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        background-color: ${color('surface-hovered')};
    }
`;

export const ExerciseDescription = styled.div`
    padding: 20px 24px;
`;

export const TipsList = styled.ol`
    margin-bottom: 24px;
    padding-left: 16px;
    text-indent: -16px;

    &:last-child {
        margin-bottom: 0;
    }

    li {
        margin-bottom: 4px;
    }
`;

export const CrossButton = styled.button`
    background: transparent;
    display: block;
    position: fixed;
    top: 88px;
    right: 16px;
    z-index: 999;

    ${mediaQueries.laptop} {
        display: none;
    }
`;

export const TimerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ProgressFullscreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
`;

export const ExerciseListWrapper = styled.div<{ show: boolean }>`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 50;
    background-color: ${color('surface-default')};
    width: 100%;
    height: 100%;
    max-width: 320px;
    padding: 24px;
    overflow-y: scroll;
    transition: all 0.3s ease;
    transform: translate(${({ show }) => (show ? 0 : 100)}%, 0);

    ${mediaQueries.desktop} {
        max-width: 380px;
    }

    ${mediaQueries.largeDesktop} {
        max-width: 456px;
    }
`;

export const ExercisesToggle = styled.button`
    width: 50px;
    height: 50px;
    top: 40px;
    right: 20px;
    position: absolute;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 10;
    cursor: pointer;

    &:hover {
        background-color: ${color('surface-default', 0.4)};
    }
`;

export const CloseExercises = styled.button`
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 16px;
    right: 20px;
    position: absolute;
    z-index: 10;
    cursor: pointer;

    &:hover {
        background-color: ${color('surface-main', 0.4)};
    }
`;
