import { Button } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { SUBSCRIPTION_LABEL_STATES } from '../constants';

import SubscriptionStatusLabel from './subComponents/SubscriptionLabel';
import SubscriptionCardHeader from './subComponents/SubscriptionCardHeader';
import PriceRow from './subComponents/PriceRow';
import PeriodRow from './subComponents/PeriodRow';
import BillingPeriod from './subComponents/BillingPeriod';
import AdditionalStatusInfo from './subComponents/AdditionalStatusInfo';

import * as S from './styled';

import { IExpiresSoonSubscription } from '../types';

import SubscriptionCardWrapper from './SubscriptionCardWrapper';

const ExpiresSubscription = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    price,
    isTrial,
    isMainSubscription,
    productCode,
    captions,
    onShowReturnOffer,
    isOfferAvailableForSubscription,
    isReturnOfferApplied,
}: IExpiresSoonSubscription) => {
    const { t } = useTranslation();

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader
                isMainSubscription={isMainSubscription}
                productCode={productCode}
                icon={tabIcon}
                heading={heading}
            />

            <SubscriptionStatusLabel state={SUBSCRIPTION_LABEL_STATES.EXPIRES_SOON} />
            <PeriodRow period={subscriptionPeriod} isTrial={isTrial} />
            <PriceRow price={price} />
            <BillingPeriod period={subscriptionPeriod} />
            <S.Line mt={16} />
            {captions?.title && <AdditionalStatusInfo captions={captions} />}
            {!isReturnOfferApplied && isOfferAvailableForSubscription && (
                <Button
                    text={t('subscription.text.showReturnOffer')}
                    dataLocator="resumeSub"
                    onClick={onShowReturnOffer}
                />
            )}
        </SubscriptionCardWrapper>
    );
};

export default ExpiresSubscription;
