import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Container = styled(Box)`
    width: 100%;
    > * {
        background-color: transparent !important;
    }

    &:hover {
        background-color: ${color('surface-hovered')} !important;
        color: ${color('surface-hovered')} !important;
    }
`;
