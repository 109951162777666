import { CLP, CURRENCY_SIGN_MAP, DEFAULT_CURRENCY } from '../constants/currency';

import { IChangeSubscriptionProduct, IDiscountSubscription, ITrialUpcomingProduct } from '../types/subscription';
import { Product } from '../types/product';

export const getToFixedNumber = (number: number, numbersAfterComma = 2) => {
    return Number(number.toFixed(numbersAfterComma));
};

export const getPriceFromCents = (price: string | number, currency?: string) => {
    if (currency === CLP.name) return Number(price);

    return getToFixedNumber(Number(price) / 100);
};

export const getDiscount = (oldPrice: number, newPrice: number) => {
    return getToFixedNumber(((oldPrice - newPrice) / oldPrice) * 100, 0);
};

export const prettyPrice = (price: number) => {
    const priceString = String(price);

    return priceString.includes('.') ? priceString : `${priceString}.00`;
};

export const getPriceForCurrency = (
    product:
        | IDiscountSubscription
        | Product
        | ITrialUpcomingProduct
        | IChangeSubscriptionProduct
        | Partial<IDiscountSubscription>,
    inCents = false
): number => {
    let productSpecificCurrencyPrice;

    if ('prices' in product && product?.prices) {
        productSpecificCurrencyPrice = product.prices.find((price) => {
            return price.currency === product.currency;
        });
    }

    const productPrice = productSpecificCurrencyPrice?.product_price || (product.amount as number);

    if (inCents) {
        return productPrice;
    }

    return getPriceFromCents(productPrice, product.currency);
};

export const getCurrencySign = (currencyName: string) => {
    return CURRENCY_SIGN_MAP[currencyName] || DEFAULT_CURRENCY.sign;
};
