import { SidebarEventLabels } from 'analytics/constants/labels';

import * as icon from 'components/Icon';

import { InferValueTypes } from 'types/commonTypes';

export enum linkType {
    Primary = 'primary',
    Secondary = 'secondary',
}

type IconType = InferValueTypes<typeof icon>;

export interface SidebarLinkProps {
    to: string;
    title: string;
    dataLocator?: string;
    onClick?: (to?: string) => void;
    color?: string;
    type: linkType;
    target?: string;
    icon?: IconType;
}

export interface SidebarLinkSecondaryProps {
    to?: string;
    title: string;
    dataLocator?: string;
    onClick?: (to?: string) => void;
    target?: string;
}

export interface SidebarLinkButtonProps {
    isActive: boolean;
    title: string;
    icon?: IconType;
}

export type primaryLinkType = {
    to: string;
    icon: IconType;
    title: string;
    dataLocator: string;
    analyticLabel?: SidebarEventLabels;
};

export type paidLinksCollectionType = {
    link: primaryLinkType;
    positionIndex: number;
};
