import { Button, Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React from 'react';

import * as S from './styled';

import { IDiscountOfferProps } from './types';

const DiscountOffer = ({
    badgeText,
    extraText,
    badgeBackgroundColor,
    oldPrice,
    period,
    periodTitle,
    isNewOffer = false,
    currencySign,
    price,
    handleSubmit,
    submitButton,
    isLoading,
    isCurrentSubscriptionMain = false,
}: IDiscountOfferProps) => {
    return (
        <S.Container mb={24} dataLocator="subscriptionContainer">
            <S.Badge backgroundColor={badgeBackgroundColor}>
                <Text text={badgeText} color="on-primary" medium center />
            </S.Badge>
            <S.Details paddingTop={20} paddingX={16} paddingBottom={16} backgroundColor="surface-default">
                {isCurrentSubscriptionMain && <Text type="small-button" text={periodTitle} center mb={20} />}
                {oldPrice && (
                    <S.CrossedText type="large-text" medium color="text-secondary" center mb={8}>
                        <Trans
                            i18nKey="subscription.cancellation.specialOffer.oldPricePeriodCrossed"
                            values={{ currencySign, price: oldPrice, period }}
                        />
                    </S.CrossedText>
                )}
                <Text type="h2" medium center mb={isNewOffer ? 20 : 0}>
                    <S.PriceWithPeriod>
                        <Trans
                            i18nKey="subscription.cancellation.specialOffer.newPricePeriod"
                            values={{ currencySign, price, period }}
                            components={{
                                SmallCurrency: <S.SmallCurrency />,
                                BigPrice: <S.BigPrice />,
                                span: <S.Period />,
                            }}
                        />
                    </S.PriceWithPeriod>
                </Text>
                {isNewOffer && (
                    <>
                        <Text type="caption" text={extraText} center mb={20} />
                        <Button
                            onClick={handleSubmit}
                            dataLocator="discountOfferChangePlanButton"
                            text={submitButton}
                            isLoading={isLoading}
                        />
                    </>
                )}
            </S.Details>
        </S.Container>
    );
};

export default DiscountOffer;
