export const sizesMap = {
    sm: {
        width: 151.88,
        height: 45,
    },
    md: {
        width: 192,
        height: 48,
    },
    lg: {
        width: 270,
        height: 69,
    },
};
