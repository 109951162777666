import { Colors, Theme } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

export const EmailIcon = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'border-default'];

    return (
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7394 1.14307L17.7172 1.10186C17.9017 1.45286 18 1.80101 18 2.76111V11.2389C18 12.199 17.9017 12.5471 17.7172 12.8981C17.5326 13.2491 17.2618 13.5246 16.9167 13.7123C16.5716 13.9 16.2293 14 15.2853 14H2.7147C1.77074 14 1.42844 13.9 1.08334 13.7123C0.73824 13.5246 0.46741 13.2491 0.28285 12.8981C0.09829 12.5471 0 12.199 0 11.2389V2.76111C0 1.83941 0.0905799 1.48169 0.26111 1.144C0.28257 1.26125 0.34251 1.37189 0.43897 1.45495L8.52106 8.415C8.79747 8.653 9.2025 8.653 9.4789 8.415L17.561 1.45495C17.5722 1.44534 17.583 1.43527 17.5933 1.42476C17.6718 1.34493 17.7205 1.24627 17.7394 1.14307ZM15.2853 0C16.0604 0 16.4299 0.0674101 16.7283 0.19545L16.6574 0.22265L16.5749 0.27318L9.4383 5.59616C9.1774 5.79075 8.82258 5.79075 8.56169 5.59616L1.42509 0.27318C1.37749 0.23768 1.32574 0.21135 1.27202 0.19404C1.57012 0.0674101 1.93956 0 2.7147 0H15.2853Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(EmailIcon);
