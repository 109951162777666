import React from 'react';

import Modal from 'components/Modal';

import * as S from './styled';

const ProgressDialog = ({ isOpen }: { isOpen: boolean }) => (
    <Modal isOpen={isOpen} customContent>
        <S.Container>
            <S.Progress />
        </S.Container>
    </Modal>
);

export default ProgressDialog;
