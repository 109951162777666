import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const ArrowRight = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'text-main'];

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.41699 10C3.41699 9.58579 3.75278 9.25 4.16699 9.25H15.8337C16.2479 9.25 16.5837 9.58579 16.5837 10C16.5837 10.4142 16.2479 10.75 15.8337 10.75H4.16699C3.75278 10.75 3.41699 10.4142 3.41699 10Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5336 3.63666C10.8265 3.92955 10.8265 4.40443 10.5336 4.69732L5.23064 10.0003L10.5336 15.3034C10.8265 15.5963 10.8265 16.0711 10.5336 16.364C10.2407 16.6569 9.76583 16.6569 9.47294 16.364L3.63965 10.5306C3.34676 10.2377 3.34676 9.76287 3.63965 9.46997L9.47294 3.63666C9.76583 3.34377 10.2407 3.34377 10.5336 3.63666Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(ArrowRight);
