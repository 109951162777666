import { Text, Image } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React from 'react';

import * as S from './styled';

const ExerciseItem = ({
    name,
    preview,
    time,
    onClick,
    isActive,
}: {
    name: string;
    preview: string;
    time?: number;
    onClick?: () => void;
    isActive?: boolean;
}) => {
    return (
        <S.Exercise onClick={onClick} isActive={isActive}>
            <S.ExerciseImage>
                <Image alt={name} src={preview} maxWidth={56} isForceVisible />
            </S.ExerciseImage>
            <S.ExerciseDescription>
                <Text type="small-text" mb={4}>
                    {name}
                </Text>
                <Text type="small-text" color="text-secondary">
                    <Trans i18nKey="workout.exercise.secs" values={{ time }} />
                </Text>
            </S.ExerciseDescription>
        </S.Exercise>
    );
};

export default ExerciseItem;
