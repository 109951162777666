/* eslint-disable max-lines */
import { Button, Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import MediaQueries from 'theme/mediaQueries';

export const ReminderModalContent = styled.div`
    height: 100%;
    padding: 83px 8px 32px;
    overflow: hidden;
    background: ${color('surface-main')};

    ${MediaQueries.largeTablet} {
        padding: 83px 16px 32px;
    }
`;

export const ModalHeaderTitle = styled.div`
    position: fixed;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding: 16px;
    background: ${color('surface-main')};
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    border-bottom: 1px solid ${color('border-default')};

    ${MediaQueries.laptop} {
        padding: 16px 40px;
    }
`;

export const TitleText = styled.div`
    flex: 1;
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${color('surface-secondary')};
    height: 44px;
    width: 44px;
    border-radius: 50%;

    &:hover {
        cursor: pointer;
    }
`;

export const StepsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const StepIconWrapper = styled.div`
    background-color: ${color('surface-hovered')};
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    flex-shrink: 0;
    z-index: 10;
`;

export const Line = styled.div`
    height: 350px;
    width: 2px;
    background-color: ${color('surface-hovered')};
    position: absolute;
    top: 56px;
    left: 28px;
    transform: translateX(-50%);
    z-index: 1;
`;

export const Step = styled.div`
    display: flex;
    margin-bottom: 40px;

    &:last-of-type {
        background-color: ${color('surface-main')};
        z-index: 20;
    }
`;

export const FirstStepBlock = styled.div`
    padding: 16px;
    border: 1px solid ${color('border-default')};
    border-radius: 20px;
`;

export const FirstStepBlockTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

export const FirstStepBlockText = styled(Text)`
    margin-left: 8px;
`;

export const SetReminderButton = styled(Button)`
    margin-top: 24px;
    width: 100%;
`;
