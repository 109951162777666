import { Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React from 'react';

import { getCurrencySign, getPriceForCurrency, getPriceFromCents } from 'helpers/prices';

import Modal from 'components/Modal';
import { Cross, MoveBack } from 'components/Icon';

import * as S from './styled';

import { IDiscountOfferModal } from './types';

import DiscountOffer from '../../DiscountOffer';

const DiscountOfferModal = ({
    onSubmit,
    onDiscard,
    onClose,
    trackLoad,
    captions,
    onMoveBack,
    oldProduct,
    specialOfferProduct,
    discount,
    isCurrentSubscriptionMain,
}: IDiscountOfferModal) => {
    const {
        title,
        headerTitle,
        firstBadgeText,
        secondBadgeText,
        subTitle,
        label,
        extraText,
        submitButton,
        discardButton,
        additionalInfo,
        period,
        periodTitle,
        bottomDisclaimer,
    } = captions;

    const oldPrice = getPriceFromCents(oldProduct.amount, oldProduct.currency);

    const handleDiscard = () => {
        onDiscard && onDiscard();
    };

    const handleSubmit = () => {
        onSubmit();
    };

    return (
        <Modal
            paddingX={0}
            paddingY={0}
            isOpen
            onClose={onClose}
            transparentOverlay={false}
            trackLoad={trackLoad}
            contentAlignmentY="top"
        >
            <S.SurveyContent data-locator="discountOfferContainer">
                <S.ModalHeaderTitle>
                    {onMoveBack && (
                        <S.IconWrapper onClick={onMoveBack} data-locator="cancelSubscriptionModalBackButton">
                            <MoveBack />
                        </S.IconWrapper>
                    )}
                    <S.TitleText>
                        <Text text={headerTitle} type="large-text" medium />
                    </S.TitleText>
                    <S.IconWrapper onClick={onClose} data-locator="cancelSubscriptionModalCloseButton">
                        <Cross />
                    </S.IconWrapper>
                </S.ModalHeaderTitle>
                <S.DiscountsContainer>
                    {additionalInfo && (
                        <>
                            <S.SubscriptionAdditionalInfo>
                                <Text text={additionalInfo?.title} mb={8} />
                                <Text text={additionalInfo?.text} color="text-secondary" />
                            </S.SubscriptionAdditionalInfo>
                            <S.OfferLabel>
                                <Text text={label} />
                            </S.OfferLabel>
                        </>
                    )}
                    <Text type="h4" medium center mb={12}>
                        <Trans
                            i18nKey={title}
                            values={{ discount }}
                            components={{
                                Primary: discount ? <S.AccentColorPrice /> : <span />,
                            }}
                        />
                    </Text>

                    {subTitle && <Text type="large-text" center text={subTitle} mb={24} />}

                    {isCurrentSubscriptionMain && (
                        <DiscountOffer
                            badgeBackgroundColor="surface-hovered"
                            badgeText={firstBadgeText}
                            price={oldPrice}
                            period={period}
                            periodTitle={periodTitle}
                            currencySign={getCurrencySign(oldProduct.currency)}
                        />
                    )}
                    <DiscountOffer
                        badgeBackgroundColor="critical-default"
                        badgeText={secondBadgeText}
                        extraText={extraText}
                        oldPrice={oldPrice}
                        price={getPriceForCurrency(specialOfferProduct)}
                        period={period}
                        periodTitle={periodTitle}
                        currencySign={getCurrencySign(specialOfferProduct.currency)}
                        isNewOffer
                        handleSubmit={handleSubmit}
                        submitButton={submitButton}
                        isCurrentSubscriptionMain={isCurrentSubscriptionMain}
                    />
                </S.DiscountsContainer>

                {discardButton && (
                    <S.ConfirmButton
                        dataLocator="keepSubBtn"
                        size="medium"
                        textColor="action-main"
                        onClick={handleDiscard}
                    >
                        {discardButton}
                    </S.ConfirmButton>
                )}
                <S.Disclaimer>
                    <Text type="caption" color="text-secondary-subdued" text={bottomDisclaimer} />
                </S.Disclaimer>
            </S.SurveyContent>
        </Modal>
    );
};

export default DiscountOfferModal;
