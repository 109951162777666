import { Trans, useTranslation } from 'react-i18next';

import { useGuides, useScreenLoadTracker } from 'hooks';

import { Guide } from 'components/Guide';

import * as S from './styled';

import { isTabletDevice } from 'theme/verifiers';
import { trackFitnessGuidePageLoad } from 'analytics/trackers/fitnessGuides';
import GuidesSkeleton from './GuidesSkeleton';
import GuidesList from './GuidesList';

export const Guides = () => {
    useScreenLoadTracker(trackFitnessGuidePageLoad);
    const { t } = useTranslation();
    const { userGuides, purchasedGuides, bonusGuides, isLoading } = useGuides();

    return (
        <S.Container>
            <S.Title type={isTabletDevice ? 'h3' : 'h5'} mb={isTabletDevice ? 48 : 40}>
                <Trans i18nKey="guides.title" />
            </S.Title>
            <S.GuidesListWrapper>
                {isLoading && <GuidesSkeleton />}

                {Boolean(purchasedGuides.length) && (
                    <GuidesList guides={purchasedGuides} title={t('guides.subtitle.purchased')} isPurchased />
                )}

                {Boolean(bonusGuides.length) && <GuidesList guides={bonusGuides} title={t('guides.subtitle.bonus')} />}

                {!userGuides.length && !isLoading && <Guide title={t('guides.notFound')} />}
            </S.GuidesListWrapper>
        </S.Container>
    );
};
