import { Trans } from 'react-i18next';
import React from 'react';

import { useWorkout } from 'hooks/workout/useWorkout';

import WorkoutExercisesList from 'components/WorkoutExercisesList';

import * as S from '../styled';

import { ExerciseType } from 'types/exercise';

const SidebarExercises = ({
    handleExerciseClick,
    currentExerciseId,
}: {
    handleExerciseClick: (selectedExercise: ExerciseType) => void;
    currentExerciseId: number;
}) => {
    const { workoutExercises } = useWorkout();

    return (
        <>
            <S.ExercisesCountBlock>
                <S.AllExercisesTitle type="h6" bold>
                    <Trans i18nKey="workout.custom.sidebarTitle" />
                </S.AllExercisesTitle>
                <S.AllExercisesSubtitle type="small-text" mb={4}>
                    <Trans i18nKey="basics.exercises.count" values={{ count: workoutExercises.length }} />
                </S.AllExercisesSubtitle>

                <S.Line />
            </S.ExercisesCountBlock>
            <S.SidebarInnerContainer>
                <S.SidebarScrollContainer>
                    <WorkoutExercisesList onClick={handleExerciseClick} activeExercise={currentExerciseId} />
                </S.SidebarScrollContainer>
            </S.SidebarInnerContainer>
        </>
    );
};

export default SidebarExercises;
