import { PRODUCT_IDS_WITH_LOW_PRICES_FOR_AQA } from 'constants/product';
import { DAYS_PER_YEAR } from 'constants/periods';

import { isMainSubscription } from 'helpers/subscriptions';

import { CancellationFlow } from 'components/Modals/CancellationReasonModal/types';

import { SubscriptionProductCodes } from 'types/product';

export const isPauseFlow = ({
    flow,
    productCode,
    wasPaused,
}: {
    flow: string;
    productCode: SubscriptionProductCodes;
    wasPaused: boolean;
}) => {
    if (wasPaused) return false;

    const isPauseSubscriptionFlow = flow === CancellationFlow.withPauseSubscription;
    const isMainPlanSubscription = isMainSubscription(productCode);

    return isPauseSubscriptionFlow && isMainPlanSubscription;
};

export const isOneYearSubscription = (period: number): boolean => {
    return period === DAYS_PER_YEAR;
};

export const checkIsProductIdWithTestPrice = (productId: string): boolean => {
    return PRODUCT_IDS_WITH_LOW_PRICES_FOR_AQA.includes(productId);
};
