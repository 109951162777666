import { ERROR_LEVELS, SUBSCRIPTION_ACTION } from 'services/Sentry/constants';
import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { AVAILABLE_PERIODS_FOR_DISCOUNT, AVAILABLE_PRODUCT_CODES_FOR_DISCOUNT } from 'constants/product';
import { AQA_KEY, DEV_KEY } from 'constants/dev';
import { subscriptionActions } from './constants';

import sentry from 'services/Sentry/SentryInstance';

import { isMainSubscription } from 'helpers/subscriptions';
import { objectEntriesPolyfill } from 'helpers/polyfills';

import { IDiscountsForSubscriptions, IDiscountSubscription, ISubscription } from 'types/subscription';

const MOVE_LEFT = -1;
const MOVE_RIGHT = 1;
const STAY = 0;

export const sortFullAccessFirst = (subscriptions: Array<ISubscription>): Array<ISubscription> =>
    subscriptions.sort((a, b) => {
        if (isMainSubscription(a.product_code)) return MOVE_LEFT;
        if (isMainSubscription(b.product_code)) return MOVE_RIGHT;

        return STAY;
    });

export const handleSubscriptionErrorInfo = (payload: any, error: any, action: subscriptionActions) => {
    const customTags: Record<string, string> = {};

    customTags.SUBSCRIPTION_ACTION = action;

    sentry.logError(
        error as Error,
        SUBSCRIPTION_ACTION,
        ERROR_LEVELS.CRITICAL,
        {
            payload,
            response: error?.response || 'No error info from BE',
        },
        objectEntriesPolyfill(customTags)
    );
};

export const getAppropriateDiscountForSubscription = (
    candidate: ISubscription,
    discountedSubscriptions: IDiscountsForSubscriptions,
    discountType: DISCOUNT_TYPES
): IDiscountSubscription | undefined => {
    const productCode = candidate?.product_code;

    if (productCode && discountedSubscriptions[productCode]?.[discountType]) {
        return discountedSubscriptions[productCode][discountType]?.subscription;
    }

    return undefined;
};

export const checkBeforeFetchingDiscount = (candidate: ISubscription) => {
    const isAvailableProductCode = AVAILABLE_PRODUCT_CODES_FOR_DISCOUNT.includes(candidate.product_code);
    const isAvailablePeriod = AVAILABLE_PERIODS_FOR_DISCOUNT.includes(candidate.product.subscription_period);
    const isAlreadyAppliedDiscount = Boolean(candidate.discount) || Boolean(candidate.restore_with_discount_time);

    if (isAqaFlow()) {
        // we skip period check for aqa purpose
        return isAvailableProductCode && !isAlreadyAppliedDiscount;
    }

    return isAvailableProductCode && isAvailablePeriod && !isAlreadyAppliedDiscount;
};

const isAqaFlow = () => {
    return AQA_KEY === localStorage.getItem(DEV_KEY);
};
