import { URL_PARAMS } from 'hooks/common/constants';

import BrowserStorage from './browserStorage';

export const getAwsId = (): string | undefined | null => {
    return BrowserStorage.getItem(URL_PARAMS.AWS_ID_PARAM_NAME);
};

export const setAwsId = (awsId: string | undefined | null): void => {
    BrowserStorage.setItem(URL_PARAMS.AWS_ID_PARAM_NAME, awsId);
};
