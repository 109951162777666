import { Text } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { borderRadius, color } from 'theme/selectors';

export const Header = styled.div`
    border-radius: ${borderRadius('medium')};
    background: ${color('surface-default')};
    margin-bottom: 24px;
    padding: 36px 12px 12px;
`;

export const ExerciseList = styled.div`
    border-radius: ${borderRadius('medium')};
    background: ${color('primary-disabled')};
    margin-bottom: 32px;
    overflow: hidden;
`;

export const ExerciseRound = styled.div`
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }

    ${ExerciseList} {
        margin-bottom: 0;
    }
`;

export const ExerciseDescription = styled.div`
    > * {
        display: block;
        text-align: left;
        text-overflow: ellipsis;
        width: 100%;
    }
`;

export const ExerciseImage = styled.div`
    width: 56px;
    height: 56px;
    border-radius: ${borderRadius('medium')};
    overflow: hidden;
    background-color: ${color('surface-default')};
    flex-shrink: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

export const Exercise = styled.button<{ isActive?: boolean; onClick?: () => void }>`
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    padding: 12px;
    gap: 12px;
    height: 80px;
    cursor: ${({ onClick }) => (Boolean(onClick) ? 'pointer' : 'default')};

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${color('secondary-disabled')};
            border-color: rgba(255, 255, 255, 0.05);
        `}
    &:last-child {
        border: none;
    }

    ${({ isActive, onClick }) =>
        onClick &&
        css`
            &:hover {
                background-color: ${color(isActive ? 'secondary-disabled' : 'secondary-disabled')};
            }
        `};
`;

export const CountExercisesTitle = styled(Text)`
    text-transform: capitalize;
`;
