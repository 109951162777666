import { ProductCodes } from './products/upsell';
import { DAYS_PER_MONTH, DAYS_PER_THREE_MONTH } from './periods';

export const PRODUCT_TYPES = {
    PERSONALIZED_PROGRAM: 'personalized_program',
    VIP_SUPPORT: 'vip_support',
};

export const PRODUCT_CODES = {
    APP_FULL_ACCESS: 'app_full_access',
    APP_MAIN_PLAN: 'main_plan',
    APP_VIP_SUPPORT: PRODUCT_TYPES.VIP_SUPPORT,
    APP_GUIDE_WEIGHT_LOSS: 'weight_loss_gd',
    APP_GUIDE_FAT_BURNING: 'fat_burning_gd',
    APP_GUIDE_ALL: 'fat_burning_gd__weight_loss_gd',
    APP_GUIDE_WEIGHT_LOSS_FULL: 'weight_loss_gd_full',
    APP_GUIDE_FAT_BURNING_FULL: 'fat_burning_gd_full',
    APP_GUIDE_ALL_FULL: 'fat_burning_gd__weight_loss_gd_full',
};

export const PRODUCT_IDS_WITH_LOW_PRICES_FOR_AQA = [
    'a52eed18-c3ab-492d-8d5f-02d12337e936',
    '784e88d8-4582-4390-abb7-9efe767ff189',
];

export const AVAILABLE_PRODUCT_CODES_FOR_DISCOUNT = [
    PRODUCT_CODES.APP_FULL_ACCESS,
    PRODUCT_CODES.APP_MAIN_PLAN,
    ProductCodes.meal_plan__challenge_7_min,
];

export const AVAILABLE_PERIODS_FOR_DISCOUNT = [DAYS_PER_MONTH, DAYS_PER_THREE_MONTH];
