/* eslint-disable max-lines */
import { isNotPrivate, isPrivate } from 'router/validators/validators';
import { RoutesType } from 'router/types';
import { AuthLayout, PublicLayout, CabinetLayout } from 'router/layouts';

import { PATHS } from 'constants/routerPaths';

import { WorkoutPreview } from 'pages/workout-preview';
import { WorkoutPage } from 'pages/workout-page';
import { WorkoutBuilder } from 'pages/workout-builder';
import { TemporaryPassword } from 'pages/temporary-password';
import { StartWorkoutPage } from 'pages/start-workout-page';
import { Shop } from 'pages/shop';
import { ReturnOffer } from 'pages/return-offer';
import { LoginInstructionSimple } from 'pages/login-instruction-simple';
import { Login } from 'pages/login';
import { InstallAppPublic } from 'pages/install-app-public';
import { Guides } from 'pages/guides';
import { FinishWorkoutPage } from 'pages/finish-workout-page';
import { ContinueProgressPage } from 'pages/continue-progress-page';
import { ChangeEmail } from 'pages/change-email';
import { AccountSettingsPage } from 'pages/account';

const PRIVATE_COMMON = {
    redirectPath: PATHS.TEMPORARY_PASSWORD,
    validators: [isPrivate],
};

const PUBLIC_COMMON = {
    redirectPath: PATHS.MAIN,
    validators: [isNotPrivate],
};

export const PRIVATE: Record<string, RoutesType> = {
    ACCOUNT: {
        path: PATHS.ACCOUNT,
        component: AccountSettingsPage,
        titleKey: 'pagesTitles.account',
        layout: CabinetLayout,
        ...PRIVATE_COMMON,
    },
    ACCOUNT_DEFAULT: {
        path: PATHS.ACCOUNT_DEFAULT,
        component: AccountSettingsPage,
        titleKey: 'pagesTitles.account',
        layout: CabinetLayout,
        ...PRIVATE_COMMON,
    },
    CHANGE_EMAIL: {
        path: PATHS.CHANGE_EMAIL,
        component: ChangeEmail,
        titleKey: 'pagesTitles.changeEmail',
        layout: CabinetLayout,
        ...PRIVATE_COMMON,
    },
    MAIN: {
        path: PATHS.MAIN,
        component: LoginInstructionSimple,
        titleKey: 'pagesTitles.loginInstruction',
        layout: CabinetLayout,
        ...PRIVATE_COMMON,
        withCenteredContent: true,
    },
    FITNESS_GUIDES: {
        path: PATHS.FITNESS_GUIDES,
        component: Guides,
        titleKey: 'pagesTitles.guides',
        layout: CabinetLayout,
        ...PRIVATE_COMMON,
    },
    WORKOUT_BUILDER: {
        path: PATHS.WORKOUT_BUILDER,
        component: WorkoutBuilder,
        titleKey: 'pagesTitles.workoutBuilder',
        layout: CabinetLayout,
        ...PRIVATE_COMMON,
    },
    WORKOUT_PREVIEW: {
        path: PATHS.WORKOUT_PREVIEW,
        component: WorkoutPreview,
        titleKey: 'pagesTitles.workoutPreview',
        layout: CabinetLayout,
        ...PRIVATE_COMMON,
    },
    WORKOUT: {
        path: PATHS.WORKOUT,
        component: WorkoutPage,
        titleKey: 'pagesTitles.workout',
        layout: CabinetLayout,
        ...PRIVATE_COMMON,
    },
    STORE_PAGE: {
        path: PATHS.STORE_PAGE,
        component: Shop,
        titleKey: '',
        layout: PublicLayout,
        ...PRIVATE_COMMON,
    },
    RETURN_OFFER: {
        path: PATHS.RETURN_OFFER,
        component: ReturnOffer,
        titleKey: '',
        layout: CabinetLayout,
        ...PRIVATE_COMMON,
    },
    START_WORKOUT_PAGE: {
        path: PATHS.START_WORKOUT_PAGE,
        component: StartWorkoutPage,
        titleKey: 'pagesTitles.installApp',
        layout: CabinetLayout,
        ...PRIVATE_COMMON,
    },
    FINISH_WORKOUT_PAGE: {
        path: PATHS.FINISH_WORKOUT_PAGE,
        component: FinishWorkoutPage,
        titleKey: 'pagesTitles.installApp',
        layout: CabinetLayout,
        ...PRIVATE_COMMON,
    },
    CONTINUE_PROGRESS_PAGE: {
        path: PATHS.CONTINUE_PROGRESS_PAGE,
        component: ContinueProgressPage,
        titleKey: 'pagesTitles.installApp',
        layout: CabinetLayout,
        ...PRIVATE_COMMON,
    },
};

export const PUBLIC: Record<string, RoutesType> = {
    LOGIN: {
        path: PATHS.LOGIN,
        component: Login,
        titleKey: 'pagesTitles.login',
        layout: AuthLayout,
        ...PUBLIC_COMMON,
    },
    TEMPORARY_PASSWORD: {
        path: PATHS.TEMPORARY_PASSWORD,
        component: TemporaryPassword,
        titleKey: 'pagesTitles.login',
        layout: AuthLayout,
        ...PUBLIC_COMMON,
    },
    DOWNLOAD_APP_INSTRUCTION: {
        path: PATHS.DOWNLOAD_APP_INSTRUCTION,
        component: InstallAppPublic,
        titleKey: 'pagesTitles.installApp',
        layout: PublicLayout,
        ...PUBLIC_COMMON,
    },
};

export const routes = Object.values({ ...PRIVATE, ...PUBLIC });

export default {
    PRIVATE,
    PUBLIC,
};
