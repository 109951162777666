import { useTranslation } from 'react-i18next';

import { selectCurrentUser } from 'store/user/selectors';

import { ProductCodes } from 'constants/products/upsell';

import { useSelector } from 'hooks';

import { doesObjectContainKey } from 'helpers/doesObjectContainKey';

const getProductCardInfo = () => {
    const { t } = useTranslation();

    return {
        [ProductCodes.meal_plan__challenge_7_min]: {
            title: t('product.subscriptions.meal7minBundle.title'),
            tips: [
                t('product.mealPlanChallenge7min.tip1'),
                t('product.mealPlanChallenge7min.tip2'),
                t('product.mealPlanChallenge7min.tip3'),
            ],
        },
        [ProductCodes.meal_plan]: {
            title: t('product.subscriptions.mealPlan.title'),
            tips: [t('product.mealPlan.tip1'), t('product.mealPlan.tip2'), t('product.mealPlan.tip3')],
        },
        [ProductCodes.challenge_7_min]: {
            title: t('product.subscriptions.morningPlan.title'),
            tips: [t('product.challenge7min.tip1'), t('product.challenge7min.tip2'), t('product.challenge7min.tip3')],
        },
        [ProductCodes.challenge_6_packs__challenge_sex]: {
            title: t('product.subscriptions.absSexBundle.title'),
            tips: [
                t('product.6PacksChallengeSex.tip1'),
                t('product.6PacksChallengeSex.tip2'),
                t('product.6PacksChallengeSex.tip3'),
            ],
        },
        [ProductCodes.challenge_6_packs]: {
            title: t('product.subscriptions.absPlan.title'),
            tips: [t('product.6Packs.tip1'), t('product.6Packs.tip2'), t('product.6Packs.tip3')],
        },
        [ProductCodes.challenge_sex]: {
            title: t('product.subscriptions.sexPlan.title'),
            tips: [t('product.challengeSex.tip1'), t('product.challengeSex.tip2'), t('product.challengeSex.tip3')],
        },
    };
};

const useProductInfo = (productCode: string) => {
    const user = useSelector(selectCurrentUser);
    const productCardInfo = getProductCardInfo();
    const subProductsCodes = productCode.split('__') as ProductCodes[];

    const getProductTitle = (productCodes: ProductCodes[]) => {
        return productCodes.map((subProductCode) => productCardInfo[subProductCode].title).join(' + ');
    };

    const existingProduct = (productCode: string) => doesObjectContainKey(user.product_list, productCode);

    const crossingExistsUserProducts = (productCodes: ProductCodes[]) => {
        if (existingProduct(productCode)) {
            return productCardInfo[productCode as ProductCodes].title;
        }

        const filteredCodes = productCodes.filter((code) => doesObjectContainKey(user.product_list, code));

        return getProductTitle(filteredCodes);
    };

    return {
        title: getProductTitle(subProductsCodes),
        userProductsTitle: crossingExistsUserProducts(subProductsCodes),
        subProductsCodes,
        productCardInfo,
    };
};

export default useProductInfo;
