import styled, { css } from 'styled-components';

import { workoutFitnessLevelsTypes } from 'constants/workouts/workouts';

import { color } from 'theme/selectors';

const getBackground =
    () =>
    ({ level }: { level: workoutFitnessLevelsTypes }) => {
        switch (level) {
            case workoutFitnessLevelsTypes.beginner:
                return css`
                    &:nth-child(2) {
                        opacity: 50%;
                    }

                    &:nth-child(3) {
                        opacity: 50%;
                    }
                `;

            case workoutFitnessLevelsTypes.intermediate:
                return css`
                    &:nth-child(3) {
                        opacity: 50%;
                    }
                `;
        }
    };

export const LevelIcon = styled.span<{ level: workoutFitnessLevelsTypes }>`
    display: inline-flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    i {
        width: 2px;
        height: 10px;
        display: inline-block;
        border-radius: 2px;
        background-color: ${color('text-main')};

        &:nth-child(2) {
            margin: 0 3px;
        }

        ${getBackground()}

`;
