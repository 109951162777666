import { Navigate } from 'react-router-dom';
import { PropsWithChildren } from 'react';

import { PATHS } from 'constants/routerPaths';

import { ValidatorFunction } from './types';

interface ProtectedRouteProps {
    redirectPath?: string;
    validators?: ValidatorFunction[];
}

const ProtectedRoute = ({ redirectPath, validators, children }: PropsWithChildren<ProtectedRouteProps>) => {
    if (validators && !validators.every((validate) => validate())) {
        return <Navigate to={redirectPath ?? PATHS.TEMPORARY_PASSWORD} />;
    }

    return children;
};

export default ProtectedRoute;
