import React from 'react';

import { LogoImage } from 'components/Logo';

import * as S from './styled';

export const PageLoader = () => {
    return (
        <S.Container data-locator="logoLoader">
            <LogoImage />
        </S.Container>
    );
};
