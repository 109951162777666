export const URL_PARAMS = {
    USER_ID_PARAM_NAME: 'c',
    SIGNATURE_PARAM_NAME: 'sg',
    USER_SOURCE_PARAM_NAME: 'ref',
    AWS_ID_PARAM_NAME: 'track-id',
    RETURN_OFFER: 'return_offer',
    UTM_SOURCE: 'utm_source',
    ANALYTIC_UUID: 'uuid',
};

export const USER_FROM_CRM = 'CRM';
