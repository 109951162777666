export enum reasonsNames {
    can_not_train = 'can_not_train',
    is_not_my_level = 'is_not_my_level',
    do_not_like = 'do_not_like',
    feel_pain = 'feel_pain',
    tech_issue = 'tech_issue',
    other = 'other',
}

export const reasons = [
    {
        name: reasonsNames.can_not_train,
        label: 'workout.reasonAbandonModal.reasons.canNotTrain',
    },

    {
        name: reasonsNames.is_not_my_level,
        label: 'workout.reasonAbandonModal.reasons.doesNotMatchLevel',
    },

    {
        name: reasonsNames.do_not_like,
        label: 'workout.reasonAbandonModal.reasons.doNotLike',
    },

    {
        name: reasonsNames.feel_pain,
        label: 'workout.reasonAbandonModal.reasons.feelPain',
    },
    {
        name: reasonsNames.tech_issue,
        label: 'workout.reasonAbandonModal.reasons.techIssue',
    },
    {
        name: reasonsNames.other,
        label: 'basics.other',
    },
];
