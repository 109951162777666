import { Swiper, SwiperSlide } from 'swiper/react';
import { Trans } from 'react-i18next';
import React from 'react';

import { workoutTargetAreas } from 'constants/workouts/workouts';

import * as S from './styled';
import './styles.css';
import 'swiper/css';
import 'swiper/css/pagination';

import { isTabletDevice } from 'theme/verifiers';
import { Scrollbar } from 'swiper';

interface TargetAreaSliderProps {
    stateAreas: string | string[];
    handleButtonClick: (value: React.MouseEvent<HTMLButtonElement>) => void;
}

const TargetAreasSlider = ({ stateAreas, handleButtonClick }: TargetAreaSliderProps) => {
    const areasEntries = Object.entries(workoutTargetAreas);

    const isActive = (name: string) => {
        return stateAreas.includes(name);
    };

    return (
        <Swiper
            slidesPerView={isTabletDevice ? 4 : 3}
            spaceBetween={isTabletDevice ? 16 : 12}
            modules={[Scrollbar]}
            scrollbar={{
                hide: true,
            }}
            className="targetZonesSwiper"
        >
            {areasEntries.map(([key, area]) => {
                const isActiveArea = isActive(key);

                return (
                    <SwiperSlide key={key}>
                        <S.TargetAreaWrapper key={key} onClick={handleButtonClick} value={key} name="areas">
                            <S.TargetArea isActive={isActiveArea} />
                            <S.TargetAreaImg isActive={isActiveArea} maxWidth={104} src={area.img} alt={area.title} />
                            <S.TargetAreaTitle isActive={isActiveArea} type="caption" center>
                                <Trans i18nKey={area.title} />
                            </S.TargetAreaTitle>
                        </S.TargetAreaWrapper>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};

export default TargetAreasSlider;
