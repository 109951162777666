import { Button } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import installApp from 'appConfig/installApp';

import isMobile from 'helpers/isMobile';

import Logo from 'components/Logo';
import InstallAppButton from 'components/InstallAppButton';

import * as S from '../styled';

import { trackAppStoreClick, trackGooglePayClick } from 'analytics/trackers/installApp';

const InstallAppFooter = ({
    handleDownloadApp,
    isManualPage,
}: {
    handleDownloadApp: () => void;
    isManualPage?: boolean;
}) => {
    const { t } = useTranslation();

    const displayOneLink = isMobile.any();

    return (
        <>
            {displayOneLink && (
                <Button
                    dataLocator="deeplinkBtn"
                    onClick={handleDownloadApp}
                    backgroundColor="primary-default"
                    mb={24}
                    text={t('main.button')}
                    restProps={{ 'aria-label': 'fitness app' }}
                />
            )}

            {!displayOneLink && (
                <S.Footer
                    borderRadius={16}
                    paddingBottom={24}
                    paddingTop={24}
                    paddingX={24}
                    backgroundColor="surface-default"
                    mb={56}
                >
                    <S.FlexWrapper>
                        <Logo withoutText />
                        <S.AppTitle type="large-text" bold text={t('main.appTitle')} />
                    </S.FlexWrapper>

                    <S.FlexWrapper>
                        <InstallAppButton
                            link={installApp.googlePlayAppLink}
                            dataLocator="googleStoreLink"
                            type="google"
                            trackClick={() => trackGooglePayClick(isManualPage)}
                            alt="workout"
                        />
                        <InstallAppButton
                            link={installApp.appStoreAppLink}
                            dataLocator="appleStoreLink"
                            type="apple"
                            trackClick={() => trackAppStoreClick(isManualPage)}
                            alt="workout"
                        />
                    </S.FlexWrapper>
                </S.Footer>
            )}
        </>
    );
};

export default InstallAppFooter;
