import React from 'react';

const Flag = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.09813 22C3.69393 22 4.18458 21.5645 4.18458 21.0016V15.5422C4.5 15.4466 5.39953 15.1599 6.74299 15.1599C10.7383 15.1599 13.472 16.9973 17.3621 16.9973C19.0561 16.9973 19.8855 16.7955 20.75 16.4344C21.5444 16.0945 22 15.4891 22 14.5544V4.60223C22 3.91184 21.4276 3.46575 20.6332 3.46575C19.979 3.46575 18.9977 3.83749 17.257 3.83749C13.4369 3.83749 10.6332 2 6.63785 2C4.96729 2 4.18458 2.19118 3.27336 2.57355C2.47897 2.90281 2 3.42326 2 4.3367V21.0016C2 21.5433 2.50234 22 3.09813 22ZM4.25467 10.6776V7.5231C4.46495 7.24695 5.25935 6.8752 6.68458 6.8752C7.01168 6.8752 7.59579 6.90706 8.0514 6.96017V4.10303C9.44159 4.26235 10.715 4.62347 12.0234 4.97398V7.83112C13.3084 8.20287 14.6519 8.51089 15.9836 8.63834V5.7812C16.3808 5.82369 16.7897 5.84493 17.2103 5.84493C18.2383 5.84493 19.1028 5.74934 19.7453 5.57939V8.43654C19.2313 8.56399 18.3201 8.70207 17.2103 8.70207C16.8715 8.70207 16.4509 8.68083 15.9836 8.63834V11.7929C16.4626 11.846 16.9065 11.8566 17.3154 11.8566C18.3318 11.8566 19.1262 11.7504 19.7453 11.5805V14.3314C19.5467 14.6075 18.7407 14.9687 17.3154 14.9687C16.8598 14.9687 16.4276 14.9368 15.9836 14.8837V11.7929C14.5584 11.6229 13.3551 11.368 12.0234 10.9857V13.9915C10.785 13.6516 9.5 13.3224 8.0514 13.2055V10.1147C7.63084 10.0722 7.26869 10.0297 6.68458 10.0297C5.25935 10.0297 4.46495 10.4015 4.25467 10.6776ZM8.0514 10.1147C9.51168 10.2847 10.6215 10.6139 12.0234 10.9857V7.83112C10.7033 7.48062 9.51168 7.11949 8.0514 6.96017V10.1147Z"
                fill="white"
            />
        </svg>
    );
};

export default Flag;
