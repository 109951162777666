import { Box, Button, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';

import { selectIsDiscountLoaded } from 'store/subscriptions/selectors';

import { DEFAULT_REASON, reasons } from './constants';

import { useSelector } from 'hooks';

import Modal from 'components/Modal';
import { Cross } from 'components/Icon';

import * as S from './styled';

import { ICancellationReasonModal, IReason } from './types';

const CancellationReasonModal = ({ onSubmit, onDiscard, onClose, trackLoad, captions }: ICancellationReasonModal) => {
    const { t } = useTranslation();
    const [selectedReason, setReason] = useState<IReason | null>(DEFAULT_REASON);
    const randomizedReasons = useMemo(() => reasons.sort(() => Math.random() - 0.5), [reasons]);

    const isDiscountLoaded: boolean = useSelector(selectIsDiscountLoaded);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        if (isDiscountLoaded) {
            setIsButtonDisabled(false);
        }
    }, [isDiscountLoaded, isButtonDisabled]);

    const handleClick = () => {
        onSubmit({
            reason: selectedReason,
        });
    };

    const handleDiscard = () => {
        setReason(null);
        onDiscard && onDiscard();
    };
    const handleSelectReason = (reason: IReason) => () => {
        setReason(reason);
    };

    const handleButtonDisabled = () => {
        return !isDiscountLoaded || isButtonDisabled;
    };

    return (
        <Modal paddingX={0} paddingY={0} isOpen onClose={onClose} transparentOverlay={false} trackLoad={trackLoad}>
            <S.SurveyContent data-locator="cancelSubscriptionModalContainer">
                <S.ModalHeaderTitle>
                    <S.TitleText>
                        <Text text={captions.headerTitle} type="large-text" medium />
                    </S.TitleText>
                    <S.CloseButton onClick={onClose} data-locator="cancelSubscriptionModalCloseButton">
                        <Cross />
                    </S.CloseButton>
                </S.ModalHeaderTitle>
                <S.ModalScrollBody>
                    {captions?.additionalInfo && (
                        <S.SubscriptionAdditionalInfo>
                            <Text text={captions?.additionalInfo?.title} mb={8} />
                            <Text text={captions?.additionalInfo?.text} color="text-secondary" />
                        </S.SubscriptionAdditionalInfo>
                    )}
                    <S.ModalHeader withAdditionalInfo={Boolean(captions?.additionalInfo)}>
                        <Text type="h5" medium text={captions.title} />
                    </S.ModalHeader>
                    <Box mb={32}>
                        {randomizedReasons.map((reason) => {
                            const { title, shortKey, preselected } = reason;

                            const isSelected =
                                (!selectedReason && preselected) || shortKey === selectedReason?.shortKey;

                            return (
                                <S.ReasonItem
                                    key={shortKey}
                                    onClick={handleSelectReason(reason)}
                                    isSelected={isSelected}
                                    data-locator="option"
                                    data-locator-reason={shortKey}
                                >
                                    <S.IconBox>
                                        <S.Icon isSelected={isSelected} />
                                    </S.IconBox>
                                    <Text bold text={t(title)} />
                                </S.ReasonItem>
                            );
                        })}
                    </Box>
                    <Button
                        onClick={handleClick}
                        dataLocator="continueButton"
                        mb={20}
                        text={captions.submitButton}
                        isLoading={handleButtonDisabled()}
                    />

                    {captions.discardButton && (
                        <S.ConfirmButton
                            dataLocator="keepSubBtn"
                            size="medium"
                            textColor="action-main"
                            onClick={handleDiscard}
                        >
                            {captions.discardButton}
                        </S.ConfirmButton>
                    )}
                </S.ModalScrollBody>
            </S.SurveyContent>
        </Modal>
    );
};

export default CancellationReasonModal;
