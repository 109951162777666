import { Image, Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React from 'react';

import { useWorkoutContext } from '../hooks';

import { ArrowRight, ArrowLeft, Flag } from 'components/Icon';

import * as S from '../styled';

import { trackNextButtonClick, trackPreviousButtonClick } from 'analytics/trackers/exercise';

const WorkoutNavigationBar = () => {
    const { prevExercise, nextExerciseData, handleNavigateExercise, toNextExercise, toPrevExercise } =
        useWorkoutContext();

    return (
        <S.PlaylistNavigation>
            {prevExercise && (
                <S.PrevExercise onClick={handleNavigateExercise(toPrevExercise, trackPreviousButtonClick)}>
                    <ArrowLeft />
                </S.PrevExercise>
            )}
            {nextExerciseData && (
                <S.NextExercise onClick={handleNavigateExercise(toNextExercise, trackNextButtonClick)}>
                    <S.ExerciseNameBlock>
                        <S.NextPreview>
                            <Image src={nextExerciseData.preview} alt={nextExerciseData.name} />
                        </S.NextPreview>
                        <div>
                            <Text type="caption" mb={4}>
                                <Trans i18nKey="basics.next" />
                            </Text>
                            <Text type="small-text" medium>
                                {nextExerciseData.name}
                            </Text>
                        </div>
                    </S.ExerciseNameBlock>
                    <ArrowRight />
                </S.NextExercise>
            )}

            {!nextExerciseData && (
                <S.NextExercise onClick={handleNavigateExercise(toNextExercise, trackNextButtonClick)}>
                    <S.ExerciseNameBlock>
                        <S.NextPreview>
                            <Flag />
                        </S.NextPreview>
                        <div>
                            <Text type="caption" mb={4}>
                                <Trans i18nKey="basics.next" />
                            </Text>
                            <Text type="small-text" medium>
                                <Trans i18nKey="workout.workoutPlayer.finish" />
                            </Text>
                        </div>
                    </S.ExerciseNameBlock>
                    <ArrowRight />
                </S.NextExercise>
            )}
        </S.PlaylistNavigation>
    );
};

export default WorkoutNavigationBar;
