import { Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React from 'react';

import { groupRoundsByRepetitions, splitByRounds } from './helpers';

import * as S from './styled';

import { BlockExercise } from 'types/workouts';
import { ExerciseMapType, ExerciseType } from 'types/exercise';

import ExerciseItem from './ExerciseItem';

const ExerciseRoundList = ({
    exercises,
    exercisesMap,
    onClick,
    activeExercise,
}: {
    exercises: BlockExercise[];
    exercisesMap: ExerciseMapType;
    onClick?: (data: ExerciseType) => void;
    activeExercise?: number;
}) => {
    const roundsWithRepetitions = groupRoundsByRepetitions(splitByRounds(exercises));

    return (
        <>
            {roundsWithRepetitions.map(({ id, exercises, restExercise, repetitions }) => {
                const restData = exercisesMap[restExercise.id];

                return (
                    <S.ExerciseRound key={id}>
                        <Text type="small-text" color="text-secondary-subdued" mb={16}>
                            <Trans i18nKey="workout.exercise.rounds" values={{ count: repetitions }} />
                        </Text>

                        <S.ExerciseList>
                            {exercises.map((exercise) => {
                                const exerciseData = exercisesMap[exercise.id];
                                const key = `${id}_${exercise.id}_training`;

                                const handleClick =
                                    onClick &&
                                    (() => {
                                        onClick(exerciseData);
                                    });

                                return (
                                    <ExerciseItem
                                        key={key}
                                        preview={exerciseData.preview}
                                        name={exerciseData.name}
                                        time={exercise.time}
                                        onClick={handleClick}
                                        isActive={activeExercise === exerciseData.id}
                                    />
                                );
                            })}

                            {restData && (
                                <ExerciseItem
                                    preview={restData.preview}
                                    name={restData.name}
                                    time={restExercise.time}
                                />
                            )}
                        </S.ExerciseList>
                    </S.ExerciseRound>
                );
            })}
        </>
    );
};

export default ExerciseRoundList;
