/* eslint-disable max-lines */
import { useRef } from 'react';

import { checkBeforeFetchingDiscount } from 'store/subscriptions/helpers';
import { cancelSubscription, fetchDiscountSubscriptions, sendFeedback } from 'store/subscriptions/actions';

import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { RETURN_OFFER } from 'analytics/constants/ids';

import { useDispatch } from 'hooks';

import { isOneYearSubscription } from './helpers';

import { IUserFeedback } from 'components/Modals/CancellationReasonModal/types';

import { ISubscription } from 'types/subscription';

import {
    trackCancelSubscriptionFailedEvent,
    trackCancelSubscriptionSuccessEvent,
    trackSelectCancellationReason,
} from 'analytics/trackers/subscription';
import { useCommonSubscriptionLogic } from './index';
import { useModals } from '../modals';

const useCaliforniaFlow = () => {
    const dispatch = useDispatch();
    const { closeModal } = useModals();
    const { displaySurveyModal, displayUpdatingProcessModal, displayCancellingIssue, displayReturnOffer } =
        useCommonSubscriptionLogic();

    const cancellationCandidateRef = useRef<ISubscription | null>(null);

    const initCancellationForCalifornia = (candidate: ISubscription) => {
        cancellationCandidateRef.current = candidate;

        onCancelSubscription();
    };

    const onCancelSubscription = () => {
        const candidate = cancellationCandidateRef.current as ISubscription;

        if (!candidate) return closeModal();

        dispatch(
            cancelSubscription({
                externalId: candidate?.external_id,
                onError: () => {
                    trackCancelSubscriptionFailedEvent();
                    displayCancellingIssue(onCancelSubscription);
                },
                onSuccess: () => {
                    handleSurveyModal(candidate);
                    trackCancelSubscriptionSuccessEvent();
                },
                onCancel: closeModal,
            })
        );

        displayUpdatingProcessModal();
    };

    const handleSurveyModal = (candidate: ISubscription) => {
        const hideSurvey = isOneYearSubscription(candidate.product.subscription_period);
        const isOfferAlreadyApplied = Boolean(candidate?.restore_with_discount_time);

        if (hideSurvey || isOfferAlreadyApplied) {
            closeModal();

            return;
        }

        displaySurveyModal(onSubmitSurvey, RETURN_OFFER);
        const shouldProceedWithFetch = checkBeforeFetchingDiscount(candidate);

        if (shouldProceedWithFetch) {
            dispatch(
                fetchDiscountSubscriptions({
                    discount_type: DISCOUNT_TYPES.extra,
                    external_id: candidate?.external_id as string,
                    onError: () => {},
                    onSuccess: () => {},
                })
            );
        }
    };

    const onSubmitSurvey = ({ reason }: IUserFeedback) => {
        const candidate = cancellationCandidateRef.current as ISubscription;

        const theReason = reason ? reason.shortKey : '';

        dispatch(
            sendFeedback({
                reason: theReason,
            })
        );

        trackSelectCancellationReason(theReason);

        displayReturnOffer(candidate);
    };

    return {
        initCancellationForCalifornia,
    };
};

export default useCaliforniaFlow;
