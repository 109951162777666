import * as action from './actions';

import * as actionTypes from './actionTypes';

import { ExerciseMapType, ExerciseDetails } from 'types/exercise';
import { InferValueTypes } from 'types/commonTypes';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

interface ExerciseState {
    exercises: ExerciseMapType;
    details: { [key: string]: ExerciseDetails };
    isLoading: boolean;
}

const initialState: ExerciseState = {
    exercises: {},
    details: {},
    isLoading: true,
};

const exercisesReducer = (state: ExerciseState = initialState, action: ActionType): ExerciseState => {
    switch (action.type) {
        case actionTypes.SET_LOADING_STATE:
            return { ...state, isLoading: action.payload };

        case actionTypes.SET_EXERCISES:
            return { ...state, isLoading: false, exercises: { ...state.exercises, ...action.payload } };

        case actionTypes.SAVE_DETAILS:
            return { ...state, details: { ...state.details, [action.payload.id]: action.payload } };

        default:
            return state;
    }
};

export default exercisesReducer;
