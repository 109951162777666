import { useEffect, useMemo } from 'react';

import { fetchGuides } from 'store/guides/actions';

import { useDispatch, useSelector } from 'hooks';

import { IGuide } from 'types/guide';

export const useGuides = () => {
    const { userGuides, isLoading } = useSelector((state) => state.guides);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchGuides());
    }, []);

    const { purchasedGuides, bonusGuides } = useMemo(
        () =>
            userGuides.reduce(
                (acc, guid) => {
                    const guidesCollection: IGuide[] = guid.is_purchased ? acc.purchasedGuides : acc.bonusGuides;

                    guidesCollection.push(guid);

                    return acc;
                },
                { purchasedGuides: [], bonusGuides: [] }
            ),
        [userGuides]
    );

    return {
        isLoading,
        userGuides,
        purchasedGuides,
        bonusGuides,
    };
};
