import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const Play = ({ theme, color, size = 24 }: { theme: Theme; color?: Colors; size?: number }) => {
    const iconColor = theme[color || 'border-default'];

    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 4.83171C5 4.04055 5.87525 3.56271 6.54076 3.99054L17.6915 11.1589C18.3038 11.5525 18.3038 12.4476 17.6915 12.8412L6.54076 20.0096C5.87525 20.4374 5 19.9595 5 19.1684V4.83171Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(Play);
