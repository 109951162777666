import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import * as S from '../styled';

interface PriceRow {
    price: string;
}

const PriceRow = ({ price }: PriceRow) => {
    const { t } = useTranslation();

    return (
        <S.SubscriptionCarRow>
            <Text text={t('basics.price')} />
            <Text text={price} dataLocator="subscriptionPrice" />
        </S.SubscriptionCarRow>
    );
};

export default PriceRow;
