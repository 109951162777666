export enum subscriptionActions {
    cancel = 'cancel',
    change = 'change',
    discount = 'discount',
    restoreSwitch = 'restoreSwitch',
    resume = 'resume',
    pause = 'pause',
    reminder = 'reminder',
    restore = 'restore',
}
