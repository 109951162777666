export enum areas {
    Glutes = 'glutes',
    Quadriceps = 'quadriceps',
    Hamstrings = 'hamstrings',
    Calves = 'calves',
    Chest = 'chest',
    Back = 'back',
    Shoulders = 'shoulders',
    Triceps = 'triceps',
    Biceps = 'biceps',
    ABS = 'abs',
    LowerBack = 'lower_back',
    Forearms = 'forearms',
    Trapezius = 'trapezius',
}

export const coreTargetAreas = [areas.ABS, areas.LowerBack];
export const upperBodyTargetAreas = [areas.Chest, areas.Triceps, areas.Biceps, areas.Shoulders, areas.Back];
export const lowerBodyTargetAreas = [areas.Quadriceps, areas.Glutes, areas.Hamstrings, areas.Calves];
export const totalBodyTargetAreas = [...coreTargetAreas, ...upperBodyTargetAreas, ...lowerBodyTargetAreas];
