import { useEffect, useRef } from 'react';

import { isAuthenticationCompleteSelector } from 'store/auth/selectors';

import { useSelector } from '../store';

const useScreenLoadTracker = (tracker?: () => void) => {
    const isComplete = useSelector(isAuthenticationCompleteSelector);
    const isSend = useRef(false);

    useEffect(() => {
        if (tracker && isComplete && !isSend.current) {
            isSend.current = true;
            tracker();
        }
    }, [isComplete, tracker]);
};

export default useScreenLoadTracker;
