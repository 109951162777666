export const HEADER = 'header';
export const SIDEBAR = 'sidebar';
export const LOG_IN = 'log_in';
export const SECURITY_CODE = 'security_code';
export const INSTALL_APP = 'muscle_booster_app';
export const APP_MANUAL = 'muscle_booster_only_manual';
export const FITNESS_GUIDES = 'fitness_guides';
export const ACCOUNT = 'account';
export const ACCOUNT_SETTINGS = 'account_settings';
export const ACCOUNT_SETTINGS_ACCOUNT = 'account_settings_account';
export const ACCOUNT_SETTINGS_SUBSCRIPTION = 'account_settings_subscription';
export const ACCOUNT_SETTINGS_PROFILE = 'account_settings_profile';
export const ACCOUNT_SUBSCRIPTION = 'account_subscription';
export const RESTORE = 'restore';
export const CHANGE_EMAIL = 'change_email';
export const CANCEL_SURVEY = 'survey';
export const DISCOUNT_OFFER = 'discount_offer';
export const SUBSCRIPTION_CANCELLED = 'subscription_cancelled';
export const SUBSCRIPTION_IS_NOT_CANCELLED = 'subscription_is_not_cancelled';
export const WORKOUT_BUILDER = 'workout_creator';
export const WORKOUT_PREVIEW = 'custom_workout';
export const EXERCISE = 'workout';
export const EXERCISE_QUIT = 'quit_popup';
export const QUITE_FEEDBACK_POPUP = 'quit_feedback_popup';
export const WORKOUT_COMPLETE = 'workout_complete';
export const WORKOUT_COMPLETE_POPUP = 'workout_complete_popup';
export const MAIN_PAGE = 'main';
export const PAUSE_SUBSCRIPTION_OFFER = 'pause_subscription_offer';
export const RESUME_SUBSCRIPTION = 'pause_subscription_resume';
export const REMINDER_OFFER = 'reminder_offer';
export const SPECIAL_OFFER_DISCOUNT = 'discount_offer_50';
export const RETURN_OFFER_DISCOUNT = 'discount_offer_80';
export const RETURN_OFFER_CA = 'discount_offer_80_ca';
export const RETURN_OFFER_CRM = 'discount_offer_80_crm';
export const CANCEL_OFFER = 'cancel_offer';
export const RETURN_OFFER = 'return_offer';
export const SHOP_SUBSCRIPTIONS = 'shop_subscriptions';
export const SHOP_DOUBLE_PURCHASE = 'shop_double_purchase_modal';
export const SHOP_DECLINE_PAYMENT_MODAL = 'shop_decline_payment_modal';
