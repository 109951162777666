import React from 'react';

import { SUBSCRIPTION_LABEL_STATES } from '../constants';

import SubscriptionStatusLabel from './subComponents/SubscriptionLabel';
import SubscriptionCardHeader from './subComponents/SubscriptionCardHeader';
import PriceRow from './subComponents/PriceRow';
import PeriodRow from './subComponents/PeriodRow';
import CancelButton from './subComponents/CancelButton';
import BillingPeriod from './subComponents/BillingPeriod';
import AdditionalStatusInfo from './subComponents/AdditionalStatusInfo';

import * as S from './styled';

import { ISubscriptionRedemption } from '../types';

import SubscriptionCardWrapper from './SubscriptionCardWrapper';

const SubscriptionRedemption = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    price,
    onCancelSubscription,
    isTrial,
    isMainSubscription,
    productCode,
    captions,
}: ISubscriptionRedemption) => {
    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader
                isMainSubscription={isMainSubscription}
                productCode={productCode}
                icon={tabIcon}
                heading={heading}
            />
            <S.GeneralInfo>
                <SubscriptionStatusLabel state={SUBSCRIPTION_LABEL_STATES.PAYMENT_ISSUE} />
                <PeriodRow period={subscriptionPeriod} isTrial={isTrial} />
                <PriceRow price={price} />
                <BillingPeriod period={subscriptionPeriod} />
                <S.Line mt={16} />
            </S.GeneralInfo>
            {captions?.title && <AdditionalStatusInfo captions={captions} />}
            <CancelButton onCancel={onCancelSubscription} />
        </SubscriptionCardWrapper>
    );
};

export default SubscriptionRedemption;
