import { Icon, Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { getDetails } from 'store/exercises/actions';

import { useDispatch, useSelector } from 'hooks';

import CollapsibleBlock from 'components/CollapsibleBlock';

import * as S from '../styled';

import { trackDetailsClick } from 'analytics/trackers/exercise';
import ExerciseDetailsList from './ExerciseDetailsList';

const ExerciseDetails = ({ exerciseId, playlistPosition }: { exerciseId: number; playlistPosition: number }) => {
    const dispatch = useDispatch();
    const exerciseDetails = useSelector((state) => state.exercises.details[exerciseId]);
    const [isExpand, setExpandStatus] = useState(false);

    useEffect(() => {
        setExpandStatus(false);
    }, [exerciseId]);

    useEffect(() => {
        if (!exerciseDetails) {
            dispatch(getDetails(exerciseId));
        }
    }, [exerciseId, exerciseDetails]);

    const handleExpand = () => {
        setExpandStatus(!isExpand);
        trackDetailsClick(exerciseId, playlistPosition);
    };

    const isInstructionsExists = Boolean(exerciseDetails?.instructions?.length);
    const isTipsExists = Boolean(exerciseDetails?.tips.length);

    return (
        <S.ExerciseDetails isDisabled={!isInstructionsExists && !isTipsExists}>
            <S.ExerciseDetailsHeader onClick={handleExpand}>
                <Text type="medium-text" bold>
                    <Trans i18nKey="workout.workoutPage.exerciseDetails" />
                </Text>
                <Icon name="arrowDown" />
            </S.ExerciseDetailsHeader>
            <CollapsibleBlock isOpen={Boolean(isExpand)}>
                <ExerciseDetailsList exerciseDetails={exerciseDetails} />
            </CollapsibleBlock>
        </S.ExerciseDetails>
    );
};

export default ExerciseDetails;
