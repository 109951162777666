/* eslint-disable max-lines */
import { Box, Image, Text } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { borderRadius, color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const Container = styled(Box)<{ centered: boolean }>`
    width: 100%;
    padding: 48px 16px;
    margin: 0 auto;
    max-width: 592px;

    ${({ centered }) =>
        centered &&
        css`
            display: flex;
            justify-content: center;
        `};

    ${mediaQueries.laptop} {
        padding: 48px 20px;
    }

    ${mediaQueries.tabletLandscape} {
        margin: 0;
        padding: 48px;
        max-width: 100%;
    }

    ${mediaQueries.desktop} {
        padding: 48px 96px;
    }
`;

export const ContentContainer = styled.div`
    max-width: 936px;
`;

export const Title = styled(Text)`
    text-align: left;

    ${mediaQueries.tabletLandscape} {
        text-align: left;
    }
`;

export const AppTitle = styled(Text)`
    margin-left: 8px;
`;

export const SubText = styled(Text)`
    display: none;

    ${mediaQueries.tablet} {
        display: block;
    }
`;

export const TabletWrapper = styled(Box)`
    display: none;

    ${mediaQueries.tabletLandscape} {
        display: block;
    }
`;

export const MobileWrapper = styled(Box)`
    display: block;

    ${mediaQueries.tabletLandscape} {
        display: none;
    }
`;

export const FlexWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;

    ${mediaQueries.tablet} {
        justify-content: center;
    }
`;

export const StoreButton = styled(FlexWrapper)`
    ${mediaQueries.tablet} {
        margin-top: -40px;
    }
`;

export const StepContainer = styled(FlexWrapper)`
    width: 100%;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;

    ${mediaQueries.desktop} {
        flex-direction: row;
        gap: 16px;
        height: 502px;
    }

    > * {
        width: 100%;
        height: 100%;
    }
`;

export const ScanQrCodeWrapper = styled(Box)`
    width: 192px;
    height: 192px;
    padding-top: 0;
    position: relative;
    display: flex;
`;

export const QRWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    border: 6px solid ${color('surface-hovered')};
    border-radius: calc(${borderRadius('medium')} + 6px);

    svg {
        transform: translate(-6px, -6px);
    }

    canvas {
        width: 100% !important;
        height: 100% !important;
        border-radius: ${borderRadius('medium')};
    }
`;

export const DownloadWrapper = styled(Box)`
    max-width: 311px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Video = styled(Image)`
    border: 6px solid ${color('border-default')};
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;

    ${mediaQueries.desktop} {
        margin-left: 0;
        margin-right: 0;
    }
`;

export const Footer = styled(FlexWrapper)`
    flex-direction: column;

    ${FlexWrapper} {
        margin-bottom: 12px;
    }

    ${mediaQueries.tabletLandscape} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        ${FlexWrapper} {
            margin-bottom: 0;
        }
    }
`;

export const Step = styled.div`
    border-radius: ${borderRadius('medium')};
    background: ${color('surface-default')};
    padding: 26px 24px;

    ${mediaQueries.tabletLandscape} {
        padding: 32px;
    }
`;

export const StepNumber = styled(Box)`
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;

    background: ${color('primary-default')};
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    ${mediaQueries.tablet} {
        margin-left: 0;
        margin-right: 0;
    }
`;

export const StepColumn = styled.div`
    width: 100%;
    text-align: center;

    ${mediaQueries.tablet} {
        text-align: left;
        width: 50%;
    }
`;

export const StepContent = styled(Box)`
    display: flex;
    flex-direction: column;

    ${mediaQueries.tablet} {
        flex-direction: row;
        gap: 20px;
    }
`;
