import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const Volume = ({ theme, color, size = 20 }: { theme: Theme; color?: Colors; size?: number }) => {
    const iconColor = theme[color || 'border-default'];

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99984 16.7851V3.20922C9.99984 2.92005 9.82734 2.66255 9.56067 2.55339C9.29234 2.44339 8.989 2.50672 8.7865 2.71255L5.264 6.29089C5.0315 6.52755 4.7065 6.66339 4.374 6.66339H2.9165C2.22734 6.66339 1.6665 7.22422 1.6665 7.91339V12.0801C1.6665 12.7692 2.22734 13.3301 2.9165 13.3301H4.374C4.7065 13.3301 5.0315 13.4659 5.264 13.7026L8.7865 17.2809C8.92317 17.4192 9.10484 17.4934 9.29067 17.4934C9.3815 17.4934 9.47317 17.4767 9.56067 17.4409C9.82734 17.3309 9.99984 17.0734 9.99984 16.7851ZM16.4765 4.28672C16.2023 3.91672 15.6798 3.84089 15.3115 4.11672C14.9423 4.39172 14.8657 4.91339 15.1407 5.28255C16.139 6.62255 16.6665 8.25255 16.6665 9.99672C16.6665 11.7409 16.139 13.3709 15.1407 14.7109C14.8657 15.0809 14.9423 15.6026 15.3115 15.8776C15.4607 15.9892 15.6348 16.0426 15.8082 16.0426C16.0623 16.0426 16.3132 15.9267 16.4765 15.7076C17.6915 14.0767 18.3332 12.1026 18.3332 9.99672C18.3332 7.89172 17.6915 5.91755 16.4765 4.28672ZM11.8223 6.42839C11.4657 6.72005 11.414 7.24422 11.7048 7.60089C12.2182 8.22672 12.4998 9.07839 12.4998 9.99672C12.4998 10.9159 12.2182 11.7667 11.7048 12.3926C11.414 12.7492 11.4657 13.2742 11.8223 13.5651C11.9773 13.6926 12.164 13.7542 12.3498 13.7542C12.5907 13.7542 12.8298 13.6501 12.9948 13.4492C13.7507 12.5251 14.1665 11.3001 14.1665 9.99672C14.1665 8.69422 13.7507 7.46839 12.9948 6.54505C12.704 6.18922 12.1782 6.13672 11.8223 6.42839Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(Volume);
