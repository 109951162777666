import { Text } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';

import { getSubscriptionTitle } from '../../helpers';

import * as S from '../styled';

import { IBillingPeriod } from '../../types';

const BillingPeriod = ({ period }: IBillingPeriod) => {
    const { t } = useTranslation();

    const label = getSubscriptionTitle(period, false);

    return (
        <S.SubscriptionCarRow>
            <Text text={t('subscription.text.billingPeriod')} />
            <Text>
                <Trans
                    i18nKey="basic.periods"
                    values={{
                        count: label?.count,
                        context: label?.context,
                    }}
                />
            </Text>
        </S.SubscriptionCarRow>
    );
};

export default BillingPeriod;
