import { IContentId } from '../types';

const getContentId = ({ product_code, price, trialPrice, payment_type, trialPeriod, period }: IContentId) => {
    const periodValue = `period: ${trialPeriod}/${period}`;

    const priceValue = `price: ${trialPrice}/${price}`;

    return `${payment_type}: ${product_code} | ${periodValue} | ${priceValue}`;
};

export default getContentId;
