import { Box, Image, Text } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { viewPortWithoutHeader } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const Container = styled.div`
    width: 100vw;
    min-height: ${viewPortWithoutHeader()};
    display: flex;
    flex-direction: row;
    align-items: start;

    ${mediaQueries.tablet} {
        align-items: center;
    }

    ${mediaQueries.tabletLandscape} {
        align-items: stretch;
        padding-bottom: 0;
    }
`;

export const AuthForm = styled.div`
    position: relative;
    width: ${({ theme }) => css`calc(400px + (${theme['default-indent']}px * 2))`};
    margin: 0 auto;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 343px;
    min-height: ${viewPortWithoutHeader()};

    ${mediaQueries.laptop} {
        padding-top: 0;
        justify-content: center;
    }
`;

export const AuthImage = styled(Box)`
    display: none;
    min-height: ${viewPortWithoutHeader()};
    width: 50%;
    height: 100%;
    position: relative;
    background: linear-gradient(180deg, #0c0c0d 22.47%, #141924 59.01%, #101010 81.71%);

    * {
        height: 100%;
        object-fit: cover;
    }

    ${mediaQueries.tabletLandscape} {
        display: block;
    }
`;

export const LoginMan = styled(Image)`
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 806px;
`;

export const LoginText = styled(Image)`
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
    position: absolute;
    bottom: 684px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 545px;
`;

export const SupportContainer = styled.div`
    display: flex;
    margin-top: 20px;

    ${mediaQueries.laptop} {
        margin-top: 0;
        position: fixed;
        bottom: 27px;
    }
`;

export const HelpText = styled(Text)`
    margin-right: 5px;
`;
