import { Text } from 'wikr-core-components';
import { FC } from 'react';

import { Download } from 'components/Icon';

import * as S from './styled';

import { trackDownloadGuideClick } from 'analytics/trackers/fitnessGuides';

interface GuideProps {
    img?: string;
    link?: string;
    title: string;
    internalName?: string;
    dataLocator?: string;
}

export const Guide: FC<GuideProps> = ({ title, img, link, internalName, dataLocator }) => (
    <S.GuideLinkButton
        href={link}
        target="_blank"
        data-locator={dataLocator}
        onClick={() => internalName && trackDownloadGuideClick(internalName)}
    >
        <S.GuideImg alt="guide-img" src={img} />
        <S.GuideTitleContainer>
            <Text center={!img} type="medium-text" text={title} />
        </S.GuideTitleContainer>
        <Download />
    </S.GuideLinkButton>
);
