export const PATHS = {
    TEMPORARY_PASSWORD: '/',
    LOGIN: '/log-in',
    DOWNLOAD_APP_INSTRUCTION: '/only-manual',
    ACCOUNT: '/account/:tabId',
    ACCOUNT_DEFAULT: '/account',
    CHANGE_EMAIL: '/change-email',
    MAIN: '/main',
    FITNESS_GUIDES: '/guides',
    WORKOUT_BUILDER: '/workout',
    WORKOUT: '/workout/play',
    WORKOUT_PREVIEW: '/workout/preview',
    STORE_PAGE: '/shop/subscriptions/:productCode',
    RETURN_OFFER: '/shop/return-offer',
    FINISH_WORKOUT_PAGE: '/finish-workout-page',
    START_WORKOUT_PAGE: '/start-workout-page',
    CONTINUE_PROGRESS_PAGE: '/continue-progress-page',
};
