import React from 'react';

import * as S from './styled';

import { BlockExercise } from 'types/workouts';
import { ExerciseMapType } from 'types/exercise';

import ExerciseItem from './ExerciseItem';

const ExerciseList = ({
    exercises,
    exercisesMap,
    onClick,
    activeExercise,
}: {
    exercises: BlockExercise[];
    exercisesMap: ExerciseMapType;
    onClick?: (args: any) => void;
    activeExercise?: number;
}) => {
    return (
        <S.ExerciseList>
            {exercises.map(({ id, time }: BlockExercise, index) => {
                const exerciseData = exercisesMap[id];
                const key = `${id}_${index}`;

                const handleClick =
                    onClick &&
                    (() => {
                        onClick(exerciseData);
                    });

                return (
                    <ExerciseItem
                        key={key}
                        isActive={activeExercise === exerciseData.id}
                        preview={exerciseData.preview}
                        name={exerciseData.name}
                        time={time}
                        onClick={handleClick}
                    />
                );
            })}
        </S.ExerciseList>
    );
};

export default ExerciseList;
