import { Text } from 'wikr-core-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { NOTIFY_STATUSES } from 'constants/notifications';

import { CheckCircle, ErrorIcon } from 'components/Icon';

const Message = ({ message, dataLocator }: { message: string; dataLocator?: string }) => {
    const { t } = useTranslation();

    return (
        <Text type="small-text" color="text-main" dataLocator={dataLocator}>
            {t(message)}
        </Text>
    );
};

const showNotify = ({ message, status = NOTIFY_STATUSES.DEFAULT }: { message: string; status: NOTIFY_STATUSES }) => {
    if (status === NOTIFY_STATUSES.SUCCESS) {
        return toast.success(<Message message={message} dataLocator="successAlertbox" />, {
            icon: <CheckCircle />,
        });
    }
    if (status === NOTIFY_STATUSES.ERROR) {
        return toast.error(<Message message={message} dataLocator="failedAlertbox" />, {
            icon: <ErrorIcon />,
        });
    }

    return toast(<Message message={message} />);
};

export default showNotify;
