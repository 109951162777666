import { SHOP_DECLINE_PAYMENT_MODAL, SHOP_DOUBLE_PURCHASE, SHOP_SUBSCRIPTIONS } from 'analytics/constants/ids';

import { trackClick, trackNewScreenLoad } from 'services/Analytics';

export const trackShopPageLoad = (productCode: string) => () =>
    trackNewScreenLoad(SHOP_SUBSCRIPTIONS, {
        event_label: productCode,
    });

export const trackDoublePurchaseModalLoad = (productCode: string) => () =>
    trackNewScreenLoad(SHOP_DOUBLE_PURCHASE, {
        event_action: 'already_have_plan',
        event_label: productCode,
    });

export const trackDeclinePaymentModalLoad = (productCode: string) => () =>
    trackNewScreenLoad(SHOP_DECLINE_PAYMENT_MODAL, {
        event_action: 'payment_declined',
        event_label: productCode,
    });

export const submitDoublePurchaseModal = (productCode: string) =>
    trackClick(SHOP_DOUBLE_PURCHASE, {
        event_action: 'button__go_to_app_already_have',
        event_label: productCode,
    });

export const trackContinueBuyClick = (productCode: string) =>
    trackClick(SHOP_SUBSCRIPTIONS, { event_action: 'button__continue_plan', event_label: productCode });

export const trackContactSupportClick = (productCode: string) =>
    trackClick(SHOP_DECLINE_PAYMENT_MODAL, {
        event_action: 'button__contact_support',
        event_label: productCode,
    });

export const trackGoToMainClick = (productCode: string) =>
    trackClick(SHOP_DECLINE_PAYMENT_MODAL, {
        event_action: 'button__go_to_main',
        event_label: productCode,
    });

export const trackTryAgainClick = (productCode: string) =>
    trackClick(SHOP_DECLINE_PAYMENT_MODAL, {
        event_action: 'button__try_again',
        event_label: productCode,
    });

export const trackProductNotFoundScreenLoad = (productCode: string) => {
    trackNewScreenLoad(SHOP_DOUBLE_PURCHASE, {
        event_action: 'not_found_screen__load',
        event_label: productCode,
    });
};
