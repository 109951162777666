import styled from 'styled-components';

import { color } from 'theme/selectors';

export const CheckboxIcon = styled.label`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease;
    border-radius: 4px;
    border: 1px solid ${color('border-default')};

    svg {
        opacity: 0;
        transition: 0.3s ease;
        visibility: hidden;
        position: relative;
        top: -2px;
    }
`;

export const Checkbox = styled.div`
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;

    input {
        display: none;
    }

    input:checked + ${CheckboxIcon} {
        background-color: ${color('primary-default')};

        svg {
            opacity: 1;
            visibility: visible;
        }
    }
`;
