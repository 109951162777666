import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Burger } from 'components/Burger/styled';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const HeaderContainer = styled.header`
    min-height: 56px;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 24px;
    position: sticky;
    background-color: ${color('surface-default')};
    z-index: 10001;
    filter: drop-shadow(0 5px 6px rgba(0, 0, 0, 0.3));

    ${mediaQueries.tabletLandscape} {
        ${Burger} {
            display: none;
            padding: 11px 24px;
        }
    }
`;

export const HeaderPublicContainer = styled(HeaderContainer)`
    border: none;
    filter: none;
`;

export const AvatarImage = styled.img`
    cursor: pointer;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    max-width: 50%;
`;

export const LogInLink = styled(Link)`
    text-transform: uppercase;
    display: flex;
    align-items: center;
`;
