import { Text } from 'wikr-core-components';
import React, { ChangeEvent } from 'react';

import { fancyTime } from '../../helpers';

import { Maximize, Mute, Volume, Minimize } from 'components/Icon';

import * as S from './styled';

import WorkoutNavigationBar from '../WorkoutNavigationBar';

const BottomBar = ({
    onMute,
    isMute,
    onVolumeChange,
    onFullscreen,
    timeLeft,
    isDesktop,
    volume,
    isFullscreen,
    isAudioAvailable,
}: {
    onMute: () => void;
    isMute: boolean;
    onVolumeChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onFullscreen: () => void;
    timeLeft: number;
    isDesktop: boolean;
    volume: number;
    isFullscreen: boolean;
    isAudioAvailable: boolean;
}) => (
    <S.PlayerBottomBar>
        {isAudioAvailable && (
            <S.VolumeWrapper>
                <S.MuteButton onClick={onMute}>
                    {isMute ? <Mute color="text-main" /> : <Volume color="text-main" />}
                </S.MuteButton>
                {isDesktop && (
                    <S.VolumeInput type="range" onChange={onVolumeChange} max={1} min={0} step={0.05} value={volume} />
                )}
            </S.VolumeWrapper>
        )}

        <S.VideoTimer>
            <Text type={isFullscreen ? 'h1' : 'h3'} key={timeLeft}>
                {fancyTime(timeLeft)}
            </Text>
        </S.VideoTimer>

        {isFullscreen && (
            <S.NavigationWrapper>
                <WorkoutNavigationBar />
            </S.NavigationWrapper>
        )}

        {isDesktop && (
            <S.FullscreenButton onClick={onFullscreen}>
                {isFullscreen ? <Minimize color="text-main" /> : <Maximize color="text-main" />}
            </S.FullscreenButton>
        )}
    </S.PlayerBottomBar>
);

export default BottomBar;
