import { Image, Text } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { borderRadius, color } from 'theme/selectors';

export const TargetAreaWrapper = styled.button`
    display: inline-flex;
    flex-direction: column;
    position: relative;
    width: 104px;
    background: transparent;
    border: none;
    z-index: 100;
    cursor: pointer;
`;

export const TargetAreaImg = styled(Image)<{ isActive: boolean }>`
    position: absolute;
    top: 0;
    z-index: 1;
    max-height: 120px;

    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};

    :hover {
        opacity: 1;
    }
`;

export const TargetAreaTitle = styled(Text)<{ isActive: boolean }>`
    margin: 0 auto;
    color: ${({ isActive, theme }) => color(isActive ? 'text-main' : 'text-secondary')({ theme })};
`;

export const TargetArea = styled.div<{ isActive: boolean }>`
    width: 104px;
    height: 120px;
    background: ${color('surface-hovered')};
    border-radius: ${borderRadius('medium')};
    position: relative;
    cursor: pointer;
    padding: 12px;
    margin-bottom: 8px;

    &::after {
        content: '';
        position: absolute;
        top: 8px;
        right: 8px;
        border: 2px solid #5d5d5d;
        width: 24px;
        height: 24px;
        z-index: 2;
        box-sizing: border-box;
        border-radius: 4px;
    }
    &::before {
        position: absolute;
        top: 14px;
        right: 16px;
        width: 5px;
        height: 9px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        z-index: 3;
    }

    ${({ isActive }) =>
        isActive &&
        css`
            &::after {
                background: ${color('primary-default')};
                border: none;
            }

            &::before {
                content: '';
            }
        `}
`;
