import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const WorkoutsContainer = styled.div`
    padding: 24px 0;
    width: 100%;
    max-width: 600px;
    position: relative;
    margin: 0 auto;

    ${mediaQueries.laptop} {
        padding: 48px 0;
    }

    ${mediaQueries.tabletLandscape} {
        max-width: 85%;
    }

    ${mediaQueries.desktop} {
        margin-left: 96px;
    }
`;

export const BackButton = styled.button`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${color('surface-hovered')};
    align-items: center;
    cursor: pointer;
    display: none;
    justify-content: center;
    margin-bottom: 24px;

    ${mediaQueries.laptop} {
        display: flex;
        position: absolute;
        top: 48px;
        left: -52px;
        margin-bottom: 0;
    }

    ${mediaQueries.desktop} {
        left: -64px;
    }
`;
