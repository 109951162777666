import { Button, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { SUBSCRIPTION_LABEL_STATES } from '../constants';

import SubscriptionStatusLabel from './subComponents/SubscriptionLabel';
import SubscriptionCardHeader from './subComponents/SubscriptionCardHeader';
import PriceRow from './subComponents/PriceRow';
import PeriodRow from './subComponents/PeriodRow';
import CancelButton from './subComponents/CancelButton';
import BillingPeriod from './subComponents/BillingPeriod';
import AdditionalStatusInfo from './subComponents/AdditionalStatusInfo';

import * as S from './styled';

import { IPausedSubscription } from '../types';

import SubscriptionCardWrapper from './SubscriptionCardWrapper';

const PausedSubscription = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    price,
    isTrial,
    isMainSubscription,
    productCode,
    captions,
    nextChargeDate,
    onResumeSubscription,
    onCancelSubscription,
    isTimePassedSincePaused,
}: IPausedSubscription) => {
    const { t } = useTranslation();

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader
                isMainSubscription={isMainSubscription}
                productCode={productCode}
                icon={tabIcon}
                heading={heading}
            />
            <S.StatusesWrapper>
                <SubscriptionStatusLabel state={SUBSCRIPTION_LABEL_STATES.PAUSED} />
                {!isTimePassedSincePaused && <SubscriptionStatusLabel state={SUBSCRIPTION_LABEL_STATES.UPDATING} />}
            </S.StatusesWrapper>
            <PeriodRow period={subscriptionPeriod} isTrial={isTrial} />
            <PriceRow price={price} />
            <BillingPeriod period={subscriptionPeriod} />
            <S.SubscriptionCarRow>
                <Text text={t('subscription.text.newChargeDate')} />
                <Text text={`${nextChargeDate} UTC`} dataLocator="subscriptionNextChargeDate" />
            </S.SubscriptionCarRow>
            <S.Line mt={16} />
            {captions?.title && <AdditionalStatusInfo captions={captions} />}
            <Button
                text={t('subscription.text.resumeSub')}
                mb={20}
                dataLocator="resumeSubscriptionButton"
                onClick={onResumeSubscription}
            />
            <CancelButton onCancel={onCancelSubscription} />
        </SubscriptionCardWrapper>
    );
};

export default PausedSubscription;
