import config from 'config';

import api from 'api';

let initData = {};

export const FRONT_CHAT_DELAY_LS_KEY = 'FRONT_CHAT_DELAY_SECONDS';

export const initFrontChat = async ({
    name,
    email,
    language,
}: {
    name: string | null;
    email: string | null;
    language: string;
}) => {
    try {
        const userHash = await api.user.hashedEmail();

        initData = {
            chatId: config.FRONT_CHAT_ID,
            email,
            name,
            userHash,
            customFields: {
                platform: 'web',
                project: config.PROJECT_NAME,
                language,
            },
        };

        await insertScript();

        // Delay front-chat start
        const lsDelaySeconds = localStorage.getItem(FRONT_CHAT_DELAY_LS_KEY);
        const delay = lsDelaySeconds ? Number(lsDelaySeconds) : 120;

        // Hide button if delay is existing
        startFrontChat(!Boolean(delay));

        if (delay) {
            setTimeout(() => {
                // reinitialize the front chat with button
                startFrontChat(true);
            }, delay * 1000);

            // Reset delay for next launches
            localStorage.setItem(FRONT_CHAT_DELAY_LS_KEY, String(0));
        }
    } catch (e) {
        console.error('chat error', e);
    }
};

const insertScript = () =>
    new Promise((resolve) => {
        const d = document;
        const script = d.createElement('script');

        script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
        script.type = 'text/javascript';
        script.async = true;

        d.body.appendChild(script);
        script.onload = resolve;
    });

export const startFrontChat = (useDefaultLauncher: boolean) => {
    window.FrontChat && window.FrontChat('init', { ...initData, useDefaultLauncher });
};

export const showFrontChat = () => {
    if (window.FrontChat) {
        window.FrontChat && window.FrontChat('show');
    }
};

export const shutdownFrontChat = () => {
    if (window.FrontChat) {
        window.FrontChat('shutdown', { clearSession: true });
    }
};
