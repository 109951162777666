import { Colors } from 'wikr-core-components/dist/components/types';
import { Button, Text } from 'wikr-core-components';
import React from 'react';

import OverlineButton from 'components/OverlineButton';
import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';
import ContactUsLink from 'components/ContactUsLink';

import * as S from './styled';

import hands from 'assets/img/webp/well-done-hands.webp';
import decline from 'assets/img/webp/decline.webp';
import errorAttention from 'assets/img/webp/attention.webp';
import warning from 'assets/img/svg/warning.svg';

const IMAGE_NAME_MAP = {
    warning,
    decline,
    errorAttention,
    'rising-hands': hands,
};

export interface UniversalModalProps {
    image?: keyof typeof IMAGE_NAME_MAP;
    onClose?: () => void;
    trackLoad?: () => void;
    onPrimaryClick?: () => void;
    onSecondaryClick?: () => void;
    onTertiaryClick?: () => void;
    withCloseButton?: boolean;
    withContactUsLink?: boolean;
    captions: {
        title: string;
        subtitle?: string;
        extraText?: {
            text?: string;
            color?: Colors;
        };
        primaryButton?: string;
        secondaryButton?: string;
        tertiaryButton?: string;
    };
    dataLocators?: {
        modal?: string;
        container?: string;
        primaryButton?: string;
        secondaryButton?: string;
        tertiaryButton?: string;
        closeButton?: string;
    };
}

const UniversalModal = ({
    onPrimaryClick,
    onSecondaryClick,
    onTertiaryClick,
    onClose,
    trackLoad,
    captions,
    dataLocators,
    withCloseButton = false,
    image = 'warning',
    withContactUsLink,
}: UniversalModalProps) => {
    return (
        <Modal
            isOpen
            onClose={onClose}
            closeOnOverlayClick
            withCloseButton={withCloseButton}
            trackLoad={trackLoad}
            paddingX={16}
            paddingY={24}
            width={343}
            dataLocatorContainer={dataLocators?.container}
        >
            <div data-locator={dataLocators?.modal}>
                <S.Img src={IMAGE_NAME_MAP[image]} alt="modal image" />

                <Text type="h5" center text={captions.title} mb={12} />

                {captions.subtitle && <Text center text={captions.subtitle} mb={12} />}

                {captions.extraText && (
                    <Text center color={captions.extraText.color} text={captions.extraText.text} mb={12} />
                )}

                <S.ButtonsBlock>
                    {onPrimaryClick && (
                        <Button
                            dataLocator={dataLocators?.primaryButton}
                            onClick={onPrimaryClick}
                            text={captions.primaryButton || 'Primary'}
                            mb={20}
                        />
                    )}

                    {onSecondaryClick && (
                        <OutlinedButton
                            dataLocator={dataLocators?.secondaryButton}
                            onClick={onSecondaryClick}
                            text={captions.secondaryButton || 'Secondary'}
                            mb={20}
                        />
                    )}

                    {onTertiaryClick && (
                        <OverlineButton
                            dataLocator={dataLocators?.tertiaryButton}
                            onClick={onTertiaryClick}
                            text={captions.tertiaryButton || 'Tertiary'}
                            mb={4}
                        />
                    )}
                    {withContactUsLink && <ContactUsLink text={captions.tertiaryButton} boldText />}
                </S.ButtonsBlock>
            </div>
        </Modal>
    );
};

export default UniversalModal;
