import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import config from 'config';

import { store, persistor } from 'store/configureStore';

import Sentry from 'services/Sentry/SentryInstance';

import App from './App';

Sentry.init();

// AQA needs
window.HIDE_FRONT_CHAT = Number(config.HIDE_FRONT_CHAT);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
);
