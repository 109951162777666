import Workouts from './components/Workouts';
import UserApi from './components/User';
import Subscriptions from './components/Subscriptions';
import PaymentApi from './components/Payment';

function ApiInstance() {
    const user = new UserApi();
    const subscriptions = new Subscriptions();
    const workouts = new Workouts();
    const payments = new PaymentApi();

    return {
        user,
        subscriptions,
        workouts,
        payments,
    };
}

export default ApiInstance();
