import { StoreButton } from 'wikr-core-components';
import styled from 'styled-components';

import { sizesMap } from './constants';

interface IStoreBtn {
    size: keyof typeof sizesMap;
}

export const StoreBtn = styled(StoreButton)<{ isMobile: boolean; onClick?: () => void; size: keyof typeof sizesMap }>`
    width: ${({ size }: IStoreBtn) => sizesMap[size].width}px !important;
    height: ${({ size }: IStoreBtn) => sizesMap[size].height}px !important;
    display: block;
`;
