/* eslint-disable quotes */
export const EMAIL_STEP = 'emailStep';
export const PASSWORD_STEP = 'passwordStep';
export const PREVENTED_CHARS = [
    'e',
    'E',
    '+',
    '-',
    '.',
    ',',
    '(',
    ')',
    '/',
    '\\',
    '*',
    '&',
    '^',
    '%',
    '$',
    '#',
    '@',
    '!',
    '`',
    '~',
    '=',
    '|',
    '[',
    ']',
    '{',
    '}',
    '<',
    '>',
    '?',
    ':',
    ';',
    "'",
    '"',
    '&',
    '*',
    '<',
    '>',
    '§',
    '¶',
    '°',
    '©',
    '®',
    '™',
    '€',
    '£',
    '¥',
    '₹',
    '•',
    '_',
    ' ',
];
