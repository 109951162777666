import * as action from './actions';

import * as actionTypes from './actionTypes';

import { IDiscountsForSubscriptions, ISubscription } from 'types/subscription';
import { InferValueTypes } from 'types/commonTypes';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

export interface SubscriptionsState {
    subscriptions: ISubscription[];
    isLoading: boolean;
    isDiscountLoaded: boolean;
    discountedSubscriptions: IDiscountsForSubscriptions;
}

const initialState: SubscriptionsState = {
    subscriptions: [],
    isLoading: true,
    isDiscountLoaded: false,
    discountedSubscriptions: {},
};

const subscriptionsReducer = (state: SubscriptionsState = initialState, action: ActionType): SubscriptionsState => {
    switch (action.type) {
        case actionTypes.RESET_SUBSCRIPTIONS:
            return { ...initialState };

        case actionTypes.SET_FETCHING_STATUS:
            return { ...state, isLoading: action.payload };

        case actionTypes.SET_USER_SUBSCRIPTIONS:
            // @ts-ignore
            return { ...state, isLoading: false, subscriptions: action.payload };

        case actionTypes.SET_IS_DISCOUNT_LOADED:
            return { ...state, isDiscountLoaded: action.payload };

        default:
            return state;
    }
};

export default subscriptionsReducer;
