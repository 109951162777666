import ApiClient from 'services/ApiClient';

import {
    DeepLinkData,
    SignInData,
    UserInfoResponse,
    UserUpdateData,
    HashedEmailResp,
    GenerateTemporaryPasswordType,
    CheckTemporaryPasswordType,
    UserGeoResp,
    UserWorkoutInfo,
} from 'types/user/userApiInterface';

class UserApi {
    getUser = async <Response = UserInfoResponse>(): Promise<Partial<Response>> => {
        return await ApiClient.get<Response>('user/');
    };

    getUserWorkoutInfoRequest = async <Response = UserWorkoutInfo>(): Promise<Partial<Response>> => {
        return await ApiClient.get<Response>('user/first-workout-info');
    };

    update = async <Data = UserUpdateData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.patch<Data, Response>('user/', payload);
    };

    generateTemporaryPassword = async <Data = GenerateTemporaryPasswordType, Response = any>(
        payload: Data
    ): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('user/generate-temporary-password', payload);
    };

    checkTemporaryPassword = async <Data = CheckTemporaryPasswordType, Response = any>(
        payload: Data
    ): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('user/check-temporary-password', payload);
    };

    signIn = async <Data = SignInData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('sign-in/', payload);
    };

    deepLinks = async <Response = DeepLinkData>(): Promise<Partial<Response>> => {
        return await ApiClient.get<Response>('user/auth-deeplink');
    };

    getUserBySignature = async <Response = UserInfoResponse>(
        params: Record<string, string>
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.get<Response>(`core/user/${params.uId}/${params.signature}`);
    };

    hashedEmail = async () => {
        return await ApiClient.get<HashedEmailResp>('front-hash');
    };

    getProducts = async () => {
        return await ApiClient.get<HashedEmailResp>('user/products');
    };

    getUserGeoData = async () => {
        return await ApiClient.get<UserGeoResp>('user/geo-data');
    };
}

export default UserApi;
