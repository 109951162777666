import { APP_MANUAL, INSTALL_APP, MAIN_PAGE } from 'analytics/constants/ids';

import { trackScreenLoad, trackClick, trackNewScreenLoad } from 'services/Analytics';

export const trackInstallAppPageLoad = (isManualPage?: boolean) =>
    trackScreenLoad(isManualPage ? APP_MANUAL : INSTALL_APP);

export const trackSimpleLoginPageLoad = () => trackScreenLoad(MAIN_PAGE);

export const trackSimpleLoginMainButtonClick = () => trackClick(MAIN_PAGE, { event_action: 'main_button' });

export const trackSimpleLoginHelpButtonClick = () => trackClick(MAIN_PAGE, { event_action: 'help_video_button' });

export const trackSimpleLoginChatClick = () => trackClick(MAIN_PAGE, { event_action: 'live_chat' });

export const trackAppStoreClick = (isManualPage?: boolean) =>
    trackClick(isManualPage ? APP_MANUAL : INSTALL_APP, { event_action: 'app_store' });

export const trackGooglePayClick = (isManualPage?: boolean) =>
    trackClick(isManualPage ? APP_MANUAL : INSTALL_APP, { event_action: 'google_play' });

export const trackWorkoutTestPageLoad = (screenId: string, eventLabel: string) =>
    trackNewScreenLoad(screenId, { event_label: eventLabel });

export const trackWorkoutTestPageClick = (screenId: string, eventLabel: string) =>
    trackClick(screenId, { event_label: eventLabel });

export const trackWorkoutTestPageHelpClick = (screenId: string) => trackClick(screenId, { event_action: 'live_chat' });
