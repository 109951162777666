import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const Pause = ({ theme, color, size = 24 }: { theme: Theme; color?: Colors; size?: number }) => {
    const iconColor = theme[color || 'border-default'];

    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 2C7.10457 2 8 2.89543 8 4V20C8 21.1046 7.10457 22 6 22C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 2C19.1046 2 20 2.89543 20 4V20C20 21.1046 19.1046 22 18 22C16.8954 22 16 21.1046 16 20V4C16 2.89543 16.8954 2 18 2Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(Pause);
