import { Text } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { getCurrencySign, getDiscount, getPriceForCurrency, getPriceFromCents } from 'helpers/prices';

import DiscountOffer from 'components/DiscountOffer';

import * as S from './styled';

import { IReturnOfferContainerProps } from './types';

import { getContent } from './config';

const ReturnOfferContainer = ({
    handleSubmit,
    oldProduct,
    specialOfferProduct,
    isLoading,
}: IReturnOfferContainerProps) => {
    const { label, title, extraText, firstBadgeText, secondBadgeText, submitButton } = getContent();
    const { t } = useTranslation();

    if (!specialOfferProduct || !oldProduct) return;

    const oldPrice = getPriceFromCents(oldProduct.amount, oldProduct.currency);
    const discount = getDiscount(getPriceForCurrency(oldProduct), getPriceForCurrency(specialOfferProduct));

    return (
        <S.SurveyContent data-locator="discountOfferContainer">
            <S.DiscountsContainer>
                <S.OfferLabel>
                    <Text text={t(label)} />
                </S.OfferLabel>
                <Text type="h4" medium center mb={12}>
                    <Trans
                        i18nKey={title}
                        values={{ discount }}
                        components={{
                            Primary: discount ? <S.AccentColorPrice /> : <span />,
                        }}
                    />
                </Text>

                <DiscountOffer
                    badgeBackgroundColor="surface-hovered"
                    badgeText={t(firstBadgeText)}
                    price={oldPrice}
                    periodTitle={t('subscription.cancellation.specialOffer.namePeriodICU', {
                        period: oldProduct.subscription_period,
                    })}
                    period={oldProduct.subscription_period}
                    currencySign={getCurrencySign(oldProduct.currency)}
                />
                <DiscountOffer
                    badgeBackgroundColor="critical-default"
                    badgeText={t(secondBadgeText, {
                        discount: discount,
                    })}
                    extraText={t(extraText)}
                    oldPrice={oldPrice}
                    price={getPriceForCurrency(specialOfferProduct)}
                    periodTitle={t('subscription.cancellation.specialOffer.namePeriodICU', {
                        period: specialOfferProduct.billing_period.value,
                    })}
                    period={specialOfferProduct.billing_period.value}
                    currencySign={getCurrencySign(specialOfferProduct.currency)}
                    isNewOffer
                    handleSubmit={handleSubmit}
                    submitButton={t(submitButton)}
                    isLoading={isLoading}
                />
            </S.DiscountsContainer>
        </S.SurveyContent>
    );
};

export default ReturnOfferContainer;
