import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import React from 'react';

import { routes } from 'router/routes';
import { RootLayout } from 'router/layouts';

import RootBoundary from './RootBoundary';
import ProtectedRoute from './ProtectedRoute';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout />} errorElement={<RootBoundary />}>
            {routes.map((route) => {
                const {
                    path,
                    layout: Layout,
                    component: Component,
                    titleKey,
                    withCenteredContent,
                    validators,
                    redirectPath,
                } = route;

                return (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <ProtectedRoute validators={validators} redirectPath={redirectPath}>
                                <Layout titleKey={titleKey} withCenteredContent={withCenteredContent}>
                                    <Component />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />
                );
            })}
        </Route>
    )
);

export default router;
