import upperBody from 'assets/images/targetAreas/upperBody.webp';
import lowBody from 'assets/images/targetAreas/lowBody.webp';
import fullBody from 'assets/images/targetAreas/fullBody.webp';
import abs from 'assets/images/targetAreas/abs.webp';

import { coreTargetAreas, lowerBodyTargetAreas, totalBodyTargetAreas, upperBodyTargetAreas } from './targetAreas';

export enum workoutMethods {
    strengthSet = 'strength_set',
    straightCircuit = 'strength_circuit',
    cardioCircuit = 'cardio_circuit',
}

export enum workoutFitnessLevelsTypes {
    beginner = 'beginner',
    intermediate = 'intermediate',
    advanced = 'advanced',
}

export const workoutFitnessLevels = [
    {
        type: workoutFitnessLevelsTypes.beginner,
        title: 'workout.workoutBuilder.difficulty.easy',
    },
    {
        type: workoutFitnessLevelsTypes.intermediate,
        title: 'workout.workoutBuilder.difficulty.medium',
    },
    {
        type: workoutFitnessLevelsTypes.advanced,
        title: 'workout.workoutBuilder.difficulty.hard',
    },
];

export const workoutDurations = [10, 20, 30, 40, 50, 60];

export const bodyParts = {
    full: 'full',
    top: 'top',
    abs: 'abs',
    low: 'low',
};

export const workoutTargetAreas = {
    [bodyParts.full]: {
        title: 'workout.workoutBuilder.targetAreas.fullBody',
        areas: totalBodyTargetAreas,
        img: fullBody,
    },
    [bodyParts.top]: {
        title: 'workout.workoutBuilder.targetAreas.upperBody',
        areas: upperBodyTargetAreas,
        img: upperBody,
    },
    [bodyParts.abs]: {
        title: 'workout.workoutBuilder.targetAreas.abs',
        areas: coreTargetAreas,
        img: abs,
    },
    [bodyParts.low]: {
        title: 'workout.workoutBuilder.targetAreas.lowerBody',
        areas: lowerBodyTargetAreas,
        img: lowBody,
    },
};
