import * as actionTypes from './actionTypes';

import { IError } from 'services/Sentry/interfaces';

export const handleErrorAction = (error: unknown, payload?: any) => {
    return {
        type: actionTypes.HANDLE_ERROR_ACTION,
        payload: { error: error as IError, data: payload },
    } as const;
};
