import { WorkoutCTAContainer } from 'containers/WorkoutCTAContainer';

export const ContinueProgressPage = () => {
    return (
        <WorkoutCTAContainer
            buttonText="continueWorkoutPage.button"
            titleText="continueWorkoutPage.title"
            screenId="first_workout_progress"
            eventLabel="continue_progress_in_app"
            boxDataLocator="continueProgressContainer"
        />
    );
};
