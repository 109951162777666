import * as actionTypes from './actionTypes';

import {
    ICancelSubscription,
    IChangeSubscription,
    IFetchDiscountSubscription,
    IPauseSubscription,
    IRestoreSubscription,
    IResumeSubscription,
    IReturnSubscription,
    ISubscription,
    ISubscriptionBase,
} from 'types/subscription';

export const fetchSubscriptions = () => {
    return {
        type: actionTypes.FETCH_USER_SUBSCRIPTIONS,
    } as const;
};

export const setSubscriptions = (payload: Array<ISubscription>) => {
    return {
        type: actionTypes.SET_USER_SUBSCRIPTIONS,
        payload,
    } as const;
};

export const cancelSubscription = (payload: ICancelSubscription) => {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION,
        payload,
    } as const;
};

export const setSubscriptionsFetchingStatus = (payload: boolean) => {
    return {
        type: actionTypes.SET_FETCHING_STATUS,
        payload,
    } as const;
};

export const resetSubscriptions = () => {
    return {
        type: actionTypes.RESET_SUBSCRIPTIONS,
    } as const;
};

export const sendFeedback = (payload: { comment?: string; reason: string }) => {
    return {
        type: actionTypes.SEND_SUBSCRIPTION_FEEDBACK,
        payload,
    } as const;
};

export const changeSubscription = (payload: {
    subscription: IChangeSubscription;
    onSuccess: () => void;
    onError: () => void;
}) => {
    return {
        type: actionTypes.CHANGE_SUBSCRIPTION,
        payload,
    } as const;
};

export const restoreSubscription = (payload: IRestoreSubscription) => {
    return {
        type: actionTypes.RESTORE_SUBSCRIPTION,
        payload,
    } as const;
};

export const resumeSubscription = (payload: IResumeSubscription) => {
    return {
        type: actionTypes.RESUME_SUBSCRIPTION,
        payload,
    } as const;
};

export const fetchDiscountSubscriptions = (payload: IFetchDiscountSubscription) => {
    return {
        type: actionTypes.FETCH_DISCOUNT_SUBSCRIPTION,
        payload,
    } as const;
};

export const returnSubscription = (payload: IReturnSubscription) => {
    return {
        type: actionTypes.RETURN_SUBSCRIPTION,
        payload,
    } as const;
};

export const setIsDiscountLoaded = (payload: boolean) => {
    return {
        type: actionTypes.SET_IS_DISCOUNT_LOADED,
        payload,
    } as const;
};

export const pauseSubscription = (payload: IPauseSubscription) => {
    return {
        type: actionTypes.PAUSE_SUBSCRIPTION,
        payload,
    } as const;
};

export const setReminder = (payload: ISubscriptionBase) => {
    return {
        type: actionTypes.SET_REMINDER,
        payload,
    } as const;
};
