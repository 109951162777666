import React from 'react';

export const progressCount = (progress: React.RefObject<HTMLDivElement>, count: React.RefObject<HTMLDivElement>) => {
    let width = 0;

    const frame = () => {
        if (width === 95) {
            clearInterval(interval);
        } else {
            width++;
            if (progress.current !== null && count.current !== null) {
                progress.current.style.width = width + '%';
                count.current.innerHTML = width + '%';
            }
        }
    };

    const interval = setInterval(frame, 60);
};
