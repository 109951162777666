import { Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const ButtonWrapper = styled.div`
    position: absolute;
    top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ButtonText = styled(Text)`
    margin-right: 9px;
`;

export const ProgressButtonContainer = styled.div`
    background-color: ${color('secondary-hovered')};
    border-radius: 10px;
    position: relative;
`;

export const ProgressBar = styled.div`
    width: 0;
    height: 64px;
    background-color: ${color('primary-default')};
    border-radius: 12px;
`;

export const ProgressNumber = styled.div`
    font-family: 'Roboto', sans-serif;
    text-align: center;
    line-height: 24px;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 2px;
    color: white;
    z-index: 200;
`;
