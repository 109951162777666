import { useTranslation } from 'react-i18next';

import { shouldDisplayFeedbackForm } from './helpers';

import * as Yup from 'yup';

const MIN_LENGTH = 20;
const MAX_LENGTH = 200;

const getValidationSchema = () => {
    const { t } = useTranslation();

    return Yup.object().shape({
        reason: Yup.string().required(t('validation.required')),
        feedback: Yup.string().when('reason', {
            is: (reason: string) => shouldDisplayFeedbackForm(reason),
            then: () =>
                Yup.string()
                    .min(20, t('validation.length.min', { count: MIN_LENGTH }))
                    .max(200, t('validation.length.max', { count: MAX_LENGTH }))
                    .required(t('validation.required')),
            otherwise: () => Yup.string(),
        }),
    });
};

export default getValidationSchema;
