import { Text } from 'wikr-core-components';

import { Guide } from 'components/Guide';

import * as S from './styled';

import { IGuide } from 'types/guide';

const GuidesList = ({ guides, title, isPurchased }: { title: string; guides: IGuide[]; isPurchased?: boolean }) => (
    <>
        <Text type="caption" color="text-secondary-subdued" bold text={title} mb={16} />
        <S.GuidesList>
            {guides.map(({ name, preview, download_url: downloadUrl, internal_name }: IGuide, index) => (
                <S.GuidesListItem key={name}>
                    <Guide
                        title={name}
                        dataLocator={isPurchased ? `purchasedGuide${index}` : `bonusGuide${index}`}
                        img={preview}
                        link={downloadUrl}
                        internalName={internal_name}
                    />
                </S.GuidesListItem>
            ))}
        </S.GuidesList>
    </>
);

export default GuidesList;
