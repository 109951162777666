import React from 'react';

import * as S from './styled';

const Switch = ({
    name,
    checked,
    onChange,
}: {
    name: string;
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    return (
        <S.SwitchBox>
            <input type="checkbox" name={name} id={name} checked={checked} onChange={onChange} />
            <S.SwitchToggle htmlFor={name} />
        </S.SwitchBox>
    );
};

export default Switch;
