import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { useWorkoutContext } from 'pages/workout-page/hooks';

import Modal from 'components/Modal';

import * as S from './styled';

import {
    trackAcceptQuitButtonClick,
    trackDiscardQuitButtonClick,
    trackExerciseQuitModalLoad,
} from 'analytics/trackers/exercise';

const ConfirmCancellation = ({ onClose, onSubmit }: { onClose: () => void; onSubmit: () => void }) => {
    const { t } = useTranslation();
    const { exerciseData, currentPlaylistPosition } = useWorkoutContext();

    const onCancelHandler = () => {
        trackDiscardQuitButtonClick(exerciseData.id, currentPlaylistPosition);
        onClose();
    };

    const onConfirmHandler = () => {
        trackAcceptQuitButtonClick(exerciseData.id, currentPlaylistPosition);
        onSubmit();
    };

    return (
        <Modal isOpen onClose={onCancelHandler} withCloseButton trackLoad={trackExerciseQuitModalLoad} width={384}>
            <Text type="h6" center mb={8} text={t('workout.confirmCancellation.title')} />
            <Text type="medium-text" center mb={32} text={t('workout.confirmCancellation.subtitle')} />
            <S.ButtonsWrp>
                <S.ConfirmButton size="small" onClick={onConfirmHandler}>
                    {t('basics.stop')}
                </S.ConfirmButton>
                <S.CancelButton size="small" onClick={onCancelHandler}>
                    {t('basics.continue')}
                </S.CancelButton>
            </S.ButtonsWrp>
        </Modal>
    );
};

export default ConfirmCancellation;
