import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { getLanguage } from 'api/utils/languageManagment';

import { getFaqLinks } from 'helpers/getFAQLinks';
import { getDeviceOs } from 'helpers/getDeviceOs';

import * as S from './styled';

import { trackSubscriptionsTabNeedSupport } from 'analytics/trackers/subscription';

const SubscriptionHelpButton = () => {
    const { t } = useTranslation();
    const lang = getLanguage();
    const deviceOs = getDeviceOs();

    return (
        <S.HelpButton>
            <Text type="medium-text" bold text={t('subscription.helpButton.question')} />
            <S.FaqLink
                href={getFaqLinks(lang, String(deviceOs))}
                data-locator="subscriptionFaqBtn"
                onClick={trackSubscriptionsTabNeedSupport}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Text type="caption" color="on-primary" bold text={t('subscription.helpButton.faq')} />
            </S.FaqLink>
        </S.HelpButton>
    );
};

export default SubscriptionHelpButton;
