import { RouterProvider } from 'react-router-dom';

import router from 'router';

import NotificationsContainer from 'containers/Notifications';

import NetworkErrorModal from './components/Modals/NetworkErrorModal';

import ThemeProvider from 'theme/provider';

function App() {
    return (
        <ThemeProvider>
            <NetworkErrorModal />
            <NotificationsContainer />
            <RouterProvider router={router} />
        </ThemeProvider>
    );
}

export default App;
