import { WORKOUT_BUILDER, WORKOUT_COMPLETE, WORKOUT_COMPLETE_POPUP, WORKOUT_PREVIEW } from 'analytics/constants/ids';

import { trackClick, trackEvent, trackScreenLoad } from 'services/Analytics';

import { workoutConfiguration } from '../types/workouts';

export const trackCreateWorkoutPageLoad = () => trackScreenLoad(WORKOUT_BUILDER);

export const trackWorkoutDurationClick = (duration: string) =>
    trackClick(WORKOUT_BUILDER, {
        event_action: 'workout_duration',
        event_label: duration,
    });

export const trackWorkoutAreasClick = (areas: string[]) =>
    trackClick(WORKOUT_BUILDER, {
        event_action: 'workout_areas',
        event_label: areas.join('_'),
    });

export const trackWorkoutDifficultyClick = (difficulty: string) =>
    trackClick(WORKOUT_BUILDER, {
        event_action: 'workout_difficulty',
        event_label: difficulty,
    });

export const trackWorkoutWarmupClick = (isActive: boolean) =>
    trackClick(WORKOUT_BUILDER, {
        event_action: 'workout_warmup',
        event_label: isActive ? '1' : '0',
    });

export const trackWorkoutCooldownClick = (isActive: boolean) =>
    trackClick(WORKOUT_BUILDER, {
        event_action: 'workout_cooldown',
        event_label: isActive ? '1' : '0',
    });

export const trackWorkoutCreateClick = () => trackClick(WORKOUT_BUILDER, { event_action: 'workout_create' });

export const trackWorkoutCreateSuccess = (configuration: workoutConfiguration, numberOfExercises: string) =>
    trackEvent('workout_create_success', {
        event_action: JSON.stringify(configuration),
        event_label: numberOfExercises,
    });

export const trackWorkoutPreviewPageLoad = () => trackScreenLoad(WORKOUT_PREVIEW);

export const trackStartWorkoutClick = () =>
    trackClick(WORKOUT_PREVIEW, {
        event_action: 'workout_start',
    });

export const trackWorkoutCompleteSuccess = () => trackEvent(WORKOUT_COMPLETE);

export const trackCompleteModalLoad = () => trackScreenLoad(WORKOUT_COMPLETE_POPUP);

export const trackCompleteWorkoutDifficultyClick = (feedback: string) =>
    trackClick(WORKOUT_COMPLETE_POPUP, { event_action: 'button_select', event_label: feedback });

export const trackCompleteWorkoutRatingClick = (isPositive: boolean) =>
    trackClick(WORKOUT_COMPLETE_POPUP, { event_action: 'button_select', event_label: isPositive ? '1' : '0' });

export const trackCompleteWorkoutContinue = () =>
    trackClick(WORKOUT_COMPLETE_POPUP, { event_action: 'button_continue' });
