import React from 'react';

import Skeleton from 'components/Skeleton';

import * as S from './styled';

const ExerciseListSkeleton = () => {
    return (
        <>
            <Skeleton height={28} width={120} mb={4} />
            <Skeleton height={20} width={190} mb={12} />
            <S.ExerciseList>
                {new Array(12).fill(0).map((itm, index) => (
                    <S.Exercise key={`${itm}_${index}`}>
                        <S.ExerciseImage>
                            <Skeleton height={56} width={56} />
                        </S.ExerciseImage>
                        <S.ExerciseDescription>
                            <Skeleton height={20} width={120} mb={4} />
                            <Skeleton height={15} width={80} />
                        </S.ExerciseDescription>
                    </S.Exercise>
                ))}
            </S.ExerciseList>
        </>
    );
};

export default ExerciseListSkeleton;
