import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useScreenLoadTracker } from 'hooks';

import AccountSettings from 'containers/AccountSettings';

import { trackAccountSettingsScreenLoad, trackChangeTab } from 'analytics/trackers/accountSettings';

export const AccountSettingsPage = () => {
    useScreenLoadTracker(trackAccountSettingsScreenLoad);

    const navigate = useNavigate();
    const location = useLocation();
    const params: { tabId?: string } = useParams();

    const handleChangeTab = (tabId: string) => {
        trackChangeTab(tabId);

        const path = params.tabId ? location.pathname.replace(params.tabId, tabId) : `${location.pathname}/${tabId}`;

        navigate(path);
    };

    return <AccountSettings tabId={params.tabId} onChangeTab={handleChangeTab} />;
};
