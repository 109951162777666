import { Button, Text } from 'wikr-core-components';
import React from 'react';

import Modal from 'components/Modal';

import * as S from './styled';

import smile from 'assets/img/webp/smile.webp';

import { IRestoreSubscriptionModal } from './types';

const RestoreSubscriptionModal = ({ onSubmit, onClose, onDiscard, trackLoad, captions }: IRestoreSubscriptionModal) => {
    const { title, description, submitButton, discardButton } = captions;

    return (
        <Modal
            isOpen
            onClose={onClose}
            withCloseButton
            closeOnOverlayClick
            trackLoad={trackLoad}
            paddingX={16}
            paddingY={16}
            dataLocatorContainer="updatingSubscriptionPlanModal"
            dataLocatorCloseButton="updatingSubscriptionPlanCloseButton"
        >
            <div data-locator="cancelSubModal">
                <S.Img src={smile} alt="smile face" width={48} height={48} />
                <Text type="h5" center text={title} mb={12} />
                {description && <Text center medium text={description} mb={24} />}
                <Button
                    onClick={onSubmit}
                    dataLocator="updatingSubscriptionPlanContinueButton"
                    mb={discardButton ? 20 : 0}
                    text={submitButton}
                />

                {onDiscard && discardButton && (
                    <S.ConfirmButton dataLocator="keepSubBtn" size="medium" textColor="action-main" onClick={onDiscard}>
                        {discardButton}
                    </S.ConfirmButton>
                )}
            </div>
        </Modal>
    );
};

export default RestoreSubscriptionModal;
