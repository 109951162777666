import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import React from 'react';

import { AuthContainer } from 'containers/AuthFlow/styled';

import SimpleHeader from 'components/SImpleHeader';

import { LayoutProps } from '../types';

const PublicLayout = ({ children, titleKey }: LayoutProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet title={t(titleKey)} />
            <SimpleHeader />
            <AuthContainer>{children}</AuthContainer>
        </>
    );
};

export default PublicLayout;
