import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import Modal from 'components/Modal';
import { Cross, MoveBack } from 'components/Icon';

import * as S from './styled';

import { PauseSubscriptionModalProps } from './types';

import { trackPauseModalLoad } from 'analytics/trackers/pauseSubscription';

const PauseSubscriptionModal = ({ onSubmit, onDiscard, onClose, onMoveBack }: PauseSubscriptionModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            paddingX={0}
            paddingY={0}
            isOpen
            onClose={onClose}
            transparentOverlay={false}
            trackLoad={trackPauseModalLoad}
        >
            <S.PauseModalContent data-locator="pauseOfferContainer">
                <S.ModalHeaderTitle>
                    <S.IconWrapper onClick={onMoveBack} data-locator="cancelSubscriptionModalBackButton">
                        <MoveBack />
                    </S.IconWrapper>
                    <S.TitleText>
                        <Text text={t('subscription.text.cancel')} type="large-text" medium />
                    </S.TitleText>
                    <S.IconWrapper onClick={onClose} data-locator="cancelSubscriptionModalCloseButton">
                        <Cross />
                    </S.IconWrapper>
                </S.ModalHeaderTitle>

                <Text type="h4" medium center bold text={t('subscription.pause.title')} mb={12} />
                <Text type="large-text" center text={t('subscription.pause.subTitle')} mb={24} />

                <S.PauseCard>
                    <S.CardHeader>
                        <Text center text={t('subscription.pause.card.header')} />
                    </S.CardHeader>

                    <S.CardBody>
                        <Text type="h5" center mb={24} text={t('subscription.pause.card.title')} />

                        <S.DetailsList>
                            <li>
                                <Text text={t('subscription.pause.card.details1')} />
                            </li>
                            <li>
                                <Text text={t('subscription.pause.card.details2')} />
                            </li>
                            <li>
                                <Text text={t('subscription.pause.card.details3')} />
                            </li>
                        </S.DetailsList>

                        <S.PauseButton dataLocator="pauseOfferPauseSubscriptionButton" onClick={onSubmit}>
                            {t('subscription.pause.text')}
                        </S.PauseButton>
                    </S.CardBody>
                </S.PauseCard>

                <S.ConfirmButton
                    dataLocator="pauseOfferCancelSubscriptionButton"
                    size="medium"
                    textColor="action-main"
                    onClick={onDiscard}
                >
                    {t('subscription.text.cancel')}
                </S.ConfirmButton>
            </S.PauseModalContent>
        </Modal>
    );
};

export default PauseSubscriptionModal;
