import { Text } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueries from 'theme/mediaQueries';

export const Title = styled(Text)`
    ${mediaQueries.desktop} {
        font-size: 32px;
        line-height: 40px;
    }
`;
