import ReactModal from 'react-modal';
import React, { memo } from 'react';

import { useScreenLoadTracker } from 'hooks';

import { Cross } from 'components/Icon';

import { CloseButton, ContentStyle, OverlayStyle } from './styled';

import { IModal, zIndexTypes } from './types';

ReactModal.defaultStyles = {};

const ContentLoadWatcher = ({ trackLoad, children }: { trackLoad?: () => void; children: React.ReactNode }) => {
    useScreenLoadTracker(trackLoad);

    return <>{children}</>;
};

const Modal = ({
    children,
    isOpen,
    onClose,
    closeOnOverlayClick,
    withCloseButton,
    fullscreen,
    paddingY,
    paddingX,
    customContent,
    trackLoad,
    width,
    height,
    dataLocatorCloseButton,
    dataLocatorContainer,
    zPosition = zIndexTypes.middle,
    transparentOverlay = true,
    buttonWithBackground,
    contentAlignmentY = 'middle',
}: IModal) => (
    <ReactModal
        shouldCloseOnOverlayClick={closeOnOverlayClick}
        shouldCloseOnEsc={false}
        isOpen={isOpen}
        contentLabel="Modal"
        contentElement={(props, content) => {
            if (customContent) return <ContentLoadWatcher trackLoad={trackLoad}>{content}</ContentLoadWatcher>;

            return (
                <ContentLoadWatcher trackLoad={trackLoad}>
                    <ContentStyle
                        {...props}
                        width={width}
                        height={height}
                        fullscreen={Boolean(fullscreen)}
                        paddingX={paddingX}
                        paddingY={paddingY}
                        contentAlignmentY={contentAlignmentY}
                        transparentOverlay={transparentOverlay}
                    >
                        {content}
                    </ContentStyle>
                </ContentLoadWatcher>
            );
        }}
        overlayElement={(props, contentElement) => (
            <OverlayStyle
                {...props}
                data-locator={dataLocatorContainer}
                zPosition={zPosition}
                transparentOverlay={transparentOverlay}
            >
                {contentElement}
            </OverlayStyle>
        )}
        ariaHideApp={false}
    >
        {withCloseButton && (
            <CloseButton data-locator={dataLocatorCloseButton} onClick={onClose} withBackground={buttonWithBackground}>
                <Cross />
            </CloseButton>
        )}
        {children}
    </ReactModal>
);

export default memo(Modal);
