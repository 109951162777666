import React from 'react';

import * as S from '../../styled';

import { PlaylistExercise } from 'types/workouts';

const WorkoutPagination = ({
    workoutExercises,
    currentIndex,
    progress,
}: {
    workoutExercises: PlaylistExercise[];
    currentIndex: number;
    progress: {
        [key: string]: {
            percents: number;
        };
    };
}) => (
    <S.Pagination>
        {workoutExercises.map(({ id, playlistPosition }, index) => {
            const currentProgress = progress[id]?.percents || 0;

            let itemProgress = 0;

            if (playlistPosition === currentIndex) {
                itemProgress = currentProgress;
            }

            if (playlistPosition < currentIndex) {
                itemProgress = 100;
            }

            return <S.Page key={`pagination_item_${id}_${itemProgress}_${index}`} progress={itemProgress} />;
        })}
    </S.Pagination>
);

export default WorkoutPagination;
