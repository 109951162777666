import { Colors, Theme } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

export const PasswordIcon = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'border-default'];

    return (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3846 6.60869H12.8463V4.69566C12.8463 2.10634 10.6725 0 8.0002 0C5.32787 0 3.15402 2.10634 3.15402 4.69566V6.60869H1.61539C0.72463 6.60869 0 7.31081 0 8.17391V16.4348C0 17.2979 0.72463 18 1.61539 18H14.3846C15.2754 18 16 17.2979 16 16.4348V8.17391C16 7.31081 15.2754 6.60869 14.3846 6.60869ZM8.5385 12.7299V14.3913C8.5385 14.6794 8.2974 14.9131 7.99998 14.9131C7.70262 14.9131 7.46151 14.6795 7.46151 14.3913V12.7299C6.8357 12.5141 6.38515 11.9415 6.3846 11.2609C6.38531 10.3962 7.10768 9.6963 7.99998 9.6957C8.8923 9.6963 9.6147 10.3962 9.6154 11.2609C9.6148 11.9415 9.1642 12.5141 8.5385 12.7299ZM10.6925 6.60869H5.30785V4.69566C5.30785 3.25681 6.51518 2.08697 8.0001 2.08697C9.4851 2.08697 10.6924 3.25681 10.6924 4.69566L10.6925 6.60869Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(PasswordIcon);
