import { DEFAULT_VOLUME, VOLUME_KEY_IN_LS } from './constants';

import isMobile from 'helpers/isMobile';

import { ExerciseMapType } from 'types/exercise';

export const splitSecondsByHMS = (seconds: number) => {
    const hrs = ~~(seconds / 3600);
    const mins = ~~((seconds % 3600) / 60);
    const secs = ~~seconds % 60;

    return {
        hrs: hrs < 10 ? `0${hrs}` : hrs,
        mins: mins < 10 ? `0${mins}` : mins,
        secs: secs < 10 ? `0${secs}` : secs,
    };
};

export const fancyTime = (seconds: number, isFullTime?: boolean): string => {
    const { hrs, mins, secs } = splitSecondsByHMS(seconds);
    const fancyMinutesAndSeconds = `${mins}:${secs}`;

    if (Number(hrs) > 0 || isFullTime) {
        return `${hrs}:${fancyMinutesAndSeconds}`;
    }

    return fancyMinutesAndSeconds;
};

export const calculateBurnedCalories = ({
    playlist,
    exercises,
    weight,
}: {
    playlist: Array<{ id: number; time: number }>;
    exercises: ExerciseMapType;
    weight: number;
}) => {
    const burnedCalories = playlist
        .map((itm) => ({
            id: itm.id,
            duration: itm.time,
            mets: exercises[itm.id].base_mets,
        }))
        .reduce((acc, itm) => acc + ((itm.duration / 60) * (itm.mets * 3.5 * weight)) / 200, 0);

    return Number(burnedCalories.toFixed());
};

export const getInitialVolume = (): number => {
    const valueFromLS = localStorage.getItem(VOLUME_KEY_IN_LS);

    if (valueFromLS) return Number(valueFromLS);

    return isMobile.any() ? 1 : DEFAULT_VOLUME;
};
