import { BACKEND_DATE_FORMAT } from 'constants/date';

import utc from 'dayjs/plugin/utc';
import dayjs, { ManipulateType } from 'dayjs';

dayjs.extend(utc);

export const getBirthdayByAge = (age: number): string => {
    const currentYear = new Date().getFullYear();

    return `${currentYear - age}-01-01`;
};

export const getAgeFromBirthday = (birthday: string | null): number => {
    if (!birthday) return 0;

    const today = new Date();
    const todayYear = today.getFullYear();
    const yearOfBirth = birthday.split('-')[0];

    return todayYear - Number(yearOfBirth);
};

export const getFormattedDate = (date: string, format: string): string => {
    return dayjs(date).format(format);
};

export const hasTimePassedSince = (dateString: string, unit: ManipulateType, amount: number): boolean => {
    const initialDate = dayjs.utc(dateString);
    const targetDate = initialDate.add(amount, unit);
    const currentDateTime = dayjs.utc();

    return currentDateTime.isAfter(targetDate);
};

export const compareDates = (date1: string, date2: string) => dayjs(date1).diff(dayjs(date2));

export const getToday = (format: string = BACKEND_DATE_FORMAT) => dayjs().format(format);

export const addDaysToDate = (days: number, date: string, format: string = BACKEND_DATE_FORMAT): string => {
    return dayjs(date, format)
        .add(days + 1, 'day')
        .format(format);
};

export const formatSecondsToFullTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
};
