import React from 'react';

import WorkoutProvider from 'pages/workout-page/WorkoutProvider';

import ConfirmCancellationModal from './ConfirmCancellationModal';

export type ConfirmCancelWorkoutProps = {
    onCancel: () => void;
    onSubmit: () => void;
};

const ConfirmCancellationModalContainer = ({ onCancel, onSubmit }: ConfirmCancelWorkoutProps) => {
    return (
        <WorkoutProvider>
            <ConfirmCancellationModal onClose={onCancel} onSubmit={onSubmit} />
        </WorkoutProvider>
    );
};

export default ConfirmCancellationModalContainer;
