import { Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React from 'react';

import { getSubscriptionTitle } from '../../helpers';

import * as S from '../styled';

import { SubRow } from '../../types';

const PeriodRow = ({ period, isTrial, color }: SubRow) => {
    const paymentLabel = getSubscriptionTitle(period, false);

    return (
        <S.SubscriptionCarRow>
            <Text type="large-text" color={color} medium dataLocator="subscriptionPeriod">
                <Trans
                    i18nKey={isTrial ? 'subscription.period.trial' : 'subscription.period.regular'}
                    values={{
                        count: paymentLabel?.count,
                        context: paymentLabel?.context,
                    }}
                />
            </Text>
        </S.SubscriptionCarRow>
    );
};

export default PeriodRow;
