import { Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React from 'react';

import * as S from './styled';

import { workoutFeedback } from 'types/enums';
import { IFeedbackSlider } from './types';

const FeedbackSlider = ({
    workoutDifficulty,
    handleWorkoutDifficultyClick,
    handleWorkoutRatingClick,
    activeButton,
}: IFeedbackSlider) => {
    return (
        <S.FeedbackSlider isActive={Boolean(workoutDifficulty)}>
            <S.FeedbackWrapper isActive={Boolean(workoutDifficulty)}>
                <S.FeedbackContainer>
                    <Text type="medium-text" mb={12} bold>
                        <Trans i18nKey="workout.workoutCompleteModal.question1" />
                    </Text>
                    <S.Feedback>
                        <S.FeedbackItem onClick={() => handleWorkoutDifficultyClick(workoutFeedback.easy)}>
                            <S.EmojiWrapper>
                                <span role="img" aria-labelledby="too easy">
                                    🥱
                                </span>
                            </S.EmojiWrapper>
                            <Text type="caption">
                                <Trans i18nKey="workout.workoutCompleteModal.tooEasy" />
                            </Text>
                        </S.FeedbackItem>
                        <S.FeedbackItem onClick={() => handleWorkoutDifficultyClick(workoutFeedback.perfect)}>
                            <S.EmojiWrapper>
                                <span role="img" aria-labelledby="perfect">
                                    😉
                                </span>
                            </S.EmojiWrapper>
                            <Text type="caption">
                                <Trans i18nKey="workout.workoutCompleteModal.perfect" />
                            </Text>
                        </S.FeedbackItem>
                        <S.FeedbackItem onClick={() => handleWorkoutDifficultyClick(workoutFeedback.hard)}>
                            <S.EmojiWrapper>
                                <span role="img" aria-labelledby="too hard">
                                    🥵
                                </span>
                            </S.EmojiWrapper>
                            <Text type="caption">
                                <Trans i18nKey="workout.workoutCompleteModal.tooHard" />
                            </Text>
                        </S.FeedbackItem>
                    </S.Feedback>
                </S.FeedbackContainer>

                <S.FeedbackContainer>
                    <Text type="medium-text" mb={12} bold>
                        <Trans i18nKey="workout.workoutCompleteModal.question2" />
                    </Text>
                    <S.Feedback>
                        <S.FeedbackItem
                            isActive={activeButton === 1}
                            disabled={activeButton !== null}
                            onClick={() => handleWorkoutRatingClick(false, 1)}
                        >
                            <S.EmojiWrapper>
                                <span role="img" aria-labelledby="didn't like">
                                    👎
                                </span>
                            </S.EmojiWrapper>
                            <Text type="caption">
                                <Trans i18nKey="workout.workoutCompleteModal.didntLike" />
                            </Text>
                        </S.FeedbackItem>
                        <S.FeedbackItem
                            isActive={activeButton === 2}
                            disabled={activeButton !== null}
                            onClick={() => handleWorkoutRatingClick(true, 2)}
                        >
                            <S.EmojiWrapper>
                                <span role="img" aria-labelledby="loved it">
                                    👍
                                </span>
                            </S.EmojiWrapper>
                            <Text type="caption">
                                <Trans i18nKey="workout.workoutCompleteModal.lovedIt" />
                            </Text>
                        </S.FeedbackItem>
                    </S.Feedback>
                </S.FeedbackContainer>
            </S.FeedbackWrapper>
        </S.FeedbackSlider>
    );
};

export default FeedbackSlider;
