import { Text } from 'wikr-core-components';
import { Navigate, useNavigate } from 'react-router-dom';
import React, { ComponentType } from 'react';

import { PRIVATE } from 'router/routes';

import { useWorkout } from 'hooks/workout/useWorkout';
import { useScreenLoadTracker, useSelector } from 'hooks';
import { useWorkoutContext } from './hooks';

import WorkoutsContainer from 'containers/Workouts';

import { WorkoutTitle } from 'components/WorkoutTitle';
import CompleteWorkoutModal from 'components/Modals/CompleteWorkoutModal';
import WorkoutProgressInfo from './components/WorkoutProgressInfo';
import SidebarExercises from './components/SidebarExercises';
import { ExerciseDetails, ExercisePlayer, WorkoutNavigationBar } from './components';

import * as S from './styled';

import { trackBackButtonClick, trackExercisePageLoad } from 'analytics/trackers/exercise';
import WorkoutProvider from './WorkoutProvider';

const ProtectPage = (Component: ComponentType) => () => {
    const { workout } = useSelector((state) => state.workouts);

    if (!workout) {
        return <Navigate to={PRIVATE.WORKOUT_BUILDER.path} />;
    }

    return (
        <WorkoutProvider>
            <Component />
        </WorkoutProvider>
    );
};

export const WorkoutPage = ProtectPage(() => {
    useScreenLoadTracker(trackExercisePageLoad);

    const navigate = useNavigate();
    const { workoutExercises } = useWorkout();

    const {
        fullWorkoutTime,
        handleExerciseClick,
        handleProgress,
        exerciseData,
        currentPlaylistPosition,
        playerContainer,
        completeData,
        currentExercise,
        isPageBlocked,
    } = useWorkoutContext();

    return (
        <>
            <WorkoutsContainer
                withBackButton
                trackOnBack={() => trackBackButtonClick(exerciseData.id, currentPlaylistPosition)}
            >
                <S.WorkoutPlayerContainer ref={playerContainer}>
                    <S.WorkoutTitleWrapper>
                        <WorkoutTitle title="workout.custom.title" subtitle="workout.custom.subtitle" />
                    </S.WorkoutTitleWrapper>

                    <S.PlayerContainer>
                        <WorkoutProgressInfo withClose />

                        <ExercisePlayer
                            playlistPosition={currentPlaylistPosition}
                            exerciseTime={currentExercise.time}
                            onProgress={handleProgress}
                            isStop={Boolean(completeData || isPageBlocked)}
                        />
                        <S.InfoBlock>
                            <Text type="medium-text" medium mb={12}>
                                {exerciseData?.name}
                            </Text>

                            <WorkoutNavigationBar />
                        </S.InfoBlock>
                    </S.PlayerContainer>

                    <ExerciseDetails exerciseId={currentExercise.id} playlistPosition={currentPlaylistPosition} />

                    <S.Sidebar>
                        <SidebarExercises
                            handleExerciseClick={handleExerciseClick}
                            currentExerciseId={currentExercise.id}
                        />
                    </S.Sidebar>
                </S.WorkoutPlayerContainer>
            </WorkoutsContainer>

            <CompleteWorkoutModal
                workoutData={{
                    duration: fullWorkoutTime,
                    exercises: workoutExercises.length,
                    burned: completeData?.calories_burned,
                }}
                isOpen={Boolean(completeData)}
                onClose={() => navigate(PRIVATE.WORKOUT_BUILDER.path)}
            />
        </>
    );
});
