import { useEffect, useState } from 'react';

const useCountdown = ({ time, onEnd, onTick, autostart = false }: any) => {
    const [left, setTimeLeft] = useState(time);
    const [isStart, setStart] = useState(autostart);

    const reset = () => {
        setStart(false);
        setTimeLeft(time);
    };

    const start = () => {
        setStart(true);
    };

    const stop = () => {
        setStart(false);
    };

    const toggle = () => {
        setStart(!isStart);
    };

    const handleTick = () => {
        if (!isStart) return;

        if (!left) {
            onEnd && onEnd();
            setStart(false);

            return;
        }

        const intervalId = setTimeout(() => {
            const nextLeft = left - 1;

            setTimeLeft(nextLeft);
            onTick && onTick(nextLeft);
        }, 1000);

        return () => clearTimeout(intervalId);
    };

    useEffect(handleTick, [left, isStart]);

    return { left, start, stop, reset, toggle, isStart };
};

export default useCountdown;
