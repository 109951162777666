/* eslint-disable max-lines */
export default {
    inline: {
        '--brand-primary': '#0045ff',
        '--brand-primary-hover': '#1847df',
        '--background-color': '#1d1d1d',
        '--card-color': '#28292b',
        '--card-color-hover': '#36373a',
        '--font-family': "'Roboto', sans-serif",
    },
    theme: {
        'primary-default': '#0045ff',
        'primary-hovered': '#1847df',
        'primary-active': '#1338af',
        'primary-disabled': '#36373a',

        'secondary-default': '#1d1d1d',
        'secondary-hovered': '#121212',
        'secondary-active': '#000000',
        'secondary-disabled': '#1d1d1d',

        'text-main': '#ffffff',
        'text-secondary': '#bababa',
        'text-secondary-subdued': '#939394',

        'on-primary': '#ffffff',

        'border-default': '#5d5d5d',
        'border-subdued': '#e0e0e0',

        'surface-main': '#1d1d1d',
        'surface-secondary': '#151515',
        'surface-default': '#28292b',
        'surface-hovered': '#36373a',

        'success-default': '#49d461',
        'success-highlight': '#11591e',
        'success-surface': '#11591e',

        'warning-default': '#feec4b',
        'warning-highlight': '#948500',
        'warning-surface': '#948500',

        'critical-default': '#de2e44',
        'critical-highlight': '#660511',
        'critical-surface': '#420a11',

        discount: '#910314',
        rating: '#f3db99',
        safe: '#1975ff',
        'safe-surface': '#002152',

        'default-indent': '16',
        'border-radius-small': '8',
        'border-radius-medium': '12',
        'default-box-shadow': '0 8px 16px rgba(47, 198, 73, 0.25)',
        'skeleton-shadow': '#242424',

        'action-main': '#C9C9C9',
        'action-secondary': '#8F8F8F',
        'action-hovered': '#DFDFDF',
        'action-pressed': '#EDEDED',
        'action-disabled': '#5F5F5F',

        'default-grid-gutter': '20',
    },
};
