import { Box, Theme } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';

import { selectCurrentUser } from 'store/user/selectors';

import { useScreenLoadTracker, useSelector } from 'hooks';

import * as S from './styled';

import { InputType } from 'types/ui';

import { getInputsData } from './config';

import { trackAccountTabLoad } from 'analytics/trackers/accountSettings';

const AccountSettings = ({ theme }: { theme: Theme }) => {
    useScreenLoadTracker(trackAccountTabLoad);

    const navigate = useNavigate();

    const user: any = useSelector(selectCurrentUser);

    const { t } = useTranslation();

    return (
        <Box paddingTop={40}>
            {getInputsData(user.email).map(({ field, icon, value, label, buttonRoute, track, dataLocator }) => (
                <Fragment key={label}>
                    <S.StyledInput>
                        <S.Input
                            name={field}
                            label={label}
                            touched
                            leftIcon={icon}
                            disabled
                            isFocus
                            value={value}
                            iconSize={20.5}
                            initialValue={value}
                            onChange={() => {}}
                            theme={theme}
                            type={field as InputType}
                        />
                    </S.StyledInput>

                    <S.SmallButton
                        size="small"
                        dataLocator={dataLocator}
                        mb={40}
                        onClick={() => {
                            track();
                            navigate(buttonRoute);
                        }}
                        text={t('account.settings.button.change')}
                    />
                </Fragment>
            ))}
        </Box>
    );
};

export default withTheme(AccountSettings);
