/* eslint-disable max-lines */
import { Box, Button, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent } from 'react';
import { FormikProps } from 'formik';

import { measure } from 'constants/units';

import { isEmpty } from 'helpers/utils';

import FormikInput from 'components/FormikInput';

import * as S from '../styled';

import { UnitTypes } from 'types/user/userApiInterface';
import { formValues, nameInput, weightInput } from '../types';

interface IProfileForm {
    currentUnit: UnitTypes;
    nameFields: nameInput[];
    weightFields: weightInput[];
    setUnit: (event: ChangeEvent) => void;
    isLoading?: boolean;
}

const { MEASURE_UNITS, WEIGHT_UNIT, TALL_UNIT } = measure;

function ProfileForm({
    nameFields,
    setFieldTouched,
    values,
    setFieldValue,
    touched,
    errors,
    currentUnit,
    weightFields,
    handleSubmit,
    setUnit,
    isLoading,
}: IProfileForm & FormikProps<formValues>) {
    const { t } = useTranslation();

    const measureUnits = Object.values(MEASURE_UNITS);

    return (
        <Box paddingTop={50}>
            {nameFields.map(({ name, label, dataLocator, placeholder }) => (
                <FormikInput
                    mb={34}
                    key={name}
                    field={name}
                    label={label}
                    type="text"
                    setFieldTouched={setFieldTouched}
                    initialValues={values}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    errors={errors}
                    dataLocator={dataLocator}
                    placeholder={placeholder}
                />
            ))}
            <FormikInput
                field="age"
                mb={34}
                label={t('account.profile.age')}
                setFieldTouched={setFieldTouched}
                initialValues={values}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
                type="number"
                dataLocator="ageInput"
                placeholder={t('account.profile.age')}
            />
            <Box mb={36}>
                <Box paddingX={12} mb={15}>
                    <Text text={t('account.profile.button.units')} type="caption" color="text-secondary" bold />
                </Box>
                <Box paddingX={10}>
                    <S.Switcher
                        defaultUnit={MEASURE_UNITS[currentUnit]}
                        data={measureUnits}
                        onChange={setUnit}
                        translatedData={measureUnits}
                    />
                </Box>
            </Box>
            <FormikInput
                field="weight"
                mb={34}
                label={t('account.profile.weight')}
                setFieldTouched={setFieldTouched}
                initialValues={values}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
                unit={WEIGHT_UNIT[currentUnit]}
                type="number"
                dataLocator={UnitTypes.Metric ? 'currentWeightKG' : 'currentWeightLbs'}
                placeholder={t('account.profile.weight')}
            />
            {currentUnit === UnitTypes.Metric && (
                <FormikInput
                    field="heightMetric"
                    label={t('account.profile.height')}
                    setFieldTouched={setFieldTouched}
                    initialValues={values}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    errors={errors}
                    type="number"
                    mb={46}
                    unit={TALL_UNIT.metric}
                    dataLocator="heightCm"
                    placeholder={t('account.profile.height')}
                />
            )}
            {currentUnit === UnitTypes.Imperial && (
                <S.ShortInputsWrapper>
                    {weightFields.map((input: weightInput) => (
                        <FormikInput
                            key={input.field}
                            setFieldTouched={setFieldTouched}
                            initialValues={values}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            values={values}
                            errors={errors}
                            type="number"
                            mb={26}
                            {...input}
                        />
                    ))}
                </S.ShortInputsWrapper>
            )}
            <Button
                onClick={handleSubmit}
                isLoading={isLoading}
                backgroundColor="primary-default"
                disabled={!Object.keys(touched).length || !isEmpty(errors)}
                mb={56}
                text={t('account.profile.button.save')}
            />
        </Box>
    );
}

export default ProfileForm;
