import { Navigate } from 'react-router-dom';
import { useRouteError, isRouteErrorResponse } from 'react-router';
import React from 'react';

import { ErrorPage } from '../components/ErrorPage';

const RootBoundary = () => {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        console.error('ROUTER ERROR');

        if (error.status === 404) {
            console.error('ROUTER | PAGE NOT FOUND');
        }

        return <Navigate to="/" />;
    }

    return <ErrorPage />;
};

export default RootBoundary;
