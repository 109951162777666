import styled from 'styled-components';

import { color } from 'theme/selectors';

export const CodeContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 12px;
`;

export const CodeInput = styled.input<{ isError: boolean }>`
    width: 70px;
    height: 56px;
    text-align: center;
    font-size: 16px;
    border-radius: 12px;
    background-color: ${color('surface-default')};
    border: ${({ isError }) => (isError ? '2px' : '1px')} solid
        ${({ isError }) => color(isError ? 'critical-highlight' : 'border-default')};
    color: ${color('text-main')};

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    [type='number'] {
        -moz-appearance: textfield;
    }
`;
