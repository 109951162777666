import { Button } from 'wikr-core-components';
import styled from 'styled-components';

import starIcon from 'assets/img/webp/star.svg';

import { color } from 'theme/selectors';
import MediaQueries from 'theme/mediaQueries';

export const PauseModalContent = styled.div`
    height: 100%;
    padding: 83px 8px 32px;
    overflow: hidden;
    background: ${color('surface-main')};

    ${MediaQueries.largeTablet} {
        padding: 83px 16px 32px;
    }
`;

export const ModalHeaderTitle = styled.div`
    position: fixed;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding: 16px;
    background: ${color('surface-main')};
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    border-bottom: 1px solid ${color('border-default')};

    ${MediaQueries.laptop} {
        padding: 16px 40px;
    }
`;

export const TitleText = styled.div`
    flex: 1;
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${color('surface-secondary')};
    height: 44px;
    width: 44px;
    border-radius: 50%;

    &:hover {
        cursor: pointer;
    }
`;

export const ConfirmButton = styled(Button)`
    border: 1px solid ${color('border-default')};
    padding: 20px;
    background-color: transparent;
    color: ${color('border-default')};
    max-width: 100%;

    &:hover {
        background-color: ${color('surface-hovered')} !important;
        color: ${color('surface-hovered')} !important;
    }
`;

export const PauseCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 20px;
    overflow: hidden;
    background: ${color('surface-default')};
    margin-bottom: 24px;
`;
export const CardHeader = styled.div`
    width: 100%;
    padding: 0 20px;
    background: ${color('safe')};
`;

export const CardBody = styled.div`
    padding: 20px 16px 16px 16px;
    width: 100%;
`;

export const PauseButton = styled(Button)`
    margin-top: 24px;
    width: 100%;
`;

export const DetailsList = styled.ul`
    padding-left: 0;

    li {
        padding-left: 21px;
        position: relative;
        margin-bottom: 12px;

        &:before {
            width: 16px;
            height: 16px;
            content: '';
            position: absolute;
            left: 0;
            top: 3px;
            background: url(${starIcon}) center center no-repeat;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;
