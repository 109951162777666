import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { setToken } from 'api/utils/tokenManagement';
import { getAwsId, setAwsId } from 'api/utils/awsIdManagement';

import { UserSource } from 'store/auth/types';
import { authentication, authenticationBySignature, setUserSource } from 'store/auth/actions';

import { AVOID_ONLY_APP_LOGGED_IN_KEY } from 'constants/user';
import { AUTH_TOKEN_NAME } from 'constants/api';
import { URL_PARAMS, USER_FROM_CRM } from './constants';

import { initAnalytics } from 'services/Analytics';

import { useDispatch } from 'hooks/store';

export const useAuthentication = () => {
    const dispatch = useDispatch();

    const { search } = useLocation();

    const params = new URLSearchParams(search);

    const {
        USER_ID_PARAM_NAME,
        SIGNATURE_PARAM_NAME,
        USER_SOURCE_PARAM_NAME,
        AWS_ID_PARAM_NAME,
        UTM_SOURCE,
        ANALYTIC_UUID,
    } = URL_PARAMS;

    const handleUtmSource = () => {
        const utmSource: UserSource | null = params.get(UTM_SOURCE) as UserSource | null;

        if (utmSource) {
            localStorage.setItem('utmSource', utmSource.toUpperCase());

            if (utmSource === USER_FROM_CRM) dispatch(setUserSource(USER_FROM_CRM));

            return;
        }

        const utmSourceFromStorage = localStorage.getItem('utmSource');

        if (utmSourceFromStorage && utmSourceFromStorage === USER_FROM_CRM) {
            dispatch(setUserSource(USER_FROM_CRM));
        }
    };

    const handleAnalyticInit = () => {
        const awsIdFromParams = params.get(AWS_ID_PARAM_NAME);
        let storedAwsId = getAwsId();

        if (awsIdFromParams && awsIdFromParams !== storedAwsId) {
            storedAwsId = awsIdFromParams;
            setAwsId(awsIdFromParams);
        }

        initAnalytics(storedAwsId);
    };

    useEffect(() => {
        const uId = params.get(USER_ID_PARAM_NAME);
        const signature = params.get(SIGNATURE_PARAM_NAME);
        const analyticUuid = params.get(ANALYTIC_UUID) || undefined;

        // If url contains this parameter, we skip the blocking of the cabinet for users which are not logged in the app
        const avoidBlockingAppKey = params.get(AVOID_ONLY_APP_LOGGED_IN_KEY);

        localStorage.setItem('urlParams', JSON.stringify(Object.fromEntries(params)));
        analyticUuid && localStorage.setItem(ANALYTIC_UUID, analyticUuid);

        handleUtmSource();

        if (avoidBlockingAppKey) {
            localStorage.setItem(AVOID_ONLY_APP_LOGGED_IN_KEY, 'true');
        }

        handleAnalyticInit();

        if (uId && signature) {
            dispatch(
                authenticationBySignature({
                    uId,
                    signature,
                })
            );

            return;
        }

        const tokenFromUrl = params.get(AUTH_TOKEN_NAME);
        const userSource: UserSource | null = params.get(USER_SOURCE_PARAM_NAME) as UserSource | null;

        if (userSource) {
            dispatch(setUserSource(userSource));
        }

        if (tokenFromUrl) {
            setToken(tokenFromUrl);
        }

        dispatch(authentication());
    }, []);
};
