import { Main } from 'wikr-core-analytics';

import config from 'config';

import { getUserData } from 'store/user/accessors';

import { GrowthBook } from '@growthbook/growthbook';
import { setUserDataToAnalytics } from '../Analytics';

const GROWTH_BOOK_TEST_NAME = 'gb_experiment_start';

const growthBook = new GrowthBook({
    apiHost: config.GROWTHBOOK_API_HOST,
    clientKey: config.GROWTHBOOK_CLIENT_KEY,
    enableDevMode: true,
    trackingCallback: (experiment, result) => {
        const userData = getUserData();

        const growthBookTestName = `${experiment.key}.${result.variationId}`;

        setUserDataToAnalytics({
            id: Number(userData.id),
            gender: userData.gender,
            country: userData.country || '',
            growthBookTestName,
            abTestName: userData?.ab_test_name,
            region: userData.geo_region_code,
        });

        Main.customData(GROWTH_BOOK_TEST_NAME);
    },
});

export { growthBook, GROWTH_BOOK_TEST_NAME };
