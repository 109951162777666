import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const Minimize = ({ theme, color, size = 20 }: { theme: Theme; color?: Colors; size?: number }) => {
    const iconColor = theme[color || 'border-default'];

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66667 1.75C7.08088 1.75 7.41667 2.08579 7.41667 2.5V5C7.41667 5.64094 7.16205 6.25563 6.70884 6.70884C6.25563 7.16205 5.64094 7.41667 5 7.41667H2.5C2.08579 7.41667 1.75 7.08088 1.75 6.66667C1.75 6.25245 2.08579 5.91667 2.5 5.91667H5C5.24312 5.91667 5.47627 5.82009 5.64818 5.64818C5.82009 5.47627 5.91667 5.24312 5.91667 5V2.5C5.91667 2.08579 6.25245 1.75 6.66667 1.75ZM13.3333 1.75C13.7475 1.75 14.0833 2.08579 14.0833 2.5V5C14.0833 5.24311 14.1799 5.47627 14.3518 5.64818C14.5237 5.82009 14.7569 5.91667 15 5.91667H17.5C17.9142 5.91667 18.25 6.25245 18.25 6.66667C18.25 7.08088 17.9142 7.41667 17.5 7.41667H15C14.3591 7.41667 13.7444 7.16205 13.2912 6.70884C12.8379 6.25563 12.5833 5.64094 12.5833 5V2.5C12.5833 2.08579 12.9191 1.75 13.3333 1.75ZM1.75 13.3333C1.75 12.9191 2.08579 12.5833 2.5 12.5833H5C5.64094 12.5833 6.25563 12.8379 6.70884 13.2912C7.16205 13.7444 7.41667 14.3591 7.41667 15V17.5C7.41667 17.9142 7.08088 18.25 6.66667 18.25C6.25245 18.25 5.91667 17.9142 5.91667 17.5V15C5.91667 14.7569 5.82009 14.5237 5.64818 14.3518C5.47627 14.1799 5.24311 14.0833 5 14.0833H2.5C2.08579 14.0833 1.75 13.7475 1.75 13.3333ZM15 14.0833C14.7569 14.0833 14.5237 14.1799 14.3518 14.3518C14.1799 14.5237 14.0833 14.7569 14.0833 15V17.5C14.0833 17.9142 13.7475 18.25 13.3333 18.25C12.9191 18.25 12.5833 17.9142 12.5833 17.5V15C12.5833 14.3591 12.8379 13.7444 13.2912 13.2912C13.7444 12.8379 14.3591 12.5833 15 12.5833H17.5C17.9142 12.5833 18.25 12.9191 18.25 13.3333C18.25 13.7475 17.9142 14.0833 17.5 14.0833H15Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(Minimize);
